import React, { useState, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import ReactTableBaseVehiclePage from 'src/components/tables/newtable/table/ReactTableBaseVehiclePage';
import { makeStyles } from '@material-ui/core/styles';
import { QueryVehiclePosition } from 'src/services/vehicle-pages-http.service';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import { GetFleets } from 'src/services/fleet-http.service'
import { Typography } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { useTableStyles } from 'src/assets/jss/components/tableStyle';
import VehiclePageDialog from 'src/components/form-elements/vehiclePage-dialog.component';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import SearchButton from 'src/components/buttons/search-button-component';
import LoadingImagePlaceHolder from 'src/components/placeholder/loadingimageplaceholder';
import CSVExport from 'src/services/csv-export.service';
import Modal from "@mui/material/Modal";
import { QueryRsaReport } from 'src/services/rsa-report-http.service';
import moment from 'moment';

let rowsPerPage = 50;

let newPageVariable = 1;

let vin = '';

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const useDrawerStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },

    drawer: {
        width: '100%',
        flexShrink: 0,
        marginTop: '100px',
        alignItems: 'center',
        position: 'absolute',
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
    },
    gridContainerStyle: {
        padding: '40px',
        position: 'sticky',
        height: '200px',
    }
}));

const rowData = [
    {
        "firstName'": "James",
        "lastName'": "Omoya",
        "customerNumber'": "100041281",
        "role'": "Primary Driver",
        "provisioned'": "2022-10-21",
        "unprovisioned'": "",
    },
    {
        "firstName'": "Alexander Scott",
        "lastName'": "Crayvenn",
        "customerNumber'": "100041282",
        "role'": "Designated Driver",
        "provisioned'": "2022-10-23",
        "unprovisioned'": "2023-01-19",
    },
    {
        "firstName'": "Ese",
        "lastName'": "Agadagba",
        "customerNumber'": "100041283",
        "role'": "Primary Driver",
        "provisioned'": "2021-08-12",
        "unprovisioned'": "2022-09-05",
    },
    {
        "firstName'": "Johnson",
        "lastName'": "Adesanya",
        "customerNumber'": "100041285",
        "role'": "Delegated Driver",
        "provisioned'": "2021-08-15",
        "unprovisioned'": "2022-09-05",
    },
    {
        "firstName'": "Emmanuel",
        "lastName'": "Omoya",
        "customerNumber'": "100041287",
        "role'": "Delegated Driver",
        "provisioned'": "2021-08-17",
        "unprovisioned'": "2022-09-05",
    },
    {
        "firstName'": "Albert",
        "lastName'": "Osaro",
        "customerNumber'": "100041290",
        "role'": "Delegated Driver",
        "provisioned'": "2021-08-19",
        "unprovisioned'": "2022-09-05",
    },
]

const ConnectivityTable = ({ userOrg, userMarketIds }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const classes = useTableStyles();
    const [totalPages, setTotalPages] = React.useState(0);
    const [totalItems, setTotalItems] = React.useState(0);
    const [selected, setSelected] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [open, setOpen] = React.useState(false);

    const [openDownload, setOpenDownload] = React.useState(false);
    const imgs = ['../assets/icons/DownloadIcon.png', '../assets/icons/DownloadIcon_Green.png'];
    const [bcgImg, setBcgImg] = useState(imgs[0]);


    const [rows, setVehicleFleet] = React.useState([]);

    const [selectedRow, setSelectedRow] = React.useState({});
    const [presSelectedMarketIds, setPresSelectedMarketIds] = React.useState([]);

    let selectedRowVehiclePostion = {}
    let marketIds = []

    const [earMarked, setEarMarked] = React.useState([]);
    const [isColumnHidden, setIsColumnHidden] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [isErrorDialog, setErrorDialogFlag] = React.useState(false);
    const drawerClasses = useDrawerStyles();
    const [vehiclePosition, setVeiclePosition] = React.useState({
        "vin": "",
        "latitude": 0,
        "longitude": 0,
        "altitude": 0,
        "canBeTrusted": false,
        "vehicleUpdatedAt": ""
    });
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedMarket, setSelectedMarket] = useState({ key: "NONE", value: "" })
    const [queryParamsVin, setQueryParamsVin] = React.useState('')
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [downloadSpinner, setDownloadSpinner] = React.useState(false);

    const [tableHight, setTableHeight] = useState(false);

    const columns = useMemo(
        () => [
            { Header: 'First Name', accessor: "firstName", disableGlobalFilter: true },
            { Header: 'Last Name', accessor: "LastName", disableGlobalFilter: true },
            { Header: 'Customer Number', accessor: "customerNumber", disableGlobalFilter: true },
            { Header: 'Role', accessor: "role", disableGlobalFilter: true, },
            { Header: 'Provisioned', accessor: "provisioned", disableGlobalFilter: true },
            { Header: 'Unprovisioned', accessor: "unprovisioned", disableGlobalFilter: true, }
        ],
        [],
    );

    React.useEffect(() => {
        setTableHeight(true);
        marketIds = userMarketIds;
        setPresSelectedMarketIds(marketIds)
        setOpenDialog(false);
        if (queryParamsVin.length === 0) {
            setQueryParamsVin('')
            initializeFleetVehicles(marketIds);
        }
    }, [queryParamsVin]);

    const initializeFleetVehicles = (marketIds) => {

        let vins = [];
        let vehicleFleetArray = [];

        QueryRsaReport({ pageNumber: newPageVariable, pageSize: rowsPerPage, vins }).then(data => {
            if (data && Array.isArray(data.items)) {
                setTotalPages(data.totalPages)
                setTotalItems(data.itemCount ?? 0)
                rowData.map(vehicle => {
                    vehicleFleetArray.push({
                        firstName: vehicle["firstName'"],
                        LastName: vehicle["lastName'"],
                        customerNumber: vehicle["customerNumber'"],
                        role: vehicle["role'"],
                        provisioned: vehicle["provisioned'"],
                        unprovisioned: vehicle["unprovisioned'"],
                    })
                })
                setDataLoaded(true)
                setVehicleFleet(vehicleFleetArray);
            }
        })
    };

    const header = [
        [
            'License Plate',
            'VIN',
            'Make',
            'Model',
            'First Date of Registration',
            'Coverage Valid From',
            'Coverage Valid To',
            'Reg. Country',
            'Length of RSA Month',
            'Vehicle Type'
        ]
    ]

    const handleClickDownloadExcel = () => {
        setDownloadSpinner(true);

        let vins = [];
        let vehicleFleetArray = [];

        QueryRsaReport({ pageNumber: 0, pageSize: 0, vins }).then(data => {
            if (data && Array.isArray(data.items)) {
                data.items.map(vehicle => {
                    vehicleFleetArray.push({
                        licensePlate: vehicle.licensePlate,
                        vin: vehicle.vin,
                        make: vehicle.make,
                        model: vehicle.model,
                        firstRegDate: moment(vehicle.firstRegDate).format("YYYY-MM-DD"),
                        coverageValidFrom: moment(vehicle.coverageValidFrom).format("YYYY-MM-DD"),
                        coverageValidTo: moment(vehicle.coverageValidTo).format("YYYY-MM-DD"),
                        regCountry: vehicle.regCountry,
                        rsaMonthLength: vehicle.rsaMonthLength,
                        vehicleType: vehicle.vehicleType,
                    })
                })
                initiateDownload(vehicleFleetArray);
                setDownloadSpinner(false);
            }
        })

        const initiateDownload = (rows) => {
            const fileName = 'RSA Report';

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            const fileExtension = '.xlsx';
            const exportToExcel = (rows, fileName) => {

                const ws = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(ws, header);

                XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });

                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                const data = new Blob([excelBuffer], { type: fileType });

                FileSaver.saveAs(data, fileName + fileExtension);

            }
            exportToExcel(rows, fileName);
        }
        setOpen(false);
    }

    const handleClickDownloadCSV = () => {
        setDownloadSpinner(true);

        let vins = [];
        let vehicleFleetArray = [];

        QueryRsaReport({ pageNumber: 0, pageSize: 0, vins }).then(data => {
            if (data && Array.isArray(data.items)) {
                data.items.map(vehicle => {
                    vehicleFleetArray.push({
                        licensePlate: vehicle.licensePlate,
                        vin: vehicle.vin,
                        make: vehicle.make,
                        model: vehicle.model,
                        firstRegDate: moment(vehicle.firstRegDate).format("YYYY-MM-DD"),
                        coverageValidFrom: moment(vehicle.coverageValidFrom).format("YYYY-MM-DD"),
                        coverageValidTo: moment(vehicle.coverageValidTo).format("YYYY-MM-DD"),
                        regCountry: vehicle.regCountry,
                        rsaMonthLength: vehicle.rsaMonthLength,
                        vehicleType: vehicle.vehicleType,
                    })
                })
                initiateDownload(vehicleFleetArray);
                setDownloadSpinner(false);
            }
        })

        const initiateDownload = (rows) => {
            CSVExport(header, rows, 'RSA Report')
        }
        setOpen(false);
    };

    //////////////////////////////////////////////////////////////////////

    const handleExtendedSearch = () => {

        let vins = [queryParamsVin.trim()];
        let vehicleFleetArray = [];

        QueryRsaReport({ pageNumber: newPageVariable, pageSize: rowsPerPage, vins }).then(data => {
            if (data && Array.isArray(data.items)) {
                data.items.map(vehicle => {
                    vehicleFleetArray.push({
                        licensePlate: vehicle.licensePlate,
                        vin: vehicle.vin,
                        make: vehicle.make,
                        model: vehicle.model,
                        firstRegDate: moment(vehicle.firstRegDate).format("YYYY-MM-DD"),
                        coverageValidFrom: moment(vehicle.coverageValidFrom).format("YYYY-MM-DD"),
                        coverageValidTo: moment(vehicle.coverageValidTo).format("YYYY-MM-DD"),
                        regCountry: vehicle.regCountry,
                        rsaMonthLength: vehicle.rsaMonthLength,
                        vehicleType: vehicle.vehicleType,
                    })
                })
                setVehicleFleet(vehicleFleetArray);
            }
        })
    }

    /////////////////////////////////////////////////////////////////////

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setDownloadSpinner(false);
        setOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setDataLoaded(false);
        newPageVariable = newPage + 1
        setPage(newPage);
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        initializeFleetVehicles(marketIds);
        setOpen(false);
    };

    const handleChangeRowsPerPage = (event) => {
        setDataLoaded(false);
        newPageVariable = 1
        rowsPerPage = parseInt(event.target.value, 10);
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        initializeFleetVehicles(marketIds);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const handleDialogOk = () => {
        setOpenDialog(false);
    }

    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setVehicleFleet(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setVehicleFleet(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    const earmarkedRowValues = (row) => {
        let currentRow = earMarked.find((item) => item.id === row.values.vin);
        let usedCar = row.values.usedCarIsB2B == 'Yes' ? true : false;

        if (currentRow) {
            let filterArray = earMarked.filter((item) => item.id != row.values.vin)
            setEarMarked([...filterArray])
        }
        else {
            setEarMarked([...earMarked, { id: row.values.vin, status: !usedCar }])
        }
    }

    const selectedRowValues = (row) => {
        setSelectedRow({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            lastMileStatus: row.values.lastMileStatus,
            orderStatus: row.values.orderStatus,
            fleetType: row.values.fleetType,
            age: row.values.age,
            inventoryState: row.values.inventoryState,
            usedCarStatus: row.values.usedCarStatus,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber,
        });
        setSelected({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            lastMileStatus: row.values.lastMileStatus,
            orderStatus: row.values.orderStatus,
            fleetType: row.values.fleetType,
            age: row.values.age,
            inventoryState: row.values.inventoryState,
            usedCarStatus: row.values.usedCarStatus,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber,
        })
        selectedRowVehiclePostion = {
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            lastMileStatus: row.values.lastMileStatus,
            orderStatus: row.values.orderStatus,
            fleetType: row.values.fleetType,
            age: row.values.age,
            inventoryState: row.values.inventoryState,
            usedCarStatus: row.values.usedCarStatus,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber
        }
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by Licence plate...',
    };

    const handleDrawar = () => {
        let fleetIds = []
        let marketIds = userMarketIds;
        vin = selectedRowVehiclePostion.vin;

        let vehiclePositionInstance = {
            "vin": "",
            "latitude": 0,
            "longitude": 0,
            "altitude": 0,
            "canBeTrusted": false,
            "vehicleUpdatedAt": ""
        }
        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })

                    QueryVehiclePosition(vin, fleetIds, marketIds)
                        .then(data => {
                            if (data) {
                                if (data.ok == true || data.ok == false) {
                                    vehiclePositionInstance.vin = data.vehiclePosition.vin
                                    // vehiclePositionInstance.latitude = data.vehiclePosition.latitude != 0 ? data.vehiclePosition.latitude : data.vehiclePosition.vin == '10023989223924' ? 57.7063469511633 : 52.36024118939894  //To be used for Development only
                                    // vehiclePositionInstance.longitude = data.vehiclePosition.longitude != 0 ? data.vehiclePosition.longitude : data.vehiclePosition.vin == '10023989223924' ? 11.940632860416727 : 4.885066435353499 //To be used for Development only
                                    vehiclePositionInstance.latitude = data.vehiclePosition.latitude
                                    vehiclePositionInstance.longitude = data.vehiclePosition.longitude
                                    vehiclePositionInstance.altitude = data.vehiclePosition.altitude
                                    vehiclePositionInstance.canBeTrusted = data.vehiclePosition.canBeTrusted
                                    // vehiclePositionInstance.vehicleUpdatedAt = data.vehiclePosition.vehicleUpdatedAt != null ? data.vehiclePosition.vehicleUpdatedAt : '2022-03-11T08:36:27Z'
                                    vehiclePositionInstance.vehicleUpdatedAt = data.vehiclePosition.vehicleUpdatedAt
                                    setVeiclePosition(vehiclePositionInstance);
                                }
                            }
                        })
                }
            })
    }

    const closeHandleDrawer = () => {
        setOpen(false);
    }

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            handleExtendedSearch();
        }
    }

    const handleSearchInput = (event) => {
        setQueryParamsVin(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
    }

    const handleCloseDownloadIcon = () => {
        setBcgImg(imgs[0]);
        setOpenDownload(false)
    };

    const handleOpenDownloadIcon = () => {
        setBcgImg(imgs[1]);
        setOpenDownload(true)
    };

    return (
        <Col md={12} lg={12}>

            <VehiclePageDialog
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
                isErrorDialog={isErrorDialog} />
            <Modal
                className={drawerClasses.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleClose}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
            >
                <div style={{
                    border: '2px solid rgb(64, 64, 64)', height: '170px', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', padding: '20px'
                }}>
                    <Typography style={{ textAlign: 'center', fontSize: '24px', fontFamily: "LynkcoTypeRegular", cursor: 'pointer', marginTop: '-80px', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex' }} onClick={() => handleDrawar('fnl')} variant="h4" id="tableTitle" component="div">
                        Download Report
                    </Typography>
                    <Row style={{ marginBottom: '-40px' }}>
                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'Excel'}
                                handleSearch={() => handleClickDownloadExcel(rows)}
                                height='true'
                            />
                        </Col>

                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'CSV'}
                                handleSearch={() => handleClickDownloadCSV(rows)}
                                height='true'
                            />
                        </Col>

                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'Cancel'}
                                handleSearch={handleClose}
                                height='true'
                                rsa='true'
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>
            <div >
                <div className="react-table__wrapper">
                    <div className="card__title">
                    </div>
                </div>
                <div style={{ marginTop: '140px', marginBottom: '51px' }}>
                </div>
            </div>

            <>
                <div style={{ backgroundColor: 'transparent', marginTop: '-140px', borderRadius: '4px' }} >
                    <>
                        {dataLoaded ? (<>
                            <ReactTableBaseVehiclePage
                                key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                columns={columns}
                                data={rows}
                                updateEditableData={updateEditableData}
                                updateDraggableData={updateDraggableData}
                                tableConfig={tableConfig}
                                isColumnHidden={isColumnHidden}
                                isSelected={isSelected}
                                selectedRowValues={selectedRowValues}
                                earmarkedRowValues={earmarkedRowValues}
                                earMarkedRows={earMarked}
                                handleDrawar={handleDrawar}
                                closeHandleDrawer={closeHandleDrawer}
                                vehiclePosition={vehiclePosition}
                                selected={selected}
                                tableHight={tableHight}
                            />

                        </>) :
                            <div style={{ marginTop: '33px', marginBottom: '70px' }}>
                                <LoadingImagePlaceHolder />
                            </div>
                        }
                        <div style={{ display: 'flex', margin: '5px 0 -20px 0' }}>
                            <Typography style={{ fontFamily: 'LynkcoTypeLight', color: '#fff', fontSize: '14px', marginLeft: '9px', marginTop: '16px' }}>Total Records: {totalItems}</Typography>
                            <TablePagination
                                style={{ flex: 'auto', marginRight: '-20px', }}
                                classes={{
                                    toolbar: classes.paginationToolbar,
                                    caption: classes.paginationCaption
                                }}
                                SelectProps={{
                                    MenuProps: {
                                        classes: { paper: classes.selectStyle },
                                        disableScrollLock: true,
                                    }
                                }}
                                rowsPerPageOptions={[50, 100, 150, 200]}
                                component="div"
                                count={totalPages * rowsPerPage}
                                rowsPerPage={rowsPerPage}
                                page={newPageVariable - 1}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={<span>Rows per page:</span>} labelDisplayedRows={({ page }) => { return `Page: ${newPageVariable} of ${totalPages}` }}
                            />
                        </div>
                    </>
                </div>
            </>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(ConnectivityTable);