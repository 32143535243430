import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, InfoWindow, Marker, Autocomplete } from "@react-google-maps/api";
import SearchBar from 'src/components/buttons/search-icon-button.component';
import InfoWindowComponentMap from "src/components/maps/infoWindowComponentMap";
import { Geocoding } from 'src/services/geocoder.service';
import { GetFleets } from 'src/services/fleet-http.service';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import VehiclePageDialog from 'src/components/form-elements/vehiclePage-dialog.component';
import { QueryVehiclePositionByMarket, QueryVehiclePositionForMapView } from 'src/services/vehicle-pages-http.service';
import { styles } from 'src/utils/MapStyle';
import PuffLoader from "react-spinners/PuffLoader";
import { Col, Row } from 'reactstrap';
import FormSelect from 'src/components/form-elements/custom-select.component';
import { makeStyles } from '@material-ui/core/styles';
import CarsAtMCC from 'src/containers/advancedSearchComponents/CarsAtMCC';
import CarsAtLCC from 'src/containers/advancedSearchComponents/CarsAtLCC';
import MarketCard from 'src/containers/advancedSearchComponents/MarketCard';
import { MCCData, LCCData, VehicleData, VehicleDataMCCLCC } from "./mockData";
import InfoWindowComponentVSP from "./infoWindowComponentVSP";

const useDrawerStyles = makeStyles({
    root2: {
        overflow: 'auto',
        height: '84.6vh',
        paddingRight: '6px',
        marginLeft: '18px',
        marginRight: '-55px',
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 1%)',
            border: 'none'
        },
        overflowX: 'hidden'
    },
    rotateIcon: {
        animation: "$bounce 2s linear infinite"
    },
    "@keyframes bounce": {
        "0%, 20%, 50%, 80%, 100%": {
            transform: "translateY(0)"
        },
        "40%": {
            transform: "translateY(-10px)"
        },
        "60%": {
            transform: "translateY(-5px)"
        }
    }
});

const Map = ({ userOrg, userMarketIds, mapheight, vehiclePositionSVT, multiMarketSelect = false, toggleInfoWindowMarker = true }) => {

    const drawerClasses = useDrawerStyles();

    const [activeMarker, setActiveMarker] = useState(null);
    const [addressInput, setAddressInput] = useState('')
    const [vehiclePosition, setVeiclePosition] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [markers, setMarkers] = useState([]);
    const [searchOnMapMarkers, setSearchOnMapMarkers] = useState([]);
    const [derivedLatLng, setDerivedLatLng] = useState({ lat: 57.71089814294498, lng: 11.945829642768519 });
    const [mapSpinner, setMapSpinner] = useState(false);
    const [message, setMessage] = useState('');
    const [searchResult, setSearchResult] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [showLCC, setShowLCC] = useState(false);
    const [showMCC, setShowMCC] = useState(false);
    const [toggleInfoWindow, setToggleInfoWindow] = useState(false);
    const [multiMarketToggleLCC, setMultiMarketToggleLCC] = useState(false);
    const [multiMarketToggleMCC, setMultiMarketToggleMCC] = useState(false);
    const [inputFieldMarker, setInputFieldMarker] = useState(false);
    const [queryParamsMarketIds, setQueryParamsMarketIds] = useState([]);
    const [map, setMap] = useState(null);
    const [disableButtons, setDisablebuttons] = useState(false);

    useEffect(() => {
        if (map) {
            if (markers.length > 1) {
                const bounds = new window.google.maps.LatLngBounds();
                markers?.map(marker => {
                    bounds.extend({ lat: parseFloat(marker.position.lat), lng: parseFloat(marker.position.lng) });
                });
                map.fitBounds(bounds);
            }
            if (markers.length == 0) {
                // const bounds = new window.google.maps.LatLngBounds();
                // markers?.map(marker => {
                setDerivedLatLng({ lat: 57.71089814294498, lng: 11.945829642768519 });
                map.setOptions({
                    disableDefaultUI: true,
                    zoomControl: true,
                    styles: styles,
                    zoom: 13
                });
            }
            map.setOptions({
                disableDefaultUI: true,
                zoomControl: true,
                styles: styles,
            });
        }
        if (!disableButtons) {
            setShowLCC(false);
            setShowMCC(false);
            setMarkers([]);
        }
        if (disableButtons && !showLCC && !showMCC) {
            setToggleInfoWindow(true);
        }
    }, [map, markers.length, inputFieldMarker, disableButtons, toggleInfoWindow]);

    const handleActiveMarker = (marker) => {
        let fleetIds = []
        let marketIds = userMarketIds;
        let vin = marker
        let vehiclePositionInstance = {
            "vin": "",
            "latitude": 0,
            "longitude": 0,
            "vehicleUpdatedAt": "",
            "color": "",
            "odometer": 0,
            "street": "",
            "postalCode": "",
            "city": "",
            "country": "",
        }
        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })

                    QueryVehiclePositionForMapView(vin, fleetIds, marketIds)
                        .then(data => {
                            if (data.ok == true) {
                                vehiclePositionInstance.vin = data.vehiclePosition.vin
                                vehiclePositionInstance.latitude = data.vehiclePosition.latitude
                                vehiclePositionInstance.longitude = data.vehiclePosition.longitude
                                vehiclePositionInstance.vehicleUpdatedAt = data.vehiclePosition.vehicleUpdatedAt
                                vehiclePositionInstance.color = data.vehiclePosition.color
                                vehiclePositionInstance.odometer = data.vehiclePosition.odometer
                                vehiclePositionInstance.street = data.vehiclePosition.addressData.street
                                vehiclePositionInstance.postalCode = data.vehiclePosition.addressData.postalCode
                                vehiclePositionInstance.city = data.vehiclePosition.addressData.city
                                vehiclePositionInstance.country = data.vehiclePosition.addressData.country
                                setVeiclePosition(vehiclePositionInstance);
                            }
                        })
                }
            })

        // }
        setActiveMarker(marker);
    };

    const onLoad = useCallback((map) => setMap(map), []);

    const handleOnLoad = (map) => {
        // const bounds = new window.google.maps.LatLngBounds();
        // markers.forEach(({ position }) => bounds.extend(position));
        // map.fitBounds(bounds);
        map.setOptions({
            disableDefaultUI: true,
            zoomControl: true,
            styles: styles,
        });
    };

    const handleSearch = (address) => {

        if (addressInput.trim()?.length != 0) {
            setMapSpinner(true);
            let marketId = userMarketIds;
            Geocoding({ address }).then(data => {
                if (data.status == 'ok') {
                    setMapSpinner(false);
                    if (data.message && Array.isArray(data.message)) {

                        const retrieveCountry = data.message[0].address_components.find(address => address['types'].includes('country'))['short_name']
                        const retrieveLatLog = data.message[0].geometry['location']

                        setDerivedLatLng(retrieveLatLog)
                        setDerivedLatLng('retrieveLatLog')
                        const matchesMarket = marketId.find(element => {
                            if (element.includes(retrieveCountry)) {
                                return true;
                            }
                        });
                        if (matchesMarket !== undefined) {
                            let fleetIds = []
                            let vehiclePositionArray = [];
                            let marketIds = userMarketIds;

                            GetFleets(userOrg.id, userMarketIds)
                                .then(data => {
                                    if (data && Array.isArray(data)) {
                                        data.map(item => {
                                            fleetIds.push(item.id);
                                        })

                                        QueryVehiclePositionByMarket(fleetIds, marketIds, matchesMarket)
                                            .then(data => {
                                                if (data.ok == true) {

                                                    data.vehiclePositions.map(vehicle => {
                                                        vehiclePositionArray.push({
                                                            vin: vehicle.vin,
                                                            position: { lat: vehicle.latitude, lng: vehicle.longitude },


                                                        });
                                                    });

                                                }
                                                setSearchOnMapMarkers(vehiclePositionArray)
                                            })
                                    }
                                })
                        } else {
                            setDialogMessage('Selected location is not part of your market. Please try again');
                            setOpenDialog(true);
                            setDerivedLatLng({ lat: 57.71089814294498, lng: 11.945829642768519 });
                            setSearchOnMapMarkers([]);
                        }
                        setAddressInput('')
                    }
                } else {
                    setDialogMessage('Invalid location, please try again')
                    setOpenDialog(true);
                }
            })
        }
    }

    const handleSearchInput = (event) => {
        setAddressInput(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
    };

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const onPlaceChanged = () => {
        if (searchResult != null) {
            const address = searchResult.getPlace().formatted_address;
            handleSearch(address);
        }
    };

    const onLoadAddress = (autocomplete) => {
        setSearchResult(autocomplete);
    };

    const haandleDropdown = (e) => {
        const { name, value } = e.target;
        if (value == 1) {
            setIsExpanded(!isExpanded);
            setMarkers([]);
            setSearchOnMapMarkers([]);
        }
    };

    const handlePositionMCC = (type) => {
        let updatedData = [];

        if (type == 'MCC' && !showLCC) {
            updatedData = [...MCCData];
            setToggleInfoWindow(false);
        }
        if (type == 'false' && !showLCC) {
            updatedData = [...VehicleData];
            setToggleInfoWindow(true);
        }
        if (type == 'false' && showLCC) {
            updatedData = markers.filter((item) => item.type == 'LCC');
        }
        if (type == 'MCC' && showLCC) {
            updatedData = [...MCCData, ...LCCData];
            setToggleInfoWindow(false);
        }
        setMarkers(updatedData);
    };

    const handlePositionLCC = (type) => {
        let updatedData = [];

        if (type == 'LCC' && !showMCC) {
            updatedData = [...LCCData];
            setToggleInfoWindow(false);
        }
        if (type == 'false' && !showMCC) {
            updatedData = [...VehicleData];
            setToggleInfoWindow(true);
        }
        if (type == 'false' && showMCC) {
            updatedData = markers.filter((item) => item.type == 'MCC');
        }
        if (type == 'LCC' && showMCC) {
            updatedData = [...LCCData, ...MCCData];
            setToggleInfoWindow(false);
        }
        setMarkers(updatedData);
    };

    const handleChange = () => {
        setToggleInfoWindow(true);
        setMarkers(VehicleDataMCCLCC);
    };

    return (
        <Col md={12} lg={12}>
            <>
                <VehiclePageDialog
                    dialogue='true'
                    open={openDialog}
                    message={dialogMessage}
                    handleDialogOk={handleDialogOk}
                    title={message}
                />
                <>
                    {
                        isExpanded ?
                            <Row>
                                <Col md={12} sm={12} xs={12} lg={10}>

                                    <Row style={{ margin: '15px -10px -15px 0', display: 'block', flexWrap: 'wrap' }}>
                                        {
                                            mapSpinner ?

                                                <div style={{ position: 'absolute', width: '99.1%', height: '82vh', marginRight: '-35px', marginLeft: '18px', alignItems: 'center', backgroundColor: '#212121', marginTop: '150px', borderWidth: '11px', borderStyle: 'solid', borderImage: mapheight ? 'linear-gradient(135deg, #101010 20%, #373737 99%) 1' : 'linear-gradient(135deg, #000, #000) 1', borderBottomLeftRadius: '10px', display: 'flex', justifyContent: 'center' }}>
                                                    <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                                                        <PuffLoader color="#1EF1C6" size={200} />
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {
                                                        vehiclePositionSVT?.latitude === null || vehiclePositionSVT?.longitude === null ?
                                                            <div style={{ position: 'absolute', width: '99.1%', height: '720px', marginLeft: '-0.9vw', alignItems: 'center', backgroundColor: '#212121', marginTop: '-4px', display: 'flex', justifyContent: 'center' }}>
                                                                <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                                                                    Vehicle Position Not Available
                                                                </div>
                                                            </div>
                                                            :
                                                            <GoogleMap
                                                                onLoad={onLoad}
                                                                onClick={() => setActiveMarker(null)}
                                                                mapContainerStyle={{ height: "83vh", marginRight: '-5px', marginTop: '150px', borderRadius: '4px', boxShadow: '3px 5px 6px #000000' }}
                                                                center={derivedLatLng}
                                                                zoom={13}

                                                            >
                                                                <Col lg="12" md="12" >
                                                                    <Row style={{ margin: '15px -10px -15px 0', display: 'block', flexWrap: 'wrap' }}>
                                                                        <span>
                                                                            {
                                                                                isExpanded ? <></> :
                                                                                    <span style={{ fontSize: '18px', float: 'left', color: 'darkgray' }}>
                                                                                        <div style={{ width: '500%', marginTop: '20px', marginLeft: '10px' }}>
                                                                                            <Autocomplete
                                                                                                onPlaceChanged={onPlaceChanged}
                                                                                                onLoad={onLoadAddress}
                                                                                            >
                                                                                                <SearchBar
                                                                                                    handleSearch={handleSearch}
                                                                                                    handleSearchInput={handleSearchInput}
                                                                                                    onKeyPress={textFieldKeypress}
                                                                                                    placeholder={'Search on map'}

                                                                                                />
                                                                                            </Autocomplete>
                                                                                        </div>
                                                                                    </span>
                                                                            }

                                                                        </span>

                                                                        <span style={{ fontSize: '18px', float: 'right', color: 'darkgray', marginTop: '7px' }}>
                                                                            {

                                                                                <FormSelect
                                                                                    menuObjectArray={[
                                                                                        { 'key': '1', 'value': 'Close Advanced Search' },
                                                                                    ]}
                                                                                    onClick={haandleDropdown}
                                                                                />
                                                                            }
                                                                        </span>
                                                                    </Row>
                                                                </Col>

                                                                {
                                                                    toggleInfoWindow ?
                                                                        <>
                                                                            {markers.map((value, index) => (
                                                                                <Marker
                                                                                    key={index}
                                                                                    position={value.position}
                                                                                    onClick={() => handleActiveMarker(index)}
                                                                                >
                                                                                    {activeMarker === index ? (
                                                                                        <InfoWindow
                                                                                            onCloseClick={() => setActiveMarker(null)}
                                                                                        >
                                                                                            <div >
                                                                                                <InfoWindowComponentMap vehiclePosition={vehiclePosition} />
                                                                                            </div>
                                                                                        </InfoWindow>
                                                                                    ) : null}
                                                                                </Marker>
                                                                            ))}
                                                                        </>
                                                                        :
                                                                        <>

                                                                            {markers.map((value, index) => (
                                                                                <Marker
                                                                                    key={index}
                                                                                    position={value.position}
                                                                                    onClick={() => handleActiveMarker(index)}
                                                                                    icon={value.icon}
                                                                                >
                                                                                    {activeMarker === index ? (
                                                                                        <InfoWindow
                                                                                            onCloseClick={() => setActiveMarker(null)}
                                                                                        >

                                                                                            <InfoWindowComponentVSP
                                                                                                heading={value.heading}
                                                                                                street={value.street}
                                                                                                postalCode={value.postalCode}
                                                                                                country={value.country}
                                                                                                vehicleUpdatedAt={value.vehicleUpdatedAt}
                                                                                                handleChange={handleChange}
                                                                                                toggleInfoWindowMarker={toggleInfoWindowMarker}
                                                                                            />

                                                                                        </InfoWindow>
                                                                                    ) : null}
                                                                                </Marker>
                                                                            )
                                                                            )}
                                                                        </>
                                                                }
                                                            </GoogleMap>
                                                    }
                                                </>
                                        }
                                    </Row>
                                </Col>
                                <Col md={12} sm={12} xs={12} lg={2} style={{ marginTop: '147px' }}>
                                    <div className={drawerClasses.root2} >
                                        <div>
                                            <CarsAtLCC
                                                handlePosition={handlePositionLCC}
                                                setShowLCC={setShowLCC}
                                                disableButtons={disableButtons}
                                            />
                                        </div>

                                        <div style={{ marginTop: '-20px' }}>
                                            <CarsAtMCC
                                                handlePosition={handlePositionMCC}
                                                setShowMCC={setShowMCC}
                                                disableButtons={disableButtons}
                                            />
                                        </div>

                                        <div style={{ marginTop: '-20px' }}>
                                            <MarketCard
                                                setQueryParamsMarketIds={setQueryParamsMarketIds}
                                                multiMarketSelect={multiMarketSelect}
                                                setMarkers={setMarkers}
                                                setMultiMarketToggleMCC={setMultiMarketToggleMCC}
                                                setMultiMarketToggleLCC={setMultiMarketToggleLCC}
                                                multiMarketToggleLCC={multiMarketToggleLCC}
                                                multiMarketToggleMCC={multiMarketToggleMCC}
                                                setToggleInfoWindow={setToggleInfoWindow}
                                                setInputFieldMarker={setInputFieldMarker}
                                                inputFieldMarker={inputFieldMarker}
                                                setDisablebuttons={setDisablebuttons}
                                                setActiveMarker={setActiveMarker}
                                            />
                                        </div>

                                    </div>

                                </Col>

                            </Row>
                            :
                            <>
                                {
                                    mapSpinner ?

                                        <div style={{ position: 'absolute', width: '99.1%', height: '82vh', marginRight: '-35px', marginLeft: '18px', alignItems: 'center', backgroundColor: '#212121', marginTop: '150px', borderWidth: '11px', borderStyle: 'solid', borderImage: mapheight ? 'linear-gradient(135deg, #101010 20%, #373737 99%) 1' : 'linear-gradient(135deg, #000, #000) 1', borderBottomLeftRadius: '10px', display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                                                <PuffLoader color="#1EF1C6" size={200} />
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {
                                                vehiclePositionSVT?.latitude === null || vehiclePositionSVT?.longitude === null ?
                                                    <div style={{ position: 'absolute', width: '99.1%', height: '720px', marginLeft: '-0.9vw', alignItems: 'center', backgroundColor: '#212121', marginTop: '-4px', display: 'flex', justifyContent: 'center' }}>
                                                        <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                                                            Vehicle Position Not Available
                                                        </div>
                                                    </div>
                                                    :
                                                    <GoogleMap
                                                        onLoad={handleOnLoad}
                                                        onClick={() => setActiveMarker(null)}
                                                        mapContainerStyle={{ height: "83vh", marginRight: '-46px', marginTop: '150px', borderRadius: '4px', boxShadow: '3px 5px 6px #000000' }}
                                                        center={derivedLatLng}
                                                        zoom={13}

                                                    >
                                                        <Col lg="12" md="12" >
                                                            <Row style={{ margin: '15px -10px -15px 0', display: 'block', flexWrap: 'wrap' }}>
                                                                <span>
                                                                    <span style={{ fontSize: '18px', float: 'left', color: 'darkgray' }}>
                                                                        <div style={{ width: '500%', marginTop: '20px', marginLeft: '10px' }}>
                                                                            <Autocomplete
                                                                                onPlaceChanged={onPlaceChanged}
                                                                                onLoad={onLoadAddress}
                                                                            >
                                                                                <SearchBar
                                                                                    handleSearch={handleSearch}
                                                                                    handleSearchInput={handleSearchInput}
                                                                                    onKeyPress={textFieldKeypress}
                                                                                    placeholder={'Search on map'}

                                                                                />
                                                                            </Autocomplete>
                                                                        </div>
                                                                    </span>
                                                                </span>

                                                                <span style={{ fontSize: '18px', float: 'right', color: 'darkgray', marginTop: '7px' }}>
                                                                    {

                                                                        <FormSelect
                                                                            menuObjectArray={[
                                                                                { 'key': '1', 'value': 'Open Advanced Search' },
                                                                            ]}
                                                                            onClick={haandleDropdown}
                                                                        />
                                                                    }
                                                                </span>
                                                            </Row>
                                                        </Col>
                                                        {searchOnMapMarkers.map(({ vin, position }) => (
                                                            <Marker
                                                                key={vin}
                                                                position={position}
                                                                onClick={() => handleActiveMarker(vin)}
                                                            >
                                                                {activeMarker === vin ? (
                                                                    <InfoWindow
                                                                        onCloseClick={() => setActiveMarker(null)}
                                                                    >
                                                                        <div >
                                                                            <InfoWindowComponentMap vehiclePosition={vehiclePosition} />
                                                                        </div>
                                                                    </InfoWindow>
                                                                ) : null}
                                                            </Marker>
                                                        ))}


                                                    </GoogleMap>
                                            }
                                        </>
                                }
                            </>
                    }
                </>
            </>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(Map);