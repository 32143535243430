import React from 'react';
import { Card, CardBody, Col, Table, Row } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import './dashboard.css';

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(3.7),
        position: 'sticky',
        marginLeft: '11px',
        marginTop: '-22px'
    },
    drawer: {
        width: 250,
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px'
    },
    gridContainerStyle1: {
        width: '100%',
    },
    cardStyle: {
        height: '703px',
        borderRadius: '10px',
        marginRight: '-5px'
    },
    cardStyleLarge: {
        height: '665px',
        borderRadius: '10px',
        marginRight: '-22px'
    },
    imageStyle: {
        marginTop: '60px',
        marginLeft: '15px',
        maxHeight: '585px',
        minHeight: '500px',
        maxWidth: '36vh',
        minWidth: '23vh'
    },
}));

const DataPrimaryDriver = [
    {
        "firstName": "Alexander Scott",
        "lastName": "Crayvenn",
        "phoneNumber": "0046733456890",
        "email": "scott.crayvenn@gmail.com",
        "prefferedfLanguage": "Swedish"
    },

]

const DataPrimaryDriver1 = [
    {
        "firstName": "Frank Jr",
        "lastName": "Smith",
        "phoneNumber": "0046708679041",
        "email": "frank.smith.jr@gmail.com",
        "prefferedfLanguage": "English"
    },
    {
        "firstName": "Emma Marie",
        "lastName": "Sjöstrand Jansson",
        "phoneNumber": "0046892781801",
        "email": "emma.marie.jansson@gmail.com",
        "prefferedfLanguage": "Swedish"
    },
    {
        "firstName": "John Adams",
        "lastName": "Wilkinson",
        "phoneNumber": "0046729093718",
        "email": "john.adams@gmail.com",
        "prefferedfLanguage": "English"
    }
]

const DataPrimaryDriver2 = [
    {
        "firstName": "James Stephen",
        "lastName": "Winters",
        "phoneNumber": "0046739561681",
        "email": "john.stephen.winters@gmail.com",
        "prefferedfLanguage": "English"
    },

]

const ConnectedDrivers = () => {

    const classes = UseStyles();

    return (
        <Row>
            <Col md={12} sm={12} xs={12} lg={12}>
                <div id="ConnectedDriverCard" >
                    <CardBody>
                        <Card id='connectedDriverCard_One' >
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <div style={{ marginLeft: '18px', marginTop: '10px', marginBottom: '15px', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', textTransform: 'uppercase', paddingTop: '15px' }}>Customer and Primary Driver</div>
                                <Table id="admin">
                                    <thead >
                                        <tr >
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Phone Number</th>
                                            <th>Email</th>
                                            <th>Preferred Language</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {DataPrimaryDriver.map((item) => (
                                            <tr>
                                                <td>{item.firstName}</td>
                                                <td>{item.lastName}</td>
                                                <td>{item.phoneNumber}</td>
                                                <td>{item.email}</td>
                                                <td>{item.prefferedfLanguage}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>

                            </Col>
                        </Card>
                        {/* <hr  /> */}

                        <Card id='connectedDriverCard_two' >
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <div style={{ marginLeft: '18px', marginTop: '10px', marginBottom: '15px', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', textTransform: 'uppercase', paddingTop: '15px' }}>Secondary Drivers</div>
                                <Table id="admin">

                                    <thead >
                                        <tr >
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Phone Number</th>
                                            <th>Email</th>
                                            <th>Preferred Language</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {DataPrimaryDriver1.map((item) => (
                                            <tr>
                                                <td>{item.firstName}</td>
                                                <td>{item.lastName}</td>
                                                <td>{item.phoneNumber}</td>
                                                <td>{item.email}</td>
                                                <td>{item.prefferedfLanguage}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>

                            </Col>
                        </Card>

                        <Card id='connectedDriverCard_One' style={{ marginTop: '-5px' }}>
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <div style={{ marginLeft: '18px', marginTop: '10px', marginBottom: '15px', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', textTransform: 'uppercase', paddingTop: '15px' }}>Car Sharing Borrower</div>
                                <Table id="admin">
                                    <thead >
                                        <tr >
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Phone Number</th>
                                            <th>Email</th>
                                            <th>Preferred Language</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {DataPrimaryDriver2.map((item) => (
                                            <tr>
                                                <td>{item.firstName}</td>
                                                <td>{item.lastName}</td>
                                                <td>{item.phoneNumber}</td>
                                                <td>{item.email}</td>
                                                <td>{item.prefferedfLanguage}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            </Col>
                        </Card>
                    </CardBody>
                </div>
            </Col>
        </Row>
    );
};

export default ConnectedDrivers;