import React, { useState } from 'react';
import { useMsal } from "@azure/msal-react";

import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import PasswordField from 'src/containers/logincomponents/Password';
import SearchButton from "src/components/buttons/button.component";
import {
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from 'src/containers/logincomponents/FormElements';
import {
  AccountButton, AccountButtons, AccountForgotPassword, LoginForm,
} from 'src/containers/logincomponents/AccountElements';

import { Col, Row, Card } from 'react-bootstrap';
import { QueryUserByEmailExternalUsers } from 'src/services/user-http.service';
import { SetUserGraphData, SetAdUserDetails, SetUserServiceInfo, SetUserPermission } from 'src/redux/user/user.actions';
import { useSelector, useDispatch } from "react-redux";
import { getUniversalCookies } from 'src/cookies/cookie';
import { callMsGraph, getSignedInUserPhoto_Beta } from "src/services/graph.service";
import { connect } from 'react-redux';
import Welcome from "src/pages/welcome-page/welcome-page"
import bgImage from 'src/assets/img/BackgroundImages/BackgroundImageDark.jpg';
import styled from 'styled-components';
import { paddingLeft } from 'src/containers/LayoutNew/scrollBar/directions';
import Content from 'src/containers/Content/TheContent';
import Topbar from 'src/containers/LayoutNew/topbar/Topbar';


const LogInForm = ({ typeFieldUser }) => {

  let emailArray = ['kristell.haas@arceurope.com', 'vinogradovay@arceurope.fr '];
  let userPassword = 'ConnectedSupportSolution@lynk&Co.com';

  const [loading, setLoading] = useState(true);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);

  const [adUserDetails, SetAdUserDetails] = useState({});
  const { instance, account, inProgress } = useMsal();

  const settings = useSelector((state) => state.settings);

  const onSubmit = async values => {
    let dataValue = JSON.parse((JSON.stringify(values, 0, 2)));
    if (emailArray.indexOf(dataValue.username.toLocaleLowerCase()) > -1 && dataValue.password === userPassword) {
      QueryUserByEmailExternalUsers('fleet.management@lynkco.com').then((serviceResponse) => {
        checkoutAccount({ 'displayName': serviceResponse.firstName, 'mail': serviceResponse.email })
        serviceResponse.tokenExpiry = Date.now() + serviceResponse.tokenExpiry - 5000
        getUniversalCookies().set('RefreshToken', serviceResponse.refreshToken);
        serviceResponse && serviceResponse.organization ?
          SetUserServiceInfo(serviceResponse)
          :
          SetUserServiceInfo({
            organization: { id: "" }, role: {}
          })
        serviceResponse && serviceResponse.permissions ?
          SetUserPermission(serviceResponse.permissions)
          :
          SetUserPermission([])

        setUserIsLoggedIn(true)
      })//.finally(checkoutAccount(response));
    }
    else
      alert('Invalid user')
  }

  const checkoutAccount = (graphData) => {
    SetAdUserDetails({
      ...adUserDetails,
      accountName: graphData.displayName,
      initials: graphData.displayName.charAt(0),
      surname: '',
      givenName: '',
      displayName: graphData.displayName,
      thumbnailPhoto: '',
      email: graphData.mail,
      isAdmin: false,
      graphData: graphData
    });
    setUserPhoto(adUserDetails); // To be used to set user photo after userAD has been set successfully
    dummyAsyncCall().then(() => setLoading(false));
  }

  const setUserPhoto = (userAD) => {
    getSignedInUserPhoto_Beta().then((response) => {
      if (!response.status) {
        if (userAD) {
          userAD.thumbnailPhoto = null;
          SetAdUserDetails(userAD);
        }
      }
      else {
        response.blob.then((data) => {
          const reader = new FileReader()
          reader.readAsDataURL(data)
          reader.onload = () => {
            if (userAD) {
              const base64data = reader.result;
              userAD.thumbnailPhoto = base64data;
              SetAdUserDetails(userAD);
            }
          }
        })
      }
    });
  }

  const dummyAsyncCall = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 10500));
  }

  return (
    <>
      {
        !userIsLoggedIn ?

          <>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit }) => (
                <LoginForm onSubmit={handleSubmit}>

                  <FormGroup>
                    <FormGroupLabel>{'Username'}</FormGroupLabel>
                    <FormGroupField>
                      <FormGroupIcon>
                        <AccountOutlineIcon />
                      </FormGroupIcon>
                      <Field
                        name="username"
                        component="input"
                        type={typeFieldUser}
                        placeholder={'Username'}
                        className="input-without-border-radius"
                        style={{ borderTopRightRadius: '15px', borderBottomRightRadius: '15px', borderLeft: 'none', height: '40px' }}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Password</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="password"
                        component={PasswordField}
                        placeholder="Password"
                        className="input-without-border-radius"
                        style={{ borderRight: 'none', borderLeft: 'none', height: '40px' }}
                        keyIcon
                      />
                      <AccountForgotPassword>
                        <NavLink to="/reset_password">
                          <span style={{ fontSize: '14px', fontFamily: "LynkcoTypeLight", marginTop: '20px', color: '#fff' }}>
                            Forgot your password?
                          </span>
                        </NavLink>
                      </AccountForgotPassword>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    {/* <FormGroupField>
                <Field
                  name="remember_me"
                  render={renderCheckBoxField}
                  label="Remember me"
                  type="checkbox"
                />
              </FormGroupField> */}
                  </FormGroup>
                  {/* <AccountButtons>
              {form === 'modal_login' ? (
                <AccountButton type="submit" variant="primary">Sign In</AccountButton>
              ) : (
                <AccountButton
                  as={NavLink}
                  variant="primary"
                  to="/online_marketing_dashboard"
                >
                  Sign In
                </AccountButton>
              )}
              <AccountButton
                as={NavLink}
                variant="outline-primary"
                to="/register"
              >
                Create Account
              </AccountButton>
            </AccountButtons> */}
                  <Row style={{ marginBottom: '8px', marginTop: '25px', }}>
                    <Col lg="6" md="6">
                      <span style={{ float: 'left' }}>
                        <SearchButton
                          placeholder={'Lynk & Co SSO'}
                          iconProp='true'
                          handleSearch={() => instance.loginPopup()}
                        />
                      </span>
                    </Col>
                    <Col lg="6" md="6">
                      <span style={{ float: 'right', marginLeft: '80px' }}>
                        <SearchButton
                          placeholder={'Sign In'}
                          height='true'
                          widthProp='true'
                          type="submit"
                        />
                      </span>
                    </Col>
                  </Row>
                </LoginForm>
              )}
            </Form>
          </>
          :

          loading ?
            <>
              <div id="main-wrapper" >
                <Welcome image={bgImage}></Welcome>
              </div>
            </> :
            <>
              <div id="main-wrapper"
                dir={settings.activeDir}
                data-theme={settings.activeTheme}
                data-layout={settings.activeThemeLayout}
                data-sidebartype={settings.activeSidebarType}
                data-sidebar-position={settings.activeSidebarPos}
                data-header-position={settings.activeHeaderPos}
                data-boxed-layout={settings.activeLayout}
              >

                <LayoutContainer >

                  <Topbar
                    userName={adUserDetails.accountName}
                    adUserDetails={adUserDetails}
                  // changeMobileSidebarVisibility={mobileSidebarVisibility}
                  // changeSidebarVisibility={sidebarVisibility}
                  />

                </LayoutContainer>

                <ContainerWrap  >
                  <Content />
                </ContainerWrap>
              </div>
            </>
      }
    </>
  );
};

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  fieldUser: PropTypes.string,
  typeFieldUser: PropTypes.string,
  form: PropTypes.string.isRequired,
};

LogInForm.defaultProps = {
  errorMessage: '',
  fieldUser: 'Username',
  typeFieldUser: 'text',
};

const mapDispatchToProps = dispatch => ({
  setGraphData: graphData => dispatch(SetUserGraphData(graphData)),
  SetAdUserDetails: adUserDetails => dispatch(SetAdUserDetails(adUserDetails)),
  setUserServiceInfo: userServiceInfo => dispatch(SetUserServiceInfo(userServiceInfo)),
  setUserPermission: userPermissions => dispatch(SetUserPermission(userPermissions)),
});

export default connect(mapDispatchToProps, null)(LogInForm);

const LayoutContainer = styled.div`
  & + div {
    ${props => props.collapse && `
      ${paddingLeft(props)}: 0;
    `};

    @media screen and (min-width: 576px) {
      ${props => props.collapse && `
        ${paddingLeft(props)}: 60px;
      `}

      ${props => props.topNavigation && `
         ${paddingLeft(props)}: 0;
      `}
    }
  }
`;

const ContainerWrap = styled.div`
  margin-left: ${(props) => (props.collapse ? '200px' : paddingLeft(props))};

`;