import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Row, Col } from "reactstrap";
// import Tooltip from '@material-ui/core/Tooltip';
import Chart from "react-apexcharts";

const UsedCarStock = () => {
  const optionsvisitors = {
    labels: ["B2B", "B2C"],
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
      borderColor: "transparent",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "82px",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "18px",
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: true,
              color: "#99abb4",
            },
            total: {
              show: true,
              label: "Total",
              color: "#99abb4",
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: "transparent",
    },
    legend: {
      show: false,
    },
    colors: ["#555B6D", "#50C2F7"],
    tooltip: {
      fillSeriesColor: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
        },
      },
    ],
  };
  const seriesvisitors = [35, 27];
  return (
    /*--------------------------------------------------------------------------------*/
    /* Used In Remarketing Dashboard                                                  */
    /*--------------------------------------------------------------------------------*/

    <Card
      className="w-100"
      style={{
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        boxShadow: '0 5px 6px #000000',
      }}
    >
      <CardBody>

        <Row  >
          <Col lg="12" md="12" >
            <CardTitle style={{ marginTop: '-3px', fontSize: "1.2vw", fontWeight: 600 }}>Used car Stock</CardTitle>
            <div style={{ fontSize: '30px', color: '#d8d8d8', cursor: 'pointer', marginLeft: '92%', marginTop: '-43px', }} >
              <span>
                <i className="mdi mdi-filter-variant" />
              </span>
            </div>
          </Col>
        </Row>
        <div>
        </div>
        <CardSubtitle className="mb-3" style={{ fontSize: "0.85vw", marginTop: '-12px' }}>
          VINs assigned to Used Car Stock
        </CardSubtitle>
        <Chart
          options={optionsvisitors}
          series={seriesvisitors}
          type="donut"
          height="255px"
        />
      </CardBody>
      <div>
        <hr className="mt-0 mb-0" />
      </div>
      <CardBody>
        <div className="d-flex no-block align-items-center justify-content-center">
          <div>
            {/* <h6 className="text-blue">
              <i className="fa fa-circle font-10 mr-2" />
              Proposed
            </h6> */}
          </div>
          <div className="ml-3">
            <h6 style={{ color: '#555B6D' }}>
              <i className="fa fa-circle font-10 mr-2" />
              B2B
            </h6>
          </div>
          <div className="ml-3">
            <h6 style={{ color: '#50C2F7' }}>
              <i className="fa fa-circle font-10 mr-2" />
              B2C
            </h6>
          </div>
          {/* <div className="ml-3">
            <h6 className="text-primary">
              <i className="fa fa-circle font-10 mr-2" />
              {'61 - 90 days'}
            </h6>
          </div>
          <div className="ml-3">
            <h6 className="text-info">
              <i className="fa fa-circle font-10 mr-2" />
              {'>90 days'}
            </h6>
          </div> */}
        </div>
      </CardBody>
    </Card>
  );
};

export default UsedCarStock;