import React, { useRef } from 'react';
import SidebarContent from 'src/containers/LayoutNew/components/sidebar/SidebarContent';
import Slide from '@mui/material/Slide';

const SidebarRightSide = ({ handleClose, setUserIsLoggedIn, sliderProp, setToggleSidebarButton, isOpen }) => {

    const containerRef = useRef(null);

    return (
        <>
            <Slide
                direction="right"
                in={sliderProp && isOpen}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 1000, exit: 1000 }}
                onExited={() => setToggleSidebarButton(false)}
                container={containerRef.current}
            >
                <div ref={containerRef} style={{ marginLeft: '-75vw' }}>
                    <SidebarContent
                        handleClose={handleClose}
                        setUserIsLoggedIn={setUserIsLoggedIn}
                    />                    
                </div>
            </Slide>
        </>
    );
}

export default SidebarRightSide;