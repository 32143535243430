import * as React from 'react';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { BsSearch } from "react-icons/bs";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    width: '20%',
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'flex-end',
    position: 'relative',
    backgroundColor: '#000',

    borderColor: (props) => props.priceBucketActiveTab ? 'rgba(255, 255, 255, 0.09)' : 'transparent',
    borderStyle: 'solid',
    borderWidth: '1px 1px',
    marginTop: '10px',
    marginLeft: '15px',
    marginBottom: '25px',
    height: (props) => props.priceBucketActiveTab ? '30px' : '30px',
    borderRadius: '30px',
    transition: 'filter 0.3s ease',
    '&:hover': {
      backgroundImage: 'linear-gradient(#131313, #131313), linear-gradient(to right, #1EF1C6 0%, #1EF1C6 100%)',
      borderRadius: '30px',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      borderWidth: '2px',
    },
    '&:focus-within': {
      backgroundImage: 'linear-gradient(#131313, #131313), linear-gradient(to right, #5D1EF1 0%, #1EF1C6 100%)',
      borderRadius: '30px',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      borderWidth: '2px',
      color: '#fff'
    },

  },
  input: {
    flex: 'auto',
    paddingLeft: '10px',
    zIndex: '1',
    color: '#fff'
  },
  iconbutton: {
    color: "#bfbfbf",
    zIndex: '2',
    marginRight: '-17px'
  },
  button: {
    backgroundColor: (props) => props.priceBucketActiveTab ? '#1ef1c6' : 'rgba(255, 255, 255, 0.1)',
    color: (props) => props.priceBucketActiveTab ? '#000' : '#1ef1c6',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: '#088a70',
      color: '#fff'
    },
    width: '140px',
    marginBottom: '10px'

  },
  heading: {
    "&.MuiTypography-root": {
      color: "#ff0000",
      fontFamily: 'LynkcoTypeLight,LynkcoTypeMedium'
    }
  },

});

export default function SearchBar(props) {
  const { handleSearch, handleSearchInput, placeholder, onKeyPress, disabled, priceBucketActiveTab = false, defaultValue, value, focused } = props
  const classes = useStyles(props);
  return (
    <div className={classes.root}
      component="form"
    >
      <InputBase
        className={classes.input}
        inputProps={{ 'aria-label': 'Search license plate' }}
        onChange={handleSearchInput}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        autoFocus={focused}
      />
      {
        priceBucketActiveTab ? <></> :
          <Button className={classes.iconbutton} aria-label="search" onClick={handleSearch}>
            <BsSearch />
          </Button>
      }

    </div>
  );
};

export const SearchButtonSalesSettings = (props) => {
  const { handleSearch, placeholder, priceBucketActiveTab = false } = props
  const classes = useStyles(props);
  return (
    <Button onClick={handleSearch} className={classes.button} variant="contained" placeholder={placeholder}>
      {placeholder}
    </Button>
  );
};