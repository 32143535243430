import React, { useState } from 'react';
import { Card, CardBody } from "reactstrap";
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';

const UsedCarReadyForSaleCard = ({ setQueryParamsAvailableForSales, availableForSalesCount, setUsedCarToggle, setDataLoaded }) => {

    const [checked, setChecked] = useState(false);

    const switchHandler = (event) => {
        let value = event.target.checked;

        if (value == true) {
            setQueryParamsAvailableForSales(true);
            setDataLoaded(false);
            setUsedCarToggle(true);
        } else {
            setQueryParamsAvailableForSales(false);
            setDataLoaded(false);
            setUsedCarToggle(false);
        }

        setChecked(event.target.checked);
    };

    return (
        <Card id="CardBodyCardAllowance1" style={{ marginBottom: '40px' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "15px", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF' }}>AVAILABLE FOR SALES</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white' }}>
                    <tr>
                        <td style={{ float: 'left', fontSize: '34px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray' }}>{availableForSalesCount}<br /></td>
                        <td style={{ float: 'right' }}>
                            <ToggleComponent checked={checked} onChange={switchHandler} />
                        </td>
                    </tr>
                </table>
            </CardBody>
        </Card>
    )
};

export default UsedCarReadyForSaleCard;

