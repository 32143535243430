import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { whiteColor } from 'src/assets/jss/GlobalStyles';
import OkButton from 'src/components/buttons/ok-button.component';
import UnsuccessfulVINsList from 'src/components/form-elements/UnsuccessfulVINsList';

const useStyles = makeStyles({
  title: {
    fontSize: (props) => props.dialogue ? '20px' : props.variant ? '20px' : 'medium',
    fontWeight: 'normal',
    fontFamily: (props) => props.dialogue ? "LynkcoTypeMedium" : props.variant ? "LynkcoTypeMedium" : "LynkcoTypeLight",
    color: whiteColor,
    textAlign: (props) => props.dialogue ? 'center' : props.variant ? 'center' : '',
    verticalAlign: (props) => props.dialogue ? 'center' : props.variant ? 'center' : '',
    marginBottom: (props) => props.dialogue ? '-25px' : props.variant ? '-30px' : '',
    marginTop: (props) => props.dialogue ? '35px' : props.variant ? '5px' : '',
  },
  message: {
    color: whiteColor,
    fontFamily: "LynkcoTypeLight",
    textAlign: 'center',
    verticalAlign: 'center',
    marginTop: (props) => props.variant ? '45px' : '25px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogAction: {
    color: whiteColor,
    fontFamily: '"LynkcoTypeLight", "LynkcoMedium", "LynkcoRegular"',
    fontWeight: 'normal',
    margin: 0,
    position: 'absolute',
    top: '87%',
    left: '50%',
    transform: ' translate(-50%, -50%)',
  },
  paperScrollPaper: {
    backgroundImage: 'linear-gradient(135deg, #101010 , #252525 99%)',
    border: (props) => props.dialogue ? '1px solid #1EF1C6' : props.variant ? '1px solid #1EF1C6' : '1px solid rgba(255, 255, 255, 0.5)',
    minHeight: (props) => props.dialogue ? 270 : props.variant ? 300 : 220,
    width: (props) => props.dialogue ? 460 : props.variant ? 550 : 370,
    borderBottomLeftRadius: '15px',
    borderBottomRightRadius: '4px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    boxShadow: '0 5px 6px #000000',
    top: -105
  }
});

const VehiclePageDialog = (props) => {
  const classes = useStyles(props);
  const { open, message, handleDialogOk, title, dialogue = false, unSuccessfulVINs, variant = false } = props;

  return (
    <div >
      <Dialog
        open={open}
        maxWidth="sm"
        classes={{
          paperScrollPaper: classes.paperScrollPaper
        }}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          position: 'absolute',
          zIndex: 2000,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0
        }}
        disableScrollLock
      >
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          <Typography className={classes.title}>{title} </Typography>
        </DialogTitle>

        <DialogContent className={classes.message} >
          <DialogContentText id="alert-dialog-description">
            <Typography className={classes.message}>{message} </Typography>
          </DialogContentText>
          {props.variant && unSuccessfulVINs.length > 0 ? <UnsuccessfulVINsList unSuccessfulVINs={unSuccessfulVINs} /> : <></>}
        </DialogContent>

        <DialogActions className={classes.dialogAction} >
          <OkButton handleDialogOk={handleDialogOk} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VehiclePageDialog;