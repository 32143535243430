import * as React from 'react';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { BsSearch } from "react-icons/bs";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '20%',
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'flex-end',
    position: 'relative',
    backgroundColor: (props) => props.priceBucketActiveTab ? 'rgba(255, 255, 255, 0.1)' : props.connectivityColor ? 'rgba(255, 255, 255, 0.1)' : '#131313',
    borderColor: (props) => props.priceBucketActiveTab ? '#131313' : props.connectivityColor ? '#131313' : 'rgba(255, 255, 255, 0.2)',
    borderStyle: 'solid',
    borderWidth: '1px 1px',
    marginTop: '10px',
    marginLeft: '13px',
    marginBottom: '25px',
    height: (props) => props.priceBucketActiveTab ? '35px' : props.connectivityColor ? '40px' : '30px',
    borderRadius: '4px',
    transition: 'filter 0.3s ease',
    '&:hover': {
      border: '1px solid #1EF1C6',
      color: '#000'
    },
    '&:focused': {
      borderColor: '#1EF1C6',
      color: '#000'
    },
  },
  input: {
    flex: 'auto',
    paddingLeft: '10px',
    zIndex: '1'
  },
  iconbutton: {
    color: "#bfbfbf",
    zIndex: '2',
    marginRight: '-17px'
  },
  button: {
    backgroundColor: (props) => props.priceBucketActiveTab ? '#1ef1c6' : 'rgba(255, 255, 255, 0.1)',
    color: (props) => props.priceBucketActiveTab ? '#000' : '#1ef1c6',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: '#088a70',
      color: '#fff'
    },
    width: '140px',
    marginBottom: '10px'
  },
  heading: {
    "&.MuiTypography-root": {
      color: "#ff0000",
      fontFamily: 'LynkcoTypeLight,LynkcoTypeMedium'
    }
  },
}))

export default function SearchBarProvisioning(props) {
  const { handleSearch, handleSearchInput, placeholder, onKeyPress, disabled, priceBucketActiveTab = false, text, value, connectivityColor = false } = props
  const classes = useStyles(props);

  return (
    <div className={classes.root}
      component="form"
    >
      <InputBase
        className={classes.input}
        inputProps={{ 'aria-label': 'Search license plate' }}
        onChange={handleSearchInput}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        disabled={disabled}
        value={value}
      />
      <Button className={classes.iconbutton} aria-label="search" onClick={handleSearch}>
        <BsSearch />
      </Button>
    </div>
  );
}

export const SearchButtonSalesSettings = (props) => {
  const { handleSearch, placeholder, priceBucketActiveTab = false } = props
  const classes = useStyles(props);
  return (
    <Button onClick={handleSearch} className={classes.button} variant="contained" placeholder={placeholder}>
      {placeholder}
    </Button>
  );
}