import React, { useRef, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Card, Row, Col, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import Typography from '@material-ui/core/Typography';

const UseStyles = makeStyles((theme) => ({
    root2: {
        overflow: 'auto',
        height: '410px',
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 1%)',
            border: 'none'
        },
        marginLeft: '1px',
        marginTop: '10px',
        overflowX: 'hidden'
    },
    rotateIcon: {
        animation: "$bounce 2s linear infinite"
    },
    "@keyframes bounce": {
        "0%, 20%, 50%, 80%, 100%": {
            transform: "translateY(0)"
        },
        "40%": {
            transform: "translateY(-10px)"
        },
        "60%": {
            transform: "translateY(-5px)"
        }
    }
}));

const DriverDetailPage = ({ vehicleDetail, provisionedDated, customerFullName }) => {
    const classes = UseStyles();
    const div = useRef(null);

    return (
        <Col md={12} lg={12}>

            <div style={{ marginLeft: '1px', marginRight: '13px' }}>
                <Card id="CardBodyCardAllowanceVSP" >
                    <CardBody>
                        <Row>
                            <Col lg="12" md="12" >
                                <div style={{ marginLeft: '-20px', marginTop: '-10px' }}>
                                    <div>
                                        <Typography style={{ marginLeft: '15px', fontSize: '14px', fontFamily: "LynkcoTypeBold", marginTop: '5px' }} variant="h4" id="tableTitle" component="div">
                                            Driver Details
                                        </Typography>
                                    </div>
                                    <Row className={classes.root2}>
                                        <div ref={div}>
                                            <div ref={div}>
                                                <Row style={{ marginLeft: '-1px', marginRight: '-30px', marginTop: '10px' }} >
                                                    <Col lg="12" md="12" >
                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Customer ID</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.customerDetails?.customerId}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Customer Name</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{customerFullName}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Provisioned Date</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{provisionedDated}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Order Number</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.orderDetails?.orderNumber}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Order Type</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.orderDetails?.orderType}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>Order Status</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail?.orderDetails?.orderStatus}</span> <br /><br />

                                                        <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium' }}>End of Contract</span> <br />
                                                        <span style={{ fontSize: '12px', color: 'rgb(255, 102, 0)', fontFamily: 'LynkcoTypeRegular' }}>Coming soon</span> <br /><br />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </Col>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(DriverDetailPage);