import React, { useState, useEffect } from 'react';
import { Card, CardBody } from "reactstrap";
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserGraphData } from 'src/redux/user/user.selectors';
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';
import { QueryUserByEmail } from 'src/services/user-http.service'
import { GetMarketByOrganizationId } from 'src/services/market-http.service';
import { VehicleData } from 'src/components/maps/mockData';

const MarketCard = ({ userOrg, setDataLoaded, setQueryParamsMarketIds, graphData, setMarketconfig, multiMarketSelect, setMarkers, setToggleInfoWindow, setInputFieldMarker, setDisablebuttons, setActiveMarker }) => {

    const [selected, setSelected] = React.useState([]);
    const [markets, setMarkets] = React.useState([]);
    const [checkedFlag, setCheckedFlag] = useState([]);
    const [sliderUser, setSliderUser] = useState({
        editMode: false
    });

    useEffect(() => {
        initializeMarkets();
        setSliderUser({
            editMode: true
        })
    }, [markets.length]);

    useEffect(() => {
        preselectMarkets();
    }, [sliderUser.editMode, markets.length]);

    const initializeMarkets = () => {
        let marketArray = [];
        GetMarketByOrganizationId(userOrg.id)
            .then(data => {
                Array.isArray(data) && data.length > 0 ? data.map(market => {
                    marketArray.push({ key: market.gemsId, value: market.name });
                })
                    : console.log("Error Occured");
                setMarkets(marketArray);
            });
    }
    const preselectMarkets = () => {
        let marketSelectArray = [];
        setSelected([]);

        if (sliderUser.editMode === true) {

            QueryUserByEmail(graphData.mail).then(apiData => {
                Array.isArray(apiData.marketIds) && apiData.marketIds.length > 0 ? apiData.marketIds.map(market => {
                    marketSelectArray.push(market);
                })
                    : console.log("Error Occured");
                setSelected(marketSelectArray);
                setCheckedFlag(getDifference(marketSelectArray, markets.map(x => x.key)));
            }
            )
        }
        else {
            setSelected([]);
        }
    };

    function getDifference(a, b) {
        return a.filter(element => !b.includes(element)).concat(b.filter(element => !a.includes(element)));
    };

    const handleChange = (name, isDisabled = true) => (event) => {
        let updatedSelected = []

        if (isDisabled) {
            updatedSelected = selected.filter((item) => item !== name)
            setQueryParamsMarketIds(updatedSelected);
            setMarketconfig(true);
            setDataLoaded(false);
        }
        if (!isDisabled) {
            updatedSelected = [...selected, name]
            setQueryParamsMarketIds(updatedSelected);
            setMarketconfig(true);
            setDataLoaded(false);
        }
        setSelected(updatedSelected);
    };

    const handleChangeMap = (name, isDisabled = true) => (event) => {

        let updatedSelected = []

        if (isDisabled) {
            updatedSelected = selected.filter((item) => item !== name)
            setToggleInfoWindow(true);
            setDisablebuttons(true);
            setInputFieldMarker(true);
            setMarkers([...VehicleData])
        }
        if (!isDisabled) {
            updatedSelected = [...selected, name]
            setMarkers([]);
            setDisablebuttons(false);
            setActiveMarker(null);
        }
        setSelected(updatedSelected);
    };

    return (
        <Card id="CardBodyCardAllowance4" style={{ marginBottom: '40px' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "16px", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF' }}>MARKET</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white', marginTop: '-15px' }}>
                    {
                        markets.map((item) => {
                            return (
                                <tr>
                                    <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', marginTop: '30px' }}>{item.value}</td> <br />
                                    <td style={{ float: 'right', }}>
                                        {
                                            multiMarketSelect ?
                                                <ToggleComponent checked={selected.indexOf(item.key) > -1} disabled={checkedFlag.indexOf(item.key) > -1} onChange={handleChange(item.key, selected.indexOf(item.key) > -1)} />
                                                :
                                                <ToggleComponent checked={!(selected.indexOf(item.key) > -1)} disabled={checkedFlag.indexOf(item.key) > -1} onChange={handleChangeMap(item.key, selected.indexOf(item.key) > -1)} />
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </table>
            </CardBody>
        </Card>
    )
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    graphData: selectUserGraphData(state)
});

export default connect(mapStateToProps, null)(MarketCard);