import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DefaultPage from 'src/pages/backgrounds/default-page';
import MapPageMainPage from 'src/pages/maps/MapPageMainPage';
import { Typography } from '@material-ui/core';
import { permission } from 'src/auth/permissions';
import { selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';

const TabPanel = (props) => {
  const { children, userPermissions, userToken, value, index, vehiclePosition, closeHandleDrawer, isExpanded, shouldRenderMap, ...other } = props;
  const values = [permission.Location_Administrator, permission.Location_Reader];
  const matchesPermission = values.some(permission => userPermissions.includes(permission));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {
        matchesPermission ?
          <>
            {value === index && value === 0 && (
              <MapPageMainPage />
            )}
          </>
          :
          <>
            {value === index && value === 0 && (
              <DefaultPage />
            )}
          </>
      }
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
    margin: -theme.spacing(2.9),
    height: '55px',
    marginLeft: '115px'
  },
  panel: {
    marginLeft: '35px',
    position: "fixed",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    padding: "55px",
    backgroundImage: 'linear-gradient(60deg, rgba(13,13,13,1), rgba(37,37,37,1) 99%)',
  },
  tabBar: {
    marginRight: theme.spacing(-22),
    marginLeft: theme.spacing(22.5),
    textTransform: 'initial',
    fontSize: '15px',

  },
  appBar: {
    backgroundColor: "#131313",
    position: 'fixed',
    marginTop: '-100px',
    marginLeft: '-180px',
    zIndex: '20',
    height: '48px',
    boxShadow: '0 5px 6px #000000'
  }
}));

const StyledTabs = withStyles({

  indicator: {
    backgroundColor: '#1EF1C6',
    height: '3px',
    maxWidth: '110px',
    marginLeft: '25px'
  },
})(Tabs);

const LocationTabs = (props) => {
  const { vehiclePosition, shouldRenderMap, closeHandleDrawer, isExpanded, userPermissions, userToken } = props
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //////////////// Commented sections to be enabled when implementing Geofence ////////////////////////

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Typography style={{ textAlign: 'center', fontSize: '15px', marginTop: '15px', marginLeft: '35px', fontFamily: "LynkcoTypeRegular", cursor: 'pointer', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', letterSpacing: '2px' }} variant="h4" id="tableTitle" component="div">
          LOCATION
        </Typography>
        <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Map View" {...a11yProps(0)} className={classes.tabBar} />
        </StyledTabs>
      </AppBar>
      <TabPanel
        vehiclePosition={vehiclePosition}
        shouldRenderMap={shouldRenderMap}
        closeHandleDrawer={closeHandleDrawer}
        value={value}
        index={0}
        className={classes.panel}
        isExpanded={isExpanded}
        userPermissions={userPermissions}
        userToken={userToken}
      >
        Maps
      </TabPanel>

      {/* <TabPanel 
        value={value} 
        index={1} 
        className={classes.panel}
        userPermissions={userPermissions}
        userToken={userToken}
      >
        Geofence
      </TabPanel> */}
    </div>
  );
};

const mapStateToProps = state => ({
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(LocationTabs);