import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ReactTableBaseVehiclePage from 'src/components/tables/newtable/table/ReactTableBaseVehiclePage';
import { makeStyles } from '@material-ui/core/styles';
import { SearchByExtendedSearch, FleetAssetsExtendedSearchKpis, GetVehicleModelYears, GetOrderStatusList } from 'src/services/vehicle-pages-http.service';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserGraphData, selectUserPermissions } from 'src/redux/user/user.selectors';
import { GetFleets } from 'src/services/fleet-http.service';
import { GetFleetTypes } from 'src/services/fleetType-http.service';
import { UpdateUsedCar } from 'src/services/sales-settings-http.service'
import { FormmatDate, } from 'src/utils/DateFormatter';
import { formatAndVerifyNumericValue } from 'src/utils/RvTableExcelFormatter';
import { Typography } from '@material-ui/core';
import { useTableStyles } from 'src/assets/jss/components/tableStyle';
import VehiclePageDialog from 'src/components/form-elements/vehiclePage-dialog.component';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import swal from 'sweetalert';
import SearchButton from 'src/components/buttons/search-button-componentNew';
import DetailsMainPage from 'src/components/vehicleDetails/DetailsMainPage';
import CSVExport from 'src/services/csv-export.service';
import Modal from "@mui/material/Modal";
import './AllCarsList.css';
import FormSelect from 'src/components/form-elements/custom-select.component';
import Tooltip from '@material-ui/core/Tooltip';
import { permission } from 'src/auth/permissions';
import ManualCaseCreationIcon from 'src/assets/img/vehicles/ManualCaseCreationIcon.png';
import ManualCaseCreationIconGreen from 'src/assets/img/vehicles/ManualCaseCreationIconGreen.png';
import IconButton from "@mui/material/IconButton";
import RoleAddEdit from 'src/components/form-elements/role-add-edit.component';
import ConfirmDetails from 'src/components/vehicleDetails/ConfirmDetails';
import magnifyIcon from 'src/assets/img/vehicles/magnifyIcon.png';
import magnifyicon_hover from 'src/assets/img/vehicles/magnifyicon_hover.png';
import DropdownSelect from 'src/components/form-elements/custom-form-select.component';
import UnassignedCases from 'src/pages/Tables/DataTable/components/UnassignedCases';
import AssignedCases from 'src/pages/Tables/DataTable/components/AssignedCases';
import ResolvedCases from 'src/pages/Tables/DataTable/components/ResolvedCases';
import Fade from 'react-reveal/Fade';

let rowsPerPage = 50;
let newPageVariable = 1;
let otdFleetType = 'Yes';
let vin = '';
let transportStatus = '';
let caseNumber = '';
let customerName = '';
let prefferedLanguage = '';
let validRSA = '';
let vehicleHealth = '';

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const useDrawerStyles = makeStyles((theme) => ({
    drawarModal: {
        width: '100%',
        flexShrink: 0,
        marginTop: '320px',
        alignItems: 'center',
        position: 'absolute',
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
    },
    root2: {
        overflow: 'auto',
        height: '780px',
        paddingRight: '6px',
        marginRight: '-22px',
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 1%)',
            border: 'none'
        },
        overflowX: 'hidden'
    },
    rotateIcon: {
        animation: "$bounce 2s linear infinite"
    },
    "@keyframes bounce": {
        "0%, 20%, 50%, 80%, 100%": {
            transform: "translateY(0)"
        },
        "40%": {
            transform: "translateY(-10px)"
        },
        "60%": {
            transform: "translateY(-5px)"
        }
    },
    drawer: {
        width: '20vw',
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    },

    drawerConfirmDetails: {
        width: '100%',
        flexShrink: 0,
        marginTop: '320px',
        alignItems: 'center',
        position: 'absolute',
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
    },
}));

const rowData = [
    {
        "caseType": "I-Call",
        "callerID": "1234567890",
        "caseNumber": "CAS-399499-Q0t5V6",
        "licensePlate": "SE-APP001",
        "vin": "L6TCX2E72NE031885",
        "validRSA": "YES",
        "customerName": "Alexander del Rey Rosso",
        "prefferedLanguage": "English",
        "carSharing": "NO",
        "lender": "NO",
        "vehicleHealth": "ALL GOOD",
    },
    {
        "caseType": "B-Call",
        "callerID": "1234567890",
        "caseNumber": "CAS-399499-A1u456",
        "licensePlate": "KDA71J",
        "vin": "L6TCX3E72LE000025",
        "validRSA": "YES",
        "customerName": "Alexander del Rey Rosso",
        "prefferedLanguage": "English",
        "carSharing": "YES",
        "lender": "Michael Andrew Johnson",
        "vehicleHealth": "ATTENTION",
    },
    {
        "caseType": "Stolen Vehicle",
        "callerID": "1234564523",
        "caseNumber": "CAS-399499-M3t295",
        "licensePlate": "DCG47B",
        "vin": "L6TCX2E31ME001198",
        "validRSA": "NO",
        "customerName": "James Bond",
        "prefferedLanguage": "Swedish",
        "carSharing": "NO",
        "lender": "NO",
        "vehicleHealth": "ALL GOOD",
    },
    {
        "caseType": "B-Call",
        "callerID": "1234569863",
        "caseNumber": "CAS-399499-H3w076",
        "licensePlate": "MPH10G",
        "vin": "L6TCX2E31ME001479",
        "validRSA": "NO",
        "customerName": "Oliver Johnson ",
        "prefferedLanguage": "German",
        "carSharing": "NO",
        "lender": "NO",
        "vehicleHealth": "ALL GOOD",
    },
    {
        "caseType": "B-Call",
        "callerID": "1234565541",
        "caseNumber": "CAS-399499-K2q230",
        "licensePlate": "XHK08C",
        "vin": "L6TCX2E31ME002327",
        "validRSA": "YES",
        "customerName": "Emma Newton West",
        "prefferedLanguage": "Dutch",
        "carSharing": "YES",
        "lender": "Julia Elisabeth Sjömark",
        "vehicleHealth": "ATTENTION",
    }
]

const MyOpenCasesTable = ({ userOrg, userMarketIds, selectTable = false, listviewyype = 2, userPermissions, multiMarketSelect = true, graphData }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [downloadSpinner, setDownloadSpinner] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [isColumnHidden, setIsColumnHidden] = useState(false);
    const [isEnableSave, setIsEnableSave] = useState(false);
    const [showExtendedSearchIcon, setShowExtendedSearchIcon] = useState(false);
    const [openDetailsPage, setOpenDetailsPage] = useState(false);

    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const classes = useTableStyles();
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [selected, setSelected] = useState('');
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);

    const [rows, setVehicleFleet] = useState([]);

    const [pageSelectAll, setPageSelectAll] = useState([]);
    const [isPageSelectAll, setIsPageSelectAll] = useState(false);

    const [selectedRow, setSelectedRow] = useState({});
    const [unSuccessfulVINs, setUnsuccessfulVINs] = useState([]);

    const [presSelectedFleetTypes, setPresSelectedFleetTypes] = useState([]);
    const [presSelectedMarketIds, setPresSelectedMarketIds] = useState([]);

    const [tableHight, setTableHeight] = useState(false);

    let selectedRowVehiclePostion = {}
    let queryParams = {}

    let fleetTypes = []
    let marketIds = []

    const [earMarked, setEarMarked] = React.useState([]);
    const [toggleAgeVisibility, setToggleAgeVisibility] = useState('visible')
    const [dialogMessage, setDialogMessage] = useState('');
    const [isErrorDialog, setErrorDialogFlag] = useState(false);
    const drawerClasses = useDrawerStyles();
    const [shouldRenderMap, setShouldRenderMap] = useState(false)
    const [vehiclePosition, setVeiclePosition] = useState({
        "vin": "",
        "latitude": 0,
        "longitude": 0,
        "altitude": 0,
        "canBeTrusted": false,
        "vehicleUpdatedAt": ""
    });
    const [openDialog, setOpenDialog] = useState(false);

    const [fleetVehicle, setFleetVehicle] = useState({
        licensePlate: "",
        vin: "",
        pnoCode: "",
        model: "",
        modelYear: "",
        engineType: "",
        obcVersion: "",
        color: "",
        hasTowbar: "",
        odometerKm: "",
        rvGross: "",
        registeredDateUtc: "",
        age: "",
        forecastedDefleetDateUtc: "",
        transportStatus: "",
        lastMileStatus: "",
        lastMileType: "",
        orderStatus: "",
        membershipEndDateUtc: "",
        usedCar: "",
        usedCarIsB2B: "",
        market: "",
        fleetType: "",
        orderType: "",
        orderNumber: "",
        rvCurve: "",
        rvRangeKm: "",
        inventoryState: "",
        fleetIds: "",
        belowLandedCost: ""
    });

    const [selectedMarket, setSelectedMarket] = useState({ key: "NONE", value: "" })
    const [selectedFleetType, setSelectedFleetType] = useState({ key: "NONE", value: "" })
    const [queryParamsAge, setQueryParamsAge] = useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    })

    const [queryParamsMarket, setQueryParamsMarket] = useState('');
    const [queryParamsMarketIds, setQueryParamsMarketIds] = useState([]);

    const [queryParamsFleetType, setQueryParamsFleetType] = useState('');
    const [queryParamsFleetTypeIds, setQueryParamsFleetTypeIds] = useState([]);

    const [queryParamsModelYear, setQueryParamsModelYear] = useState([]);

    const [queryParamsModelYearSelect, setQueryParamsModelYearSelect] = useState([]);

    const [modelYearToggle, setModelYearToggle] = useState(false);

    const [orderStatusToggle, setOrderStatusToggle] = useState(false);

    const [queryParamsVin, setQueryParamsVin] = useState('');
    const [queryParamsLicensePlate, setQueryParamsLicensePlate] = useState('');
    const [queryParamsOrderType, setQueryParamsOrderType] = useState(['B2B', 'B2C', '']);
    const [queryParamsOrderStatus, setQueryParamsOrderStatus] = useState([]);
    const [queryParamsOrderStatusSelect, setQueryParamsOrderStatusSelect] = useState([]);

    const [queryParamsOrderNumber, setQueryParamsOrderNumber] = useState('');
    const [queryParamsPNOCode, setQueryParamsPNOCode] = useState('');
    const [queryParamsEngine, setQueryParamsEngine] = useState(['HEV', 'PHEV']);

    const [queryParamsAvailableForSales, setQueryParamsAvailableForSales] = useState(false);
    const [queryParamsCommercialCostMonthIndex, setQueryParamsCommercialCostMonthIndex] = useState('');

    const [queryParamsColor, setQueryParamsColor] = useState(['Blue', 'Black']);

    const [queryParamsRvBelowLandedCost, setQueryParamsRvBelowLandedCost] = useState('');
    const [queryParamsCarSwapInXMonths, setQueryParamsCarSwapInXMonths] = useState('');
    const [queryParamsUsedCarStock, setQueryParamsUsedCarStock] = useState('');
    const [queryParamsURvRange, setQueryParamsRvRange] = useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    });
    const [queryParamsUsedInventoryStatus, setQueryParamsInventoryStatus] = useState('');
    const [queryParamsFilteredTowbar, setQueryParamsFilteredTowbar] = useState(false);

    const [queryParamsLastMileStatus, setQueryParamsLastmileStatus] = useState('');
    const [queryParamsProvisionStatus, setQueryParamsProvisionStatus] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [closeB2BSelection, setCloseB2BSelection] = useState(false);

    const [marketconfig, setMarketconfig] = useState(false);

    const [fleetTypeIdsToggle, setFleetTypeIdsToggle] = useState([]);

    const [fleetTypeIdsconfig, setFleetTypeIdscongfig] = useState(false);

    const [userCarStockCount, setUsedCarStockCount] = useState(null);
    const [availableForSalesCount, setAvailableForSales] = useState(null);
    const [rvBelowLCCount, setRVBelowLC] = useState(null);
    const [towBarCount, setTowBarCount] = useState(null);
    const [usedCarToggle, setUsedCarToggle] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const imgs = [ManualCaseCreationIcon, ManualCaseCreationIconGreen];


    const [icon, setIcon] = useState(imgs[0]);
    const [openIcon, setOpenIcon] = useState(false);

    const [roles, setRoles] = React.useState({
        id: "",
        name: "Vehicle reported stolen",
        createdDate: "24.11.2021",
        description: "Vehcicle reported stolen 23rd Novemeber 21. Last know position was at central station in Gothenburg. Vehicle is no longer reporting its postion. Police investigation ongoing. Case ref no: 1234569  ",
        editMode: false
    });

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [confirmVehicleDetails, setConfirmvehicleDetails] = useState(false);
    const [openDownload, setOpenDownload] = useState(false);

    const [displayValue, setDisplayValue] = useState('');
    const [displayMyOpenCases, setDisplayMyOpenCases] = useState(false);
    const [displayUnassignedCases, setDisplayUnassignedCases] = useState(false);
    const [displayAssignedCases, setDisplayAssignedCases] = useState(false);
    const [displayResolvedCases, setDisplayResolvedCases] = useState(false);

    const [toggleSearchBar, setToggleSearchBar] = useState(false);


    const handleChangeUCA = (value) => (event) => {
        setDisplayMyOpenCases(false);
        let selected = event.target.value;
        if (value == 'cases') {
            if (selected == 'My Open Cases') {
                setDisplayMyOpenCases(true);
                setDisplayUnassignedCases(false);
                setDisplayAssignedCases(false);
                setDisplayResolvedCases(false);
                setToggleSearchBar(false);
            }

            if (selected == 'Unassigned Cases') {
                setDisplayMyOpenCases(false);
                setDisplayUnassignedCases(true);
                setDisplayAssignedCases(false);
                setDisplayResolvedCases(false);
            }

            if (selected == 'Assigned Cases') {
                setDisplayMyOpenCases(false);
                setDisplayUnassignedCases(false);
                setDisplayAssignedCases(true);
                setDisplayResolvedCases(false);
                setToggleSearchBar(false);
            }

            if (selected == 'Resolved Cases') {
                setDisplayMyOpenCases(false);
                setDisplayUnassignedCases(false);
                setDisplayAssignedCases(false);
                setDisplayResolvedCases(true);
                setToggleSearchBar(false);
            }

            setDisplayValue(event.target.value);
        };

    };

    const [showText, setShowText] = useState(false);
    const [showTable, setShowTable] = useState(false);

    const [showMyFleetText, setShowMyFleetText] = useState(false);

    const [showSearchBar, setShowSearchBar] = useState(false);

    const handleSearchbar = () => {
        setShowSearchBar(!showSearchBar);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRoles({ ...roles, [name]: value });
    };

    const handleClickAdd = () => {
        setOpenModal(true);
        setRoles({
            ...roles, id: "", name: "", createdDate: "24.11.2021", description: "", editMode: false
        });
        setOpenCreateModal(true);
        setConfirmvehicleDetails(false);
        setOpenDownload(false);
    };

    const handleClickEdit = () => {
        setOpen(false);
    };

    const handleConfirmvehicleDetails = () => {
        setOpenCreateModal(false);
        setConfirmvehicleDetails(true);
        setOpenDownload(false);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleDone = () => {
        setOpenModal(false);
        // saveFleet();
    };

    const handleOpenDetailsPage = () => {
        setOpenCreateModal(false);
        setConfirmvehicleDetails(false);
        setOpenDownload(false);
        setOpenModal(false);

        vin = 'TESTSUBSET0000021';
        transportStatus = 'selectedRowVehiclePostion.transportStatus'
        setOpenDetailsPage(true);
        setShouldRenderMap(true);
        caseNumber = 'selectedRowVehiclePostion.caseNumber';
        customerName = 'selectedRowVehiclePostion.customerName';
        validRSA = 'selectedRowVehiclePostion.validRSA';
        prefferedLanguage = 'selectedRowVehiclePostion.prefferedLanguage';

    }

    const handleReturnPage = () => {
        setOpenCreateModal(true);
        setConfirmvehicleDetails(false);
        setOpenDownload(false);
    }

    const open1 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        // setAnchorEl(null);
        setOpenModal(false);
        setOpenCreateModal(false);
    };

    const handleCloseHomeIcon = () => {

        setIcon(imgs[0]);
        setOpenIcon(false);
    };

    const handleOpenHomeIcon = () => {
        setIcon(imgs[1]);
        setOpenIcon(true);
    };

    const div = useRef(null);

    const columns = useMemo(
        () => [
            { Header: 'Case Type', accessor: "caseType", disableGlobalFilter: true },
            { Header: 'Caller ID', accessor: "callerID", disableGlobalFilter: true },
            { Header: 'Case Number', accessor: "caseNumber", disableGlobalFilter: true },
            { Header: 'License Plate', accessor: "licensePlate", disableGlobalFilter: true },
            { Header: 'VIN', accessor: "vin", disableGlobalFilter: true },
            { Header: 'Valid RSA', accessor: "validRSA", disableGlobalFilter: true, },
            { Header: 'Customer Name', accessor: "customerName", disableGlobalFilter: true, },
            { Header: 'Language', accessor: "prefferedLanguage", disableGlobalFilter: true, },
            { Header: 'Car Sharing', accessor: "carSharing", disableGlobalFilter: true, },
            { Header: 'Lender', accessor: "lender", disableGlobalFilter: true }
        ],
        [],
    );

    useEffect(() => {
        setDisplayMyOpenCases(true);
        const timer1 = setTimeout(() => {
            setShowText(true);
            setShowMyFleetText(true);

        }, 300); // Adjust the delay as needed

        const timer2 = setTimeout(() => {
            setShowTable(true);
            // setShowText(false);

        }, 1000); // Adjust the delay as needed

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, []);

    useEffect(() => {
        setTableHeight(true);
        setModelYears();
        setOderStatus();
        marketIds = userMarketIds;
        setPresSelectedMarketIds(marketIds);
        setOpenDialog(false);
        // if (queryParamsModelYearSelect.length !== 0 && queryParamsOrderStatusSelect.length !== 0) {
        setQueryParamsVin('')
        initializeFleetVehicles(marketIds);
        AdvancedSearchCountQuery(marketIds);
        // }
        if (queryParamsOrderType.length == 0) {
            setQueryParamsOrderType([])
        }
    }, [queryParamsAvailableForSales, queryParamsCommercialCostMonthIndex, queryParamsOrderStatusSelect.length, queryParamsModelYearSelect.length, queryParamsUsedCarStock, queryParamsRvBelowLandedCost, queryParamsURvRange, queryParamsFleetTypeIds.length, queryParamsColor, queryParamsEngine, queryParamsMarketIds, queryParamsOrderType.length, queryParamsOrderStatus.length, queryParamsAge, queryParamsModelYear.length, queryParamsFilteredTowbar, queryParamsCarSwapInXMonths]);

    const setModelYears = () => {
        let modelYearArray = []

        GetVehicleModelYears().then(data => {
            Array.isArray(data.data) && data.data.length > 0 ? data.data.map(

                modelYear => {
                    modelYearArray.push(
                        modelYear.value
                    );
                })
                : console.log("Error Occured");
            setQueryParamsModelYearSelect(modelYearArray);
        });
    };

    const setOderStatus = () => {
        let orderStatusArray = []

        GetOrderStatusList().then(data => {
            Array.isArray(data.data) && data.data.length > 0 ? data.data.map(

                orderStatus => {
                    orderStatusArray.push(
                        orderStatus.value
                    );
                })
                : console.log("Error Occured");
            setQueryParamsOrderStatusSelect(orderStatusArray);
        });
    };

    const updateQueryParamsMarketIds = (updatedMarketIds) => {
        setQueryParamsMarketIds(updatedMarketIds);
        marketIds = [];
    };

    const initializeFleetVehicles = (marketIds) => {
        let fleetIds = [];
        let vehicleFleetArray = [];

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear.length == 0 && !modelYearToggle ? queryParamsModelYearSelect : queryParamsModelYear }
        queryParams = { ...queryParams, marketIds: queryParamsMarketIds }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, fleetTypeIds: queryParamsFleetTypeIds }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus.length == 0 && !orderStatusToggle ? queryParamsOrderStatusSelect : queryParamsOrderStatus }
        queryParams = { ...queryParams, filterByTowbar: queryParamsFilteredTowbar }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCarSwapInXMonths }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                                setFleetTypeIdsToggle(fleetTypes)
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: newPageVariable, pageSize: rowsPerPage, listViewType: listviewyype, fleetTypeIds: queryParamsFleetTypeIds.length == 0 && !fleetTypeIdsconfig ? fleetTypes : queryParamsFleetTypeIds, fleetIds: fleetIds, marketIds: queryParamsMarketIds.length == 0 && !marketconfig ? marketIds : queryParamsMarketIds, otdFleetType })
                                .then(data => {

                                    if (data && Array.isArray(data.items)) {
                                        setTotalPages(data.totalPages)
                                        setTotalItems(data.totalItems ?? 0)
                                        // data.items.map(vehicle => {
                                        rowData.map(vehicle => {
                                            vehicleFleetArray.push(
                                                {
                                                    caseType: vehicle["caseType"],
                                                    callerID: vehicle["callerID"],
                                                    caseNumber: vehicle["caseNumber"],
                                                    licensePlate: vehicle["licensePlate"],
                                                    vin: vehicle["vin"],
                                                    validRSA: vehicle["validRSA"],
                                                    customerName: vehicle["customerName"],
                                                    prefferedLanguage: vehicle["prefferedLanguage"],
                                                    carSharing: vehicle["carSharing"],
                                                    lender: vehicle["lender"] == 'NO' ? null : vehicle["lender"],
                                                    vehicleHealth: vehicle["vehicleHealth"]
                                                }
                                            );
                                        });
                                    }
                                    setDataLoaded(true);
                                    setVehicleFleet(vehicleFleetArray);
                                });
                        })
                }
            })
    };

    const AdvancedSearchCountQuery = (marketIds) => {
        let fleetIds = [];

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear.length == 0 && !modelYearToggle ? queryParamsModelYearSelect : queryParamsModelYear }
        queryParams = { ...queryParams, marketIds: queryParamsMarketIds }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, fleetTypeIds: queryParamsFleetTypeIds }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus.length == 0 && !orderStatusToggle ? queryParamsOrderStatusSelect : queryParamsOrderStatus }
        queryParams = { ...queryParams, filterByTowbar: queryParamsFilteredTowbar }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCarSwapInXMonths }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            FleetAssetsExtendedSearchKpis({ ...queryParams, pageNumber: newPageVariable, pageSize: rowsPerPage, listViewType: listviewyype, fleetTypeIds: queryParamsFleetTypeIds.length == 0 && !fleetTypeIdsconfig ? fleetTypes : queryParamsFleetTypeIds, fleetIds: fleetIds, marketIds: queryParamsMarketIds.length == 0 && !marketconfig ? marketIds : queryParamsMarketIds, otdFleetType })
                                .then(data => {
                                    if (data !== undefined) {
                                        if (data.ok == true) {
                                            setUsedCarStockCount(data.userCarStock);
                                            setAvailableForSales(data.availableForSales);
                                            setRVBelowLC(data.rvBelowLC);
                                            setTowBarCount(data.towBar)
                                        }
                                        setDataLoaded(true);

                                    }

                                });
                        })
                }
            })
    };

    const header = [
        [
            'License Plate',
            'VIN',
            'PNO',
            'Model',
            'Model Year',
            'Engine Type',
            'OBC',
            'Color',
            'Towbar',
            'Odometer',
            'RV [gross]',
            'Registered',
            'Age [Months]',
            'Est. De-fleet',
            'Transport Status',
            'Last Mile Status',
            'Last Mile Type',
            'Customer Order Status',
            'Last Day of Contract',
            'Inventory Status',
            'Used Car Stock',
            'Used Car B2B',
            'Market',
            'Fleet Type',
            'Order Type',
            'Order Number'
        ]
    ]

    //////////////////////////////////////// Download in Excel format

    const handleClickDownloadExcel = () => {
        setDownloadSpinner(true);

        let fleetIds = [];
        let vehicleFleetArray = [];
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, marketIds: queryParamsMarketIds }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, fleetTypeIds: queryParamsFleetTypeIds }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus.length == 0 && !orderStatusToggle ? queryParamsOrderStatusSelect : queryParamsOrderStatus }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCarSwapInXMonths }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear.length == 0 && !modelYearToggle ? queryParamsModelYearSelect : queryParamsModelYear }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: 0, pageSize: 0, listViewType: listviewyype, fleetTypeIds: queryParamsFleetTypeIds.length == 0 && !fleetTypeIdsconfig ? fleetTypes : queryParamsFleetTypeIds, fleetIds: fleetIds, marketIds: queryParamsMarketIds.length == 0 ? marketIds : queryParamsMarketIds, otdFleetType })
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                odometerKm: vehicle.odometerKm,
                                                rvGross: vehicle.rvDetails.rvGross,
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                age: vehicle.age,
                                                forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                transportStatus: vehicle.transportStatus,
                                                lastMileStatus: vehicle.lastMileStatus,
                                                lastMileType: vehicle.lastMileType,
                                                orderStatus: vehicle.orderStatus,
                                                membershipEndDateUtc: FormmatDate(vehicle.membershipEndDateUtc),
                                                inventoryState: vehicle.inventoryState,
                                                usedCar: vehicle.usedCar,
                                                usedCarIsB2B: vehicle.usedCarIsB2B,
                                                market: vehicle.market,
                                                fleetType: vehicle.fleetType,
                                                orderType: vehicle.orderType,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    initiateDownload(vehicleFleetArray);
                                    setDownloadSpinner(false);
                                })
                        })

                }
            })

        const initiateDownload = (row) => {

            const fileName = 'L&C Assets';

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            const fileExtension = '.xlsx';
            const exportToExcel = (row, fileName) => {

                const ws = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(ws, header);

                XLSX.utils.sheet_add_json(ws, row, { origin: 'A2', skipHeader: true });

                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                const data = new Blob([excelBuffer], { type: fileType });

                FileSaver.saveAs(data, fileName + fileExtension);

            }
            exportToExcel(row, fileName);
        }
        setOpenModal(false);
    }

    //////////////////////////////////// Download in CSV format

    const handleClickDownloadCSV = () => {
        setDownloadSpinner(true);

        let fleetIds = []
        let vehicleFleetArray = [];
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, marketIds: queryParamsMarketIds }

        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, fleetTypeIds: queryParamsFleetTypeIds }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus.length == 0 && !orderStatusToggle ? queryParamsOrderStatusSelect : queryParamsOrderStatus }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCarSwapInXMonths }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear.length == 0 && !modelYearToggle ? queryParamsModelYearSelect : queryParamsModelYear }

        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: 0, pageSize: 0, listViewType: listviewyype, fleetTypeIds: queryParamsFleetTypeIds.length == 0 ? fleetTypes : queryParamsFleetTypeIds, fleetIds: fleetIds, marketIds: queryParamsMarketIds.length == 0 ? marketIds : queryParamsMarketIds, otdFleetType })
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                odometerKm: vehicle.odometerKm,
                                                rvGross: vehicle.rvDetails.rvGross,
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                age: vehicle.age,
                                                forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                transportStatus: vehicle.transportStatus,
                                                lastMileStatus: vehicle.lastMileStatus,
                                                lastMileType: vehicle.lastMileType,
                                                orderStatus: vehicle.orderStatus,
                                                membershipEndDateUtc: FormmatDate(vehicle.membershipEndDateUtc),
                                                inventoryState: vehicle.inventoryState,
                                                usedCar: vehicle.usedCar,
                                                usedCarIsB2B: vehicle.usedCarIsB2B,
                                                market: vehicle.market,
                                                fleetType: vehicle.fleetType,
                                                orderType: vehicle.orderType,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    initiateDownload(vehicleFleetArray);
                                    setDownloadSpinner(false);
                                })
                        })
                }
            })


        const initiateDownload = (row) => {
            CSVExport(header, row, 'L&C Assets')
        }
        setOpenModal(false);
    };

    const handleCloseDownload = () => {
        setOpenModal(false);
    };

    //////////////////////////////////////////////////////////////////////

    const handleChangeExtended = (name, filterGroup) => (event) => {
        const value = event.target.value
        console.log(event.target)
        newPageVariable = 1
        if (filterGroup == 'age') {
            setQueryParamsAge({ ...queryParamsAge, [name]: value })
        }
        else if (name == 'engine') {
            setQueryParamsEngine(value)
        }
        else if (name == 'color') {
            setQueryParamsColor(value)
        }
        else if (name == 'vin') {
            setQueryParamsVin(value.trim())
        }
        else if (name == 'licensePlate') {
            setQueryParamsLicensePlate(value.trim())
        }
        else if (name == 'orderNumber') {
            setQueryParamsOrderNumber(value.trim())
        }
        else if (name == 'pnoCode') {
            setQueryParamsPNOCode(value);
        }
        else if (name == 'fleetTypeIds') {
            setPresSelectedFleetTypes()
        }
        else if (name == 'marketIds') {
            setSelectedMarket({ key: name, value: value });
            setQueryParamsMarket()
        }
        else if (name == 'orderType') {
            setQueryParamsOrderType(value)
        }
        else if (name == 'orderStatus') {
            setQueryParamsOrderStatus(value)
        }
        else if (name == 'rvBelowLandedCost') {
            setQueryParamsRvBelowLandedCost(value)
        }
        else if (name == 'carSwapInXMonths') {
            setQueryParamsCarSwapInXMonths(value)
        }
        else if (name == 'usedCar') {
            setQueryParamsUsedCarStock(value)
        }
        else if (name == 'rvRange') {
            setQueryParamsRvRange(value)
        }
        else if (name == 'inventoryStatus') {
            setQueryParamsInventoryStatus(value)
        }
        else if (name == 'lastMileStatus') {
            setQueryParamsLastmileStatus(value)
        }
        else if (name == 'provisionStatus') {
            setQueryParamsProvisionStatus(value)
        }
        else {
            queryParams = { ...queryParams, [name]: value }
        }

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus.length == 0 && !orderStatusToggle ? queryParamsOrderStatusSelect : queryParamsOrderStatus }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCarSwapInXMonths }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }
    }

    const handleExtendedSearch = () => {
        setDataLoaded(false);

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, marketIds: queryParamsMarketIds }

        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, fleetTypeIds: queryParamsFleetTypeIds }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus.length == 0 && !orderStatusToggle ? queryParamsOrderStatusSelect : queryParamsOrderStatus }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCarSwapInXMonths }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear.length == 0 && !modelYearToggle ? queryParamsModelYearSelect : queryParamsModelYear }

        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }

        console.log(queryParams)

        let fleetIds = []
        let vehicleFleetArray = [];
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        GetFleets(userOrg.id, userMarketIds)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            queryParams = { ...queryParams, pageNumber: 1, pageSize: rowsPerPage, listViewType: listviewyype, fleetTypeIds: queryParamsFleetTypeIds.length == 0 ? fleetTypes : queryParamsFleetTypeIds, fleetIds: fleetIds, marketIds: queryParamsMarketIds.length == 0 ? marketIds : queryParamsMarketIds, otdFleetType }
                            SearchByExtendedSearch(queryParams)
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        setTotalPages(data.totalPages)
                                        setTotalItems(data.totalItems ?? 0)
                                        newPageVariable = data.currentPage;
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                odometerKm: formatAndVerifyNumericValue(vehicle.odometerKm),
                                                rvGross: formatAndVerifyNumericValue(vehicle.rvDetails.rvGross),
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                age: vehicle.age,
                                                forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                transportStatus: vehicle.transportStatus,
                                                lastMileStatus: vehicle.lastMileStatus,
                                                lastMileType: vehicle.lastMileType,
                                                orderStatus: vehicle.orderStatus,
                                                membershipEndDateUtc: FormmatDate(vehicle.membershipEndDateUtc),
                                                inventoryState: vehicle.inventoryState,
                                                usedCar: vehicle.usedCar,
                                                usedCarIsB2B: vehicle.usedCarIsB2B,
                                                market: vehicle.market,
                                                fleetType: vehicle.fleetType,
                                                orderType: vehicle.orderType,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    setVehicleFleet(vehicleFleetArray);
                                    setDataLoaded(true);
                                });

                        })

                }
            })

    }

    const handleConditionalChoice = name => (event) => {
        const value = event.target.value
        const stateUpdateMethods = {
            age: setToggleAgeVisibility,
        }

        if (value === 'less than') {
            if (name === 'age') {
                setQueryParamsAge({ ...queryParamsAge, [`lessThan`]: true, [`greaterThan`]: false, [`between`]: false })
            }
            stateUpdateMethods[name]('hidden')
        }
        else if (value === 'greater than') {
            if (name === 'age') {
                setQueryParamsAge({ ...queryParamsAge, [`greaterThan`]: true, [`lessThan`]: false, [`between`]: false })
            }
            stateUpdateMethods[name]('hidden')
        }
        else if (value === 'between') {
            if (name === 'age') {
                setQueryParamsAge({ ...queryParamsAge, [`between`]: true, [`lessThan`]: false, [`greaterThan`]: false })
            }
            stateUpdateMethods[name]('visible')
        }
        else {
            if (name === 'age') {
                setQueryParamsAge({ ...queryParamsAge, [`between`]: false, [`lessThan`]: false, [`greaterThan`]: false, [`startValue`]: "0", [`endValue`]: "0" })
            }
            stateUpdateMethods[name]('visible')
        }
    }

    /////////////////////////////////////////////////////////////////////

    const handleChangePage = (event, newPage) => {
        setDataLoaded(false);
        newPageVariable = newPage + 1
        setPage(newPageVariable);
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        initializeFleetVehicles(marketIds);
        setOpen(false);
        let newPageSelectAll = pageSelectAll.filter(x => x.pageNumber === newPageVariable);
        if (newPageSelectAll.length > 0) {
            setIsPageSelectAll(newPageSelectAll[0].selectAllStatus);
        } else {
            setIsPageSelectAll(false);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setDataLoaded(false);
        newPageVariable = 1
        rowsPerPage = parseInt(event.target.value, 10);
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        initializeFleetVehicles(marketIds);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const handleDialogOk = () => {
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        setIsPageSelectAll(false);
        setOpenDialog(false);
        setIsEnableSave(false);
        setIsColumnHidden(false);
        setDataLoaded(false);
        initializeFleetVehicles(marketIds);
    }

    const handleClickSave = () => {
        setUnsuccessfulVINs([]);
        let usedCars = earMarked
        let fleetIds = []
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        swal({
            title: "",
            text: "You are about to update Used Car Stock!",
            className: "swal-modal",
            buttons: {
                cancel: {
                    text: "NO WAY!",
                    data: false,
                    visible: true,
                    className: "swal-buttons",
                    closeModal: true,
                },
                confirm: ({
                    text: "CONTINUE",
                    value: true,
                    visible: true,
                    className: "swal-button",
                    closeModal: true
                })
            },
        }).then((willSave) => {
            if (earMarked.length <= 0 || earMarked == null) {
                setIsColumnHidden(false);
                setIsEnableSave(false);
                setShowExtendedSearchIcon(false);
                setDataLoaded(false);
                initializeFleetVehicles(marketIds);
                return false;
            }

            if (willSave) {
                GetFleets(userOrg.id, userMarketIds)
                    .then(data => {
                        if (data && Array.isArray(data)) {
                            data.map(item => {
                                fleetIds.push(item.id);
                            })
                            UpdateUsedCar({ usedCars, fleetIds, marketIds })
                                .then(data => {
                                    if (data.ok == true && data.unsucessfulVins.length === 0) {
                                        setDialogMessage(data.errorMessage)
                                        setUnsuccessfulVINs(data.successfulVins);
                                        setOpenDialog(true);
                                    } else {
                                        setDialogMessage('Failed Vehicle(s) !');
                                        setOpenDialog(true);
                                        setUnsuccessfulVINs(data.unsucessfulVins);
                                    }
                                });
                            setEarMarked([]);
                            initializeFleetVehicles(marketIds);
                        }
                        setShowExtendedSearchIcon(false);
                    })
            } else {
                setEarMarked([]);
                initializeFleetVehicles(marketIds);
                setIsColumnHidden(false);
                setIsEnableSave(false);
                setShowExtendedSearchIcon(false);
            }
        });
    }

    const handleCloseB2BButton = () => {
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        setEarMarked([]);
        initializeFleetVehicles(marketIds);
        setIsColumnHidden(false);
        setIsEnableSave(false);
        setShowExtendedSearchIcon(false);
        setDataLoaded(false);
    }

    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setVehicleFleet(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setVehicleFleet(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    let selectAllArray = [];

    const selectAllRows = (value) => {
        selectAllArray = [];
        setIsPageSelectAll(value.target.checked);

        let filteredPageNumber = pageSelectAll.filter(item => item.pageNumber !== page)
        setPageSelectAll([...filteredPageNumber, { pageNumber: page, selectAllStatus: value.target.checked }]);

        let rowVins = rows.map(item => item.vin);

        rows.map((row) => {
            let status = row.usedCarIsB2B == 'Yes' ? true : false;
            if (status != value.target.checked) {
                earmarkedRowValues(row, true, value.target.checked)
            }
        });

        let filterArray = earMarked.filter((item) => !rowVins.includes(item.id));
        if (filterArray.length > 0) {
            selectAllArray = selectAllArray.concat(filterArray);
        }
        setEarMarked(selectAllArray);
    }

    const earmarkedRowValues = (row, isHeader, isSelectAll) => {
        if (isHeader) {
            selectAllArray.push({ id: row.vin, status: isSelectAll });
        } else {
            let currentRow = earMarked.find((item) => item.id === row.values.vin);
            let usedCar = row.values.usedCarIsB2B == 'Yes' ? true : false;

            if (currentRow) {
                let filterArray = earMarked.filter((item) => item.id != row.values.vin);
                setEarMarked([...filterArray]);
            }
            else {
                setEarMarked([...earMarked, { id: row.values.vin, status: !usedCar }]);
            };
        }
    }

    const selectedRowValues = (row) => {
        setSelectedRow({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            age: row.values.age,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            transportStatus: row.values.transportStatus,
            lastMileStatus: row.values.lastMileStatus,
            lastMileType: row.values.lastMileType,
            orderStatus: row.values.orderStatus,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            inventoryState: row.values.inventoryState,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            fleetType: row.values.fleetType,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber
        });
        setSelected({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            age: row.values.age,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            transportStatus: row.values.transportStatus,
            lastMileStatus: row.values.lastMileStatus,
            lastMileType: row.values.lastMileType,
            orderStatus: row.values.orderStatus,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            inventoryState: row.values.inventoryState,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            fleetType: row.values.fleetType,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber
        })
        selectedRowVehiclePostion = {
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            caseNumber: row.values.caseNumber,
            validRSA: row.values.validRSA,
            customerName: row.values.customerName,
            prefferedLanguage: row.values.prefferedLanguage,
            vehicleHealth: row.values.vehicleHealth,
            registeredDateUtc: row.values.registeredDateUtc,
            age: row.values.age,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            transportStatus: row.values.transportStatus,
            lastMileStatus: row.values.lastMileStatus,
            lastMileType: row.values.lastMileType,
            orderStatus: row.values.orderStatus,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            inventoryState: row.values.inventoryState,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            fleetType: row.values.fleetType,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber
        }
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by Licence plate...',
    };

    const handleDrawar = () => {
        vin = selectedRowVehiclePostion.vin;
        transportStatus = selectedRowVehiclePostion.transportStatus
        setOpenDetailsPage(true);
        setShouldRenderMap(true);
        caseNumber = selectedRowVehiclePostion.caseNumber;
        customerName = selectedRowVehiclePostion.customerName;
        validRSA = selectedRowVehiclePostion.validRSA;
        prefferedLanguage = selectedRowVehiclePostion.prefferedLanguage;
        vehicleHealth = selectedRowVehiclePostion.vehicleHealth
    }


    const closeHandleDrawer = () => {
        setOpenDetailsPage(false);
    }

    const handleDropdown = (e) => {
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        const { name, value } = e.target;
        if (value == 2) {
            setIsExpanded(!isExpanded)
            if (isExpanded) {
                setQueryParamsAge({
                    lessThan: false,
                    greaterThan: false,
                    between: false,
                    startValue: "0",
                    endValue: "0",
                })
                setQueryParamsMarket('');
                setQueryParamsFleetType('');
                setQueryParamsVin('');
                setQueryParamsLicensePlate('');
                setQueryParamsOrderType(['B2B', 'B2C', '']);
                setQueryParamsOrderNumber('');
                setQueryParamsPNOCode('');
                setQueryParamsEngine(['HEV', 'PHEV']);
                setQueryParamsColor(['Blue', 'Black']);
                setQueryParamsRvBelowLandedCost('');
                setQueryParamsCarSwapInXMonths('');
                setQueryParamsCommercialCostMonthIndex('');
                setQueryParamsUsedCarStock('');
                setQueryParamsRvRange({
                    lessThan: false,
                    greaterThan: false,
                    between: false,
                    startValue: "0",
                    endValue: "0",
                });
                setQueryParamsModelYear(queryParamsModelYearSelect);
                setQueryParamsInventoryStatus('');
                setQueryParamsLastmileStatus('');
                setQueryParamsProvisionStatus('');
                setQueryParamsFleetTypeIds(fleetTypeIdsToggle);
                setQueryParamsMarketIds(marketIds);
                setQueryParamsAvailableForSales(false);
                setQueryParamsFilteredTowbar(false);
            }
            initializeFleetVehicles(marketIds);
            setDataLoaded(false);
        }

        if (value == 1) {
            handleOpenDownload()
        }

        if (value == 3) {
            // handleClickIsEditable();
        }
    };

    const handleOpenDownload = () => {
        setDownloadSpinner(false);
        setOpenModal(true);

        setOpenCreateModal(false);
        setConfirmvehicleDetails(false);
        setOpenDownload(true);
    };

    const handleClickIsEditable = () => {
        setIsEnableSave(true);
        setIsColumnHidden(true);
        setShowExtendedSearchIcon(true);
        setCloseB2BSelection(true);
    };

    const handleSearchInput = (event) => {
        setQueryParamsVin(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
        }
    };

    const textFieldKeypressSearch = (e) => {
        if (e.key === 'Enter') {
            handleExtendedSearch();
        }
    };



    const matchesPermission = userPermissions.includes(permission.Vehicle_Administrator)

    return (
        <Container>
            <Row>
                <Col md={12} sm={12} xs={12} lg={12}>
                    <VehiclePageDialog
                        open={openDialog}
                        title={dialogMessage}
                        handleDialogOk={handleDialogOk}
                        isErrorDialog={isErrorDialog}
                        unSuccessfulVINs={unSuccessfulVINs}
                        variant='true'
                    />
                    <Modal
                        className={confirmVehicleDetails ? drawerClasses.drawerConfirmDetails : drawerClasses.drawarModal}
                        variant="persistent"
                        anchor="right"
                        open={openModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        onClose={handleCloseModal}
                        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
                    >
                        <>
                            {
                                openDownload &&
                                <div style={{
                                    border: '2px solid rgb(64, 64, 64)', height: '170px', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', padding: '20px'
                                }}>
                                    <Typography style={{ textAlign: 'center', fontSize: '24px', fontFamily: "LynkcoTypeRegular", cursor: 'pointer', marginTop: '-80px', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex' }} onClick={() => handleDrawar('fnl')} variant="h4" id="tableTitle" component="div">
                                        Download Table
                                    </Typography>
                                    <Row style={{ marginBottom: '-40px' }}>
                                        <Col lg="4" md="4">
                                            <SearchButton
                                                placeholder={'Excel'}
                                                handleSearch={() => handleClickDownloadExcel(rows)}
                                                height='true'
                                            />
                                        </Col>

                                        <Col lg="4" md="4">
                                            <SearchButton
                                                placeholder={'CSV'}
                                                handleSearch={() => handleClickDownloadCSV(rows)}
                                                height='true'
                                            />
                                        </Col>

                                        <Col lg="4" md="4">
                                            <SearchButton
                                                placeholder={'Cancel'}
                                                handleSearch={handleCloseDownload}
                                                height='true'
                                                rsa='true'
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            }


                            {
                                openCreateModal &&
                                <RoleAddEdit
                                    roles={roles}
                                    onChange={handleChange}
                                    handleClose={handleClose}
                                    handleDone={handleDone}
                                    title='New comment'
                                    heading='Title'
                                    description='Description'
                                    variant='true'
                                    handleConfirmvehicleDetails={handleConfirmvehicleDetails}
                                />
                            }
                            {
                                confirmVehicleDetails &&
                                <ConfirmDetails handleOpenDetailsPage={handleOpenDetailsPage} handleReturnPage={handleReturnPage} />
                            }
                        </>
                    </Modal>


                    {
                        openDetailsPage ?
                            <div>
                                <DetailsMainPage
                                    vin={vin || queryParamsVin}
                                    closeVehicleDetail={closeHandleDrawer}
                                    openDetail={isExpanded}
                                    selectTable={selectTable}
                                    inputVIN={vin}
                                    setOpenUsedCar={true}
                                    transportStatus={transportStatus}
                                    caseNumber={caseNumber}
                                    customerName={customerName}
                                    prefferedLanguage={prefferedLanguage}
                                    validRSA={validRSA}
                                    status={'Open'}
                                    caseOwner={graphData.displayName}
                                    vehicleHealth={vehicleHealth}
                                />
                            </div>

                            :
                            <div id='tablecard' >
                                <Row className='headerStyleMyOpenCases'>
                                    <Col lg="8" md="8" sm="8" xs="8" >
                                        {
                                            showText && displayMyOpenCases && <div className="fontStylingDriver" style={{ fontFamily: 'LynkcoTypeBold' }}>My Cases</div>
                                        }
                                        {
                                            showText && displayAssignedCases && <div className="fontStylingAssigned" style={{ fontFamily: 'LynkcoTypeBold' }}>Assigned</div>
                                        }
                                        {
                                            showText && displayUnassignedCases && <div className="fontStylingUnassigned" style={{ fontFamily: 'LynkcoTypeBold' }} setShowText={setShowText}>Unassigned</div>
                                        }
                                        {
                                            showText && displayResolvedCases && <div className="fontStylingResolved" style={{ fontFamily: 'LynkcoTypeBold' }}>Resolved</div>
                                        }
                                    </Col>
                                    <Col lg="4" md="4" sm="4" xs="4" >
                                        {
                                            showTable &&
                                            <Row className='rowheightOpenCases'>
                                                <Col lg="4" md="4" sm="4" xs="4">
                                                    {
                                                        toggleSearchBar ?
                                                            <></>
                                                            :
                                                            <div className={displayUnassignedCases ? 'dropdownSelectUnassigned' : displayAssignedCases || displayResolvedCases ? 'dropdownSelectAssigned' : 'dropdownSelect'}>
                                                                <DropdownSelect
                                                                    label='Cases'
                                                                    name='cases'
                                                                    dropdownfont='true'
                                                                    value={displayValue}
                                                                    style={{ zIndex: 2000 }}
                                                                    tabledropdownheight="true"
                                                                    menuObjectArray={[
                                                                        { 'key': 'My Open Cases', 'value': 'My Open Cases' },
                                                                        { 'key': 'Unassigned Cases', 'value': 'Unassigned Cases' },
                                                                        { 'key': 'Assigned Cases', 'value': 'Assigned Cases' },
                                                                        { 'key': 'Resolved Cases', 'value': 'Resolved Cases' },
                                                                    ]}
                                                                    onChange={handleChangeUCA("cases")}
                                                                />
                                                            </div>
                                                    }

                                                </Col >
                                                <Col lg="4" md="4" sm="4" xs="4">
                                                    {
                                                        showText && displayMyOpenCases &&
                                                        <Tooltip
                                                            title="Create new case"
                                                            onOpen={handleOpenHomeIcon}
                                                            onClose={handleCloseHomeIcon}
                                                            open={openIcon}
                                                            style={{ zIndex: 20 }}
                                                        >
                                                            <IconButton
                                                                onClick={handleClickAdd}
                                                                size="small"
                                                                sx={{ ml: 2 }}
                                                                aria-controls={open1 ? "account-menu" : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open1 ? "true" : undefined}
                                                            >
                                                                <img
                                                                    className="plusIconMyOpenCases"
                                                                    src={icon}
                                                                    alt="Three Dot Image"
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }


                                                </Col >
                                                <Col lg="4" md="4" sm="4" xs="4">
                                                    {
                                                        showText && displayMyOpenCases &&
                                                        <div className='downloadSelectStyle'>
                                                            <FormSelect
                                                                menuObjectArray={[
                                                                    { 'key': '1', 'value': 'Download' }
                                                                ]}
                                                                onClick={handleDropdown}
                                                            />
                                                        </div>

                                                    }

                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                    {
                                        <Fade bottom duration={1500}>
                                            <div className="tableFixHeadCarPool">
                                                {
                                                    showTable && displayMyOpenCases &&
                                                    <Row>
                                                        <Col md={12} sm={12} xs={12} lg={12}>

                                                            {
                                                                <ReactTableBaseVehiclePage
                                                                    key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                                                    columns={columns}
                                                                    data={rows}
                                                                    updateEditableData={updateEditableData}
                                                                    updateDraggableData={updateDraggableData}
                                                                    tableConfig={tableConfig}
                                                                    isColumnHidden={isColumnHidden}
                                                                    isSelected={isSelected}
                                                                    selectedRowValues={selectedRowValues}
                                                                    earmarkedRowValues={earmarkedRowValues}
                                                                    earMarkedRows={earMarked}
                                                                    handleDrawar={handleDrawar}
                                                                    closeHandleDrawer={closeHandleDrawer}
                                                                    vehiclePosition={vehiclePosition}
                                                                    selected={selected}
                                                                    isExpanded={isExpanded}
                                                                    selectAllRows={selectAllRows}
                                                                    isPageSelectAll={isPageSelectAll}
                                                                    tableStyleToggleMyOpenCases={tableHight}
                                                                />
                                                            }

                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                            <>
                                                {
                                                    showTable && displayMyOpenCases && dataLoaded && <div className='paginationMyOpenCases' style={{ fontFamily: 'LynkcoTypeLight' }}>Total Records: {'5'}</div>
                                                }
                                            </>
                                        </Fade>
                                    }
                                </Row>
                            </div>}

                    {/* </div> */}
                </Col>
            </Row>
            {
                showTable && displayUnassignedCases && <UnassignedCases setToggleSearchBar={setToggleSearchBar} toggleSearchBar={toggleSearchBar} setShowText={setShowText} />
            }
            {
                showTable && displayAssignedCases && <AssignedCases setToggleSearchBar={setToggleSearchBar} toggleSearchBar={toggleSearchBar} setShowText={setShowText} />
            }
            {
                showTable && displayResolvedCases && <ResolvedCases setToggleSearchBar={setToggleSearchBar} toggleSearchBar={toggleSearchBar} setShowText={setShowText} />
            }
        </Container>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state),
    graphData: selectUserGraphData(state)
});

export default connect(mapStateToProps, null)(MyOpenCasesTable);