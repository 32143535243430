
import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ label, handleChange }) => {
  return (
    <label className="container">
      {/* {label}{" "} */}
      <span>Yearly</span>
      <span className="toggle-switch">
        <input type="checkbox" className="checkbox"
          name={label} id={label} onChange={handleChange} />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </span>
      <span>Current Month</span>
    </label>
  );
};

export default ToggleSwitch;