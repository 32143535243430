import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import SearchBar from 'src/components/buttons/search-icon-button.component';
import { Icon } from '@iconify/react';


export default function RadioButtonsGroup({ roleDetails, roleSubDetails, stolenVehicleFlag }) {

  const [value, setValue] = React.useState('');
  const [value1, setValue1] = React.useState('');
  const [SearchFieldInput, setSearchFieldInput] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);

    if (event.target.value == 'Yes') {
    }
  };

  const handleChange1 = (event) => {
    setValue1(event.target.value);
  };

  const controlProps = (item) => ({
    checked: value === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },

  });

  const handleSearchInput = (event) => {
    setSearchFieldInput(event.target.value)
    console.log(event.target.value)
    console.log(event.target.value.length)
    if (event.target.value.length === 0) {
    }
  };

  // const textFieldKeypressSearch = (e) => {
  //     if (e.key === 'Enter') {
  //         handleExtendedSearch();
  //     }
  // };


  return (
    <>
      {
        stolenVehicleFlag &&
        <>
          <div style={{ width: '300%', marginTop: '-3px' }}>
            <div style={{ marginLeft: '15px' }}>
              Police reference number
            </div>
            <SearchBar
              placeholder={''}
              // handleSearch={handleExtendedSearch}
              handleSearchInput={handleSearchInput}
              // onKeyPress={textFieldKeypressSearch}
              value={SearchFieldInput}
              priceBucketActiveTab='true'
              autoFocus='true'
            />
          </div>
        </>
      }

      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          onChange={handleChange}
        >
          {
            roleDetails.map((item, index) => {
              return (
                item.value == 'Vehicle towed to workshop' && value == 'Vehicle towed to workshop' ?
                  <>
                    <FormControlLabel
                      disableTypography='true'
                      sx={{
                        color: '#BFBFBF',
                        fontSize: '15px',
                        fontFamily: 'LynkcoTypeLight',
                        // marginBottom: '10px'
                      }}
                      value={item.value}
                      control={
                        <Radio
                          {...controlProps(item.value)}
                          sx={{
                            color: '#BFBFBF',
                            '&.Mui-checked': {
                              color: '#1EF1C6',

                            },
                            marginTop: '-107px',
                          }}
                          icon={<Icon icon="material-symbols-light:radio-button-checked-outline" color="darkgray" width="28" height="28" />}
                          checkedIcon={<Icon icon="material-symbols-light:radio-button-checked-outline" color="#1EF1C6" width="28" height="28" />}
                        />
                      }
                      label={
                        <span style={{ marginLeft: '20px', marginTop: '12px' }}>
                          <span style={{ marginLeft: '-20px', marginBottom: '-3px' }}>
                            {item.value}
                          </span>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            onChange={handleChange1}
                          >
                            {
                              roleSubDetails.map((item) => {
                                return (
                                  <FormControlLabel
                                    disableTypography='true'
                                    sx={{
                                      color: '#BFBFBF',
                                      fontSize: '15px',
                                      fontFamily: 'LynkcoTypeLight',
                                      marginTop: '6px'
                                    }}
                                    value={item.value}

                                    control={
                                      <Radio
                                        // {...controlProps1(item.value)}
                                        sx={{
                                          color: '#BFBFBF',
                                          '&.Mui-checked': {
                                            color: '#1EF1C6',
                                          },
                                        }}
                                        icon={<Icon icon="material-symbols-light:radio-button-checked-outline" color="darkgray" width="28" height="28" />}
                                        checkedIcon={<Icon icon="material-symbols-light:radio-button-checked-outline" color="#1EF1C6" width="28" height="28" />}
                                      />}
                                    label={item.value}

                                  />
                                )
                              })
                            }
                          </RadioGroup>

                        </span>
                      }
                    />
                  </>
                  :
                  <FormControlLabel
                    disableTypography='true'
                    sx={{
                      color: '#BFBFBF',
                      fontSize: '15xpx',
                      fontFamily: 'LynkcoTypeLight',
                      marginLeft: stolenVehicleFlag ? '6px' : ''
                    }}
                    value={item.value}
                    control={
                      <Radio
                        {...controlProps(item.value)}
                        sx={{
                          color: '#BFBFBF',
                          '&.Mui-checked': {
                            color: '#1EF1C6',
                          }
                        }}
                        icon={<Icon icon="material-symbols-light:radio-button-checked-outline" color="darkgray" width="28" height="28" />}
                        checkedIcon={<Icon icon="material-symbols-light:radio-button-checked-outline" color="#1EF1C6" width="28" height="28" />}
                      />
                    }
                    label={<span style={{ marginTop: '3px', fontSize: '15px' }}>
                      {item.value}
                    </span>
                    }
                  />
              )
            })
          }
        </RadioGroup>
      </FormControl>
    </>
  );
};