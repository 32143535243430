import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: (props) => props.iconProp ? 'transparent' : props.rsa ? '#DC1860' : props.loader ? 'transparent' : props.cancelprop ? 'transparent' : '#1ef1c6',
        borderRadius: '30px',
        zIndex: 2000,
        position: 'relative',
        height: (props) => props.height ? '45px' : props.width ? '40px' : '36px',
        color: (props) => props.iconProp ? '#DC1860' : props.rsa ? 'white' : props.loader ? '#b3b3b3' : props.cancelprop ? '#b3b3b3' : '#000',
        border: (props) => props.iconProp ? '1px solid #DC1860' : props.rsa ? '' : props.loader ? '1px solid #b3b3b3' : props.cancelprop ? '1px solid #b3b3b3' : '',
        textTransform: 'initial',
        fontFamily: 'LynkcoTypeMedium',
        '&:hover': {
            backgroundColor: (props) => props.iconProp ? 'transparent' : props.rsa ? '#893257' : props.loader ? 'transparent' : props.cancelprop ? 'transparent' : '#0A9E80',
            color: (props) => props.iconProp ? '#DC1860' : props.loader ? '#1ef1c6' : props.cancelprop ? '#dc1860' : '#000',
            border: (props) => props.loader ? '1px solid #1ef1c6' : props.cancelprop ? '1px solid #dc1860' : '',
        },
        whiteSpace: 'nowrap',
        width: (props) => props.width ? '3vw' : props.height ? '100px' : '113px',
        fontSize: 'calc(9px + 0.390625vw)',
        '@media (min-width: 1501px) and (max-width: 1600px)': {
            // marginTop: '-58px',
            borderRadius: '30px',
            height: (props) => props.table ? '47px' : '42px'
        },
        '@media (min-width: 1401px) and (max-width: 1500px)': {
            // marginTop: (props) => props.table ? '-5px' : '-220px',
            height: (props) => props.height ? '40px' : props.width ? '40px' : '50px',
            marginBottom: (props) => props.table ? '10px' : '0px'
        },
        '@media (min-width: 2601px) and (max-width: 4600px)': {
            height: (props) => props.height ? '40px' : props.width ? '47px' : '50px',
            width: (props) => props.width ? '2.2vw' : '90px',
            fontSize: 'calc(7px + 0.390625vw)',
        },
        '@media (min-width: 1281px) and (max-width: 1400px)': {
            // marginTop: '-220px',
            height: (props) => props.height ? '40px' : props.width ? '30px' : '50px',
        },
        '@media (min-width: 1274px) and (max-width: 1280px)': {
            // marginTop: (props) => props.table ? '-90px' : '-220px',
            height: (props) => props.height ? '40px' : props.width ? '30px' : '45px',
            marginBottom: (props) => props.table ? '-60px' : '0px'
        },
        '@media (min-width: 1112px) and (max-width: 1273px)': {
            height: (props) => props.height ? '40px' : props.width ? '30px' : '35px',
            width: (props) => props.width ? '3vw' : '95px',
            // marginTop: (props) => props.table ? '-20px' : '-300px',
            marginBottom: (props) => props.table ? '3px' : '0px'
        },
        '@media (min-width: 1026px) and (max-width: 1075px)': {
            // marginTop: (props) => props.table ? '-20px' : '-200px',
            height: (props) => props.height ? '40px' : props.width ? '30px' : '40px',
            width: (props) => props.width ? '3vw' : '90px'
        },
        '@media (min-width: 1001px) and (max-width: 1025px)': {
            // marginTop: (props) => props.table ? '-20px' : '-195px',
            height: (props) => props.height ? '40px' : props.width ? '30px' : '40px',
            width: (props) => props.width ? '3vw' : '90px'
        },
        '@media (min-width: 771px) and (max-width: 1000px)': {
            height: (props) => props.height ? '50px' : props.width ? '30px' : '56px',
            width: (props) => props.width ? '3vw' : '133px',
            fontSize: 'calc(15px + 0.390625vw)',
            marginBottom: (props) => props.table ? '10px' : '0px'
        },
        '@media (min-width: 600px) and (max-width: 770px)': {
            height: (props) => props.height ? '40px' : props.width ? '30px' : '56px',
            width: (props) => props.width ? '3vw' : '133px',
            fontSize: 'calc(15px + 0.390625vw)',
            marginBottom: (props) => props.table ? '10px' : '0px'
        }
    }
}));

const SearchButton = (props) => {
    const classes = useStyles(props);
    const { handleSearch, placeholder, icon, iconProp = false, height, rsa, width, packageSelect = false, loader = false, table, cancelprop = false } = props;

    return (
        <Button onClick={handleSearch} className={classes.button} variant="contained" placeholder={placeholder} icon={icon}>
            {placeholder}
            {icon}
        </Button>
    );
};

export default SearchButton;