import React from 'react';
import { Card, CardBody, } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { FormTextFieldSalesPlain } from 'src/components/form-elements/custom-form-text-field.salessettings';
import { FormSelectSales } from 'src/components/form-elements/custom-form-select.SalesSettings.component';
import { makeStyles, styled } from '@material-ui/core/styles';
import blackVehicle from 'src/assets/img/vehicles/blackVehicle.png';
import blueVehicle from 'src/assets/img/vehicles/blueVehicle.png';
import ColorSelect from 'src/components/form-elements/color-select.component';
import EngineSelect from 'src/components/form-elements/engine-select.component';
import ModelYearSelect from 'src/components/form-elements/modelYear-select.component';
import Divider from '@material-ui/core/Divider';

const UseStyles = makeStyles((theme) => ({
  gridContainerStyle: {
    height: '30%',
    maxHeight: '50%',
    width: '24%',
    border: "1px solid rgba(255, 255, 255, 0.5)",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'sticky',
  },
  heading: {
    "&.MuiTypography-root": {
      color: "#fff",
      fontFamily: 'LynkcoTypeLight,LynkcoTypeMedium',
      marginTop: theme.spacing(2),
    }
  },
  heading1: {
    "&.MuiTypography-root": {
      color: "#fff",
      fontFamily: 'LynkcoTypeLight,LynkcoTypeMedium',
      marginTop: theme.spacing(2),
      marginLeft: '15px'
    }
  },
  numOfVins: {
    color: (props) => props.variant.numberOfVins > 10 ? '#1EF1C6' : '#DC1860',
    fontSize: '1.2rem'
  },
  tooltipImg: {
    flexDirection: 'row',
    flexWrap: 'noWrap',
    marginTop: '-10px'
  },
  img: {
    backgroundImage: (props) => props.vehicleColor == 'Blue' ?
      `url(${blueVehicle})` : props.vehicleColor == 'Black' ?
        `url(${blackVehicle})` : '',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: "150px",
    display: "block",
    left: "0px",
    top: "0",
    backgroundPosition: "center center",
    '-webkit-transition': { background: '3s' },
    '-moz-transition': { background: '3s' },
    '-o-transition': { background: '3s' },
    transition: { background: '3s' },
    transform: 'scale(1.1)'

  },
  dividerStyle: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    marginLeft: -theme.spacing(.3),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(.5),
    height: '1px'
  },
  title: {
    flex: '1 1 180%',
    fontSize: 'large'
  },

}));

const MasterProductVariant = (props) => {
  let { bucketName, onChange, variantName, vehicleColor = 'default', variant, click } = props

  const classes = UseStyles(props);

  let menuObjectModelArray = [{ 'key': '01', 'value': 'Lynk & Co 01' }]
  let menuOjecthasTowbarArray = [{ 'key': true, 'value': 'Incl. towbar' }, { 'key': false, 'value': 'No towbar' }]

  let ageRange = [
    { 'key': '6', 'value': '6' }, { 'key': '7', 'value': '7' }, { 'key': '8', 'value': '8' },
    { 'key': '9', 'value': '9' }, { 'key': '10', 'value': '10' }, { 'key': '11', 'value': '11' },
    { 'key': '12', 'value': '12' }, { 'key': '13', 'value': '13' }, { 'key': '14', 'value': '14' },
    { 'key': '15', 'value': '15' }, { 'key': '16', 'value': '16' }, { 'key': '17', 'value': '17' },
    { 'key': '18', 'value': '18' }, { 'key': '19', 'value': '19' }, { 'key': '20', 'value': '20' },
    { 'key': '21', 'value': '21' }, { 'key': '22', 'value': '22' }, { 'key': '23', 'value': '23' },
    { 'key': '24', 'value': '24' }
  ]

  let mileageRange = [
    { 'key': '10000', 'value': '10000' }, { 'key': '12000', 'value': '12000' },
    { 'key': '15000', 'value': '15000' }, { 'key': '17000', 'value': '17000' },
    { 'key': '20000', 'value': '20000' }, { 'key': '25000', 'value': '25000' },
    { 'key': '30000', 'value': '30000' }, { 'key': '35000', 'value': '35000' },
    { 'key': '40000', 'value': '40000' }
  ]

  return (
    <div>
      <Card style={{ backgroundColor: 'rgba(33, 33, 33, 0.5)', marginBottom: '1px' }}>
        <CardBody style={{ marginLeft: '-27px', marginRight: '-20px', marginTop: '-12px' }}>
          <div style={{ padding: '2px' }}>
            <Grid container spacing={1} >
            <Grid container  style={{ marginLeft: '3px' }}>
              <Grid item xs={3} style={{ marginRight: '34px' }}>
                <Typography style={{ width: '480%' }} className={classes.heading1}>Available</Typography>
              </Grid>
              <Grid item xs={3} style={{ marginRight: '-34px' }}>
                <FormTextFieldSalesPlain onClick={click} value={variant != undefined ? variant.numberOfVins != undefined ? variant.numberOfVins : 0 : 0} adjustTopMargin={false} name='noOfVins' placeholder='' disabled={true} isNumberOfVins={true} />
              </Grid>
              <Grid item xs={3} style={{ marginRight: '34px' }}>
                <Typography style={{ width: '480%' }} className={classes.heading1}>Pending</Typography>
              </Grid>
              <Grid item xs={3} style={{ marginRight: '-34px' }}>
                <FormTextFieldSalesPlain onClick={click} value={variant != undefined ? variant.pendingForVINCount != undefined ? variant.pendingForVINCount : 0 : 0} adjustTopMargin={false} name='pendingForVINCount' placeholder='' disabled={true} />
              </Grid>
              </Grid>
              <Grid item xs={6} >
                <FormSelectSales value={variant != undefined ? variant.model.toString() : ''} onChange={onChange(bucketName, variantName, 'model')} label='Model' name='model' placeholder='Model'
                  menuObjectArray={menuObjectModelArray} />
              </Grid>
              <Grid item xs={6} spacing={6}>
                <ColorSelect value={variant != undefined ? variant.color.toString() : ''} onChange={onChange(bucketName, variantName, 'color')} label='Color' name='color' placeholder='Color' />
              </Grid>
              <Grid item xs={6}>
                <EngineSelect value={variant != undefined ? variant.engineType.toString() : ''} onChange={onChange(bucketName, variantName, 'engineType')} label='Engine' name='engineType' placeholder='Engine' />
              </Grid>
              <Grid item xs={6}>
                <FormSelectSales value={variant != undefined ? variant.hasTowbar.toString() : ''} onChange={onChange(bucketName, variantName, 'hasTowbar')} label='Towbar State' name='hasTowbar'
                  menuObjectArray={menuOjecthasTowbarArray} />
              </Grid>
              <Grid item xs={6}>
              </Grid>
              <Grid item xs={12} md={12} className={classes.img}>
                {/* <GetVehicleImage color={"blue"}></GetVehicleImage> */}
              </Grid>
              <div className={classes.title} style={{ marginLeft: '18px', flex: '1 1 180%', }}>
                <Grid container spacing={6}>
                  <Grid item xs={6} >
                    <Typography className={classes.heading}><span >Model Year</span></Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <ModelYearSelect value={variant != undefined ? variant.modelYear.toString() : ''} onChange={onChange(bucketName, variantName, 'modelYear')} label='' name='modelYear'
                    />
                  </Grid>

                </Grid>
                <Divider className={classes.dividerStyle} orientation='horizontal'></Divider>
              </div>
              <div className={classes.title} style={{ marginLeft: '18px', flex: '1 1 180%', }}>
                <Grid container spacing={0.6}>
                  <Grid item xs={3}>
                    <Typography className={classes.heading}>Age</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormSelectSales value={variant != undefined ? variant.age.minValue.toString() : ''} onChange={onChange(bucketName, variantName, 'fromAge')} label='from' name='fromAge' placeholder='from'
                      menuObjectArray={ageRange} />
                  </Grid>
                  <Grid item xs={5}>
                    <FormSelectSales value={variant != undefined ? variant.age.maxValue : ''} onChange={onChange(bucketName, variantName, 'toAge')} label='to' name='toAge'
                      menuObjectArray={ageRange} />
                  </Grid>
                </Grid>
                <Divider className={classes.dividerStyle} orientation='horizontal'></Divider>
              </div>
              <div className={classes.title} style={{ marginLeft: '18px', flex: '1 1 180%', }}>
                <Grid container >

                  <Grid item xs={3} >
                    <Typography className={classes.heading}>Mileage</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormTextFieldSalesPlain value={variant != undefined ? variant.mileage.minValue : ''} onChange={onChange(bucketName, variantName, 'fromMileage')} adjustTopMargin={false} name='fromMileage' placeholder='fromMileage' disabled={false} />
                  </Grid>
                  <Grid item xs={5}>
                    <FormSelectSales value={variant != undefined ? variant.mileage.maxValue : ''} onChange={onChange(bucketName, variantName, 'toMileage')} label='to' name='toMileage'
                      menuObjectArray={mileageRange} />
                  </Grid>
                  <Grid item xs={4}>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
export default MasterProductVariant;