import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RvReactTable from 'src/pages/Tables/DataTable/indexRV';
import SalesSettings from 'src/pages/sales-settings-page/SalesSettings';
import DefaultPage from 'src/pages/backgrounds/default-page';
import { selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';
import { permission } from 'src/auth/permissions';
import { Typography } from '@material-ui/core';
import BMCPricing from 'src/pages/bmc-pricing/BMCPricing';

function TabPanel(props) {
  const { userPermissions, userToken, children, value, index, ...other } = props;
  const values = [permission.Remarketing_Administrator, permission.Remarketing_Reader];
  const matchesPermission = values.some(permission => userPermissions.includes(permission))

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {matchesPermission ? (
        <>
          {value === index && value === 0 && (
            <RvReactTable />
          )}
          {value === index && value === 1 && (
            <SalesSettings />
          )}
          {value === index && value === 2 && (
            <BMCPricing />
          )}
        </>
      ) : (
        <>
          {value === index && value === 0 && (
            <DefaultPage />
          )}
          {value === index && value === 1 && (
            <DefaultPage />
          )}
          {value === index && value === 2 && (
            <DefaultPage />
          )}
        </>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
    margin: -theme.spacing(2.9),
    height: '55px',
    marginLeft: '115px'
  },
  panel: {
    marginLeft: '35px',
    position: "fixed",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundImage: 'linear-gradient(60deg, rgba(13,13,13,1), rgba(37,37,37,1) 99%)',
    padding: "55px",
  },
  tabBar: {
    marginRight: theme.spacing(-22),
    marginLeft: theme.spacing(22.5),
    textTransform: 'initial',
    fontSize: '15px',

  },
  appBar: {
    backgroundColor: "#131313",
    position: 'fixed',
    marginTop: '-100px',
    marginLeft: '-180px',
    zIndex: '20',
    height: '48px',
    boxShadow: '0 5px 6px #000000'
  }
}));

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#1EF1C6',
    height: '3px',
    maxWidth: '110px',
    marginLeft: '25px'
  }
})(Tabs);

const RvTabs = ({ userPermissions, userToken }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Typography style={{ textAlign: 'center', fontSize: '15px', marginTop: '15px', marginLeft: '35px', fontFamily: "LynkcoTypeRegular", cursor: 'pointer', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', letterSpacing: '2px' }} variant="h4" id="tableTitle" component="div">
          REMARKETING
        </Typography>
        <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Residual Value" {...a11yProps(0)} className={classes.tabBar} />
          <Tab label="B2C eCom" {...a11yProps(1)} className={classes.tabBar} />
          <Tab label=" BMC Pricing" {...a11yProps(2)} className={classes.tabBar} />

        </StyledTabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
        RV CURVE
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
        SALES SETTINGS
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
        BMC PRICING
      </TabPanel>
    </div>
  );
}

const mapStateToProps = state => ({
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(RvTabs)