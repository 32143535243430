import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#1ef1c6',
        color: '#000',
        '&:hover': {
            backgroundColor: '#339586',
            color: '#FFF'
        },
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    }
}));

const OkButton = ({ handleDialogOk, okButtondKeypress }) => {
    const classes = useStyles();
    return (
        <Button onClick={handleDialogOk} onKeyPress={okButtondKeypress} className={classes.button}>
            Ok
        </Button>
    );
}

export default OkButton;