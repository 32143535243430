import { environment } from 'src/environments/environment';
import { msalInstance } from "src/index";
import { loginRequest } from "src/authConfig";

export async function GetTranslation(propertyType) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    // headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(
            propertyType
        )
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetTranslation/GetFleetTranslationByPropertType`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}



export async function GetTranslationWithIntegratorValues(propertyType) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    // headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(
            propertyType
        )
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/FleetTranslation/GetFleetTranslationIntegratorValuesByPropertTypes`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}