import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Row, Col, CardBody, CardImg } from "reactstrap";
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds } from "src/redux/user/user.selectors";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import { GetFleets } from "src/services/fleet-http.service";
import { QueryByVIN } from "src/services/vehicle-pages-http.service";
import { GetFleetTypes } from "src/services/fleetType-http.service";
import SearchBarProvisioning from "src/components/buttons/search-button-provisioning.component";
import profilebgBlue from "src/assets/img/BackgroundImages/ProvisioningPicture_Blue.png";
import profilebgBlack from "src/assets/img/BackgroundImages/ProvisioningPicture_Black.png";
import { FormmatDate, } from 'src/utils/DateFormatter';
import ConnectivityTable from "src/pages/driver-page/ConnectivityTable";

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(3.7),
        position: 'sticky',
        marginLeft: '11px',
        marginTop: '-22px'
    },
    drawer: {
        width: 250,
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px'
    },
    gridContainerStyle1: {
        width: '100%',
    },
    cardStyle: {
        height: '665px',
        borderRadius: '1px'
    },
    cardStyleLarge: {
        height: '665px',
        borderRadius: '4px',
        marginRight: '-22px'
    },
    imageStyle: {
        marginTop: '60px',
        marginLeft: '15px',
        maxHeight: '610px',
        minHeight: '500px'
    },
}));

let vin = ''

const UnProvisioningPage = (props) => {
    const { userOrg, userMarketIds } = props
    const classes = UseStyles(props);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [searchInputVIN, setSearchInputVIN] = useState(false);
    const [displaySelectvehicle, setDisplaySelectedVehicle] = useState(false);
    const [searchVIN, setSearchVIN] = React.useState('')
    const [vehicleDetail, setVehicleDetail] = React.useState({});
    const [message, setMessage] = React.useState('');

    const handleDialogOk = () => {
        setOpenDialog(false);
        setSearchInputVIN(false);
    }

    const handleChangeVINorLIcensePlate = () => {

        if (searchVIN?.trim()?.length != 0) {
            let fleetTypes = [];
            let fleetIds = [];
            let marketIds = userMarketIds;

            GetFleets(userOrg.id, userMarketIds)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(item => {
                            fleetIds.push(item.id);
                        })
                        GetFleetTypes(userOrg.id)
                            .then(data => {
                                if (data && Array.isArray(data)) {
                                    data.map(fleetType => {
                                        fleetTypes.push(fleetType['id']);
                                    })
                                }
                                QueryByVIN(searchVIN?.trim(), fleetIds, fleetTypes, marketIds)
                                    .then(data => {
                                        if (data.ok == true) {
                                            setSearchInputVIN(true);
                                            setVehicleDetail(data.fleetAsset);
                                            setDisplaySelectedVehicle(true);
                                        } else {
                                            setSearchVIN();
                                            setOpenDialog(true);
                                            setMessage(data.title);
                                            setDialogMessage(data.errorMessage);
                                        }
                                    });
                            })
                    }
                })
        }
    }

    const handleSearchInputVIN = (event) => {
        setSearchVIN(event.target.value);
        console.log(event.target.value);
        console.log(event.target.value.length);
        if (event.target.value.length === 0) {
            setSearchInputVIN(false);
        }
    }

    const textFieldKeypressVIN = (e) => {
        if (e.key === 'Enter') {
            handleChangeVINorLIcensePlate();
        }
    }

    return (
        <div style={{ marginTop: '75px', marginRight: '34px', borderRadius: '1px', position: 'relative', }}>
            <VehiclePageDialog
                dialogue='true'
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
                title={message}
            />
            <Row>
                <div style={{ width: '80%', marginTop: '-10px' }}>
                    <SearchBarProvisioning
                        connectivityColor='true'
                        placeholder={'VIN or Registration Number'}
                        handleSearch={handleChangeVINorLIcensePlate}
                        handleSearchInput={handleSearchInputVIN}
                        onKeyPress={textFieldKeypressVIN}
                        value={searchVIN}
                    />
                </div>
            </Row>
            {searchInputVIN &&
                <Row>
                    <Col lg="4" md="12" >
                        <div>
                            <Card id="CardBodyCardAllowance3" className={classes.cardStyle} >
                                <CardBody>
                                    <Row>
                                        <Col lg="12" md="12" >
                                            <Row style={{ marginRight: '-10px' }}>
                                                {searchInputVIN && <>
                                                    {
                                                        vehicleDetail != undefined &&
                                                        <Row>
                                                            <Col md={12} sm={12} xs={12} lg={7}>
                                                                <CardImg
                                                                    alt="Card image cap"
                                                                    src={vehicleDetail.color == 'Blue' ? profilebgBlue : profilebgBlack}
                                                                    className={classes.imageStyle}
                                                                />
                                                            </Col>

                                                            <Col lg="5" md="12" >
                                                                <div style={{ marginLeft: '15px' }} >
                                                                    <div style={{ marginTop: '70px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }}  >License Plate</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }}  >{vehicleDetail.licensePlate}</span> <br /><br />
                                                                    </div>

                                                                    <div style={{ marginTop: '15px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }} >Model</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }} >{vehicleDetail.model === '01' ? 'Lynk & Co 01' : ''}</span> <br /><br />
                                                                    </div>

                                                                    <div style={{ marginTop: '15px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }} >Model Year</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }} >{vehicleDetail.modelYear}</span> <br /><br />
                                                                    </div>

                                                                    <div style={{ marginTop: '15px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }} >Engine Type</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }} >{vehicleDetail.engineType}</span> <br /><br />
                                                                    </div>

                                                                    <div style={{ marginTop: '15px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }} >Color</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }} >{vehicleDetail.color}</span> <br /><br />
                                                                    </div>

                                                                    <div style={{ marginTop: '15px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }} >Towbar</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }} >{vehicleDetail.hasTowbar}</span> <br /><br />
                                                                    </div>

                                                                    <div style={{ marginTop: '15px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }} >Registered</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }} >{FormmatDate(vehicleDetail.registeredDateUtc)}</span> <br /><br />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </>}
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>

                    <Col lg="8" md="12" >
                        {displaySelectvehicle &&
                            <div>
                                <Card id="CardBodyCardAllowance3" className={classes.cardStyleLarge} >
                                    <CardBody>
                                        <ConnectivityTable searchVIN={searchVIN} />
                                    </CardBody>
                                </Card>
                            </div>
                        }
                    </Col>
                </Row>
            }
        </div>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(UnProvisioningPage);