import React, { useState } from "react";
import { Row, Col } from "reactstrap";
// import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Feeds,
  TodoList,
  ActiveSubscriptions,
  EndingSubscriptions,
  NewOrderSubscriptions
} from "../../components/dashboard/index.js";
import DefaultPage from 'src/pages/backgrounds/default-page';
import MarketSelect from 'src/components/form-elements/market-select.dashboard.component';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';

// import img1 from "../../assets/images/users/1.jpg";
// import img4 from "../../assets/images/users/4.jpg";
// import img5 from "../../assets/images/users/5.jpg";

const Subscriptions = ({ userOrg, userMarketIds }) => {

  const [isMarketSelected, setIsMarkedSelected] = useState(false)

  let selectedMarket = userMarketIds.toString()

  let marketId = 'ALL'

  const handleChange = (event, markets) => {
    const { name, value } = event.target;
    let orgsMarkets = [];
    marketId = value.split('#')[0];
    let marketNmae = value.split('#')[1];

    if (marketId == 'ALL') { selectedMarket = userMarketIds }
    else {
      selectedMarket = [marketId]
      setIsMarkedSelected(true)
    }
  }

  return (
    <div style={{ overflow: 'auto', height: '96vh', backgroundColor: 'rgb(19 19 19 / 95%)', marginTop: '-170px', padding: '40px 0 10px 0', marginLeft: '-20px', marginRight: '-26px' }}>
      <DefaultPage backgroundtype={true} />
      <MarketSelect
        orgId={userOrg.id}
        value={selectedMarket.value}
        userMarketIds={userMarketIds}
        handleChange={handleChange}
      />

      {/* <ProgressCards /> */}
      {/* --------------------------------------------------------------------------------*/}
      {/* Row-1                                                                          */}
      {/* --------------------------------------------------------------------------------*/}

      <Row>
        <Col lg="4" md="12">
          <EndingSubscriptions />
        </Col>
        <Col lg="4" md="12">
          <NewOrderSubscriptions />

        </Col>
        <Col lg="4" md="12">
          <ActiveSubscriptions />
        </Col>
      </Row>
      {/* --------------------------------------------------------------------------------*/}
      {/* Row-2                                                                          */}
      {/* --------------------------------------------------------------------------------*/}
      <Row>
        {/* <Col lg="12" className="d-flex align-items-stretch">
          <ActiveSubscriptions />
        </Col> */}
        {/* <Col lg="4" className="d-flex align-items-stretch">
          <CardProfile />
        </Col> */}
      </Row>
      {/* --------------------------------------------------------------------------------*/}
      {/* Row-3                                                                          */}
      {/* --------------------------------------------------------------------------------*/}
      <Row>
        <Col lg="6" >
          <Feeds />
        </Col>
        <Col lg="6" >
          <TodoList />
        </Col>
      </Row>
      {/* --------------------------------------------------------------------------------*/}
      {/* Row-4                                                                          */}
      {/* --------------------------------------------------------------------------------*/}
      {/* <Row>
        <Col lg="6">
          <Card>
            <CardBody>
              <CardTitle>Recent Comments</CardTitle>
              <CardSubtitle>
                Latest Comments on users from Material
              </CardSubtitle>
            </CardBody>
            <div
              className="comment-widgets scrollable"
              style={{ height: "382px" }}
            >
              <PerfectScrollbar>
                <RecentComment
                  image={img1}
                  badge="Pending"
                  badgeColor="info"
                  name="James Anderson"
                  comment="Lorem Ipsum is simply dummy text of the printing and type setting industry."
                  date="April 14, 2016"
                />
                <RecentComment
                  image={img4}
                  badge="Approved"
                  badgeColor="success"
                  name="Michael Jorden"
                  comment="Lorem Ipsum is simply dummy text of the printing and type setting industry."
                  date="April 14, 2016"
                />
                <RecentComment
                  image={img5}
                  badge="Rejected"
                  badgeColor="danger"
                  name="Johnathan Doeting"
                  comment="Lorem Ipsum is simply dummy text of the printing and type setting industry."
                  date="April 14, 2016"
                />
                <RecentComment
                  image={img1}
                  badge="Pending"
                  badgeColor="info"
                  name="James Anderson"
                  comment="Lorem Ipsum is simply dummy text of the printing and type setting industry."
                  date="April 14, 2016"
                />
                <RecentComment
                  image={img4}
                  badge="Approved"
                  badgeColor="success"
                  name="Michael Jorden"
                  comment="Lorem Ipsum is simply dummy text of the printing and type setting industry."
                  date="April 14, 2016"
                />
              </PerfectScrollbar>
            </div>
          </Card>
        </Col>
        <Col lg="6">
          <TodoList />
        </Col>
      </Row> */}
    </div>
  );
};

const mapStateToProps = state => ({
  userOrg: selectUserOrganization(state),
  userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(Subscriptions);