import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import FormTextField from 'src/components/form-elements/form-text-field.component';
import { Row, Col, CardBody } from 'reactstrap';
import DoneButton from 'src/components/buttons/done-button.component';
import SearchButton from "src/components/buttons/search-button-component";
import SearchBar from 'src/components/buttons/search-icon-button.component';
import dots from 'src/assets/img/vehicles/rightArrow_black.png'
import arrowLeft_red from 'src/assets/img/vehicles/arrowLeft_red.png'


const useStyles = makeStyles((theme) => ({
  presentation: {
    height: theme.spacing(60)
  },
  cardStyle: {
    "&.MuiCard-root": {
      overflow: 'auto',
      position: 'relative',
      borderRadius: '10px',
      backgroundColor: 'rgba(19, 19, 19, .5)'
    },
    "&::-webkit-scrollbar": {
      height: "0.01px",
      width: "0.01px",
      opacity: "0.1",
      backgroundColor: "rgba(19, 19, 19, 0.01)",

    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "none",
      backgroundColor: "rgba(19, 19, 19, 0.01)"
    }
  },
  cardActionStyle: {
    "&.MuiCardActions-root": {
      display: 'flex',
      padding: '16px',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'relative',
      marginBottom: -30,
      top: -25,
      right: 0,
      overflow: 'auto',
    },
  },
  tooltipImg2: {
    marginTop: '10px',
    marginLeft: (props) => props.variant ? '5vw' : '2vw',
  },
  contentStyle: {
    marginLeft: (props) => props.variant ? '-378px' : '-175px',
    fontSize: '16px',
    marginBottom: '-10px'
  },
  contentStyle2: {
    marginLeft: (props) => props.variant ? '-425px' : '-175px',
    fontSize: '16px',
    marginBottom: '-10px'
  },
  contentheader: {
    marginLeft: (props) => props.variant ? '-190px' : '-107px',
    marginTop: '3px',
  }
}));

const RoleAddEdit = (props) => {
  const { onChange, handleClose, handleDone, roles, variant = false, handleConfirmvehicleDetails } = props
  const handleChange = (event) => onChange(event);
  const classes = useStyles(props);

  return (
    <div role="presentation" className={classes.presentation}>
      <Card id="CardBodyCardAllowanceCreate" >
        <CardBody>

          <Row >
            <span style={{ float: 'left', marginLeft: '20px', fontFamily: 'LynkcoTypeMedium', fontSize: '15px' }}>Step One - Identify Vehicle</span>
            <Col lg="12" md="12">
              <div style={{ width: '484%', marginTop: '40px', marginBottom: '10px', marginLeft: '-10px', }}>

                <SearchBar
                  placeholder={'License plate or VIN'}
                  // handleSearch={handleExtendedSearch}
                  // handleSearchInput={handleSearchInput}
                  // onKeyPress={textFieldKeypressSearch}
                  // value={queryParamsVin}
                  // priceBucketActiveTab='true'
                  autoFocus='true'
                />
              </div>
            </Col>


          </Row>

          {/* <Typography className={classes.contentStyle} style={{ marginTop: '20px' }} variant="h4" id="tableTitle" component="div">
            {props.description}
          </Typography> */}
          {/* <div style={{ width: '100%', marginTop: '10px' }}>
            <FormTextField
              name="description"
              onChange={handleChange}
              value={roles?.description}
              multiline={true}
            />
          </div> */}
          {/* </CardContent> */}
          <Row style={{ marginBottom: '20px', marginTop: '50px', marginLeft: '-10px', marginRight: '1px' }}>
            <Col lg="6" md="6">
              <span style={{ float: 'left' }}>
                <SearchButton
                  placeholder='Go Back'
                  // iconProp='true'
                  cancelProp='true'
                  icon={arrowLeft_red}
                  handleSearch={
                    // setDisplaySelectedVehicle(true);
                    // setSearchVIN('');
                    handleClose
                    // console.log('______________________---------------')

                  }
                />
              </span>
            </Col>
            <Col lg="6" md="6">
              <span style={{ float: 'right', marginRight: '-10px' }}>
                <SearchButton
                  placeholder={'Confirm'}
                  height='true'
                  widthProp='true'
                  icon={dots}
                  handleConfirmvehicleDetails={
                    // setDisplaySelectedVehicle(true);
                    // setSearchVIN('');
                    handleConfirmvehicleDetails

                  }

                />
              </span>
            </Col>
          </Row>

        </CardBody>

        {/* <CardContent> */}

        {/* <CardActions classes={{ root: classes.cardActionStyle }} >
          <DoneButton handleDone={handleDone} placeholder={variant == 'true' ? 'Confirm' : 'DONE'} />
        </CardActions>

        <CardActions classes={{ root: classes.cardActionStyle }} >
          <DoneButton handleDone={handleDone} placeholder={variant == 'true' ? 'Cancel' : 'DONE'} />
        </CardActions> */}

      </Card>
    </div>
  );
}

export default RoleAddEdit;