import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Row, Col } from "reactstrap";
import DefaultPage from "src/pages/backgrounds/default-page";
import Modal from "@mui/material/Modal";
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import RoleAddEdit from 'src/components/form-elements/role-add-edit.component';
import Services from "src/pages/driver-page/Services";
import PackageSelect from "src/pages/driver-page/PackageSelect";
import { CreatePackage, UpdatePackage, GetPackages, GetServices, MapServiceToPackage, UnmapServiceFromPackage } from "src/services/provisioning-package.service";
import { permission } from 'src/auth/permissions';
import { connect } from "react-redux";
import { selectUserPermissions } from "src/redux/user/user.selectors";

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(15),
        position: 'sticky',
        marginLeft: '11px',
    },
    drawer: {
        width: 350,
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px'
    },
    gridContainerStyle1: {
        width: '100%',
    },
    cardStyle: {
        backgroundColor: 'rgb(19 19 19 / 97%)',
        height: '800px',
        borderRadius: '1px',
        position: 'fixed'
    },
}));


const Administration = ({ userPermissions }) => {
    const classes = UseStyles();
    const [open, setOpen] = React.useState(false);
    const [roles, setPackage] = React.useState({
        id: "",
        name: "",
        description: "",
        editMode: false
    });

    const [selectedPackage, setSelectedPackage] = React.useState({});

    const [role, setRole] = React.useState('');
    const [services, setServices] = React.useState([]);

    const [description, setDescription] = React.useState('');
    const [updatePackage, setUpdatePackage] = React.useState(false);
    const [showServices, setShowServices] = React.useState(false);

    const handleClickAdd = () => {
        setOpen(true);
        setPackage({
            ...roles, name: "", description: "", editMode: false
        });

    };

    const handleClickEdit = () => {
        if (selectedPackage !== null) {
            setPackage({
                ...roles, id: selectedPackage.id, name: selectedPackage.name,
                description: selectedPackage.description, editMode: true
            });
            setOpen(true);
        }
    };

    const saveFleet = () => {
        setUpdatePackage(false);
        roles.editMode ?
            UpdatePackage({
                "id": roles.id,
                "name": roles.name,
                "description": roles.description
            })
                .then(data => {

                    GetPackages()
                        .then(data => {
                            if (data.ok === true) {
                                let filteredPackage = data?.packages.filter(x => x.id == roles.id)[0]
                                setSelectedPackage(filteredPackage)
                                if (filteredPackage !== undefined) {
                                    setDescription(filteredPackage.description);
                                } else {
                                    setDescription('')
                                }
                            }
                        });
                    resetRoles();
                    setUpdatePackage(true);
                })
            :

            CreatePackage({
                "name": roles.name,
                "description": roles.description,
            })
                .then(data => {
                    resetRoles();
                    setUpdatePackage(true)
                });
    };


    const handleClose = () => {
        setOpen(false);
    };

    const handleDone = () => {
        setOpen(false);
        saveFleet();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setPackage({ ...roles, [name]: value });
    };

    const resetRoles = () => {
        setPackage({
            id: "",
            name: "",
            description: "",
            editMode: false
        });
    };

    const selectServices = (selectPackageServices) => {
        setServices([]);
        let serviceArray = [];

        GetServices()
            .then(data => {
                if (data.ok == true) {
                    if (data.services && Array.isArray(data.services)) {
                        data.services.map(service => {
                            serviceArray.push({
                                id: service.id,
                                name: service.name,
                                subscriptionType: service.subscriptionType,
                                serviceScope: service.serviceScope,
                                checked: selectPackageServices?.filter(x => x.id == service.id).length > 0,
                                disabled: service.disabled
                            });
                        });
                    }
                    setServices(serviceArray);
                }
            });
    }

    const refreshpackageServices = (packageId) => {
        GetPackages()
            .then(data => {
                if (data.ok === true) {
                    let filteredPackage = data?.packages.filter(x => x.id == packageId)[0]
                    setSelectedPackage(filteredPackage)
                    if (filteredPackage !== undefined) {
                        setDescription(filteredPackage.description);
                        selectServices(filteredPackage.services);
                    } else {
                        setDescription('');
                        setShowServices(false);
                    }
                }
            });
    }

    const handleChangeDropdown = (event) => {
        setServices([]);
        const { value } = event.target;
        setRole(value);
        refreshpackageServices(value);
        setShowServices(true);
    };


    const handleCheckbox = (event) => {
        const { id, checked } = event.target;

        if (checked) {
            let packageId = role;
            let serviceId = id
            MapServiceToPackage(packageId, serviceId).then(data => {
                refreshpackageServices(role);
            })
        } else {
            let packageId = role;
            let serviceId = id
            UnmapServiceFromPackage(packageId, serviceId).then(data => {
                refreshpackageServices(role);
            })
        }
    };

    const matchesPermission = userPermissions.includes(permission.Connectivity_Administrator);

    return (
        <>
            {
                matchesPermission ?

                    <Card style={{ marginTop: '25px', marginLeft: '8px', marginRight: '34px', position: 'fixed', backgroundColor: 'rgb(0 0 0 / 90%)' }}>
                        <div style={{ marginLeft: '-8px', marginRight: '-18px', borderRadius: '1px', position: 'fixed', width: '93%', marginTop: '66px' }}>
                            <Modal
                                className={classes.drawer}
                                variant="persistent"
                                anchor="right"
                                open={open}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                onClose={handleClose}
                                disableScrollLock
                                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' }, }}
                            >
                                <RoleAddEdit
                                    roles={roles}
                                    onChange={handleChange}
                                    handleClose={handleClose}
                                    handleDone={handleDone}
                                    title='New Package'
                                    edit='Edit Package'
                                    heading='Enter Package name'
                                    editheading='Edit package name'
                                    description='Package description'

                                />
                            </Modal>
                            <Row style={{ marginLeft: '-25px', marginTop: '-15px', marginRight: '1px' }}>
                                <Col lg="4" md="4" >
                                    <Card id="CardBodyCardAllowance3" style={{ marginLeft: '10px', height: '770px', marginRight: '-20px', backgroundColor: 'rgb(0 0 0 / 90%)' }} >
                                        <Row style={{ marginTop: '20px', paddingRight: '20px' }}>
                                            <table style={{ width: '100%', color: 'darkgray', marginLeft: '50px', }}>
                                                <tr>
                                                    <td><span style={{ float: 'left', fontSize: '16px', fontFamily: "LynkcoTypeMedium", }}>Select Package</span></td>
                                                    <td >
                                                        <span style={{ cursor: 'pointer' }} onClick={handleClickAdd}>
                                                            <AddIcon style={{ color: 'gray', width: '25px' }} />
                                                        </span>
                                                    </td>
                                                    <td >
                                                        <span style={{ cursor: 'pointer' }} onClick={handleClickEdit}>
                                                            <EditIcon style={{ color: 'gray', width: '20px' }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <br />
                                                <br />
                                                <tr>
                                                    <td >
                                                        <span style={{ width: '280px', marginTop: '-20px', float: 'left' }}>
                                                            <PackageSelect value={role.id} onChange={handleChangeDropdown} updatePackage={updatePackage} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <br />
                                                <br />
                                                <span style={{ fontSize: '16px', fontFamily: "LynkcoTypeRegular" }}>
                                                    {description}
                                                </span>
                                            </table>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col lg="8" md="8" >
                                    {showServices &&
                                        <Card id="CardBodyCardAllowance3" style={{ marginLeft: '32px', marginRight: '-18px', height: '770px', backgroundColor: 'rgb(0 0 0 / 90%)' }} >
                                            <span style={{ marginLeft: '35px', fontSize: '16px', marginTop: '21px', marginBottom: '8px', fontFamily: "LynkcoTypeBold", color: '#fff' }} variant="h4" id="tableTitle" component="div">
                                                Select Services
                                            </span>
                                            <br />
                                            <br />
                                            <br />
                                            <span>
                                                <Services
                                                    handleCheckbox={handleCheckbox}
                                                    services={services}
                                                />
                                            </span>
                                        </Card>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Card>
                    :
                    <DefaultPage backgroundtype='true' />
            }
        </>
    );
};

const mapStateToProps = state => ({
    userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(Administration);