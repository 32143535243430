import React, { useState, useMemo } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import ReactTableBaseRvCurve from 'src/components/tables/newtable/table/ReactTableBaseRvCurve';
import { GetRVTable } from 'src/services/rvcurve-http.service';
import RVcurveUpload from 'src/components/form-elements/rvcurve-upload.component';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import EnhancedRvCurveTableToolbar from 'src/components/tables/rvCurve-table-toolbar.component';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { UploadEditRVcurveTable } from 'src/services/rvcurve-http.service';
import swal from 'sweetalert';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { useTableStyles } from 'src/assets/jss/components/tableStyle';
import { UnformmatRvTableExcelJson } from 'src/utils/RvTableExcelFormatter';
import Grid from '@material-ui/core/Grid';
import { FormTextFieldSalesPlain } from 'src/components/form-elements/custom-form-text-field.salessettings';
import { Typography } from '@material-ui/core';
import { FormmatDateMap } from 'src/utils/DateFormatter';
import LoadingImagePlaceHolder from 'src/components/placeholder/loadingimageplaceholder';
import LoadingImagePlaceHolderRVTable from 'src/components/placeholder/loadingimageplaceholderRVTable';
import { selectUserOrganization, selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { permission } from 'src/auth/permissions';
import DefaultPage from 'src/pages/backgrounds/default-page';

const drawerWidth = 240;

const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const useDrawerStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: '-15px',

  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "&.MuiDrawer-paperAnchorDockedRight": {
      top: '160px'
    }
  },
  drawerPaper: {
    width: drawerWidth,
    height: 'max-content',
    "&.MuiDrawer-paperAnchorDockedRight": {
      top: '19.5%'
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  SwipeableDrawerRvCurve: {
    height: '52%',
    width: '20%',
    "&.MuiDrawer-paperAnchorRight": {
      top: '80px',
      right: '50px'
    }
  },
}));

const RvReactTable = ({ reactTableData, userOrg, userPermissions, userToken }) => {
  const drawerClasses = useDrawerStyles();
  const [open, setOpen] = React.useState(false);
  const [isEnableSave, setIsEnableSave] = React.useState(false);
  const [rows, setData] = useState(reactTableData.tableRowsData);
  const [tableConfig, setTableConfig] = useState({
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by Licence plate...',
  });

  const setIsEditable = (currentState) => {
    setTableConfig({ ...tableConfig, isEditable: currentState });

  }

  const setWithDragAndDrop = (currentState) => {
    setTableConfig({ ...tableConfig, withDragAndDrop: currentState });

  }

  const setWithPaginationTable = (currentState) => {
    setTableConfig({ ...tableConfig, withPagination: currentState });

  }

  const setWithSearchEngine = (currentState) => {
    setTableConfig({ ...tableConfig, withSearchEngine: currentState });

  }

  const [isResizable, setIsResizable] = useState(false);
  const [isSortable, setIsSortable] = useState(false);
  const [isDisabledDragAndDrop, setIsDisabledDragAndDrop] = useState(false);
  const [isDisabledEditable, setIsDisabledEditable] = useState(false);
  const [isDisabledResizable, setIsDisabledResizable] = useState(false);

  const classes = useTableStyles();

  const columns = useMemo(
    () => [
      { Header: 'Market', sticky: "left", accessor: 'market', disableGlobalFilter: true, },
      { Header: 'Model', sticky: "left", accessor: "model", disableGlobalFilter: true, },
      { Header: 'Model Year', sticky: "left", accessor: "modelYear", disableGlobalFilter: true, },
      { Header: 'Version', sticky: "left", accessor: "version", disableGlobalFilter: true },
      { Header: 'Consumer price (gross) incl. subsidies', accessor: "_ConsumerPriceInclSubsidies", disableGlobalFilter: true },
      { Header: 'Consumer price (gross) excl. subsidies', accessor: "_ConsumerPriceExclSubsidies", disableGlobalFilter: true },
      { Header: 'Landed Cost (net)', accessor: "landedCost", disableGlobalFilter: true },
      { Header: 'Remarketing Cost', accessor: "remarketingCost", disableGlobalFilter: true },
      { Header: 'Profit Margin', accessor: "profitMargin", disableGlobalFilter: true },
      { Header: 'Range (km)', accessor: "rangeKm", disableGlobalFilter: true },
      { Header: '1 [%]', accessor: "_1", disableGlobalFilter: true },
      { Header: '1-€', accessor: "_1EUR", disableGlobalFilter: true },
      { Header: '1-€ (ex VAT)', accessor: "_1EURExVAT", disableGlobalFilter: true },
      { Header: '1m tot. km', accessor: "_1MthKm", disableGlobalFilter: true },
      { Header: '2 [%]', accessor: "_2", disableGlobalFilter: true },
      { Header: '2-€', accessor: "_2EUR", disableGlobalFilter: true },
      { Header: '2-€ (ex VAT)', accessor: "_2EURExVAT", disableGlobalFilter: true },
      { Header: '2m tot. km', accessor: "_2MthKm", disableGlobalFilter: true },
      { Header: '3 [%]', accessor: "_3", disableGlobalFilter: true },
      { Header: '3-€', accessor: "_3EUR", disableGlobalFilter: true },
      { Header: '3-€ (ex VAT)', accessor: "_3EURExVAT", disableGlobalFilter: true },
      { Header: '3m tot. km', accessor: "_3MthKm", disableGlobalFilter: true },
      { Header: '4 [%]', accessor: "_4", disableGlobalFilter: true },
      { Header: '4-€', accessor: "_4EUR", disableGlobalFilter: true },
      { Header: '4-€ (ex VAT)', accessor: "_4EURExVAT", disableGlobalFilter: true },
      { Header: '4m tot. km', accessor: "_4MthKm", disableGlobalFilter: true },
      { Header: '5 [%]', accessor: "_5", disableGlobalFilter: true },
      { Header: '5-€', accessor: "_5EUR", disableGlobalFilter: true },
      { Header: '5-€ (ex VAT)', accessor: "_5EURExVAT", disableGlobalFilter: true },
      { Header: '5m tot. km', accessor: "_5MthKm", disableGlobalFilter: true },
      { Header: '6 [%]', accessor: "_6", disableGlobalFilter: true },
      { Header: '6-€', accessor: "_6EUR", disableGlobalFilter: true },
      { Header: '6-€ (ex VAT)', accessor: "_6EURExVAT", disableGlobalFilter: true },
      { Header: '6m tot. km', accessor: "_6MthKm", disableGlobalFilter: true },
      { Header: '7 [%]', accessor: "_7", disableGlobalFilter: true },
      { Header: '7-€', accessor: "_7EUR", disableGlobalFilter: true },
      { Header: '7-€ (ex VAT)', accessor: "_7EURExVAT", disableGlobalFilter: true },
      { Header: '7m tot. km', accessor: "_7MthKm", disableGlobalFilter: true },
      { Header: '8 [%]', accessor: "_8", disableGlobalFilter: true },
      { Header: '8-€', accessor: "_8EUR", disableGlobalFilter: true },
      { Header: '8-€ (ex VAT)', accessor: "_8EURExVAT", disableGlobalFilter: true },
      { Header: '8m tot. km', accessor: "_8MthKm", disableGlobalFilter: true },
      { Header: '9 [%]', accessor: "_9", disableGlobalFilter: true },
      { Header: '9-€', accessor: "_9EUR", disableGlobalFilter: true },
      { Header: '9-€ (ex VAT)', accessor: "_9EURExVAT", disableGlobalFilter: true },
      { Header: '9m tot. km', accessor: "_9MthKm", disableGlobalFilter: true },
      { Header: '10 [%]', accessor: "_10", disableGlobalFilter: true },
      { Header: '10-€', accessor: "_10EUR", disableGlobalFilter: true },
      { Header: '10-€ (ex VAT)', accessor: "_10EURExVAT", disableGlobalFilter: true },
      { Header: '10m tot. km', accessor: "_10MthKm", disableGlobalFilter: true },
      { Header: '11 [%]', accessor: "_11", disableGlobalFilter: true },
      { Header: '11-€', accessor: "_11EUR", disableGlobalFilter: true },
      { Header: '11-€ (ex VAT)', accessor: "_11EURExVAT", disableGlobalFilter: true },
      { Header: '11m tot. km', accessor: "_11MthKm", disableGlobalFilter: true },
      { Header: '12 [%]', accessor: "_12", disableGlobalFilter: true },
      { Header: '12-€', accessor: "_12EUR", disableGlobalFilter: true },
      { Header: '12-€ (ex VAT)', accessor: "_12EURExVAT", disableGlobalFilter: true },
      { Header: '12m tot. km', accessor: "_12MthKm", disableGlobalFilter: true },
      { Header: '13 [%]', accessor: "_13", disableGlobalFilter: true },
      { Header: '13-€', accessor: "_13EUR", disableGlobalFilter: true },
      { Header: '13-€ (ex VAT)', accessor: "_13EURExVAT", disableGlobalFilter: true },
      { Header: '13m tot. km', accessor: "_13MthKm", disableGlobalFilter: true },
      { Header: '14 [%]', accessor: "_14", disableGlobalFilter: true },
      { Header: '14-€', accessor: "_14EUR", disableGlobalFilter: true },
      { Header: '14-€ (ex VAT)', accessor: "_14EURExVAT", disableGlobalFilter: true },
      { Header: '14m tot. km', accessor: "_14MthKm", disableGlobalFilter: true },
      { Header: '15 [%]', accessor: "_15", disableGlobalFilter: true },
      { Header: '15-€', accessor: "_15EUR", disableGlobalFilter: true },
      { Header: '15-€ (ex VAT)', accessor: "_15EURExVAT", disableGlobalFilter: true },
      { Header: '15m tot. km', accessor: "_15MthKm", disableGlobalFilter: true },
      { Header: '16 [%]', accessor: "_16", disableGlobalFilter: true },
      { Header: '16-€', accessor: "_16EUR", disableGlobalFilter: true },
      { Header: '16-€ (ex VAT)', accessor: "_16EURExVAT", disableGlobalFilter: true },
      { Header: '16m tot. km', accessor: "_16MthKm", disableGlobalFilter: true },
      { Header: '17 [%]', accessor: "_17", disableGlobalFilter: true },
      { Header: '17-€', accessor: "_17EUR", disableGlobalFilter: true },
      { Header: '17-€ (ex VAT)', accessor: "_17EURExVAT", disableGlobalFilter: true },
      { Header: '17m tot. km', accessor: "_17MthKm", disableGlobalFilter: true },
      { Header: '18 [%]', accessor: "_18", disableGlobalFilter: true },
      { Header: '18-€', accessor: "_18EUR", disableGlobalFilter: true },
      { Header: '18-€ (ex VAT)', accessor: "_18EURExVAT", disableGlobalFilter: true },
      { Header: '18m tot. km', accessor: "_18MthKm", disableGlobalFilter: true },
      { Header: '19 [%]', accessor: "_19", disableGlobalFilter: true },
      { Header: '19-€', accessor: "_19EUR", disableGlobalFilter: true },
      { Header: '19-€ (ex VAT)', accessor: "_19EURExVAT", disableGlobalFilter: true },
      { Header: '19m tot. km', accessor: "_19MthKm", disableGlobalFilter: true },
      { Header: '20 [%]', accessor: "_20", disableGlobalFilter: true },
      { Header: '20-€', accessor: "_20EUR", disableGlobalFilter: true },
      { Header: '20-€ (ex VAT)', accessor: "_20EURExVAT", disableGlobalFilter: true },
      { Header: '20m tot. km', accessor: "_20MthKm", disableGlobalFilter: true },
      { Header: '21 [%]', accessor: "_21", disableGlobalFilter: true },
      { Header: '21-€', accessor: "_21EUR", disableGlobalFilter: true },
      { Header: '21-€ (ex VAT)', accessor: "_21EURExVAT", disableGlobalFilter: true },
      { Header: '21m tot. km', accessor: "_21MthKm", disableGlobalFilter: true },
      { Header: '22 [%]', accessor: "_22", disableGlobalFilter: true },
      { Header: '22-€', accessor: "_22EUR", disableGlobalFilter: true },
      { Header: '22-€ (ex VAT)', accessor: "_22EURExVAT", disableGlobalFilter: true },
      { Header: '22m tot. km', accessor: "_22MthKm", disableGlobalFilter: true },
      { Header: '23 [%]', accessor: "_23", disableGlobalFilter: true },
      { Header: '23-€', accessor: "_23EUR", disableGlobalFilter: true },
      { Header: '23-€ (ex VAT)', accessor: "_23EURExVAT", disableGlobalFilter: true },
      { Header: '23m tot. km', accessor: "_23MthKm", disableGlobalFilter: true },
      { Header: '24 [%]', accessor: "_24", disableGlobalFilter: true },
      { Header: '24-€', accessor: "_24EUR", disableGlobalFilter: true },
      { Header: '24-€ (ex VAT)', accessor: "_24EURExVAT", disableGlobalFilter: true },
      { Header: '24m tot. km', accessor: "_24MthKm", disableGlobalFilter: true },
      { Header: '36 [%]', accessor: "_36", disableGlobalFilter: true },
      { Header: '36-€', accessor: "_36EUR", disableGlobalFilter: true },
      { Header: '36-€ (ex VAT)', accessor: "_36EURExVAT", disableGlobalFilter: true },
      { Header: '36m tot. km', accessor: "_36MthKm", disableGlobalFilter: true },
      { Header: '48 [%]', accessor: "_48", disableGlobalFilter: true },
      { Header: '48-€', accessor: "_48EUR", disableGlobalFilter: true },
      { Header: '48-€ (ex VAT)', accessor: "_48EURExVAT", disableGlobalFilter: true },
      { Header: '48m tot. km', accessor: "_48MthKm", disableGlobalFilter: true },
      { Header: '60 [%]', accessor: "_60", disableGlobalFilter: true },
      { Header: '60-€', accessor: "_60EUR", disableGlobalFilter: true, },
      { Header: '60-€ (ex VAT)', accessor: "_60EURExVAT", disableGlobalFilter: true },
      { Header: '60m tot. km', accessor: "_60MthKm", disableGlobalFilter: true }
    ],
    [],
  );

  const [dataRows, setVehicleDataFleet] = React.useState([]);
  const [dateCreated, setDateCreated] = React.useState('');
  const [validFromDate, setvalidFromDate] = React.useState('');
  const [dataLoaded, setDataLoaded] = React.useState(false)
  const [dataLoadedForm, setDataLoadedForm] = React.useState(false)

  React.useEffect(() => {
    Datarows();

  }, [userPermissions.length]);

  let Datarows = () => {
    const data = [];
    GetRVTable(userOrg.id).then(value => {
      if (value && Array.isArray(value.tableData)) {
        value.tableData.map(vehicle => {
          data.push(populateRVmodel(vehicle))
        });
        setDataLoadedForm(true)
        setDateCreated(value.createdDateUtc)
        setvalidFromDate(value.validFromDateUtc)
      }
      setDataLoaded(true)
      setVehicleDataFleet(data)
    });

  };

  const convertDecimalToPercentage = (value) => {
    if (isNaN(value))
      return value
    else
      return Math.round((value * 100) * 100) / 100
  }

  const convertDecimalToPercentage_4DEC = (value) => {
    if (isNaN(value))
      return value
    else
      return Math.round((value * 1) * 100) / 100
  }

  const convertPercentageToDecimal = (value) => {
    if (isNaN(value))
      return value
    else
      return Math.round((value / 10000) * 10000) / 10000
  }

  const convertPercentageToDecimalReversed = (value) => {
    if (isNaN(value))
      return value
    else
      return Math.round((value / 100) * 10000) / 10000
  }

  const convertPercentageToDecimalReversed_4DEC = (value) => {
    if (isNaN(value))
      return value
    else
      return Math.round((value / 1) * 10000) / 10000
  }

  const reversePopulateRVmodel = (vehicle) => {
    return (
      {
        market: vehicle.market,
        model: vehicle.model,
        modelYear: vehicle.modelYear,
        version: vehicle.version,
        _ConsumerPriceInclSubsidies: convertPercentageToDecimalReversed_4DEC(vehicle._ConsumerPriceInclSubsidies),
        _ConsumerPriceExclSubsidies: convertPercentageToDecimalReversed_4DEC(vehicle._ConsumerPriceExclSubsidies),
        landedCost: vehicle.landedCost,
        remarketingCost: vehicle.remarketingCost,
        profitMargin: vehicle.profitMargin,
        rangeKm: vehicle.rangeKm,
        _1: convertPercentageToDecimalReversed(vehicle._1),
        _1EUR: convertPercentageToDecimalReversed_4DEC(vehicle._1EUR),
        _1EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._1EURExVAT),
        _1MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._1MthKm),
        _2: convertPercentageToDecimalReversed(vehicle._2),
        _2EUR: convertPercentageToDecimalReversed_4DEC(vehicle._2EUR),
        _2EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._2EURExVAT),
        _2MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._2MthKm),
        _3: convertPercentageToDecimalReversed(vehicle._3),
        _3EUR: convertPercentageToDecimalReversed_4DEC(vehicle._3EUR),
        _3EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._3EURExVAT),
        _3MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._3MthKm),
        _4: convertPercentageToDecimalReversed(vehicle._4),
        _4EUR: convertPercentageToDecimalReversed_4DEC(vehicle._4EUR),
        _4EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._4EURExVAT),
        _4MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._4MthKm),
        _5: convertPercentageToDecimalReversed(vehicle._5),
        _5EUR: convertPercentageToDecimalReversed_4DEC(vehicle._5EUR),
        _5EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._5EURExVAT),
        _5MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._5MthKm),
        _6: convertPercentageToDecimalReversed(vehicle._6),
        _6EUR: convertPercentageToDecimalReversed_4DEC(vehicle._6EUR),
        _6EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._6EURExVAT),
        _6MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._6MthKm),
        _7: convertPercentageToDecimalReversed(vehicle._7),
        _7EUR: convertPercentageToDecimalReversed_4DEC(vehicle._7EUR),
        _7EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._7EURExVAT),
        _7MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._7MthKm),
        _8: convertPercentageToDecimalReversed(vehicle._8),
        _8EUR: convertPercentageToDecimalReversed_4DEC(vehicle._8EUR),
        _8EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._8EURExVAT),
        _8MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._8MthKm),
        _9: convertPercentageToDecimalReversed(vehicle._9),
        _9EUR: convertPercentageToDecimalReversed_4DEC(vehicle._9EUR),
        _9EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._9EURExVAT),
        _9MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._9MthKm),
        _10: convertPercentageToDecimalReversed(vehicle._10),
        _10EUR: convertPercentageToDecimalReversed_4DEC(vehicle._10EUR),
        _10EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._10EURExVAT),
        _10MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._10MthKm),
        _11: convertPercentageToDecimalReversed(vehicle._11),
        _11EUR: convertPercentageToDecimalReversed_4DEC(vehicle._11EUR),
        _11EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._11EURExVAT),
        _11MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._11MthKm),
        _12: convertPercentageToDecimalReversed(vehicle._12),
        _12EUR: convertPercentageToDecimalReversed_4DEC(vehicle._12EUR),
        _12EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._12EURExVAT),
        _12MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._12MthKm),
        _13: convertPercentageToDecimalReversed(vehicle._13),
        _13EUR: convertPercentageToDecimalReversed_4DEC(vehicle._13EUR),
        _13EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._13EURExVAT),
        _13MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._13MthKm),
        _14: convertPercentageToDecimalReversed(vehicle._14),
        _14EUR: convertPercentageToDecimalReversed_4DEC(vehicle._14EUR),
        _14EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._14EURExVAT),
        _14MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._14MthKm),
        _15: convertPercentageToDecimalReversed(vehicle._15),
        _15EUR: convertPercentageToDecimalReversed_4DEC(vehicle._15EUR),
        _15EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._15EURExVAT),
        _15MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._15MthKm),
        _16: convertPercentageToDecimalReversed(vehicle._16),
        _16EUR: convertPercentageToDecimalReversed_4DEC(vehicle._16EUR),
        _16EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._16EURExVAT),
        _16MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._16MthKm),
        _17: convertPercentageToDecimalReversed(vehicle._17),
        _17EUR: convertPercentageToDecimalReversed_4DEC(vehicle._17EUR),
        _17EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._17EURExVAT),
        _17MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._17MthKm),
        _18: convertPercentageToDecimalReversed(vehicle._18),
        _18EUR: convertPercentageToDecimalReversed_4DEC(vehicle._18EUR),
        _18EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._18EURExVAT),
        _18MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._18MthKm),
        _19: convertPercentageToDecimalReversed(vehicle._19),
        _19EUR: convertPercentageToDecimalReversed_4DEC(vehicle._19EUR),
        _19EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._19EURExVAT),
        _19MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._19MthKm),
        _20: convertPercentageToDecimalReversed(vehicle._20),
        _20EUR: convertPercentageToDecimalReversed_4DEC(vehicle._20EUR),
        _20EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._20EURExVAT),
        _20MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._20MthKm),
        _21: convertPercentageToDecimalReversed(vehicle._21),
        _21EUR: convertPercentageToDecimalReversed_4DEC(vehicle._21EUR),
        _21EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._21EURExVAT),
        _21MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._21MthKm),
        _22: convertPercentageToDecimalReversed(vehicle._22),
        _22EUR: convertPercentageToDecimalReversed_4DEC(vehicle._22EUR),
        _22EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._22EURExVAT),
        _22MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._22MthKm),
        _23: convertPercentageToDecimalReversed(vehicle._23),
        _23EUR: convertPercentageToDecimalReversed_4DEC(vehicle._23EUR),
        _23EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._23EURExVAT),
        _23MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._23MthKm),
        _24: convertPercentageToDecimalReversed(vehicle._24),
        _24EUR: convertPercentageToDecimalReversed_4DEC(vehicle._24EUR),
        _24EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._24EURExVAT),
        _24MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._24MthKm),
        _36: convertPercentageToDecimalReversed(vehicle._36),
        _36EUR: convertPercentageToDecimalReversed_4DEC(vehicle._36EUR),
        _36EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._36EURExVAT),
        _36MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._36MthKm),
        _48: convertPercentageToDecimalReversed(vehicle._48),
        _48EUR: convertPercentageToDecimalReversed_4DEC(vehicle._48EUR),
        _48EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._48EURExVAT),
        _48MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._48MthKm),
        _60: convertPercentageToDecimalReversed(vehicle._60),
        _60EUR: convertPercentageToDecimalReversed_4DEC(vehicle._60EUR),
        _60EURExVAT: convertPercentageToDecimalReversed_4DEC(vehicle._60EURExVAT),
        _60MthKm: convertPercentageToDecimalReversed_4DEC(vehicle._60MthKm)
      }
    )
  }

  const populateRVmodel = (vehicle) => {
    return (
      {
        market: vehicle.market,
        model: vehicle.model,
        modelYear: vehicle.modelYear,
        version: vehicle.version,
        _ConsumerPriceInclSubsidies: convertDecimalToPercentage_4DEC(vehicle._ConsumerPriceInclSubsidies),
        _ConsumerPriceExclSubsidies: convertDecimalToPercentage_4DEC(vehicle._ConsumerPriceExclSubsidies),
        landedCost: vehicle.landedCost,
        remarketingCost: vehicle.remarketingCost,
        profitMargin: vehicle.profitMargin,
        rangeKm: vehicle.rangeKm,
        _1: convertDecimalToPercentage(vehicle._1),
        _1EUR: convertDecimalToPercentage_4DEC(vehicle._1EUR),
        _1EURExVAT: convertDecimalToPercentage_4DEC(vehicle._1EURExVAT),
        _1MthKm: convertDecimalToPercentage_4DEC(vehicle._1MthKm),
        _2: convertDecimalToPercentage(vehicle._2),
        _2EUR: convertDecimalToPercentage_4DEC(vehicle._2EUR),
        _2EURExVAT: convertDecimalToPercentage_4DEC(vehicle._2EURExVAT),
        _2MthKm: convertDecimalToPercentage_4DEC(vehicle._2MthKm),
        _3: convertDecimalToPercentage(vehicle._3),
        _3EUR: convertDecimalToPercentage_4DEC(vehicle._3EUR),
        _3EURExVAT: convertDecimalToPercentage_4DEC(vehicle._3EURExVAT),
        _3MthKm: convertDecimalToPercentage_4DEC(vehicle._3MthKm),
        _4: convertDecimalToPercentage(vehicle._4),
        _4EUR: convertDecimalToPercentage_4DEC(vehicle._4EUR),
        _4EURExVAT: convertDecimalToPercentage_4DEC(vehicle._4EURExVAT),
        _4MthKm: convertDecimalToPercentage_4DEC(vehicle._4MthKm),
        _5: convertDecimalToPercentage(vehicle._5),
        _5EUR: convertDecimalToPercentage_4DEC(vehicle._5EUR),
        _5EURExVAT: convertDecimalToPercentage_4DEC(vehicle._5EURExVAT),
        _5MthKm: convertDecimalToPercentage_4DEC(vehicle._5MthKm),
        _6: convertDecimalToPercentage(vehicle._6),
        _6EUR: convertDecimalToPercentage_4DEC(vehicle._6EUR),
        _6EURExVAT: convertDecimalToPercentage_4DEC(vehicle._6EURExVAT),
        _6MthKm: convertDecimalToPercentage_4DEC(vehicle._6MthKm),
        _7: convertDecimalToPercentage(vehicle._7),
        _7EUR: convertDecimalToPercentage_4DEC(vehicle._7EUR),
        _7EURExVAT: convertDecimalToPercentage_4DEC(vehicle._7EURExVAT),
        _7MthKm: convertDecimalToPercentage_4DEC(vehicle._7MthKm),
        _8: convertDecimalToPercentage(vehicle._8),
        _8EUR: convertDecimalToPercentage_4DEC(vehicle._8EUR),
        _8EURExVAT: convertDecimalToPercentage_4DEC(vehicle._8EURExVAT),
        _8MthKm: convertDecimalToPercentage_4DEC(vehicle._8MthKm),
        _9: convertDecimalToPercentage(vehicle._9),
        _9EUR: convertDecimalToPercentage_4DEC(vehicle._9EUR),
        _9EURExVAT: convertDecimalToPercentage_4DEC(vehicle._9EURExVAT),
        _9MthKm: convertDecimalToPercentage_4DEC(vehicle._9MthKm),
        _10: convertDecimalToPercentage(vehicle._10),
        _10EUR: convertDecimalToPercentage_4DEC(vehicle._10EUR),
        _10EURExVAT: convertDecimalToPercentage_4DEC(vehicle._10EURExVAT),
        _10MthKm: convertDecimalToPercentage_4DEC(vehicle._10MthKm),
        _11: convertDecimalToPercentage(vehicle._11),
        _11EUR: convertDecimalToPercentage_4DEC(vehicle._11EUR),
        _11EURExVAT: convertDecimalToPercentage_4DEC(vehicle._11EURExVAT),
        _11MthKm: convertDecimalToPercentage_4DEC(vehicle._11MthKm),
        _12: convertDecimalToPercentage(vehicle._12),
        _12EUR: convertDecimalToPercentage_4DEC(vehicle._12EUR),
        _12EURExVAT: convertDecimalToPercentage_4DEC(vehicle._12EURExVAT),
        _12MthKm: convertDecimalToPercentage_4DEC(vehicle._12MthKm),
        _13: convertDecimalToPercentage(vehicle._13),
        _13EUR: convertDecimalToPercentage_4DEC(vehicle._13EUR),
        _13EURExVAT: convertDecimalToPercentage_4DEC(vehicle._13EURExVAT),
        _13MthKm: convertDecimalToPercentage_4DEC(vehicle._13MthKm),
        _14: convertDecimalToPercentage(vehicle._14),
        _14EUR: convertDecimalToPercentage_4DEC(vehicle._14EUR),
        _14EURExVAT: convertDecimalToPercentage_4DEC(vehicle._14EURExVAT),
        _14MthKm: convertDecimalToPercentage_4DEC(vehicle._14MthKm),
        _15: convertDecimalToPercentage(vehicle._15),
        _15EUR: convertDecimalToPercentage_4DEC(vehicle._15EUR),
        _15EURExVAT: convertDecimalToPercentage_4DEC(vehicle._15EURExVAT),
        _15MthKm: convertDecimalToPercentage_4DEC(vehicle._15MthKm),
        _16: convertDecimalToPercentage(vehicle._16),
        _16EUR: convertDecimalToPercentage_4DEC(vehicle._16EUR),
        _16EURExVAT: convertDecimalToPercentage_4DEC(vehicle._16EURExVAT),
        _16MthKm: convertDecimalToPercentage_4DEC(vehicle._16MthKm),
        _17: convertDecimalToPercentage(vehicle._17),
        _17EUR: convertDecimalToPercentage_4DEC(vehicle._17EUR),
        _17EURExVAT: convertDecimalToPercentage_4DEC(vehicle._17EURExVAT),
        _17MthKm: convertDecimalToPercentage_4DEC(vehicle._17MthKm),
        _18: convertDecimalToPercentage(vehicle._18),
        _18EUR: convertDecimalToPercentage_4DEC(vehicle._18EUR),
        _18EURExVAT: convertDecimalToPercentage_4DEC(vehicle._18EURExVAT),
        _18MthKm: convertDecimalToPercentage_4DEC(vehicle._18MthKm),
        _19: convertDecimalToPercentage(vehicle._19),
        _19EUR: convertDecimalToPercentage_4DEC(vehicle._19EUR),
        _19EURExVAT: convertDecimalToPercentage_4DEC(vehicle._19EURExVAT),
        _19MthKm: convertDecimalToPercentage_4DEC(vehicle._19MthKm),
        _20: convertDecimalToPercentage(vehicle._20),
        _20EUR: convertDecimalToPercentage_4DEC(vehicle._20EUR),
        _20EURExVAT: convertDecimalToPercentage_4DEC(vehicle._20EURExVAT),
        _20MthKm: convertDecimalToPercentage_4DEC(vehicle._20MthKm),
        _21: convertDecimalToPercentage(vehicle._21),
        _21EUR: convertDecimalToPercentage_4DEC(vehicle._21EUR),
        _21EURExVAT: convertDecimalToPercentage_4DEC(vehicle._21EURExVAT),
        _21MthKm: convertDecimalToPercentage_4DEC(vehicle._21MthKm),
        _22: convertDecimalToPercentage(vehicle._22),
        _22EUR: convertDecimalToPercentage_4DEC(vehicle._22EUR),
        _22EURExVAT: convertDecimalToPercentage_4DEC(vehicle._22EURExVAT),
        _22MthKm: convertDecimalToPercentage_4DEC(vehicle._22MthKm),
        _23: convertDecimalToPercentage(vehicle._23),
        _23EUR: convertDecimalToPercentage_4DEC(vehicle._23EUR),
        _23EURExVAT: convertDecimalToPercentage_4DEC(vehicle._23EURExVAT),
        _23MthKm: convertDecimalToPercentage_4DEC(vehicle._23MthKm),
        _24: convertDecimalToPercentage(vehicle._24),
        _24EUR: convertDecimalToPercentage_4DEC(vehicle._24EUR),
        _24EURExVAT: convertDecimalToPercentage_4DEC(vehicle._24EURExVAT),
        _24MthKm: convertDecimalToPercentage_4DEC(vehicle._24MthKm),
        _36: convertDecimalToPercentage(vehicle._36),
        _36EUR: convertDecimalToPercentage_4DEC(vehicle._36EUR),
        _36EURExVAT: convertDecimalToPercentage_4DEC(vehicle._36EURExVAT),
        _36MthKm: convertDecimalToPercentage_4DEC(vehicle._36MthKm),
        _48: convertDecimalToPercentage(vehicle._48),
        _48EUR: convertDecimalToPercentage_4DEC(vehicle._48EUR),
        _48EURExVAT: convertDecimalToPercentage_4DEC(vehicle._48EURExVAT),
        _48MthKm: convertDecimalToPercentage_4DEC(vehicle._48MthKm),
        _60: convertDecimalToPercentage(vehicle._60),
        _60EUR: convertDecimalToPercentage_4DEC(vehicle._60EUR),
        _60EURExVAT: convertDecimalToPercentage_4DEC(vehicle._60EURExVAT),
        _60MthKm: convertDecimalToPercentage_4DEC(vehicle._60MthKm)
      }
    )
  }

  const populateExcelRvModel = (vehicle) => {
    return (
      {
        market: vehicle["Market"],
        model: vehicle["Model"],
        modelYear: vehicle["Model Year"],
        version: vehicle["Version"],
        _ConsumerPriceInclSubsidies: vehicle["Consumer price (gross) incl. subsidies"],
        _ConsumerPriceExclSubsidies: vehicle["Consumer price (gross) excl. subsidies"],
        landedCost: vehicle["Landed Cost (net)"],
        remarketingCost: vehicle["Remarketing Cost"],
        profitMargin: vehicle["Profit Margin"],
        rangeKm: vehicle["Range (km) "],
        _1: convertPercentageToDecimal(vehicle["1"]),
        _1EUR: vehicle["1-€"],
        _1EURExVAT: vehicle["1-€ (ex VAT)"],
        _1MthKm: vehicle["1m tot. km"],
        _2: convertPercentageToDecimal(vehicle["2"]),
        _2EUR: vehicle["2-€"],
        _2EURExVAT: vehicle["2-€ (ex VAT)"],
        _2MthKm: vehicle["2m tot. km"],
        _3: convertPercentageToDecimal(vehicle["3"]),
        _3EUR: vehicle["3-€"],
        _3EURExVAT: vehicle["3-€ (ex VAT)"],
        _3MthKm: vehicle["3m tot. km"],
        _4: convertPercentageToDecimal(vehicle["4"]),
        _4EUR: vehicle["4-€"],
        _4EURExVAT: vehicle["4-€ (ex VAT)"],
        _4MthKm: vehicle["4m tot. km"],
        _5: convertPercentageToDecimal(vehicle["5"]),
        _5EUR: vehicle["5-€"],
        _5EURExVAT: vehicle["5-€ (ex VAT)"],
        _5MthKm: vehicle["5m tot. km"],
        _6: convertPercentageToDecimal(vehicle["6"]),
        _6EUR: vehicle["6-€"],
        _6EURExVAT: vehicle["6-€ (ex VAT)"],
        _6MthKm: vehicle["6m tot. km"],
        _7: convertPercentageToDecimal(vehicle["7"]),
        _7EUR: vehicle["7-€"],
        _7EURExVAT: vehicle["7-€ (ex VAT)"],
        _7MthKm: vehicle["7m tot. km"],
        _8: convertPercentageToDecimal(vehicle["8"]),
        _8EUR: vehicle["8-€"],
        _8EURExVAT: vehicle["8-€ (ex VAT)"],
        _8MthKm: vehicle["8m tot. km"],
        _9: convertPercentageToDecimal(vehicle["9"]),
        _9EUR: vehicle["9-€"],
        _9EURExVAT: vehicle["9-€ (ex VAT)"],
        _9MthKm: vehicle["9m tot. km"],
        _10: convertPercentageToDecimal(vehicle["10"]),
        _10EUR: vehicle["10-€"],
        _10EURExVAT: vehicle["10-€ (ex VAT)"],
        _10MthKm: vehicle["10m tot. km"],
        _11: convertPercentageToDecimal(vehicle["11"]),
        _11EUR: vehicle["11-€"],
        _11EURExVAT: vehicle["11-€ (ex VAT)"],
        _11MthKm: vehicle["11m tot. km"],
        _12: convertPercentageToDecimal(vehicle["12"]),
        _12EUR: vehicle["12-€"],
        _12EURExVAT: vehicle["12-€ (ex VAT)"],
        _12MthKm: vehicle["12m tot. km"],
        _13: convertPercentageToDecimal(vehicle["13"]),
        _13EUR: vehicle["13-€"],
        _13EURExVAT: vehicle["13-€ (ex VAT)"],
        _13MthKm: vehicle["13m tot. km"],
        _14: convertPercentageToDecimal(vehicle["14"]),
        _14EUR: vehicle["14-€"],
        _14EURExVAT: vehicle["14-€ (ex VAT)"],
        _14MthKm: vehicle["14m tot. km"],
        _15: convertPercentageToDecimal(vehicle["15"]),
        _15EUR: vehicle["15-€"],
        _15EURExVAT: vehicle["15-€ (ex VAT)"],
        _15MthKm: vehicle["15m tot. km"],
        _16: convertPercentageToDecimal(vehicle["16"]),
        _16EUR: vehicle["16-€"],
        _16EURExVAT: vehicle["16-€ (ex VAT)"],
        _16MthKm: vehicle["16m tot. km"],
        _17: convertPercentageToDecimal(vehicle["17"]),
        _17EUR: vehicle["17-€"],
        _17EURExVAT: vehicle["17-€ (ex VAT)"],
        _17MthKm: vehicle["17m tot. km"],
        _18: convertPercentageToDecimal(vehicle["18"]),
        _18EUR: vehicle["18-€"],
        _18EURExVAT: vehicle["18-€ (ex VAT)"],
        _18MthKm: vehicle["18m tot. km"],
        _19: convertPercentageToDecimal(vehicle["19"]),
        _19EUR: vehicle["19-€"],
        _19EURExVAT: vehicle["19-€ (ex VAT)"],
        _19MthKm: vehicle["19m tot. km"],
        _20: convertPercentageToDecimal(vehicle["20"]),
        _20EUR: vehicle["20-€"],
        _20EURExVAT: vehicle["20-€ (ex VAT)"],
        _20MthKm: vehicle["20m tot. km"],
        _21: convertPercentageToDecimal(vehicle["21"]),
        _21EUR: vehicle["21-€"],
        _21EURExVAT: vehicle["21-€ (ex VAT)"],
        _21MthKm: vehicle["21m tot. km"],
        _22: convertPercentageToDecimal(vehicle["22"]),
        _22EUR: vehicle["22-€"],
        _22EURExVAT: vehicle["22-€ (ex VAT)"],
        _22MthKm: vehicle["22m tot. km"],
        _23: convertPercentageToDecimal(vehicle["23"]),
        _23EUR: vehicle["23-€"],
        _23EURExVAT: vehicle["23-€ (ex VAT)"],
        _23MthKm: vehicle["23m tot. km"],
        _24: convertPercentageToDecimal(vehicle["24"]),
        _24EUR: vehicle["24-€"],
        _24EURExVAT: vehicle["24-€ (ex VAT)"],
        _24MthKm: vehicle["24m tot. km"],
        _36: convertPercentageToDecimal(vehicle["36"]),
        _36EUR: vehicle["36-€"],
        _36EURExVAT: vehicle["36-€ (ex VAT)"],
        _36MthKm: vehicle["36m tot. km"],
        _48: convertPercentageToDecimal(vehicle["48"]),
        _48EUR: vehicle["48-€"],
        _48EURExVAT: vehicle["48-€ (ex VAT)"],
        _48MthKm: vehicle["48m tot. km"],
        _60: convertPercentageToDecimal(vehicle["60"]),
        _60EUR: vehicle["60-€"],
        _60EURExVAT: vehicle["60-€ (ex VAT)"],
        _60MthKm: vehicle["60m tot. km"]
      }
    )
  }

  const handleClickIsResizable = () => {
    setIsEditable(false);
    setWithDragAndDrop(false);
    setIsDisabledDragAndDrop(!isDisabledDragAndDrop);
    setIsDisabledEditable(!isDisabledEditable);
    setIsResizable(!isResizable);
  };
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  const handleClickWithDragAndDrop = () => {
    if (!tableConfig.isEditable) setIsDisabledResizable(!isDisabledResizable);
    setIsResizable(false);
    setWithDragAndDrop(!tableConfig.withDragAndDrop);
  };
  const handleClickWithPagination = () => {
    setWithPaginationTable(!tableConfig.withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!tableConfig.withSearchEngine);
  };

  const updateDraggableData = (result) => {
    const items = reorder(
      rows,
      result.source.index,
      result.destination.index,
    );
    setVehicleDataFleet(items);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditable(false);
  };

  const updateEditableData = (rowIndex, columnId, value) => {
    setVehicleDataFleet(old => old.map((item, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [columnId]: value,
        };
      }
      return item;
    }));
  };


  const handleClickIsEditable = () => {
    setTableConfig({ ...tableConfig, isEditable: true });
    setIsEnableSave(true);
  };

  const handleClickSave = () => {
    swal({
      title: "Are you sure?",
      text: "You are about to update the RV table, the fate of Lynk & Co lies in your hands!",
      icon: "warning",
      className: "swal-modal",
      buttons: {
        cancel: {
          text: "NO WAY!",
          value: false,
          visible: true,
          className: "swal-buttons",
          closeModal: true,
        },
        confirm: ({
          text: "CONTINUE",
          value: true,
          visible: true,
          className: "swal-button",
          closeModal: true
        })
      },
    })
      .then((willSave) => {
        if (willSave) {
          const data = [];
          const dataRv = [];
          dataRows.map(vehicle => {
            var rowdata = reversePopulateRVmodel(vehicle)
            dataRv.push(rowdata)
          });
          UploadEditRVcurveTable(dataRv, userOrg.id, false).then(value => {
            if (value && Array.isArray(value.tableData)) {
              value.tableData.map(vehicle => {
                data.push(populateRVmodel(vehicle))
              });
              setDataLoadedForm(true)
              setDateCreated(value.createdDateUtc)
              setvalidFromDate(value.valueFromDateUtc)
            }
            setVehicleDataFleet(data)
            setIsEnableSave(false);
            Datarows();
            setIsEditable(false);
          });
        } else {
          Datarows();
          setIsEnableSave(false);
          setIsEditable(false);
        }
      });
  }

  const handleClickUpload = () => {
    setOpen(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClickDownload = () => {

    const fileName = 'RV Curve';

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const header = [
      [
        'Market ',
        'Model ',
        'Model Year ',
        'Version ',
        'Consumer price (gross) incl. subsidies',
        'Consumer price (gross) excl. subsidies',
        'Landed Cost (net)',
        'Remarketing Cost',
        'Profit Margin',
        'Range (km) ',
        '1',
        '1-€',
        '1-€ (ex VAT)',
        '1m tot. km',
        '2',
        '2-€',
        '2-€ (ex VAT)',
        '2m tot. km',
        '3',
        '3-€',
        '3-€ (ex VAT)',
        '3m tot. km',
        '4',
        '4-€',
        '4-€ (ex VAT)',
        '4m tot. km',
        '5',
        '5-€',
        '5-€ (ex VAT)',
        '5m tot. km',
        '6',
        '6-€',
        '6-€ (ex VAT)',
        '6m tot. km',
        '7',
        '7-€',
        '7-€ (ex VAT)',
        '7m tot. km',
        '8',
        '8-€',
        '8-€ (ex VAT)',
        '8m tot. km',
        '9',
        '9-€',
        '9-€ (ex VAT)',
        '9m tot. km',
        '10',
        '10-€',
        '10-€ (ex VAT)',
        '10m tot. km',
        '11',
        '11-€',
        '11-€ (ex VAT)',
        '11m tot. km',
        '12',
        '12-€',
        '12-€ (ex VAT)',
        '12m tot. km',
        '13',
        '13-€',
        '13-€ (ex VAT)',
        '13m tot. km',
        '14',
        '14-€',
        '14-€ (ex VAT)',
        '14m tot. km',
        '15',
        '15-€',
        '15-€ (ex VAT)',
        '15m tot. km',
        '16',
        '16-€',
        '16-€ (ex VAT)',
        '16m tot. km',
        '17',
        '17-€',
        '17-€ (ex VAT)',
        '17m tot. km',
        '18',
        '18-€',
        '18-€ (ex VAT)',
        '18m tot. km',
        '19',
        '19-€',
        '19-€ (ex VAT)',
        '19m tot. km',
        '20',
        '20-€',
        '20-€ (ex VAT)',
        '20m tot. km',
        '21',
        '21-€',
        '21-€ (ex VAT)',
        '21m tot. km',
        '22',
        '22-€',
        '22-€ (ex VAT)',
        '22m tot. km',
        '23',
        '23-€',
        '23-€ (ex VAT)',
        '23m tot. km',
        '24',
        '24-€',
        '24-€ (ex VAT)',
        '24m tot. km',
        '36',
        '36-€',
        '36-€ (ex VAT)',
        '36m tot. km',
        '48',
        '48-€',
        '48-€ (ex VAT)',
        '48m tot. km',
        '60',
        '60-€',
        '60-€ (ex VAT)',
        '60m tot. km'
      ]
    ]

    const exportToCSV = (dataRows, fileName) => {
      const ws = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(ws, header);

      const dataRv = [];
      dataRows.map(vehicle => {
        let rowdata = populateRVmodel(vehicle)
        dataRv.push(rowdata)
      });
      let newdataRows = UnformmatRvTableExcelJson(dataRv)

      const excelDataRv = [];
      newdataRows.map(vehicle => {
        let rowdata = populateExcelRvModel(vehicle)
        excelDataRv.push(rowdata)
      });

      XLSX.utils.sheet_add_json(ws, excelDataRv, { origin: 'A2', skipHeader: true });
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(data, fileName + fileExtension);
    }
    exportToCSV(dataRows, fileName)
  };

  const anchor = "right";
  const slider = (anchor) => (
    <div role="presentation">
      <RVcurveUpload orgId={userOrg.id}
        onUploadComplete={Datarows} handleClose={handleClose} >
      </RVcurveUpload>
    </div>
  );

  const matchesPermission = userPermissions.includes(permission.Remarketing_Administrator)

  return (
    <Col md={12} lg={12}>
      <Card id="CardBodyCardAllowance3" style={{ marginTop: '75px', borderRadius: '4px' }} >

        <div style={{ marginLeft: '30px', flex: '1 1 180%',  }}>
          <Grid container>
            <Grid item xs={1} >
              <Typography style={{ marginTop: '18px' }}>Last Updated:</Typography>
            </Grid>
            <Grid item xs={2} style={{ marginLeft: '-0.3vw', marginTop: '2px' }}>
              {dataLoadedForm ?
                (<FormTextFieldSalesPlain value={FormmatDateMap(dateCreated)} />) : (<LoadingImagePlaceHolderRVTable />)
              }
            </Grid>
          </Grid>
          <Grid item xs={9}>
          </Grid>
          <Grid container>
            <Grid item xs={1} >
              <Typography style={{ marginTop: '-5px' }}>Valid from:</Typography>
            </Grid>
            <Grid item xs={2} style={{ marginLeft: '-0.3vw', marginTop: '-20px' }}>
              {dataLoadedForm ?
                (<FormTextFieldSalesPlain value={FormmatDateMap(validFromDate)} />) : (<LoadingImagePlaceHolderRVTable />)
              }
            </Grid>
          </Grid>
          <Grid item xs={9}>
          </Grid>
        </div>
        <CardBody style={{ marginTop: '-48px', marginBottom: '24px' }}>
          <div  style={{ marginTop: '58px' }}>
            <EnhancedRvCurveTableToolbar
              handleClickUpload={handleClickUpload}
              handleClickDownload={handleClickDownload}
              handleClickIsEditable={handleClickIsEditable}
              handleClickSave={handleClickSave}
              isEnableSave={isEnableSave}
            />
          </div>

          <div style={{ marginTop: '-85px' }}>

            {dataLoaded ?
              <ReactTableBaseRvCurve
                key={tableConfig.withSearchEngine || isResizable || tableConfig.isEditable ? 'modified' : 'common'}
                columns={columns}
                data={dataRows}
                updateEditableData={updateEditableData}
                updateDraggableData={updateDraggableData}
                tableConfig={tableConfig}
              />
              :
              <div style={{ marginTop: '-55px', height: '650px' }}>
                <LoadingImagePlaceHolder />
              </div>
            }
          </div>

          <SwipeableDrawer
            classes={{
              paper: drawerClasses.SwipeableDrawerRvCurve,
            }}
            anchor={anchor}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            {slider(anchor)}
          </SwipeableDrawer>
        </CardBody>
      </Card>
    </Col>
  );
};

const mapStateToProps = state => ({
  userOrg: selectUserOrganization(state),
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(RvReactTable);