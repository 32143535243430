import MCCImage from 'src/assets/img/vehicles/MCCImage.png';
import LCCImage from 'src/assets/img/vehicles/LCCImage.png';

export const MCCData = [
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 54.3259709608153, lng: 3.2000110794093883 }, street: 'Gistelse Steenweg 276', postalCode: '24782 Büdelsdorf', country: 'Belgium', vehicleUpdatedAt: '', heading: 'DE GRANDE CARS nv',
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 50.40451148945042, lng: 4.4526137237593115 }, street: 'Rue de Montigny 181', postalCode: '6000 Charleroi', country: 'Belgium', vehicleUpdatedAt: '', heading: 'PLV Motor'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 51.060192193925424, lng: 4.458296843225772 }, street: 'Antwerpsesteenweg 271', postalCode: '2800 Mechelen', country: 'Belgium', vehicleUpdatedAt: '', heading: 'Vermant NV'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 50.44807682470095, lng: 4.896448807211482 }, street: 'Chaussée de Marche 441', postalCode: '5101 Namur', country: 'Belgium', vehicleUpdatedAt: '', heading: 'ACG nv'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 51.0541276731431, lng: 3.736390103808946 }, street: 'IJzerweglaan 101', postalCode: '9050 Gent', country: 'Belgium', vehicleUpdatedAt: '', heading: 'LCC Address'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 48.318678141984826, lng: 10.892478716989714 }, street: 'Unterer Talweg 48', postalCode: '86179 Augsburg', country: 'Germany', vehicleUpdatedAt: '', heading: 'Automobile Tierhold GmbH'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 52.58006386443226, lng: 13.297188965880224 }, street: 'Wittestraße 32', postalCode: '13509 Berlin', country: 'Germany', vehicleUpdatedAt: '', heading: 'ACW König GmbH'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 51.27521622219305, lng: 6.743051361757959 }, street: 'Am Seestern 3', postalCode: '40547 Düsseldorf', country: 'Germany', vehicleUpdatedAt: '', heading: 'Moll Gmbh & Co. KG'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 51.52135557732092, lng: 7.50223635961358 }, street: 'Hannöversche Str. 44', postalCode: '44143 Dortmund', country: 'Germany', vehicleUpdatedAt: '', heading: 'Premium Cars Peters GmbH & Co. KG'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 48.83678204707503, lng: 9.195089956077402 }, street: 'Cannstatter Straße 46', postalCode: '70190 Stuttgart', country: 'Germany', vehicleUpdatedAt: '', heading: 'Schwabengarage GmbH'
    },

    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 40.53096377755595, lng: -3.654136211772033 }, street: 'CALLE SEPULVEDA 10', postalCode: '28108 ALCOBENDAS – MADRID', country: 'Spain', vehicleUpdatedAt: '', heading: 'Battinver'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 36.722541589058366, lng: -4.463090390271706 }, street: 'Av. de. Velazquez 192', postalCode: '28004 Malaga', country: 'Spain', vehicleUpdatedAt: '', heading: 'Vypsa'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 41.410754707038684, lng: 2.146537894236767 }, street: 'AVDA. VALLCARCA 77-79', postalCode: '08023 Barcelona', country: 'Spain', vehicleUpdatedAt: '', heading: 'LESSEPS MOTOR, S.L'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 37.41898567185309, lng: -5.943054205166935 }, street: 'Av. Fernández Murube 10', postalCode: '41007 Sevilla', country: 'Spain', vehicleUpdatedAt: '', heading: 'Volvo Sevilla - Codimotor S.L'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 39.48237200014021, lng: -0.3500433977500779 }, street: `Carrer d'Abén al Abbar, 8 Junto`, postalCode: '46021 València', country: 'Spain', vehicleUpdatedAt: '', heading: 'AUTO SWEDEN S.A.'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 48.70294671378484, lng: 2.3714097067140383 }, street: '57/59 AVENUE FRANCOIS MITTERAND', postalCode: '91200 ATHIS MONS', country: 'France', vehicleUpdatedAt: '', heading: 'STE BIDAUD PERE ET FILS'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 43.276299216474925, lng: 5.4001553061140655 }, street: `Village Automobile Ferrié, 4 Bd des Aciéries`, postalCode: '13010 Marseille', country: 'France', vehicleUpdatedAt: '', heading: 'AUTO SWEDEN S.A.'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 43.66073281568581, lng: 1.4182747058748224 }, street: `360 Av. des États-Unis`, postalCode: '31200 Toulouse', country: 'France', vehicleUpdatedAt: '', heading: 'Volvo - Sipa Automobiles - Toulouse Nord'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 45.778312456505695, lng: 4.797954384960979 }, street: `88 Rue Marietton`, postalCode: '69009 Lyon', country: 'France', vehicleUpdatedAt: '', heading: 'VOLVO Lyon Vaise - FELIX FAURE AUTOMOBILES'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 49.036218767097104, lng: 2.1225297112599337 }, street: `135 Rue de Paris`, postalCode: `95310 Saint-Ouen-l'Aumône`, country: 'France', vehicleUpdatedAt: '', heading: 'Volvo ABVV Saint Ouen Alms'
    },

    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 43.53708229820728, lng: 13.258569263101935 }, street: `Via Ancona 80`, postalCode: `60035 Saint-Ouen-l'AumôneJesi AN`, country: 'Italy', vehicleUpdatedAt: '', heading: 'Citroën Pieralisi Jesi'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 45.50732575653854, lng: 9.201566663556637 }, street: `Viale Fulvio Testi 60`, postalCode: `20125 Milano`, country: 'Italy', vehicleUpdatedAt: '', heading: 'Kia LARIO MI AUTO | Concessionaria Milano'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 40.853547406474235, lng: 14.288279992508429 }, street: 'Via Emanuele Gianturco 109', postalCode: '80143 Napoli', country: 'Italy', vehicleUpdatedAt: '', heading: 'Autorally'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 41.84245558650311, lng: 12.455438348437811 }, street: 'Via della Magliana 309', postalCode: '00146 Roma', country: 'Italy', vehicleUpdatedAt: '', heading: 'I.W.R. Ital Wagen Roma S.R.L.'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 45.40612194018751, lng: 10.967430763554521 }, street: 'Via Gianbattista Morgagni 16', postalCode: '37135 Verona', country: 'Italy', vehicleUpdatedAt: '', heading: 'Autoverona Concessionaria Volvo'
    },

    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 52.135520422536196, lng: 4.6683282781693745 }, street: 'Willem de Zwijgerlaan 39', postalCode: '2406 HD Alphen aan den Rijn', country: 'Netherlands', vehicleUpdatedAt: '', heading: 'Hooftman Volvo Alphen aan den Rijn'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 52.30450850627456, lng: 4.933909242600472 }, street: 'Kollenbergweg 15', postalCode: '1101 AR Amsterdam', country: 'Netherlands', vehicleUpdatedAt: '', heading: 'Volvo Bangarage Amsterdam Zuidoost'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 51.581878829304934, lng: 4.7430176923788325 }, street: 'Ettensebaan 17C', postalCode: '4812 XA Breda', country: 'Netherlands', vehicleUpdatedAt: '', heading: 'Bluekens Volvo'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 52.115200491820254, lng: 5.03124954927574 }, street: 'Meijewetering 41', postalCode: '3543 AA  Utrecht', country: 'Netherlands', vehicleUpdatedAt: '', heading: 'Verkerk Volvo Leidsche Rijn'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 51.377407298403924, lng: 6.159962492363233 }, street: 'Venrayseweg 28', postalCode: '5928 NZ Venlo', country: 'Netherlands', vehicleUpdatedAt: '', heading: 'Volvo Rutten Venlo'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 57.925115488044234, lng: 12.531495551537235 }, street: 'Stråhles Allé 6', postalCode: '441 32 Alingsäs', country: 'Sweden', vehicleUpdatedAt: '', heading: 'Bil-Nilsson I Alingsås AB'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 57.75929997451123, lng: 11.880824979255786 }, street: 'Örnekulans väg 1', postalCode: '417 29 Göteborg', country: 'Sweden', vehicleUpdatedAt: '', heading: 'Volvo Car - Tuve'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 55.5717861830387, lng: 13.054898319212475 }, street: 'Agnesfridsvägen 119', postalCode: '213 75 Malmö', country: 'Sweden', vehicleUpdatedAt: '', heading: 'Bilia Jägersro'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 59.36455310931385, lng: 18.016426637672765 }, street: 'Torfredsplatsen 1', postalCode: '168 75 Solna', country: 'Sweden', vehicleUpdatedAt: '', heading: 'Bilia Haga Norra'
    },
    {
        icon: MCCImage, type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 58.35187507316354, lng: 11.869374713013261 }, street: 'Kärranäsvägen 2', postalCode: '451 76 Uddevalla', country: 'Sweden', vehicleUpdatedAt: '', heading: 'Brandt Bil Uddevalla'
    }
];

export const LCCData = [
    {
        icon: LCCImage, type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 51.303017, lng: -3.6038544290329217 }, street: 'Margareta Van Oostenrijkstraat 1', postalCode: '8380 Zeebrugge', country: 'Belgium', vehicleUpdatedAt: '', heading: 'Ruben Hoste'
    },
    {
        icon: LCCImage, type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 40.177324560262335, lng: 3.233512 }, street: 'P.I. Huertecillos, P.º de la Calderona, s/n, 28350 Ciempozuelos', postalCode: '28350 Madrid', country: 'Spain', vehicleUpdatedAt: '', heading: 'Berge Compound Santander'
    },
    {
        icon: LCCImage, type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 45.651958286866616, lng: 8.826691092889133 }, street: 'Via Armando Diaz, 2, 21012 Cassano Magnago VA', postalCode: '21012 Millan', country: 'Italy', vehicleUpdatedAt: '', heading: 'Saturno Compound'
    },
    {
        icon: LCCImage, type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 40.177324560262335, lng: 3.233512 }, street: 'P.I. Huertecillos, P.º de la Calderona, s/n, 28350 Ciempozuelos', postalCode: '28350 Madrid', country: 'Germany', vehicleUpdatedAt: '', heading: 'Ruben Hoste'
    },
    {
        icon: LCCImage, type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 49.3506966078823, lng: 2.7578502936612286 }, street: 'ZI LE MEUX RUE DU BOIS BARBIER', postalCode: '60660 LE MEUX', country: 'France', vehicleUpdatedAt: '', heading: 'GROUPE CHARLES ANDRE COMPOUND'
    },
    {
        icon: LCCImage, type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 51.6696185188472, lng: 4.8297167807222765 }, street: 'LOGISTIEKWEG 2', postalCode: 'TBC AB OOSTERHOUT', country: 'Netherlands', vehicleUpdatedAt: '', heading: 'GEFCO OOSTERHOUT COMPOUND'
    },
    {
        icon: LCCImage, type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 57.718602396521575, lng: 11.841683507605595 }, street: 'TBC', postalCode: 'TBC Göteborg', country: 'Sweden', vehicleUpdatedAt: '', heading: 'VCC Yard Management'
    }
]

export const VehicleData = [
    {
        type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 51.27521622219305, lng: 6.743051361757959 }, street: 'Am Seestern 3', postalCode: '40547 Düsseldorf', country: 'Germany', vehicleUpdatedAt: '', heading: 'Moll Gmbh & Co. KG'
    },
    {
        type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 51.52135557732092, lng: 7.50223635961358 }, street: 'Hannöversche Str. 44', postalCode: '44143 Dortmund', country: 'Germany', vehicleUpdatedAt: '', heading: 'Premium Cars Peters GmbH & Co. KG'
    },
    {
        type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 48.83678204707503, lng: 9.195089956077402 }, street: 'Cannstatter Straße 46', postalCode: '70190 Stuttgart', country: 'Germany', vehicleUpdatedAt: '', heading: 'Schwabengarage GmbH'
    },
    {
        type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 40.53096377755595, lng: -3.654136211772033 }, street: 'CALLE SEPULVEDA 10', postalCode: '28108 ALCOBENDAS – MADRID', country: 'Spain', vehicleUpdatedAt: '', heading: 'Battinver'
    },
    {
        type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 36.722541589058366, lng: -4.463090390271706 }, street: 'Av. de. Velazquez 192', postalCode: '28004 Malaga', country: 'Spain', vehicleUpdatedAt: '', heading: 'Vypsa'
    },
    {
        type: 'MCC', vin: 'L6TCX2E78LE000105', position: { lat: 41.410754707038684, lng: 2.146537894236767 }, street: 'AVDA. VALLCARCA 77-79', postalCode: '08023 Barcelona', country: 'Spain', vehicleUpdatedAt: '', heading: 'LESSEPS MOTOR, S.L'
    }
]

export const VehicleDataMCCLCC = [
    {
        type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 51.303017, lng: -3.6038544290329217 }, street: 'Margareta Van Oostenrijkstraat 1', postalCode: '8380 Zeebrugge', country: 'Belgium', vehicleUpdatedAt: '', heading: 'Ruben Hoste'
    },
    {
        type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 40.177324560262335, lng: 3.233512 }, street: 'P.I. Huertecillos, P.º de la Calderona, s/n, 28350 Ciempozuelos', postalCode: '28350 Madrid', country: 'Spain', vehicleUpdatedAt: '', heading: 'Berge Compound Santander'
    },
    {
        type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 45.651958286866616, lng: 8.826691092889133 }, street: 'Via Armando Diaz, 2, 21012 Cassano Magnago VA', postalCode: '21012 Millan', country: 'Italy', vehicleUpdatedAt: '', heading: 'Saturno Compound'
    },
    {
        type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 40.177324560262335, lng: 3.233512 }, street: 'P.I. Huertecillos, P.º de la Calderona, s/n, 28350 Ciempozuelos', postalCode: '28350 Madrid', country: 'Germany', vehicleUpdatedAt: '', heading: 'Ruben Hoste'
    },
    {
        type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 49.3506966078823, lng: 2.7578502936612286 }, street: 'ZI LE MEUX RUE DU BOIS BARBIER', postalCode: '60660 LE MEUX', country: 'France', vehicleUpdatedAt: '', heading: 'GROUPE CHARLES ANDRE COMPOUND'
    },
    {
        type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 51.6696185188472, lng: 4.8297167807222765 }, street: 'LOGISTIEKWEG 2', postalCode: 'TBC AB OOSTERHOUT', country: 'Netherlands', vehicleUpdatedAt: '', heading: 'GEFCO OOSTERHOUT COMPOUND'
    },
    {
        type: 'LCC', vin: 'L6TCX2E78LE000105', position: { lat: 57.718602396521575, lng: 11.841683507605595 }, street: 'TBC', postalCode: 'TBC Göteborg', country: 'Sweden', vehicleUpdatedAt: '', heading: 'VCC Yard Management'
    }
]