import { makeStyles } from '@material-ui/core/styles';

export const useToolbarStyles = makeStyles((theme) => ({

    // To be used to style Vehicle page     
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(10),
        paddingBottom: theme.spacing(1),
        // marginBottom: theme.spacing(3),
        // borderBottom: '1px solid #a3a3a3',
        // borderBottomStyle: 'ridge',
        marginTop: '30px'
    },
    tooltipDiv: {
        display: 'flex',
        marginRight: '-23px'
    },
    tooltipImg: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        cursor: 'pointer'
    },
    highlight:
    {
        color: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: 'row',
    },
    title: {
        flex: '1 1 100%',
        fontSize: 'large'
    },

    // To be used to style User page

    root1: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginBottom:  theme.spacing(1),
        // marginTop: '2px'
    },
    tooltipDiv1: {
        display: 'flex',
        marginBottom: '118px',
        marginLeft: '1050px'
    },
    tooltipImg1: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        cursor: 'pointer'
    },
    highlight1:
    {
        color: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: 'row',
    },
    title1: {
        flex: '1 1 100%',
        fontSize: 'large',
    },

    // To be used to style Organisation and Fleet

    root2: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(13),
        // borderBottom: '1px solid #a3a3a3',
        // borderBottomStyle: 'ridge',
        marginTop: '-98px'
    },
    tooltipDiv2: {
        display: 'flex',
        marginBottom: '2px',
        marginLeft: '1050px'
    },
    tooltipImg2: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
    },
    highlight2:
    {
        color: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: 'row',
    },
    title2: {
        flex: '1 1 100%',
        fontSize: 'large',
    },

    // To be used to style Rv Table toolbar

    root3: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginBottom: - theme.spacing(7),
        marginTop: '44px'
    },
    tooltipDiv3: {
        display: 'flex',
        marginBottom: '2px',
        marginLeft: '1050px'
    },
    tooltipImg3: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        marginBottom: '90px'
    },
    highlight3:
    {
        color: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: 'row',
    },
    title3: {
        flex: '1 1 100%',
        fontSize: 'large',
    },

    // To be used to style Internal Fleet page    
    root4: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(12),
        marginTop: '-90px'
    },
    tooltipDiv4: {
        display: 'flex',
        marginBottom: '-90px',
        marginRight: '-23px'
    },
    tooltipImg4: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
    },
    highlight4:
    {
        color: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: 'row',
    },
    title4: {
        flex: '1 1 100%',
        fontSize: 'large'
    },
}));