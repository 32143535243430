import React, { useState } from 'react';
import { Card, CardBody, Row } from "reactstrap";
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';

const RVBelowLCCard = ({ setQueryParamsRvBelowLandedCost, setDataLoaded, rvBelowLCCount }) => {

    const [checked, setChecked] = useState(false);  

    const switchHandler = (event) => {
        let value = event.target.checked;

        if (value == true) {
            
            setQueryParamsRvBelowLandedCost('Yes');
            setDataLoaded(false);
        } else {
            setQueryParamsRvBelowLandedCost('');
            setDataLoaded(false);
        }

        setChecked(event.target.checked);
    };

    return (
        <Card id="CardBodyCardAllowance1" style={{ marginBottom: '40px' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "16px", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF' }}>RV BELOW LC</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white' }}>
                    <tr>
                        <td style={{ float: 'left', fontSize: '34px', fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF' }}>{rvBelowLCCount}<br /></td>
                        <td style={{ float: 'right' }}>
                            <ToggleComponent checked={checked} onChange={switchHandler} />
                        </td>
                    </tr>
                </table>
            </CardBody>
        </Card>
    )
};

export default RVBelowLCCard;