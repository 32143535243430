import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Card, Row, Col, CardBody, CardImg } from "reactstrap";
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds } from "src/redux/user/user.selectors";
import profilebgBlue from "src/assets/img/BackgroundImages/ProvisioningPicture_Blue.png";
import profilebgBlack from "src/assets/img/BackgroundImages/ProvisioningPicture_Black.png";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { FormTextFieldSalesPlain } from "src/components/form-elements/custom-form-text-field.driverPage";
import SearchButton from "src/components/buttons/search-button-component";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import okIcon from "src/assets/img/vehicles/okIcon.png";
import noIcon from "src/assets/img/vehicles/noIcon.png";
import Modal from "@mui/material/Modal";
import { GetFleets } from "src/services/fleet-http.service";
import { QueryByVIN } from "src/services/vehicle-pages-http.service";
import { GetFleetTypes } from "src/services/fleetType-http.service";
import { ProvisionVehicle, GetUsersProvisionedToVehicle } from "src/services/provisioning-http.service";
import { QueryCEPCustomer } from "src/services/cep-http.service";
import SearchBarProvisioning from "src/components/buttons/search-button-provisioning.component";
import PackageSelect from "./PackageSelect";
import RoleSelect from 'src/pages/driver-page/RoleSelect';

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(15),
        position: 'sticky',
        marginLeft: '11px',
    },
    drawer: {
        width: 250,
        flexShrink: 0,
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '43%',
        position: 'absolute',
        height: '20px'
    },
    checkbox: {
        "&.Mui-checked": {
            color: "#1EF1C6"
        },
        "&.Mui-disabled": {
            color: "#BFBFBF"
        },
        color: "#BFBFBF",
    },
    titleStyle: {
        marginLeft: '19px',
        fontSize: '16px',
        marginTop: '20px',
        fontFamily: "LynkcoTypeMedium",
    },
    contentStyle: {
        fontSize: '16px',
        fontFamily: "LynkcoTypeMedium"
    },
    imageStyle: {
        marginTop: '-13px',
        transform: 'scale(0.9)',
        marginBottom: '30px',
        marginLeft: '-10px',
        height: '520px'
    },
    backgroundStyle: {
        marginTop: '75px',
        marginRight: '34px',
        position: 'relative',
    },
    cardStyle: {
        height: '770px',
        backgroundImage: 'linear-gradient(135deg, #0A0A0A , #252525 99%)',
    },
    iconStyle: {
        marginTop: '155px',
        transform: 'scale(1.3)',
        marginBottom: '30px',
        padding: '10px',
        height: '28vh',
        width: '100%',
        alignContent: 'center'
    },
    searchfieldStyle: {
        width: '300%',
        marginTop: '50px',
        marginLeft: '5px'
    },
    inputWrapStyle: {
        marginLeft: '-3px',
        marginRight: '10px',
        marginTop: '100px'
    },
    vehicleInputWrapStyle: {
        marginLeft: '6px',
        marginRight: '10px',
        marginTop: '100px'
    },
    roleWrapStyle: {
        marginTop: '30px',
        marginBottom: '-60px'
    },
    root: {
        overflow: 'scroll',
        overflowX: 'hidden',
        height: '500px',
        marginLeft: '-2px',

        "&::-webkit-scrollbar": {
            height: "1px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 0.1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 0.1%)',
            border: 'none'
        },
    },
}));

const ProvisioningPage = (props) => {
    const { userOrg, userMarketIds } = props
    const classes = UseStyles(props);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [searchInput, setSearchInput] = useState(false);
    const [searchInputVIN, setSearchInputVIN] = useState(false);

    const [displaySelectvehicle, setDisplaySelectedVehicle] = useState(false);
    const [displayRole, setDisplayRole] = useState(false);

    const [iconDisplay, setIconDisplay] = useState(false);
    const [addressInput, setAddressInput] = React.useState('');

    const [searchVIN, setSearchVIN] = React.useState('');
    const [vehicleDetail, setVehicleDetail] = React.useState({});

    const [customerData, setCustomerData] = React.useState([]);
    const [sonwflakeID, setSnowflakeID] = React.useState('');

    const [inputVIN, setInputVIN] = React.useState('');
    const [message, setMessage] = React.useState('');

    const [displayModal, setDisplayModal] = useState(false);

    const icon = `../assets/icons/OkProvisioningMarker.png`;

    const [packageID, setPackageID] = React.useState('');

    const [roleId, setRoleID] = React.useState('');
    const [showPackageChecked, setShowPackageChecked] = useState(false);
    const [showRoleeChecked, setShowRoleChecked] = useState(false);

    const handleProvisioning = () => {
        if (roleId !== '' && packageID !== '') {
            ProvisionVehicle(inputVIN.trim(), sonwflakeID, packageID, roleId)
                .then(data => {
                    if (data.ok == true) {
                        setOpen(true);
                        setIconDisplay(true)
                        setAddressInput('');
                        setSearchVIN('');
                    } else {
                        setOpen(true);
                        setIconDisplay(false)
                    }
                });
        }

        if (roleId == '' || packageID == '') {
            setOpenDialog(true);
            setMessage('Role or Provisioning Package not selected!');
            setDialogMessage('Please make sure to select a role and provisioning package before continuing with provisioning');
        }
    };

    const handleDialogOk = () => {
        if (displayModal) {
            setOpenDialog(false);
            setOpen(false);
            setSearchInput(false);
        }
        if (!displayModal) {
            setOpenDialog(false);
            setOpen(false);
        }
    }

    const handleClose = () => {
        setOpen(false);
        setSearchInput(false);
        setDisplaySelectedVehicle(false);
        setSearchInputVIN(false);
        setDisplayRole(false);
    };

    const handleChangeCustomerNumber = () => {

        if (addressInput?.trim()?.length != 0) {
            let id = ""
            let email = ""
            let customerNumber = addressInput.trim();
            let snowflakeId = ""
            let cepArray = []

            QueryCEPCustomer(id, email, customerNumber, snowflakeId)
                .then(data => {
                    if (data.ok == true) {
                        if (data.cepCustomer && Array.isArray(data.cepCustomer)) {
                            data?.cepCustomer.map((item) => {
                                cepArray.push({
                                    firstName: item.firstName,
                                    lastName: item.lastName,
                                    email: item.email,
                                    customerType: item.customerType,
                                    market: item.market
                                });
                                setSnowflakeID(item.snowflakeId)
                            })
                            setSearchInput(true);
                        }
                        setCustomerData(cepArray);
                    } else {
                        setDisplayModal(true);
                        setOpenDialog(true);
                        setMessage('Customer ID not found!');
                        setDialogMessage('Please check if customer has an active account and try again');
                        setSearchInput(false);
                        setAddressInput('');
                        setOpen(false);
                        setDisplaySelectedVehicle(false);
                    }
                })
        }
    }

    const handleChangeVINorLIcensePlate = () => {

        if (searchVIN?.trim()?.length != 0) {
            let fleetTypes = []
            let fleetIds = []
            let marketIds = userMarketIds;

            GetFleets(userOrg.id, userMarketIds)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(item => {
                            fleetIds.push(item.id);
                        })
                        GetFleetTypes(userOrg.id)
                            .then(data => {
                                if (data && Array.isArray(data)) {
                                    data.map(fleetType => {
                                        fleetTypes.push(fleetType['id'])
                                    })
                                }
                                QueryByVIN(searchVIN?.trim(), fleetIds, fleetTypes, marketIds)
                                    .then(data => {
                                        if (data.ok == true) {
                                            setSearchInputVIN(true);
                                            setVehicleDetail(data.fleetAsset);
                                            setInputVIN(data?.fleetAsset?.vin);
                                        } else {
                                            setSearchVIN();
                                            setOpenDialog(true);
                                            setMessage(data.title);
                                            setDisplayModal(false);
                                            setDialogMessage(data.errorMessage);
                                        }
                                    });
                            })
                    }
                })
        }
    }

    const handleSearchInput = (event) => {
        setAddressInput(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            setSearchInput(false);
            setDisplaySelectedVehicle(false);
            setSearchInputVIN(false);
            setDisplayRole(false);
        }
    }

    const handleSearchInputVIN = (event) => {
        setSearchVIN(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            setSearchInputVIN(false);
            setDisplayRole(false);
        }
    }

    const textFieldKeypressVIN = (e) => {
        if (e.key === 'Enter') {
            handleChangeVINorLIcensePlate();
        }
    }

    const textFieldKeypressCustomerDetails = (e) => {
        if (e.key === 'Enter') {
            handleChangeCustomerNumber();
        }
    }

    const handleConfirmVehicle = () => {
        let vin = inputVIN.trim()

        GetUsersProvisionedToVehicle(vin).then(data => {

            if (data.ok == true && data.usersProvisionedToVehicle.length === 0) {
                setDisplayRole(true);
            } else {
                setSearchVIN();
                setDisplayModal(false);
                setSearchInputVIN(false);
                setDisplayRole(false);
                setOpenDialog(true);
                setMessage('Vehicle already has a primary driver!')
                setDialogMessage('Please make sure to un-provision any connected primary driver prior to this action.');
            }
        })
    };

    const handleChangePackageDropdown = (event) => {
        const { value } = event.target;

        setPackageID(value);
        setShowPackageChecked(true);
        if (value == 0) {
            setPackageID('');
            setShowPackageChecked(false);
        }
    };

    const handleChangeRoleDropdown = (event) => {
        const { value } = event.target;

        setRoleID(value);
        setShowRoleChecked(true);
        if (value == 0) {
            setPackageID('');
            setShowRoleChecked(false);
        }
    };

    return (
        <div className={classes.backgroundStyle}>
            <VehiclePageDialog
                dialogue='true'
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
                title={message}
            />

            <Modal
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleClose}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
            >
                <div>
                    <CardImg
                        alt="Card image cap"
                        src={iconDisplay ? okIcon : noIcon}
                        className={classes.iconStyle}
                    />
                </div>
            </Modal>

            <Row>
                <Col lg="4" md="12" >
                    <Card id="CardBodyCardAllowance3" className={classes.cardStyle}  >
                        <CardBody>
                            <Row>
                                <Col lg="12" md="12" >
                                    <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                        Select Driver
                                    </Typography>
                                    <div className={classes.searchfieldStyle} >
                                        <SearchBarProvisioning
                                            priceBucketActiveTab='true'
                                            placeholder={'Customer Number'}
                                            handleSearch={handleChangeCustomerNumber}
                                            handleSearchInput={handleSearchInput}
                                            onKeyPress={textFieldKeypressCustomerDetails}
                                            value={addressInput}
                                        />
                                    </div>

                                    <Divider className={classes.dividerStyle} orientation='horizontal' />

                                </Col>

                                <Row >
                                    <Col lg="12" md="12" >

                                        {searchInput &&
                                            <>
                                                {customerData != undefined && customerData.map((value, key) => {
                                                    return (
                                                        <div key={key}>
                                                            <div className={classes.root}>
                                                                <Col lg="12" md="12" >
                                                                    <div style={{ marginLeft: '10px' }}>
                                                                        <div style={{ marginLeft: '-3px', marginRight: '10px' }}>
                                                                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                                <Grid item xs={12} md={12} lg={12} style={{ marginTop: '26px' }}>
                                                                                    <Typography className={classes.contentStyle}><span >First Name</span></Typography>
                                                                                    <div style={{ marginLeft: '-13px', fontSize: '20px', marginTop: '-8px' }}>
                                                                                        <FormTextFieldSalesPlain value={value.firstName} updateFontSize='true' disabled />
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                        <div className={classes.inputWrapStyle} >
                                                                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                                <Grid item xs={12} md={12} lg={12} style={{ marginTop: '50px' }}>
                                                                                    <Typography className={classes.contentStyle}><span >Last Name</span></Typography>
                                                                                    <div style={{ marginLeft: '-13px', marginTop: '-8px' }}>
                                                                                        <FormTextFieldSalesPlain value={value.lastName} updateFontSize='true' disabled />
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                        <div className={classes.inputWrapStyle}>
                                                                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                                <Grid item xs={12} md={12} lg={12} style={{ marginTop: '50px' }}>
                                                                                    <Typography className={classes.contentStyle}><span >Email</span></Typography>
                                                                                    <div style={{ marginLeft: '-13px', marginTop: '-8px', marginRight: '-250px' }}>
                                                                                        <FormTextFieldSalesPlain value={value.email} updateFontSize='true' disabled />
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>

                                                                        <div className={classes.inputWrapStyle}>
                                                                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                                <Grid item xs={12} md={12} lg={12} style={{ marginTop: '50px' }}>
                                                                                    <Typography className={classes.contentStyle}>Customer Type</Typography>
                                                                                    <div style={{ marginLeft: '-13px', marginTop: '-8px' }}>
                                                                                        <FormTextFieldSalesPlain value={value.customerType} updateFontSize='true' disabled />
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                        <div className={classes.inputWrapStyle}>
                                                                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                                <Grid item xs={12} md={12} lg={12} style={{ marginTop: '50px' }}>
                                                                                    <Typography className={classes.contentStyle}><span >Market</span></Typography>
                                                                                    <div style={{ marginLeft: '-13px', marginTop: '-8px' }}>
                                                                                        <FormTextFieldSalesPlain value={value.market} updateFontSize='true' disabled />
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                        </div>
                                                    )

                                                })}
                                            </>
                                        }
                                    </Col>
                                </Row>

                            </Row>
                            <Row style={{ float: 'right', paddingRight: '20px', paddingTop: '5px' }}>
                                <tr>
                                    <td>
                                        {searchInput && customerData != undefined && !displaySelectvehicle &&
                                            <SearchButton
                                                placeholder={'Confirm Driver'}
                                                handleSearch={() => {
                                                    setDisplaySelectedVehicle(true);
                                                    setSearchVIN('');
                                                }
                                                }
                                            />
                                        }
                                    </td>
                                </tr>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg="4" md="12" >
                    {displaySelectvehicle &&
                        <Card id="CardBodyCardAllowance3" className={classes.cardStyle}  >
                            <CardBody>
                                <Row>
                                    <Col lg="12" md="12" >
                                        <Col lg="12" md="12" >
                                            <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                                Select Vehicle
                                            </Typography>
                                            <div className={classes.searchfieldStyle} >
                                                <SearchBarProvisioning
                                                    priceBucketActiveTab='true'
                                                    handleSearch={handleChangeVINorLIcensePlate}
                                                    handleSearchInput={handleSearchInputVIN}
                                                    onKeyPress={textFieldKeypressVIN}
                                                    placeholder={'VIN'}
                                                    value={searchVIN}
                                                />
                                            </div>
                                            <Divider className={classes.dividerStyle} orientation='horizontal' />
                                        </Col>

                                        <Row>
                                            {searchInputVIN &&
                                                <>
                                                    {
                                                        vehicleDetail != undefined &&
                                                        <div>
                                                            <Row style={{ marginLeft: '25px' }}>
                                                                <Col lg="7" md="12" >
                                                                    <CardImg
                                                                        alt="Card image cap"
                                                                        src={vehicleDetail.color == 'Blue' ? profilebgBlue : profilebgBlack}
                                                                        className={classes.imageStyle}
                                                                    />
                                                                </Col>

                                                                <Col lg="5" md="12" >
                                                                    <div style={{ marginLeft: '15px' }} >
                                                                        <div style={{ marginLeft: '6px', marginRight: '10px' }}>
                                                                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                                <Grid item xs={12} md={12} lg={12} style={{ marginTop: '26px' }}>
                                                                                    <Typography className={classes.contentStyle}><span >Model</span></Typography>
                                                                                    <div style={{ marginLeft: '-13px', fontSize: '20px', marginTop: '-8px' }}>
                                                                                        <FormTextFieldSalesPlain value={vehicleDetail.model === '01' ? 'Lynk & Co 01' : ''} updateFontSize='true' disabled />
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>

                                                                        <div className={classes.vehicleInputWrapStyle} >
                                                                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                                <Grid item xs={12} md={12} lg={12} style={{ marginTop: '50px' }}>
                                                                                    <Typography className={classes.contentStyle}><span >Color</span></Typography>
                                                                                    <div style={{ marginLeft: '-13px', marginTop: '-8px' }}>
                                                                                        <FormTextFieldSalesPlain value={vehicleDetail.color === 'Blue' ? 'Blue' : 'Black'} updateFontSize='true' disabled />
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                        <div className={classes.vehicleInputWrapStyle} >
                                                                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                                <Grid item xs={12} md={12} lg={12} style={{ marginTop: '50px' }}>
                                                                                    <Typography className={classes.contentStyle}><span >Engine Type</span></Typography>
                                                                                    <div style={{ marginLeft: '-13px', marginTop: '-8px' }}>
                                                                                        <FormTextFieldSalesPlain value={vehicleDetail.engineType} updateFontSize='true' disabled />
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>

                                                                        <div className={classes.vehicleInputWrapStyle} >
                                                                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                                <Grid item xs={12} md={12} lg={12} style={{ marginTop: '50px' }}>
                                                                                    <Typography className={classes.contentStyle}>VIN</Typography>
                                                                                    <div style={{ marginLeft: '-13px', marginTop: '-8px' }}>
                                                                                        <FormTextFieldSalesPlain value={vehicleDetail.vin} updateFontSize='true' disabled />
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                        <div className={classes.vehicleInputWrapStyle} >
                                                                            <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                                <Grid item xs={12} md={12} lg={12} style={{ marginTop: '50px' }}>
                                                                                    <Typography className={classes.contentStyle}><span >License Plate</span></Typography>
                                                                                    <div style={{ marginLeft: '-13px', marginTop: '-8px' }}>
                                                                                        <FormTextFieldSalesPlain value={vehicleDetail.licensePlate} updateFontSize='true' disabled />
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                    }
                                                </>}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row style={{ float: 'right', paddingRight: '20px', marginTop: '-30px' }}>
                                    <tr>
                                        <td >
                                            {displaySelectvehicle && searchInputVIN && vehicleDetail !== undefined && !displayRole &&

                                                <SearchButton
                                                    placeholder={'Confirm Vehicle'}
                                                    handleSearch={() => handleConfirmVehicle()}
                                                />
                                            }
                                        </td>
                                    </tr>
                                </Row>
                            </CardBody>
                        </Card>
                    }
                </Col>

                <Col lg="4" md="12" >
                    {displayRole &&
                        <Card id="CardBodyCardAllowance7" className={classes.cardStyle}  >
                            <CardBody>
                                <Row>
                                    <Col lg="12" md="12" >
                                        <br />
                                        <span>
                                            <span style={{ fontSize: "16px", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF', marginTop: '20px', marginLeft: '25px' }}> Select Role and Provisioning Package</span> <br /><br />
                                        </span><br />

                                        <table style={{ width: '100%', color: 'white' }}>
                                            <span>
                                                <span style={{ fontSize: "16px", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF', marginLeft: '25px' }}> Role</span> <br /><br />
                                            </span>
                                            <tr>
                                                <td style={{ float: 'left', fontFamily: 'LynkcoTypeRegular', color: '#BFBFBF', marginLeft: '25px' }}>
                                                    <div style={{ minWidth: '250px' }}>
                                                        <RoleSelect value={roleId.id} onChange={handleChangeRoleDropdown} />
                                                    </div>

                                                    <br /></td>
                                                <td style={{ float: 'right' }}>

                                                    {showRoleeChecked && <img style={{ height: '35px', width: '100%', }} src={icon} alt="Your SVG" />}
                                                </td>
                                            </tr> <br /> <br />

                                            <span>
                                                <span style={{ fontSize: "16px", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF', marginLeft: '25px' }}> Provisioning Package</span> <br /><br />
                                            </span>

                                            <tr>
                                                <td style={{ float: 'left', fontFamily: 'LynkcoTypeRegular', color: '#BFBFBF', marginLeft: '25px' }}>
                                                    <div style={{ minWidth: '250px' }}>
                                                        <PackageSelect value={packageID.id} onChange={handleChangePackageDropdown} />
                                                    </div>

                                                    <br /></td>
                                                <td style={{ float: 'right' }}>

                                                    {showPackageChecked && <img style={{ height: '35px', width: '100%' }} src={icon} alt="Your SVG" />}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{ float: 'right', marginTop: '320px' }}>
                                                    <SearchButton
                                                        placeholder={'Start Provisioning'}
                                                        handleSearch={handleProvisioning}
                                                    />
                                                </td>
                                            </tr>

                                        </table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    }
                </Col>
            </Row>
        </div>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(ProvisioningPage);