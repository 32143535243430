import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
// import TrendingDownIcon from 'mdi-react/TrendingDownIcon';
// import { mdiInformationOutline } from '@mdi/js';
// import Tooltip from '@material-ui/core/Tooltip';
// import Chart from "react-apexcharts";

const LandedCostInView = () => {
  const optionsdownload = {
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
      },
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
    colors: "rgba(255, 255, 255, 0.5)",
    chart: {
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: "dark",
    },
  };
  // const seriesdownload = [
  //   {
  //     name: "",
  //     data: [4, 5, 2, 4, 9, 4, 9],
  //   },
  // ];
  /*--------------------------------------------------------------------------------*/
  /* Used In Remarketing Dashboard                                                  */
  /*--------------------------------------------------------------------------------*/
  return (
    <Card
      className="bg-brightpurple"
      style={{
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        boxShadow: '0 5px 6px #000000',
      }}
    >
      <CardBody>
        <div className="d-flex">
          <div className="mr-3 align-self-center">
            <h1 className="text-white">
              {/* <i className="icon-cloud-download" /> */}
            </h1>
          </div>
          <div>
            <CardTitle style={{ fontSize: "1.2vw", fontWeight: 600, textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '-10px' }} className="text-darky">Average Days in Inventory for Used Cars</CardTitle>
            <CardSubtitle style={{ fontSize: "0.85vw", marginLeft: '-8px' }} className="text-darky op-6">Used Car stock holding period until sold</CardSubtitle>
          </div>
        </div>
        <div className="d-flex align-items-center mt-4">
          <div className="mr-auto">
            <h2 className="text-darky" style={{ marginLeft: '1px', fontSize: "4.2vw" }}>87</h2>
          </div>

          {/* <Chart
              options={optionsdownload}
              series={seriesdownload}
              type="bar"
              height="75px"
              width="65%"
            /> */}
          <div style={{ fontSize: '30px', color: '#000', cursor: 'pointer', marginTop: '-241px', marginLeft: '-20px' }} >
            <span>
              <i className="mdi mdi-filter-variant" />
            </span>
          </div>
          {/* <div className="text-darky" >
                <TrendingDownIcon className="dashboard__trend-icon" style={{ marginTop: '0.4vw' }} size={55} />
          </div> */}
        </div>
      </CardBody>
    </Card>
  );
};

export default LandedCostInView;