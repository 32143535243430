import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Row, Col, CardBody, CardImg } from "reactstrap";
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds } from "src/redux/user/user.selectors";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import { GetFleets } from "src/services/fleet-http.service";
import { QueryByVIN } from "src/services/vehicle-pages-http.service";
import { GetFleetTypes } from "src/services/fleetType-http.service";
import profilebgBlue from "src/assets/img/BackgroundImages/ProvisioningPicture_Blue.png";
import profilebgBlack from "src/assets/img/BackgroundImages/ProvisioningPicture_Black.png";
import { QueryVehicleHealth } from 'src/services/vehicle-pages-http.service';
import { formatAndVerifyNumericValue } from 'src/utils/RvTableExcelFormatter';
import { QueryConnectivityInformation } from 'src/services/connectivity-http.service';
import Tooltip from '@material-ui/core/Tooltip';
import AwesomeSlider from 'react-awesome-slider';
import blueslider1 from 'src/assets/img/vehicles/blueslider1.png';
import blueslider2 from 'src/assets/img/vehicles/blueslider2.png';
import blueslider3 from 'src/assets/img/vehicles/blueslider3.png';
import blueslider4 from 'src/assets/img/vehicles/blueslider4.png';
import blueslider5 from 'src/assets/img/vehicles/blueslider5.png';
import blackslider6 from 'src/assets/img/vehicles/blackslider6.png';
import blackslider1 from 'src/assets/img/vehicles/blackslider1.png';
import blackslider2 from 'src/assets/img/vehicles/blackslider2.png';
import blackslider3 from 'src/assets/img/vehicles/blackslider3.png';
import blackslider4 from 'src/assets/img/vehicles/blackslider4.png';
import blackslider5 from 'src/assets/img/vehicles/blackslider5.png';
import VehicleInfo from 'src/assets/img/vehicles/VehicleInfo.png';
import VehicleInfo_Green from 'src/assets/img/vehicles/VehicleInfo_Green.png';
import VehicleRange from 'src/assets/img/vehicles/VehicleRange.png';
import VehicleRange_Green from 'src/assets/img/vehicles/VehicleRange_Green.png';
import VehicleHealth from 'src/assets/img/vehicles/VehicleHealth.png';
import VehicleHealth_Green from 'src/assets/img/vehicles/VehicleHealth_Green.png';
import VehicleLock from 'src/assets/img/vehicles/VehicleLock.png';
import VehicleLock_Green from 'src/assets/img/vehicles/VehicleLock_Green.png';
import vehicleInfoButton from 'src/assets/img/vehicles/vehicleInfoButton.png';
import ButtonShadow from 'src/assets/img/vehicles/ButtonShadow.png';


import './styles.css';
// import 'react-awesome-slider/dist/styles.css';


const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(3.7),
        position: 'sticky',
        marginLeft: '11px',
        marginTop: '-22px'
    },
    drawer: {
        width: 250,
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px'
    },
    gridContainerStyle1: {
        width: '100%',
    },
    cardStyle: {
        height: '744px',
        borderRadius: '15px',
        marginRight: '-5px',
        marginLeft: '5px',
    },
    cardStyleLarge: {
        height: '605px',
        borderRadius: '15px',
        marginRight: '-22px'
    },
    imageStyle: {
        marginTop: '60px',
        marginLeft: '15px',
        maxHeight: '585px',
        minHeight: '500px',
        maxWidth: '34vh',
        minWidth: '23vh'
    },
    tooltipImg: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        marginTop: '2px',
    },
    tooltipImgButton: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        marginTop: '50px',
    },
    rowheight: {
        marginTop: '-210px',
        '@media (min-width: 1401px) and (max-width: 1600px)': {
            marginTop: '-180px',
          },
    },
    rowheightMiddle: {
        marginTop: '-235px',
        '@media (min-width: 1401px) and (max-width: 1600px)': {
            marginTop: '-200px',
          },
    }
}));

let vin = ''

const VehicleDetailsPage = (props) => {
    const { userOrg, userMarketIds, vin, value } = props
    const classes = UseStyles(props);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [searchInputVIN, setSearchInputVIN] = useState(false);
    const [displaySelectvehicle, setDisplaySelectedVehicle] = useState(false);
    const [searchVIN, setSearchVIN] = React.useState('')
    const [vehicleDetail, setVehicleDetail] = React.useState({});
    const [message, setMessage] = React.useState('');

    const [openVehicleInfoIconToggle, setOpenVehicleInfoIconToggle] = useState(false);
    const imgsVehicleInfo = [VehicleInfo, VehicleInfo_Green];
    const [vehicleImg, setVehicleImg] = useState(imgsVehicleInfo[0]);
    const [openVehicleInfoIcon, setVehicleInfoIcon] = useState(false);

    const [openVehicleRangeIconToggle, setOpenVehicleRangeIconToggle] = useState(false);
    const imgsVehicleRange = [VehicleRange, VehicleRange_Green];
    const [VehicleRangeImg, setVehicleRangeImg] = useState(imgsVehicleRange[0]);
    const [openVehicleRangeIcon, setVehicleRangeIcon] = useState(false);


    const [openVehicleHealthIconToggle, setOpenVehicleHealthIconToggle] = useState(false);
    const imgsVehicleHealth = [VehicleHealth, VehicleHealth_Green];
    const [VehicleHealthImg, setVehicleHealthImg] = useState(imgsVehicleHealth[0]);
    const [openVehicleHealthIcon, setVehicleHealthIcon] = useState(false);

    const [openVehicleLockIconToggle, setOpenVehicleLockIconToggle] = useState(false);
    const imgsVehicleLock = [VehicleLock, VehicleLock_Green];
    const [VehicleLockImg, setVehicleLockImg] = useState(imgsVehicleLock[0]);
    const [openVehicleLockIcon, setVehicleLockIcon] = useState(false);
    const [displayVehicleInformation, setDisplayVehicleInformation] = useState(false);

    const [displayVehicleMaintenance, setDisplayVehicleMaintenance] = useState(false);

    const [displayVehicleRange, setDisplayVehicleRange] = useState(false);

    const [displayVehicleLock, setDisplayVehicleLock] = useState(false);


    const [vehicleHealth, setVehicleHealth] = React.useState({
        "colour": "",
        "distanceToService": {
            "isDistanceToServiceOk": null,
            "value": ""
        },
        "daysToService": {
            "isDaysToServiceOk": null,
            "value": ""
        },
        "serviceWarningStatus": {
            "isServiceWarningStatusOk": null,
            "value": null
        },
        "engineOilLevelStatus": {
            "isEngineOilLevelStatusOk": null,
            "value": null
        },
        "engineOilPressureStatus": {
            "isEngineOilPressureStatusOk": null,
            "value": null
        },
        "washerFluidLevelStatus": {
            "isWasherFluidLevelStatusOk": null,
            "value": null
        }
    });

    useEffect(() => {
        if (vin !== undefined && value == 1) {
            handleChangeVINorLIcensePlate(vin);
            handleVehicleHealth(vin);
            handleVehicleInformation();
        }
    }, [])

    const handleDialogOk = () => {
        setOpenDialog(false);
        setSearchInputVIN(false);
    }

    const handleChangeVINorLIcensePlate = (vin) => {
        QueryConnectivityInformation(vin)
            .then(data => {
                if (data.ok == true) {
                    setSearchInputVIN(true);
                    setVehicleDetail(data.vspConnectivity);
                    setDisplaySelectedVehicle(true);
                } else {
                    setSearchVIN();
                    // setOpenDialog(true);
                    setMessage(data.title);
                    setDialogMessage(data.errorMessage);
                }
            })

    };

    const handleVehicleHealth = (vin) => {
        if (value == 1) {
            let fleetIds = []
            let marketIds = userMarketIds;

            GetFleets(userOrg.id)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(item => {
                            fleetIds.push(item.id);
                        })

                        QueryVehicleHealth(vin, fleetIds, marketIds)
                            .then(data => {
                                if (data.ok == true) {
                                    setVehicleHealth(data.vehicleMaintenance);
                                }
                            })
                    }
                })
        }
    };

    const handleOpenVehicleInfoIcon = () => {
        setVehicleImg(imgsVehicleInfo[0]);
        setVehicleInfoIcon(false)
    };

    const handleCloseVehicleInfoIcon = () => {
        setVehicleImg(imgsVehicleInfo[1]);
        setVehicleInfoIcon(true)
    };

    const handleOpenVehicleRangeIcon = () => {
        setVehicleRangeImg(imgsVehicleRange[0]);
        setVehicleRangeIcon(false)
    };

    const handleCloseVehicleRangeIcon = () => {
        setVehicleRangeImg(imgsVehicleRange[1]);
        setVehicleRangeIcon(true)
    };

    const handleOpenVehicleHealthIcon = () => {
        setVehicleHealthImg(imgsVehicleHealth[0]);
        setVehicleHealthIcon(false)
    };

    const handleCloseVehiclehealthIcon = () => {
        setVehicleHealthImg(imgsVehicleHealth[1]);
        setVehicleHealthIcon(true)
    };

    const handleOpenVehicleHealthLock = () => {
        setVehicleLockImg(imgsVehicleLock[0]);
        setVehicleLockIcon(false)
    };

    const handleCloseVehiclehealthLock = () => {
        setVehicleLockImg(imgsVehicleLock[1]);
        setVehicleLockIcon(true)
    };

    const handleVehicleInformation = () => {
        setDisplayVehicleInformation(true);
        setVehicleImg(imgsVehicleInfo[1]);
        setVehicleInfoIcon(true);

        // setOpenHomeIcon(false)
        // setHomeImg(imgsHome[0]);

        setOpenVehicleInfoIconToggle(true);
        setVehicleRangeIcon(false);
        setVehicleHealthIcon(false);
        setVehicleLockIcon(false)
        setOpenVehicleRangeIconToggle(false);
        setOpenVehicleHealthIconToggle(false);
        setOpenVehicleLockIconToggle(false);
        // setOpenHomeIconToggle(false);
        setVehicleRangeImg(imgsVehicleRange[0]);
        setVehicleHealthImg(imgsVehicleHealth[0]);
        setVehicleLockImg(imgsVehicleLock[0]);
        setDisplayVehicleRange(false);
        setDisplayVehicleLock(false);
        setDisplayVehicleMaintenance(false);
    };

    const handleVehicleRange = () => {
        setDisplayVehicleRange(true);
        setVehicleRangeImg(imgsVehicleRange[1]);
        setVehicleRangeIcon(true);
        setOpenVehicleRangeIconToggle(true);

        // setOpenHomeIcon(false)
        // setHomeImg(imgsHome[0]);

        setOpenVehicleHealthIconToggle(false);
        setOpenVehicleLockIconToggle(false);
        setOpenVehicleInfoIconToggle(false);

        setVehicleInfoIcon(false);
        setVehicleLockIcon(false);
        setVehicleHealthIcon(false);

        setDisplayVehicleMaintenance(false);
        setDisplayVehicleLock(false);
        setDisplayVehicleInformation(false);

        // setOpenHomeIconToggle(false);
        setVehicleImg(imgsVehicleInfo[0]);
        setVehicleLockImg(imgsVehicleLock[0]);
        setVehicleHealthImg(imgsVehicleHealth[0]);
    };

    const handleVehicleMaintenance = () => {
        setDisplayVehicleMaintenance(true);
        setVehicleHealthImg(imgsVehicleHealth[1]);
        setVehicleHealthIcon(true);
        setOpenVehicleHealthIconToggle(true);

        // setOpenHomeIcon(false)
        // setHomeImg(imgsHome[0]);

        setOpenVehicleRangeIconToggle(false);
        setOpenVehicleLockIconToggle(false);
        setOpenVehicleInfoIconToggle(false);

        setVehicleInfoIcon(false);
        setVehicleRangeIcon(false);
        setVehicleLockIcon(false);

        setDisplayVehicleRange(false);
        setDisplayVehicleLock(false);
        setDisplayVehicleInformation(false);
        // setOpenHomeIconToggle(false);

        setVehicleImg(imgsVehicleInfo[0]);
        setVehicleRangeImg(imgsVehicleRange[0]);
        setVehicleLockImg(imgsVehicleLock[0]);
    };

    const handleVehicleLock = () => {
        setDisplayVehicleLock(true);
        setVehicleLockImg(imgsVehicleLock[1]);
        setVehicleLockIcon(true);
        setOpenVehicleLockIconToggle(true);

        // setOpenHomeIcon(false)
        // setHomeImg(imgsHome[0]);

        setOpenVehicleRangeIconToggle(false);
        setOpenVehicleHealthIconToggle(false);
        setOpenVehicleInfoIconToggle(false);

        setVehicleRangeIcon(false);
        setVehicleInfoIcon(false);
        setVehicleHealthIcon(false);

        setDisplayVehicleRange(false);
        setDisplayVehicleMaintenance(false);
        setDisplayVehicleInformation(false);
        // setOpenHomeIconToggle(false);
        setVehicleImg(imgsVehicleInfo[0]);
        setVehicleRangeImg(imgsVehicleRange[0]);
        setVehicleHealthImg(imgsVehicleHealth[0]);
    }

    return (
        <Row >
            <VehiclePageDialog
                dialogue='true'
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
                title={message}
            />

            {/* <Row> */}
            <Col md={12} sm={12} xs={12} lg={12}>
                <Card id="CardBodyCardCarousel" className={classes.cardStyle} >
                    <Row style={{ marginRight: '-12px', marginLeft: '-24px' }}>
                        <Col md={12} sm={12} xs={12} lg={12}>
                            <table style={{ width: '100%', color: 'white', marginTop: '-130px', paddingTop: '10px' }}>
                                <tr>
                                    <td id="Tabletitles">
                                        {
                                            vehicleDetail?.vehicleDetails?.color == 'Blue' ?
                                                <AwesomeSlider bullets={false} >
                                                    <div style={{ transform: 'scale(0.40)', paddingTop: '10px' }} data-src={blueslider1} />
                                                    <div style={{ transform: 'scale(0.65)' }} data-src={blueslider2} />
                                                    <div style={{ transform: 'scale(0.65)' }} data-src={blueslider3} />
                                                    <div style={{ transform: 'scale(0.65)' }} data-src={blueslider4} />
                                                    <div style={{ transform: 'scale(0.40)', paddingTop: '10px' }} data-src={blueslider5} />
                                                </AwesomeSlider>
                                                :
                                                <AwesomeSlider bullets={false}>
                                                    <div style={{ transform: 'scale(0.40)', paddingTop: '10px' }} data-src={blackslider1} />
                                                    <div style={{ transform: 'scale(0.65)' }} data-src={blackslider2} />
                                                    <div style={{ transform: 'scale(0.65)' }} data-src={blackslider4} />
                                                    <div style={{ transform: 'scale(0.65)' }} data-src={blackslider6} />
                                                    <div style={{ transform: 'scale(0.40)', paddingTop: '10px' }} data-src={blackslider3} />
                                                </AwesomeSlider>
                                        }
                                    </td>
                                </tr>
                            </table>
                        </Col>

                    </Row>

                    <Row >
                        <table style={{ width: '100%', color: 'white', marginTop: '-220px', marginLeft: '75px', zIndex: 10000 }}>
                            <tr>
                                <td style={{ width: '20%' }}></td>
                                <td style={{ width: '15%' }}>
                                    <Tooltip
                                        className={classes.tooltipImg}
                                        title="Vehicle Information"
                                        onOpen={handleCloseVehicleInfoIcon}
                                        onClose={openVehicleInfoIconToggle ? null : handleOpenVehicleInfoIcon}
                                        // open={ openVehicleInfoIcon}
                                        onClick={() => handleVehicleInformation()}
                                    >
                                        <img
                                            className="icons"
                                            src={vehicleImg}
                                            alt="Icons Image"
                                        />
                                    </Tooltip>


                                </td>
                                <td style={{ width: '15%' }}>
                                    <Tooltip
                                        className={classes.tooltipImg}
                                        title="Range"
                                        onOpen={handleCloseVehicleRangeIcon}
                                        onClose={openVehicleRangeIconToggle ? null : handleOpenVehicleRangeIcon}
                                        // open={ openVehicleInfoIcon}
                                        onClick={() => handleVehicleRange()}
                                    >
                                        <img
                                            className="icons"
                                            src={VehicleRangeImg}
                                            alt="Icons Image"
                                        // onClick={(e) => {
                                        //     e.preventDefault();
                                        //     onClick(e);
                                        // }}
                                        // ref={ref}
                                        />
                                    </Tooltip>
                                </td>
                                <td style={{ width: '15%' }}>

                                    <Tooltip
                                        className={classes.tooltipImg}
                                        title="Maintenance"
                                        onOpen={handleCloseVehiclehealthIcon}
                                        onClose={openVehicleHealthIconToggle ? '' : handleOpenVehicleHealthIcon}
                                        // open={ openVehicleInfoIcon}
                                        onClick={() => handleVehicleMaintenance()}
                                    >
                                        <img
                                            className="icons"
                                            src={VehicleHealthImg}
                                            alt="Icons Image"
                                        // onClick={(e) => {
                                        //     e.preventDefault();
                                        //     onClick(e);
                                        // }}
                                        // ref={ref}
                                        />
                                    </Tooltip>

                                </td>
                                <td style={{ width: '15%' }}>

                                    <Tooltip
                                        className={classes.tooltipImg}
                                        title="Lock/Unlock"
                                        onOpen={handleCloseVehiclehealthLock}
                                        onClose={openVehicleLockIconToggle ? '' : handleOpenVehicleHealthLock}
                                        // open={ openVehicleInfoIcon}
                                        onClick={() => handleVehicleLock()}
                                    >
                                        <img
                                            className="icons"
                                            src={VehicleLockImg}
                                            alt="Icons Image"
                                        // onClick={(e) => {
                                        //     e.preventDefault();
                                        //     onClick(e);
                                        // }}
                                        // ref={ref}
                                        />
                                    </Tooltip>
                                </td>
                                <td style={{ width: '20%' }}></td>

                            </tr>

                        </table>
                    </Row>
                    {
                        displayVehicleInformation &&
                        <Row style={{ marginBottom: '8px', marginTop: '-30px', marginLeft: '15px', marginRight: '15px' }}>
                            <Col lg="4" md="4">
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '25px' }}>
                                    <img
                                        className="button"
                                        src={vehicleInfoButton}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                    <img
                                        className="buttonShadow"
                                        src={ButtonShadow}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>

                                <div className={classes.rowheight}>
                                    <span style={{ fontFamily: 'LynkcoTypeLight', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '5px' }} >ENGINE TYPE</span>
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex' }} >{vehicleDetail?.vehicleDetails?.engineType}</span> <br /><br />
                                </div>

                                <div style={{ marginTop: '-10px', }}>
                                    <span style={{ fontFamily: 'LynkcoTypeLight', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '5px' }} >COLOR</span>
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase' }} >{vehicleDetail?.vehicleDetails?.color}</span> <br /><br />
                                </div>

                            </Col>
                            <Col lg="4" md="4">
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '25px' }}>
                                    <img
                                        className="buttonMedium"
                                        src={vehicleInfoButton}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                    <img
                                        className="buttonShadow"
                                        src={ButtonShadow}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <div className={classes.rowheightMiddle}>
                                    <span style={{ fontFamily: 'LynkcoTypeLight', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '5px' }} >License Plate</span>
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex' }} >{vehicleDetail?.vehicleDetails?.licensePlate}</span> <br /><br />
                                </div>

                                <div style={{ marginTop: '-10px', }}>
                                    <span style={{ fontFamily: 'LynkcoTypeLight', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '5px' }} >VIN</span>
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase' }} >{vin}</span> <br /><br />
                                </div>
                            </Col>
                            <Col lg="4" md="4">
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '25px' }}>
                                    <img
                                        className="button"
                                        src={vehicleInfoButton}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                    <img
                                        className="buttonShadow"
                                        src={ButtonShadow}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <div className={classes.rowheight}>
                                    <span style={{ fontFamily: 'LynkcoTypeLight', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase', marginBottom: '5px' }} >Tow Bar</span>
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase' }} >{vehicleDetail?.vehicleDetails?.towbarState}</span> <br /><br />
                                </div>

                                <div style={{ marginTop: '-10px', }}>
                                    <span style={{ fontFamily: 'LynkcoTypeLight', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase', marginBottom: '5px' }} >Market</span>
                                    <div style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase' }} >{vehicleDetail?.vehicleDetails?.market}</div> <br /><br />
                                </div>
                            </Col>
                        </Row>
                    }
                    {
                        displayVehicleRange &&
                        <Row style={{ marginBottom: '8px', marginTop: '-30px', marginLeft: '15px', marginRight: '15px' }}>
                            <Col lg="4" md="4">
                            </Col>
                            <Col lg="4" md="4">
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '25px' }}>
                                    <img
                                        className="buttonMedium"
                                        src={vehicleInfoButton}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                    <img
                                        className="buttonShadow"
                                        src={ButtonShadow}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <div className={classes.rowheightMiddle}>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '5px', textTransform: 'uppercase', color: 'darkgrey' }} >Range</span>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '55px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '5px', color: 'darkgrey' }} >{'356'}</span>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase', color: 'darkgrey' }} >km left</span>
                                </div>

                            </Col>
                            <Col lg="4" md="4">
                            </Col>
                        </Row>
                    }

                    {
                        displayVehicleMaintenance &&
                        <Row style={{ marginBottom: '8px', marginTop: '-30px', marginLeft: '15px', marginRight: '15px' }}>
                            <Col lg="4" md="4">
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '25px' }}>
                                    <img
                                        className="button"
                                        src={vehicleInfoButton}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                    <img
                                        className="buttonShadow"
                                        src={ButtonShadow}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>

                                <div className={classes.rowheight}>
                                    <span style={{ fontFamily: 'LynkcoTypeLight', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '5px' }} >ENGINE TYPE111</span>
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex' }} >{vehicleDetail?.vehicleDetails?.engineType}</span> <br /><br />
                                </div>

                                <div style={{ marginTop: '-10px', }}>
                                    <span style={{ fontFamily: 'LynkcoTypeLight', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '5px' }} >COLOR</span>
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase' }} >{vehicleDetail?.vehicleDetails?.color}</span> <br /><br />
                                </div>

                            </Col>
                            <Col lg="4" md="4">
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '25px' }}>
                                    <img
                                        className="buttonMedium"
                                        src={vehicleInfoButton}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                    <img
                                        className="buttonShadow"
                                        src={ButtonShadow}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <div className={classes.rowheightMiddle}>
                                    <span style={{ fontFamily: 'LynkcoTypeLight', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '5px' }} >License Plate</span>
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex' }} >{vehicleDetail?.vehicleDetails?.licensePlate}</span> <br /><br />
                                </div>

                                <div style={{ marginTop: '-10px', }}>
                                    <span style={{ fontFamily: 'LynkcoTypeLight', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '5px' }} >VIN</span>
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase' }} >{vin}</span> <br /><br />
                                </div>
                            </Col>
                            <Col lg="4" md="4">
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '25px' }}>
                                    <img
                                        className="button"
                                        src={vehicleInfoButton}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                    <img
                                        className="buttonShadow"
                                        src={ButtonShadow}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <div className={classes.rowheight}>
                                    <span style={{ fontFamily: 'LynkcoTypeLight', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase', marginBottom: '5px' }} >Tow Bar</span>
                                    <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase' }} >{vehicleDetail?.vehicleDetails?.towbarState}</span> <br /><br />
                                </div>

                                <div style={{ marginTop: '-10px', }}>
                                    <span style={{ fontFamily: 'LynkcoTypeLight', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase', marginBottom: '5px' }} >Market</span>
                                    <div style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase' }} >{vehicleDetail?.vehicleDetails?.market}</div> <br /><br />
                                </div>
                            </Col>
                        </Row>
                    }

                    {
                        displayVehicleLock &&
                        <Row style={{ marginBottom: '8px', marginTop: '-30px', marginLeft: '15px', marginRight: '15px' }}>
                            <Col lg="4" md="4">
                            </Col>
                            <Col lg="4" md="4">
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '25px' }}>
                                    <img
                                        className="buttonMedium"
                                        src={vehicleInfoButton}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                    <img
                                        className="buttonShadow"
                                        src={ButtonShadow}
                                        alt="Three Dot Image"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     onClick(e);
                                    // }}
                                    // ref={ref}
                                    />
                                </span>
                                <div className={classes.rowheightMiddle}>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '5px', textTransform: 'uppercase', color: 'darkgrey' }} >Lock</span>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '55px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '5px', color: 'darkgrey' }} >{'356'}</span>
                                    <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '16px', alignItems: 'center', justifyContent: 'center', display: 'flex', textTransform: 'uppercase', color: 'darkgrey' }} >km left</span>
                                </div>

                            </Col>
                            <Col lg="4" md="4">
                            </Col>
                        </Row>
                    }

                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(VehicleDetailsPage);