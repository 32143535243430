import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
// import BasicCarousel from './components/BasicCarousel';
// import BasicCarouselWithoutCaption from './components/BasicCarouselWithoutCaption';
import BasicCarouselWithCaption from './components/BasicCarouselWithCaption';

const Carousel = ({ modelData }) => {
  // const { t } = useTranslation('common');

  return (
      
      <>
        {/* <BasicCarousel />
        <BasicCarouselWithoutCaption /> */}
        <BasicCarouselWithCaption modelData={modelData} />
      </>
  );
};

export default Carousel;
