export const UnTransformTodoData = (todos) => {
  let newData = [];

  if (Array.isArray(todos)) {

    for (let i = 0; i < todos.length; i++) {
      delete todos[i]['id']
      newData.push({ ...todos[i] })
    }
    return newData;
  }
  else {
    delete todos['id']
    return todos
  }
}

export const TransformTodoData = (todoData) => {
  let newData = [];

  for (let i = 0; i < todoData.length; i++) {
    newData.push({ id: i + 1, ...todoData[i] })
  }
  return newData;
}


export const AddTodoData = (title, desc, todos) => {
  let data = UnTransformTodoData(todos);

  data.push({
    "desc": desc,
    "title": title,
    "deleted": false,
    "starred": false,
    "isCompleted": false
  })
  return data;
}


export const DeleteSelectedTodo = (selectedTodoItem, todos, todoId) => {
  let data = {}
  let index = todos.findIndex(x => x.id == todoId)

  if (typeof (selectedTodoItem) == "undefined" || typeof (selectedTodoItem) != "undefined" && selectedTodoItem.id != todoId) {
    let selectedTodoItemArray = todos.filter(x => x.id == todoId)

    if (Array.isArray(selectedTodoItemArray)) {
      selectedTodoItem = selectedTodoItemArray[0]
    }
  }
  if (typeof (selectedTodoItem) != "undefined") {
    let data = UnTransformTodoData(todos);
    var selectedItem = UnTransformTodoData(selectedTodoItem);
    selectedItem.deleted = !selectedItem.deleted
    data[index] = selectedItem
    return data;
  }
  else {
    data = UnTransformTodoData(todos);
    return data
  }
}

export const StarSelectedTodo = (selectedTodoItem, todos, todoId) => {
  let data = {}
  let index = todos.findIndex(x => x.id == todoId)

  if (typeof (selectedTodoItem) == "undefined" || typeof (selectedTodoItem) != "undefined" && selectedTodoItem.id != todoId) {
    let selectedTodoItemArray = todos.filter(x => x.id == todoId)

    if (Array.isArray(selectedTodoItemArray)) {
      selectedTodoItem = selectedTodoItemArray[0]
    }
  }
  if (typeof (selectedTodoItem) != "undefined") {
    let data = UnTransformTodoData(todos);
    var selectedItem = UnTransformTodoData(selectedTodoItem);
    selectedItem.starred = !selectedItem.starred
    data[index] = selectedItem
    return data;
  }
  else {
    data = UnTransformTodoData(todos);
    return data
  }
}

export const CompleteSelectedTodo = (selectedTodoItem, todos, todoId) => {
  let data = {}
  let index = todos.findIndex(x => x.id == todoId)

  if (typeof (selectedTodoItem) == "undefined" || typeof (selectedTodoItem) != "undefined" && selectedTodoItem.id != todoId) {
    let selectedTodoItemArray = todos.filter(x => x.id == todoId)

    if (Array.isArray(selectedTodoItemArray)) {
      selectedTodoItem = selectedTodoItemArray[0]
    }
  }
  if (typeof (selectedTodoItem) != "undefined") {
    let data = UnTransformTodoData(todos);
    var selectedItem = UnTransformTodoData(selectedTodoItem);
    selectedItem.isCompleted = !selectedItem.isCompleted
    data[index] = selectedItem
    return data;
  }
  else {
    data = UnTransformTodoData(todos);
    return data
  }
}
