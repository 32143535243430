import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DefaultPage from 'src/pages/backgrounds/default-page';
import MapPage from 'src/pages/svt-page/MapPage';
import { Typography } from '@material-ui/core';
import SVTMainComponent from 'src/pages/svt-page/SVTMainComponent';
import DetailsMainPage from 'src/pages/svt-page/vehicleDetails/DetailsMainPage';
import * as AppSettings from 'src/AppSettings.json';

function TabPanel(props) {
  const { children, value, index, vehiclePosition, closeHandleDrawer, isExpanded, selectedVIN, setSelectedVIN, selectedVINMap, setSelectedVINMap, shouldRenderMap, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && value === 0 && (
        AppSettings.config["environment"] === "production" ? <DefaultPage backgroundtype='true' /> : <SVTMainComponent setSelectedVIN={setSelectedVIN} selectedVIN={selectedVIN} valueVIN={value} selectedVINMap={selectedVINMap} setSelectedVINMap={setSelectedVINMap} />
      )}
      {value === index && value === 1 && (
        AppSettings.config["environment"] === "production" ? <DefaultPage backgroundtype='true' /> : <DetailsMainPage setSelectedVIN={setSelectedVIN} selectedVIN={selectedVIN} valueVIN={value} selectedVINMap={selectedVINMap} setSelectedVINMap={setSelectedVINMap} />
      )}
      {value === index && value === 2 && (
        AppSettings.config["environment"] === "production" ? <DefaultPage backgroundtype='true' /> : <MapPage />
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
    margin: -theme.spacing(2.9),
    height: '55px',
    marginLeft: '115px'
  },
  panel: {
    marginLeft: '35px',
    position: "fixed",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    padding: "55px",
    backgroundImage: 'linear-gradient(60deg, rgba(13,13,13,1), rgba(37,37,37,1) 99%)'
  },
  tabBar: {
    marginRight: theme.spacing(-22),
    marginLeft: theme.spacing(22.5),
    textTransform: 'initial',
    fontSize: '15px',
  },
  appBar: {
    backgroundColor: "#131313",
    position: 'fixed',
    marginTop: '-100px',
    marginLeft: '-180px',
    zIndex: '20',
    height: '48px',
    boxShadow: '0 5px 6px #000000'
  }
}));

const StyledTabs = withStyles({

  indicator: {
    backgroundColor: '#1EF1C6',
    height: '3px',
    maxWidth: '110px',
    marginLeft: '25px'
  },
})(Tabs);

export default function SVTTab(props) {
  const { vehiclePosition, shouldRenderMap, closeHandleDrawer, isExpanded } = props
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [selectedVIN, setSelectedVIN] = React.useState('');
  const [selectedVINMap, setSelectedVINMap] = React.useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Typography style={{ textAlign: 'center', fontSize: '15px', marginTop: '15px', marginLeft: '35px', fontFamily: "LynkcoTypeRegular", cursor: 'pointer', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', letterSpacing: '2px' }} variant="h4" id="tableTitle" component="div">
          SVT
        </Typography>
        <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Track and Disable" {...a11yProps(0)} className={classes.tabBar} />
          <Tab label="Vehicle Details" {...a11yProps(1)} className={classes.tabBar} />
          <Tab label="Case View" {...a11yProps(2)} className={classes.tabBar} />
        </StyledTabs>
      </AppBar>
      <TabPanel
        vehiclePosition={vehiclePosition}
        shouldRenderMap={shouldRenderMap}
        closeHandleDrawer={closeHandleDrawer}
        value={value}
        index={0}
        className={classes.panel}
        isExpanded={isExpanded}
        setSelectedVIN={setSelectedVIN}
        selectedVIN={selectedVIN}
        setSelectedVINMap={setSelectedVINMap}
        selectedVINMap={selectedVINMap}
      >
        Track and Disable
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        className={classes.panel}
        setSelectedVIN={setSelectedVIN}
        selectedVIN={selectedVIN}
        setSelectedVINMap={setSelectedVINMap}
        selectedVINMap={selectedVINMap}
      >
        Vehicle Details
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.panel}>
        Case View
      </TabPanel>
    </div>
  );
};