import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ReactTableBase from 'src/components/tables/newtable/table/ReactTableBase';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import { GetUsers, CreateUser, UpdateUser, DeleteUser, SearchUser } from 'src/services/user-http.service';
import { useToolbarStyles } from 'src/assets/jss/components/tableToolbarStyle';
import { useTableStyles } from 'src/assets/jss/components/tableStyle';
import FormTextField from 'src/components/form-elements/form-text-field.component';
import FormEmailField from 'src/components/form-elements/form-email-field.component';
import OrganizationSelect from 'src/components/form-elements/organization-select.component';
import RoleSelect from 'src/components/form-elements/role-select.component';
import MarketMultiselect from 'src/components/form-elements/extendedSearch-market-multiselect.component';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import DoneButton from 'src/components/buttons/done-button.component';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TablePagination from '@material-ui/core/TablePagination';
import { permission } from 'src/auth/permissions';
import VehiclePageDialog from 'src/components/form-elements/vehiclePage-dialog.component';
import SearchBar from 'src/components/buttons/search-icon-button.component';

let rowsPerPage = 50;

let newPageVariable = 1;

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, handleClickOpen, handleClickDelete, handleClickEdit } = props;

    return (
        <Toolbar
            className={clsx(classes.root1, {
                [classes.highlight1]: numSelected > 0,
            })}
        >
            <Typography className={classes.title1} variant="h4" id="tableTitle" component="div">
            </Typography>
            <div className={classes.tooltipDiv}>
                <Tooltip className={classes.tooltipImg} title="Add" onClick={handleClickOpen}>
                    <IconButton aria-label="filter list">
                        <AddIcon style={{ width: '20px', height: '40px', marginBottom: '-1px' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip className={classes.tooltipImg1} title="Edit" onClick={handleClickEdit}>
                    <span >
                        <IconButton disabled={numSelected.trim().length == 0} aria-label="edit">
                            <EditIcon style={{ width: '20px', height: '40px', marginBottom: '-1px' }} />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip className={classes.tooltipImg1} title="Delete" onClick={handleClickDelete}>
                    <span >
                        <IconButton disabled={numSelected.trim().length == 0} aria-label="delete">
                            <DeleteIcon style={{ width: '20px', height: '40px', marginBottom: '-1px' }} />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.string.isRequired,
};

const UserTable = ({ userOrg, userPermissions }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [isSortable, setIsSortable] = useState(false);
    const [isDisabledDragAndDrop, setIsDisabledDragAndDrop] = useState(false);
    const [isDisabledEditable, setIsDisabledEditable] = useState(false);
    const [isDisabledResizable, setIsDisabledResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withPagination, setWithPaginationTable] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [selected, setSelected] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [userEmailInput, setUserEmailInput] = React.useState('')
    const classes = useTableStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [sliderUser, setSliderUser] = React.useState({
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        roleId: "",
        organization: "",
        organizationId: "",
        marketIds: [],
        editMode: false
    });

    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [searchInput, setSearchInput] = React.useState('');

    const columns = useMemo(
        () => [
            { Header: 'First Name', accessor: "firstName", disableGlobalFilter: true, },
            { Header: 'Last Name', accessor: "lastName", disableGlobalFilter: true, },
            { Header: 'Email', accessor: "email", disableGlobalFilter: true, },
            { Header: 'Role', accessor: "role", disableGlobalFilter: true, },
            { Header: 'Organization', accessor: "organization", disableGlobalFilter: true },
        ],
        [],
    );

    const [selectedRows, setSelectedRows] = useState({ "0": true, "9": true });

    const [rows, setUsers] = React.useState([]);

    React.useEffect(() => {
        initializeUsers();
    }, []);

    React.useEffect(() => {
        if (searchInput.length === 0) {
            initializeUsers();
        }
    }, [searchInput])

    const getUsers = (pageNumber, pageSize) => {
        let userArray = [];

        GetUsers({ pageNumber: pageNumber, pageSize: pageSize })
            .then(data => {
                if (data && Array.isArray(data.users)) {
                    setTotalPages(data.totalPages)
                    data.users.map(user => {
                        userArray.push(
                            {
                                firstName: user.firstName,
                                lastName: user.lastName,
                                email: user.email,
                                role: user.role.name,
                                organization: user.organization.name,
                                marketIds: user.marketIds,
                                roleId: user.role.id,
                                organizationId: user.organization.id,
                            }
                        );
                    });
                }
                setUsers(userArray);
            });
    }

    const initializeUsers = () => {
        getUsers(newPageVariable, rowsPerPage);
    };

    const handleSliderOpen = () => {
        setOpen(true);
        setSliderUser({
            ...sliderUser, firstName: "", lastName: "", email: "",
            roleId: "", organizationId: "", role: "", organization: "", market: "", editMode: false
        });
    };

    const handleClickDelete = () => {
        DeleteSelectedUser(selectedRow.email);
    };

    const handleClickEdit = () => {
        if (selectedRow !== null) {
            setOpen(true);
            setSliderUser({
                ...sliderUser, firstName: selectedRow.firstName,
                lastName: selectedRow.lastName, email: selectedRow.email,
                roleId: selectedRow.roleId, organizationId: selectedRow.organizationId,
                role: selectedRow.role, organization: selectedRow.organization, market: selectedRow.market, marketIds: selectedRow.marketIds,
                editMode: true
            });
        }
    };

    const DeleteSelectedUser = (email) => {
        DeleteUser({
            "email": email
        })
            .then(data => {
                initializeUsers();
                setSelected('');
            })
    };

    const saveSliderUser = () => {
        sliderUser.editMode ?
            UpdateUser({
                "firstName": sliderUser.firstName,
                "lastName": sliderUser.lastName,
                "email": sliderUser.email,
                "roleId": sliderUser.roleId,
                "organizationId": sliderUser.organizationId,
                "marketIds": sliderUser.marketIds
            })
                .then(data => {
                    if (data.ok == false) {
                        setOpenDialog(true)
                        setDialogMessage(data.errorMessage)
                    }
                    initializeUsers();
                    setSelected('');
                })
            :
            CreateUser({
                "firstName": sliderUser.firstName,
                "lastName": sliderUser.lastName,
                "email": sliderUser.email,
                "roleId": sliderUser.roleId,
                "organizationId": sliderUser.organizationId,
                "marketIds": sliderUser.marketIds
            })
                .then(data => {
                    initializeUsers();
                    setSelected('');
                });
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleDone = () => {
        setOpen(false);
        saveSliderUser();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSliderUser({ ...sliderUser, [name]: value });
    };

    const handleClick = (event, name, row) => {
        let newSelected = selected;

        if (name !== selected) {
            newSelected = name;
        }
        setSelected(newSelected);
        setSelectedRow({
            firstName: row.firstName,
            lastName: row.lastName,
            email: row.email,
            role: row.role,
            roleId: row.roleId,
            organization: row.organization,
            organizationId: row.organizationId
        });
    };

    const handleChangePage = (event, newPage) => {
        newPageVariable = newPage + 1
        setPage(newPage);
        getUsers(newPageVariable, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        newPageVariable = 1
        rowsPerPage = parseInt(event.target.value, 10);
        getUsers(newPageVariable, rowsPerPage);
    };

    const selectedRowValues = (row) => {
        setSelectedRow({
            firstName: row.original.firstName,
            lastName: row.original.lastName,
            email: row.original.email,
            role: row.original.role,
            roleId: row.original.roleId,
            organization: row.original.organization,
            organizationId: row.original.organizationId,
            marketIds: row.original.marketIds
        });
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by first name...',
    };

    //................. Search.....................//

    const handleSearchInput = (event) => {
        setSearchInput(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            setSearchInput('');
        }
    };

    const handleSearch = () => {
        if (searchInput) {
            let userArray = [];

            SearchUser(searchInput.trim())
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(user => {
                            userArray.push(
                                {
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    email: user.email,
                                    role: user.role.name,
                                    organization: user.organization.name,
                                    marketIds: user.marketIds,
                                    roleId: user.role.id,
                                    organizationId: user.organization.id,
                                }
                            );
                        })
                    }
                    setUsers(userArray);
                });
        }
    }

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;
    const anchor = "right";
    const slider = (anchor) => (
        <div
            className={clsx(classes.slider, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation">
            <Card classes={{ root: classes.cardStyle }} style={{ backgroundColor: 'rgb(19 19 19 / 90%)' }} >
                <CardHeader title={sliderUser.editMode ? "Edit Existing User" : "Add New User"} />
                <Divider />
                <CardContent>
                    <FormTextField name="firstName" labelText="First Name" value={sliderUser.firstName} onChange={handleChange}></FormTextField>
                    <FormTextField name="lastName" labelText="Last Name" value={sliderUser.lastName} onChange={handleChange}></FormTextField>
                    <FormEmailField name="email" labelText="Email" value={sliderUser.email} onChange={handleChange}></FormEmailField>
                    <Divider className={classes.divider} />
                    <RoleSelect value={sliderUser.roleId} onChange={handleChange}></RoleSelect>
                    <OrganizationSelect value={sliderUser.organizationId} onChange={handleChange}></OrganizationSelect>
                    <MarketMultiselect email={sliderUser.email} name="market" orgId={userOrg.id} onChange={handleChange} editMode={sliderUser.editMode} labelText="Market"></MarketMultiselect>
                </CardContent>
                <CardActions classes={{ root: classes.cardActionStyle }} style={{ marginBottom: '-170px' }}  >
                    <Button variant="contained" onClick={handleClose} color="default">
                        Cancel
                    </Button>
                    <DoneButton handleDone={handleDone} placeholder='Done'></DoneButton>
                </CardActions>
            </Card>
        </div>
    );

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    const matchesPermission = userPermissions.includes(permission.Administration_Administrator)

    return (
        <Col md={12} lg={12}>
            <VehiclePageDialog
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
            />
            <Card id="CardBodyCardAllowance3" style={{ marginRight: '10px', marginTop: '75px', borderRadius: '4px' }} >
                <CardBody >
                    {matchesPermission ? (
                        <>
                            <span>
                                <span style={{ fontSize: '18px', float: 'left', color: 'darkgray' }}>
                                    <div style={{ width: '498%', marginLeft: '-10px', }}>
                                        <SearchBar
                                            placeholder={'User email'}
                                            handleSearch={handleSearch}
                                            handleSearchInput={handleSearchInput}
                                            onKeyPress={textFieldKeypress}
                                            value={searchInput}
                                            priceBucketActiveTab='true'
                                            autoFocus='true'
                                        />
                                    </div>
                                </span>
                            </span>
                            <EnhancedTableToolbar numSelected={selected}
                                handleClickOpen={handleSliderOpen}
                                handleClickDelete={handleClickDelete}
                                handleClickEdit={handleClickEdit}
                            />
                            <div>
                            
                                <ReactTableBase
                                    key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                    columns={columns}
                                    data={rows}
                                    tableConfig={tableConfig}
                                    onClick={(event) => handleClick(event, rows.email, rows)}
                                    onRowSelected={handleClick}
                                    isSelected={isSelected}
                                    selectedRows={selectedRows}
                                    onSelectedRowsChange={setSelectedRows}
                                    selectedRowValues={selectedRowValues}
                                />
                            </div>

                        </>) : (
                        <>
                            <div style={{ marginTop: '29px' }}>
                            </div>
                            <span>
                                <span style={{ fontSize: '18px', float: 'left', color: 'darkgray' }}>
                                    <div style={{ width: '498%', marginLeft: '-10px', }}>
                                        <SearchBar
                                            placeholder={'User email'}
                                            handleSearch={handleSearch}
                                            handleSearchInput={handleSearchInput}
                                            onKeyPress={textFieldKeypress}
                                            value={searchInput}
                                            priceBucketActiveTab='true'
                                            autoFocus='true'
                                        />
                                    </div>
                                </span>
                            </span>
                            <ReactTableBase
                                key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                columns={columns}
                                data={rows}
                                tableConfig={tableConfig}
                                onClick={(event) => handleClick(event, rows.email, rows)}
                                onRowSelected={handleClick}
                                isSelected={isSelected}
                                selectedRows={selectedRows}
                                onSelectedRowsChange={setSelectedRows}
                                selectedRowValues={selectedRowValues}
                            />
                        </>)}
                    <TablePagination
                        style={{ marginTop: '42px', marginBottom: '-5px' }}
                        classes={{
                            toolbar: classes.paginationToolbar,
                            caption: classes.paginationCaption
                        }}
                        SelectProps={{
                            MenuProps: {
                                classes: { paper: classes.selectStyle },
                                disableScrollLock: true,
                            }
                        }}
                        rowsPerPageOptions={[50, 100, 150, 200]}
                        component="div"
                        count={totalPages * rowsPerPage}
                        rowsPerPage={rowsPerPage}
                        page={newPageVariable - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={<span>Rows per page:</span>} labelDisplayedRows={({ page }) => { return `Page: ${newPageVariable} of ${totalPages}`; }}
                    />
                    <SwipeableDrawer
                        classes={{
                            paper: classes.SwipeableDrawer,
                        }}
                        anchor={anchor}
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                    >
                        {slider(anchor)}
                    </SwipeableDrawer>
                </CardBody>
            </Card>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state),
    userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(UserTable);