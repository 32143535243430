import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import Chart from "react-apexcharts";

const RvToLcDifference = () => {
  const optionsvisitors = {
    labels: [">2.5k€ above LC", "up to 2.5k€ above LC ", "RV below LC"],
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
      borderColor: "transparent",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "82px",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "18px",
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: true,
              color: "#99abb4",
            },
            total: {
              show: true,
              label: "Total",
              color: "#99abb4",
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: "transparent",
    },
    legend: {
      show: false,
    },
    colors: ["#373737", "#fd7e13", "#dc1860",],
    tooltip: {
      fillSeriesColor: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
        },
      },
    ],
  };
  const seriesvisitors = [6748, 1560, 123];
  return (
    /*--------------------------------------------------------------------------------*/
    /* Used In Dashboard-1,2                                                          */
    /*--------------------------------------------------------------------------------*/
    <Card
      className="w-100"
      style={{
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        boxShadow: '0 5px 6px #000000',
      }}
    >      <CardBody>
        <CardTitle style={{ marginTop: '-4px', fontSize: "1.2vw", fontWeight: 600 }}>RV to LC difference</CardTitle>
        <CardSubtitle className="mb-3" style={{ fontSize: "0.85vw", marginTop: '-11px' }}>
          Residual value in relation to landed cost
        </CardSubtitle>
        <Chart
          options={optionsvisitors}
          series={seriesvisitors}
          type="donut"
          height="255px"
        />
      </CardBody>
      <div>
        <hr className="mt-0 mb-0" />
      </div>
      <CardBody>
        <div className="d-flex no-block align-items-center justify-content-center">
          <div>
            {/* <h6 className="text-blue">
              <i className="fa fa-circle font-10 mr-2" />
              Proposed
            </h6> */}
          </div>
          {/* <div className="ml-3">
            <h6 className="text-white">
              <i className="fa fa-circle font-10 mr-2" />
              {'0 - 30 days'}
            </h6>
          </div> */}
          <div className="ml-3">
            <h6 style={{ color: '#808080' }}>
              <i className="fa fa-circle font-10 mr-2" />
              {'>2.5k€ above LC'}
            </h6>
          </div>
          <div className="ml-3">
            <h6 style={{ color: '#fd7e13' }}>
              <i className="fa fa-circle font-10 mr-2" />
              up to 2.5k€ above LC
            </h6>
          </div>
          <div className="ml-3">
            <h6 style={{ color: '#dc1860' }}>
              <i className="fa fa-circle font-10 mr-2" />
              RV below LC
            </h6>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default RvToLcDifference;