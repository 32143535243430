import React from 'react';
// import {  } from 'react-bootstrap';
import { Card, CardBody, Col, Table, Row, CardImg } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import profilebgBlue from "src/assets/img/BackgroundImages/ProvisioningPicture_Blue.png";
import profilebgBlack from "src/assets/img/BackgroundImages/ProvisioningPicture_Black.png";
import dots from 'src/assets/img/vehicles/rightArrow_black.png'
import SearchButton from "src/components/buttons/search-button-component";


// import blue from 'src/shared/img/blue.png';
// import black from 'src/shared/img/black.png';
import './dashboard.css';
// import check from 'src/shared/img/check.png';

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(3.7),
        position: 'sticky',
        marginLeft: '11px',
        marginTop: '-22px'
    },
    drawer: {
        width: 250,
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px'
    },
    gridContainerStyle1: {
        width: '100%',
    },
    cardStyle: {
        height: '703px',
        borderRadius: '10px',
        marginRight: '-5px',
        border: '2px solid #333333',
        // marginTop: '70px'
    },
    cardStyleLarge: {
        height: '665px',
        borderRadius: '10px',
        marginRight: '-22px'
    },
    imageStyle: {
        marginTop: '50px',
        marginLeft: '15px',
        maxHeight: '585px',
        minHeight: '500px',
        maxWidth: '32vh',
        minWidth: '23vh'
    },
}));

const DataPrimaryDriver = [
    {
        "firstName": "Alexander Scott",
        "lastName": "Crayvenn",
        "phoneNumber": "0046733456890",
        "email": "Alexander.crayvenn@gmail.com",
    },

]

const DataPrimaryDriver1 = [
    {
        "firstName": "Frank Jr",
        "lastName": "Smith",
        "phoneNumber": "0046708679041",
        "email": "frank.smith.jr@gmail.com",
    },
    {
        "firstName": "Emma Marie",
        "lastName": "Sjöstrand Jansson",
        "phoneNumber": "0046892781801",
        "email": "emma.marie.jansson@gmail.com",
    },
    {
        "firstName": "John Adams",
        "lastName": "Wilkinson",
        "phoneNumber": "0046729093718",
        "email": "john.adams@gmail.com",
    }
]

const DataPrimaryDriver2 = [
    {
        "firstName": "James Stephen",
        "lastName": "Winters",
        "phoneNumber": "0046739561681",
        "email": "john.stephen.winters@gmail.com",
    },

]

const ConfirmDetails = (props) => {
    const { handleOpenDetailsPage, handleReturnPage } = props
    const classes = UseStyles();

    return (


        <Col lg="9" md="9" >
            <div style={{ marginTop: '75px', borderRadius: '1px', position: 'relative', }}>

                <Card id="ConnectedDriverCard" className={classes.cardStyle} >
                    <CardBody>
                        <Row>
                            <Col lg="4" md="4" >
                                {/* <Card id="ConnectedDriverCard" className={classes.cardStyle} >
                                <CardBody> */}
                                <Row>
                                    <Col lg="12" md="12" >
                                        <Row style={{ marginRight: '-10px' }}>
                                            {/* {searchInputVIN && <> */}

                                            <Col md={12} sm={12} xs={12} lg={12}>
                                                <CardImg
                                                    alt="Card image cap"
                                                    src={profilebgBlack}
                                                    className={classes.imageStyle}
                                                />
                                            </Col>

                                            {/* <Col lg="5" md="12" >
                                                                <div style={{ marginLeft: '15px' }} >
                                                                    <div style={{ marginTop: '70px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }}  >License Plate</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }}  >{vehicleDetail.licensePlate}</span> <br /><br />
                                                                    </div>

                                                                    <div style={{ marginTop: '15px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }} >Model</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }} >{vehicleDetail.model === '01' ? 'Lynk & Co 01' : ''}</span> <br /><br />
                                                                    </div>

                                                                    <div style={{ marginTop: '15px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }} >Model Year</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }} >{vehicleDetail.modelYear}</span> <br /><br />
                                                                    </div>

                                                                    <div style={{ marginTop: '15px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }} >Engine Type</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }} >{vehicleDetail.engineType}</span> <br /><br />
                                                                    </div>

                                                                    <div style={{ marginTop: '15px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }} >Color</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }} >{vehicleDetail.color}</span> <br /><br />
                                                                    </div>

                                                                    <div style={{ marginTop: '15px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }} >Towbar</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }} >{vehicleDetail.hasTowbar}</span> <br /><br />
                                                                    </div>

                                                                    <div style={{ marginTop: '15px', marginLeft: '13px', }}>
                                                                        <span style={{ fontFamily: 'LynkcoTypeMedium', fontSize: '14px', }} >Registered</span><br />
                                                                        <span style={{ fontFamily: 'LynkcoTypeRegular', fontSize: '14px', }} >{FormmatDate(vehicleDetail.registeredDateUtc)}</span> <br /><br />
                                                                    </div>
                                                                </div>
                                                            </Col> */}

                                            {/* </>} */}
                                        </Row>
                                    </Col>
                                </Row>
                                {/* </CardBody>
                            </Card> */}
                            </Col>

                            <Col lg="8" md="8" >
                                {/* {displaySelectvehicle &&
                            <div>
                                <Card id="CardBodyCardAllowance3" className={classes.cardStyleLarge} >
                                    <CardBody>
                                        <ConnectivityTable searchVIN={searchVIN} />
                                    </CardBody>
                                </Card>
                            </div>
                        } */}

                                <Row style={{ marginLeft: '-80px' }}>
                                

                                    <Col md={12} sm={12} xs={12} lg={12}>
                                    <span style={{ float: 'left', marginLeft: '24px', fontFamily: 'LynkcoTypeMedium', fontSize: '19px', color: '#fff' }}>Step Two - Identify Driver</span>
                                        {/* <Card id="ConnectedDriverCard" >
                                        <CardBody> */}
                                        <Row style={{ marginLeft: '-8px', marginTop: '80px' }}>
                                            {/* <span style={{ float: 'left', marginLeft: '20px', fontFamily: 'LynkcoTypeMedium', fontSize: '15px', color: '#fff' }}>Step One - Identify Vehicle</span> */}
                                            <Col md={12} sm={12} xs={12} lg={12}>
                                                <div style={{ marginLeft: '18px', marginTop: '-30px', marginBottom: '15px', fontSize: '14px', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: '#fff' }}>Customer and Primary Driver</div>
                                                <Table id="admin">

                                                    <thead >
                                                        <tr >
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Phone Number</th>
                                                            <th>Email</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {DataPrimaryDriver.map((item) => (
                                                            <tr>
                                                                <td>{item.firstName}</td>
                                                                <td>{item.lastName}</td>
                                                                <td>{item.phoneNumber}</td>
                                                                <td>{item.email}</td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </Table>

                                            </Col>
                                        </Row>
                                        <hr style={{ backgroundColor: '#333333'  }} />

                                        <Row style={{ marginLeft: '-8px', marginTop: '70px' }}>
                                            {/* <div style={{ marginLeft: '18px', marginTop: '-30px', marginBottom: '15px', fontSize: '14px' }}>Digital Key of car is not Shared with anybody</div> */}

                                            <Col md={12} sm={12} xs={12} lg={12}>
                                                <div style={{ marginLeft: '18px', marginTop: '-30px', marginBottom: '15px', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: '#fff' }}>Secondary Drivers</div>
                                                <Table id="admin">

                                                    <thead >
                                                        <tr >
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Phone Number</th>
                                                            <th>Email</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {DataPrimaryDriver1.map((item) => (
                                                            <tr>
                                                                <td>{item.firstName}</td>
                                                                <td>{item.lastName}</td>
                                                                <td>{item.phoneNumber}</td>
                                                                <td>{item.email}</td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </Table>

                                            </Col>
                                        </Row>
                                        <hr style={{ backgroundColor: '#333333' }} />

                                        <Row style={{ marginLeft: '-8px', marginTop: '70px' }}>
                                            {/* <div style={{ marginLeft: '18px', marginTop: '-30px', marginBottom: '15px', fontSize: '14px' }}>Digital Key of car is not Shared with anybody</div> */}

                                            <Col md={12} sm={12} xs={12} lg={12}>
                                                <div style={{ marginLeft: '18px', marginTop: '-30px', marginBottom: '15px', fontSize: '14px', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: '#fff' }}>Car Sharing Borrower</div>
                                                <Table id="admin">

                                                    <thead >
                                                        <tr >
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Phone Number</th>
                                                            <th>Email</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {DataPrimaryDriver2.map((item) => (
                                                            <tr>
                                                                <td>{item.firstName}</td>
                                                                <td>{item.lastName}</td>
                                                                <td>{item.phoneNumber}</td>
                                                                <td>{item.email}</td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </Table>

                                            </Col>
                                        </Row>
                                        {/* </CardBody>
                                    </Card> */}
                                        <Row style={{ marginBottom: '20px', marginTop: '50px', marginLeft: '10px', marginRight: '1px' }}>
                                            <Col lg="6" md="6">
                                                <span style={{ float: 'left' }}>
                                                    <SearchButton
                                                        placeholder='Go Back'
                                                        // iconProp='true'
                                                        cancelProp='true'
                                                        //   icon={arrowLeft_red}
                                                        handleSearch={
                                                            // setDisplaySelectedVehicle(true);
                                                            // setSearchVIN('');
                                                            handleReturnPage

                                                        }
                                                    />
                                                </span>
                                            </Col>
                                            <Col lg="6" md="6">
                                                <span style={{ float: 'right', marginRight: '-10px' }}>
                                                    <SearchButton
                                                        placeholder={'Confirm'}
                                                        height='true'
                                                        widthProp='true'
                                                        icon={dots}
                                                        handleConfirmvehicleDetails={
                                                            // setDisplaySelectedVehicle(true);
                                                            // setSearchVIN('');
                                                            handleOpenDetailsPage

                                                        }

                                                    />
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {/* <VehiclePageDialog
                dialogue='true'
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
                title={message}
            />
            <Row>
                <div style={{ width: '80%', marginTop: '-10px' }}>
                    <SearchBarProvisioning
                        connectivityColor='true'
                        placeholder={'VIN or Registration Number'}
                        handleSearch={handleChangeVINorLIcensePlate}
                        handleSearchInput={handleSearchInputVIN}
                        onKeyPress={textFieldKeypressVIN}
                        value={searchVIN}
                    />
                </div>
            </Row> */}
                {/* {searchInputVIN && */}

                {/* } */}
            </div>
        </Col>



    );
};

export default ConfirmDetails;