import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Table } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';

const UseStyles = makeStyles((theme) => ({
    root: {
        overflow: 'auto',
        height: '610px',
        paddingRight: '5px',
        marginLeft: '5px',
        marginTop: '-40px',
        overflowX: 'hidden',
        "&::-webkit-scrollbar": {
            height: "3px",
            width: "8px",
            opacity: "0.5",
            backgroundColor: "#212121"
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            border: "none",
            backgroundColor: "rgba(255, 255, 255, 0.1)"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#1EF1C6"
        }
    },
    checkbox: {
        "&.Mui-checked": {
            color: "#1EF1C6"
        },
        "&.Mui-disabled": {
            color: "#BFBFBF"
        },
        color: "#BFBFBF"
    },
}));

const Services = ({ handleCheckbox, services }) => {
    const classes = UseStyles();

    return (
        <div>
            <div className={classes.root} >
                <Table id="admin">
                    <>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Service Name</th>
                                <th>Subscription Type</th>
                                <th>Service Scope</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                services.length > 0 && services.map(value => {
                                    return (
                                        <>
                                            <tr >
                                                <td>
                                                    <span>
                                                        <Checkbox
                                                            className={classes.checkbox}
                                                            onChange={handleCheckbox}
                                                            checked={value?.checked}
                                                            disabled={value?.disabled}
                                                            id={value?.id}
                                                        />
                                                    </span>
                                                </td>
                                                <td style={{ fontSize: '14px', fontFamily: "LynkcoTypeLight" }}><span>{value?.name}</span></td>
                                                <td style={{ fontSize: '14px', fontFamily: "LynkcoTypeLight" }}><span >{value?.subscriptionType}</span> </td>
                                                <td style={{ fontSize: '14px', fontFamily: "LynkcoTypeLight" }}><span >{value?.serviceScope}</span></td>

                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </>
                </Table>
            </div>
        </div>
    );
};

export default Services;