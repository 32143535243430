import React, { useState, useEffect } from 'react';
import 'src/pages/loading-page/SlideInAnimation.css';
import { Col, Row } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import bgImage from 'src/assets/img/BackgroundImages/newBackground.png'
import backgroundImage from "src/assets/img/BackgroundImages/NewBackgroundImage.jpg";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    panel: {
        backgroundImage: (props) => props.backgroundtype ? "url(" + backgroundImage + ")" : 'inear-gradient(97deg, rgba(10,10,10,1) 40%, rgba(37,37,37,1) 99%)',
        position: "fixed",
        height: "100%",
        width: "100%",
        display: "block",
        top: "0",
        left: "0",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
    },
    text: {
        position: 'absolute',
        width: '100%',
        margin: '30px',
        height: '100%',
        alignItems: 'center',
        backgroundImage: 'linear-gradient(97deg, rgba(10,10,10,1) 40%, rgba(37,37,37,1) 99%)',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '40px',
        textAlign: 'center',
        padding: '50px',
        fontFamily: 'LynkcoTypeRegular'
    }
}));

const DefaultPage = (props) => {
    const { backgroundtype } = props
    const classes = useStyles(props);
    const [displayMesage, setDisplaymesage] = useState(false);

    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);

    const [isVisible3, setIsVisible3] = useState(false);
    const [isVisible4, setIsVisible4] = useState(false);
    const [isVisible5, setIsVisible5] = useState(false);
    const [isVisible6, setIsVisible6] = useState(false);

    useEffect(() => {

        const timer1 = setTimeout(() => {
            setIsVisible(true);
            setDisplaymesage(false);

        }, 1000); // Adjust the delay as needed

        const timer2 = setTimeout(() => {
            setIsVisible2(true);
        }, 2000); // Adjust the delay as needed

        const timer3 = setTimeout(() => {
            setIsVisible(false);
            setIsVisible2(false);
        }, 4000); // Adjust the delay as needed

        const timer4 = setTimeout(() => {
            setIsVisible3(true);
            setIsVisible4(true);
        }, 4500);
        const timer5 = setTimeout(() => {
            setIsVisible4(true);
        }, 5500);
        const timer6 = setTimeout(() => {
            setIsVisible3(false);
            setIsVisible4(false);
            setIsVisible5(true);
        }, 6500);
        const timer7 = setTimeout(() => {
            
            setIsVisible6(true);
        }, 7000);

        const timer8 = setTimeout(() => {
            setIsVisible3(false);
            setIsVisible4(false);
            setIsVisible5(false);
            setIsVisible6(false);
            setDisplaymesage(true)
        }, 13000);


        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
            clearTimeout(timer4);
            clearTimeout(timer5);
            clearTimeout(timer6);
            clearTimeout(timer7);
            clearTimeout(timer8);
        };

    }, [displayMesage]);

    return (
        <div className={classes.panel}>
            {
                backgroundtype ?
                    <>
                        {bgImage !== undefined ? (
                            <div
                                backgroundtype={backgroundtype}
                                className={classes.panel}
                            >
                                <Row className="containerbg">
                                    <Col sm={12}>
                                        <div style={{ backgroundColor: 'transparent', marginTop: '-20px' }}>
                                            {isVisible && (<span className="centered-text">
                                                {isVisible && (<span style={{ bottom: '-60px' }} className="slide-left text-left centered-textparagraph">YOU ARE NOT</span>)} <br />
                                                {isVisible2 && (<span className="slide-right text-border centered-textparagraph">LOST IN SPACE</span>)}
                                            </span>)}

                                            {isVisible3 && (<span style={{ height: '100%' }} className="centered-text2">

                                                {isVisible3 && (<span style={{ bottom: '-60px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="slide-left text-left centered-textparagraph">THIS PAGE</span>)}<br />

                                                {isVisible4 && (<span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="slide-right text-border-three centered-textparagraph">IS BEING TRANSFORMED</span>)}<br />
                                            </span>)}

                                            {isVisible6 && (<span style={{ height: '100%' }} className="centered-text2">

                                                {isVisible5 && (<span style={{ bottom: '-60px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="slide-right text-left centered-textparagraph">FROM STARDUST</span>)}<br />

                                                {isVisible6 && (<span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="slide-left text-border-three centered-textparagraph">INTO SOMETHING AWESOME</span>)}<br />
                                            </span>)}

                                        </div>

                                    </Col>
                                </Row>

                            </div>

                        ) : null}
                    </>
                    :
                    <>
                        <span className={classes.text}>
                            The resource that you are attempting to access is protected and you don't have the necessary permissions to view it.
                        </span>
                    </>
            }
        </div>
    );
};

export default DefaultPage;