import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PositionTracking from 'src/pages/vsp/position-tracking-page';
import VehicleDetailsPage from 'src/components/vehicleDetails/VehicleDetailsPageRSA';
import ConnectedDrivers from 'src/components/vehicleDetails/ConnectedDrivers';
import SVTMainComponent from 'src/pages/svt-page/SVTMainComponent';
import Chat from 'src/containers/Chat';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

function TabPanel(props) {
  const { children, value, index, vehiclePosition, closeHandleDrawer, isExpanded, vin, shouldRenderMap, handleChangeVIN, detailsmap,
    mapSpinner,
    setAddressInput,
    addressInput,
    comments,
    getComments,
    dataLoaded,
    ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && value === 0 && (
        <PositionTracking
          vin={vin}
          value={3}
          vehiclePosition={vehiclePosition}
          mapSpinner={mapSpinner}
          setAddressInput={setAddressInput}
          addressInput={addressInput}
          detailsmap={detailsmap}
        />
      )}
      {value === index && value === 1 && (
        <VehicleDetailsPage value={value} vin={vin} vehiclePosition={vehiclePosition} />
      )}
      {value === index && value === 2 && (
        <ConnectedDrivers value={value} vin={vin} />
      )}
      {value === index && value === 3 && (
        <ConnectedDrivers value={value} vin={vin} />
      )}
      {value === index && value === 4 && (
        <SVTMainComponent selectedVIN={vin} valueVIN={value} />
      )}
      {value === index && value === 5 && (
        <Chat vin={vin} valueVIN={value} comments={comments} getComments={getComments} dataLoaded={dataLoaded} />
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  // vehiclePosition: PropTypes.object,
  // closeHandleDrawer: PropTypes.func.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const theme = createTheme({
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent',
        height: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginLeft: '4vw',
        borderRadius: '30px',
        zIndex: 10000,
        '@media (min-width: 1401px) and (max-width: 1600px)': {
          maxWidth: '7.5vw',
          marginLeft: '3vw',
        },
      }
    },
    MuiTab: {
      root: {
        "&$selected": {
          backgroundColor: '#b3b3b3',
          color: '#000',
          borderRadius: '30px',
          marginLeft: '1vw',
          minWidth: '130px',
          height: '10px'
        }
      }
    }
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: '-10px',
    marginRight: '5px',
  },
  panel: {
    marginTop: '-79vh',
    position: "relative",
    height: "100%",
    paddingLeft: '9px',
    backgroundColor: "transparent",
    width: '91vw',
    '@media (min-width: 1501px) and (max-width: 1600px)': {
      marginLeft: '-19px',
      width: '110.5vw',
      marginTop: '-75.8vh',
    },
    '@media (min-width: 1401px) and (max-width: 1450px)': {
      marginLeft: '-19px',
      width: '110.5vw',
      marginTop: '-78.6vh',
    },
    '@media (min-width: 1276px) and (max-width: 1280px)': {
      marginTop: '-84vh',
    },
    '@media (min-width: 1111px) and (max-width: 1275px)': {
      marginTop: '-80vh',
    },
    '@media (min-width: 1075px) and (max-width: 1110px)': {
      marginTop: '-80vh',
    },
  },
  panel1: {
    marginTop: '-79vh',
    position: "relative",
    height: "100%",
    paddingLeft: '10px',
    backgroundColor: "transparent",
    zIndex: 1000,
    width: '91vw',
    '@media (min-width: 2501px) and (max-width: 4500px)': {
      paddingRight: '1750px',
    },
    '@media (min-width: 1501px) and (max-width: 1600px)': {
      marginLeft: '0px',
      width: '90vw',
      marginTop: '-77vh',
    },
    '@media (min-width: 1401px) and (max-width: 1450px)': {
      marginLeft: '3px',
      marginTop: '-81vh'
    },
    '@media (min-width: 1800px) and (max-width: 1872px)': {
      marginLeft: '3px',
      marginTop: '-77vh'
    },
    '@media (min-width: 1276px) and (max-width: 1280px)': {
      marginTop: '-84vh',
    },
    '@media (min-width: 1111px) and (max-width: 1275px)': {
      marginTop: '-80vh',
    },
    '@media (min-width: 1075px) and (max-width: 1110px)': {
      marginTop: '-82vh',
    },
  },
  tabBar: {
    marginLeft: '-15px',
    marginRight: '13px',
    marginTop: '5px',
    minWidth: '12.3vw',
    maxWidth: '28vw',
    color: '#b3b3b3',
    fontFamily: 'LynkcoTypeMedium',
    fontSize: '17px',
    zIndex: 1000,
    '@media (min-width: 2501px) and (max-width: 4500px)': {
      minWidth: '7vw',
      // marginTop: '68vh',
    },
    '@media (min-width: 1501px) and (max-width: 1600px)': {
      minWidth: '13vw',
      maxWidth: '15vw',
      marginTop: '5px',
      fontSize: '15px',
    },
    '@media (min-width: 1401px) and (max-width: 1500px)': {
      marginTop: '2px',
      fontSize: '15px',
    },
    '@media (min-width: 1800px) and (max-width: 1872px)': {
      marginTop: '10px',
    },
    '@media (min-width: 1276px) and (max-width: 1280px)': {
      fontSize: '15px',
    },
    '@media (min-width: 1111px) and (max-width: 1275px)': {
      fontSize: '15px',
      minWidth: '12vw',
    },
    '@media (min-width: 1025px) and (max-width: 1110px)': {
      fontSize: '15px',
      minWidth: '12vw',
    },
  },
  appBar: {
    backgroundColor: "#181818",
    position: 'relative',
    marginTop: '71vh',
    marginLeft: '200px',
    width: '70vw',
    height: '6vh',
    borderRadius: '30px',
    '@media (min-width: 2501px) and (max-width: 4500px)': {
      marginLeft: '100px',
      width: '40vw',
      // marginTop: '68vh',
      height: '6vh',
    },
    '@media (min-width: 1451px) and (max-width: 1600px)': {
      marginLeft: '100px',
      width: '75vw',
      marginTop: '68vh',
      height: '7vh',
    },
    '@media (min-width: 1401px) and (max-width: 1450px)': {
      marginLeft: '100px',
      width: '75vw',
      marginTop: '72vh',
      height: '7vh',
    },
    '@media (min-width: 1800px) and (max-width: 1872px)': {
      height: '6.2vh',
    },
    '@media (min-width: 1276px) and (max-width: 1280px)': {
      height: '8vh',
      marginTop: '73vh',
      marginLeft: '120px',
    },
    '@media (min-width: 1111px) and (max-width: 1275px)': {
      height: '9.5vh',
      marginTop: '66vh',
      marginLeft: '120px',
    },
    '@media (min-width: 1025px) and (max-width: 1110px)': {
      height: '6.5vh',
      marginTop: '73vh',
      marginLeft: '90px',
      width: '73vw',
    },
    '@media (min-width: 1001px) and (max-width: 1024px)': {
      height: '7.5vh',
      marginTop: '70.5vh',
      marginLeft: '80px',
      width: '76vw',
    },
    '@media (min-width: 771px) and (max-width: 1000px)': {
      height: '5.5vh',
      marginTop: '77vh',
      marginLeft: '35px',
      width: '82vw',
    },
    '@media (min-width: 600px) and (max-width: 770px)': {
      height: '5.5vh',
      marginTop: '79vh',
      marginLeft: '35px',
      width: '82vw',
    }
  }
}));

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#bfbfbf',
    height: '80%',
    maxWidth: '8.5vw',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    // marginTop: '-150px',
    paddingTop: '50px',
    marginLeft: '2vw',
    borderRadius: '30px',
    zIndex: 10000,
    '@media (min-width: 1401px) and (max-width: 1600px)': {
      maxWidth: '7.5vw',
      marginLeft: '3vw',
    },

    // marginRight: '-50px'
  },


})(Tabs);

export default function VehicleDetailsTabs(props) {
  const { vehiclePosition, shouldRenderMap, closeHandleDrawer, isExpanded, vin, comments, getComments, dataLoaded, mapSpinner, handleChangeVIN, defaultcolor = false, detailsmap } = props
  const classes = useStyles(props);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar color='secondary' position="static" className={classes.appBar}>
          <Tabs variant="scrollable"
            scrollButtons={false} value={value} onChange={handleChange} aria-label="simple tabs example" >
            <Tab label="MAP" {...a11yProps(0)} className={classes.tabBar} />
            <Tab label="Details" {...a11yProps(1)} className={classes.tabBar} />
            <Tab label="Drivers" {...a11yProps(2)} className={classes.tabBar} />
            <Tab label="Remote" {...a11yProps(3)} className={classes.tabBar} />
            <Tab label="SVT" {...a11yProps(4)} className={classes.tabBar} />
            <Tab label="Notes" {...a11yProps(value)} className={classes.tabBar} />
          </Tabs>
        </AppBar>
        <TabPanel
          vehiclePosition={vehiclePosition}
          shouldRenderMap={shouldRenderMap}
          vin={vin}
          closeHandleDrawer={closeHandleDrawer}
          value={value}
          index={0}
          className={classes.panel1}
          isExpanded={isExpanded}
          mapSpinner={mapSpinner}
          handleChangeVIN={handleChangeVIN}
          detailsmap={detailsmap}
        >
          Map
        </TabPanel>
        <TabPanel value={value} vin={vin} index={1} className={classes.panel} vehiclePosition={vehiclePosition}>
          Details
        </TabPanel>
        <TabPanel value={value} vin={vin} index={2} className={classes.panel}>
          Drivers
        </TabPanel>
        <TabPanel value={value} vin={vin} index={3} className={classes.panel}>
          Remote
        </TabPanel>
        <TabPanel value={value} vin={vin} index={4} className={classes.panel}>
          SVT
        </TabPanel>
        <TabPanel value={value} vin={vin} comments={comments} getComments={getComments} dataLoaded={dataLoaded} index={5} className={classes.panel}>
          Notes
        </TabPanel>
      </div>
    </MuiThemeProvider>
  );
}