import React from 'react'
import FormSelectSalesSettings from 'src/components/form-elements/form-select.sales.settings.component'
import { GetMarketByOrganizationId } from 'src/services/market-http.service'
import { makeStyles } from '@material-ui/core/styles';

const useSelectStyles = makeStyles((theme) => ({
    SelectControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        minWidth: "100%",
    },
}));

const MarketSelect = ({ handleChange, variant, orgId, userMarketIds, value, isVariant }) => {
    const [markets, setMarkets] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const classes = useSelectStyles();

    markets.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

    React.useEffect(() => {
        initializeMarkets();
    }, [markets.length]);

    const initializeMarkets = () => {
        let orgsMarkets = [];
        let userMarketArray = [];

        GetMarketByOrganizationId(orgId)
            .then(data => {

                Array.isArray(data) && data.length > 0 ? data.map(market => {
                    orgsMarkets.push({ key: market.gemsId, value: market.name });
                })
                    : console.log("Error Occured");
                userMarketIds.map(id => {
                    orgsMarkets.map(market => {
                        if (userMarketArray.length == 0)
                            userMarketArray.push({ key: "NONE", value: " Market" });
                        if (market.key == id) {
                            userMarketArray.push({ key: market.key, value: market.value });
                        }
                    })
                })
                setMarkets(userMarketArray);
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormSelectSalesSettings
            className={classes.SelectControl}
            label="Market"
            id="market"
            name={''}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            value={value}
            onChange={handleChange}
            menuObjectArray={markets}
            variant={variant ? variant : false}
            isVariant={isVariant}
        />
    )
}

export default MarketSelect;