import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles'
import { OutlinedInput } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "30px",
    "& .MuiOutlinedInput-input": {
      color: "white",
      fontSize: '16px',
      textAlign: (props) => props.isPriceBucketField ? 'center' : 'start'

    },
    "& .MuiInputLabel-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: (props) => props.priceBucketActiveTab ? "#1EF1C6" : 'rgba(255, 255, 255, 0.01)',
      borderWidth: (props) => props.priceBucketActiveTab ? '1px' : 'thin'
    },

    "&:hover .MuiOutlinedInput-input": {
      color: "white"
    },
    "&:hover .MuiInputLabel-root": {
      color: "white",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1EF1C6"
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#1EF1C6"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#1EF1C6",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1EF1C6"
    },
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    // height: '500px',
  "& .MuiMenuItem-root.Mui-selected": {
    backgroundColor: "#2c2c2c"
  },
  "& .MuiMenuItem-root:hover": {
    backgroundColor: "#212121"
  },
  "& .MuiMenuItem-root.Mui-selected:hover": {
    backgroundColor: "#1EF1C6",
    color: '#000'
  },
  "&::-webkit-scrollbar": {
    height: "6px",
    width: "6px",
    opacity: "0.3",
    backgroundColor: "#101010"
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "100px",
    border: "1px solid transparent",
    backgroundColor: "#1EF1C6" 
  }
  }
}));

const useStylesBordered = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "30px",
    "& .MuiOutlinedInput-input": {
      color: "white",
      fontSize: '16px',
      textAlign: (props) => props.isPriceBucketField ? 'center' : 'start'

    },
    "& .MuiInputLabel-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: (props) => props.priceBucketActiveTab ? "#1EF1C6" : 'rgba(255, 255, 255, 0.2)',
      borderWidth: (props) => props.priceBucketActiveTab ? '1px' : 'thin'
    },

    "&:hover .MuiOutlinedInput-input": {
      color: "white"
    },
    "&:hover .MuiInputLabel-root": {
      color: "white",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1EF1C6"
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#1EF1C6"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#1EF1C6",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1EF1C6"
    }
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    height: '500px',
  "& .MuiMenuItem-root.Mui-selected": {
    backgroundColor: "#2c2c2c"
  },
  "& .MuiMenuItem-root:hover": {
    backgroundColor: "#212121"
  },
  "& .MuiMenuItem-root.Mui-selected:hover": {
    backgroundColor: "#1EF1C6",
    color: '#000'
  },
  "&::-webkit-scrollbar": {
    height: "6px",
    width: "6px",
    opacity: "0.3",
    backgroundColor: "#101010"
  },

  "&::-webkit-scrollbar-thumb": {
    borderRadius: "100px",
    border: "1px solid transparent",
    backgroundColor: "#1EF1C6" 
  }
  }
}));

export const FormSelectSales = (props) => {
  const { open, onClose, onOpen, defaultValue, value, onChange, label, id, name, placeholder, variant, menuObjectArray = [{ key: '', value: '' }],
    disabled = false, priceBucketActiveTab = false, isPriceBucketField = false } = props
  const classes = useStyles(props);

  return (
    <div >
      <FormControl className={classes.root} variant={variant ? variant : "outlined"} margin='dense'>
        <InputLabel id={`${label}-label`} >{''}</InputLabel>
            <Select
                labelId={`${label}-label`}
                id={id}
                name={name}
                open={open}
                onClose={onClose}
                onOpen={onOpen}
                value={value}
                onChange={onChange}
                disabled={disabled}
                displayEmpty  
                // className={classes.dropdownStyle}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
            >
                <MenuItem   value="">
                    <em style={{ fontStyle: 'normal' }}>{placeholder}</em>
                </MenuItem>
                {
                    menuObjectArray.map(menu => {
                        return (<MenuItem value={menu.key}>{menu.value}</MenuItem>);
                    })
                }
            </Select>
      </FormControl>
    </div>
  );
}



export const FormSelectSalesBordered = (props) => {
  const { open, onClose, onOpen, defaultValue, value, onChange, label, id, name, placeholder, variant, menuObjectArray = [{ key: '', value: '' }],
    disabled = false, priceBucketActiveTab = false, isPriceBucketField = false } = props
  const classes = useStylesBordered(props);

  return (
    <div >
      <FormControl className={classes.root} variant={variant ? variant : "outlined"} margin='dense'>
        <InputLabel id={`${label}-label`} >{''}</InputLabel>
            <Select
                labelId={`${label}-label`}
                id={id}
                name={name}
                open={open}
                onClose={onClose}
                onOpen={onOpen}
                value={value}
                onChange={onChange}
                disabled={disabled}
                displayEmpty  
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
            >
                <MenuItem  value="">
                    <em style={{ fontStyle: 'normal' }}>{placeholder}</em>
                </MenuItem>
                {
                    menuObjectArray.map(menu => {
                        return (<MenuItem value={menu.key}>{menu.value}</MenuItem>);
                    })
                }
            </Select>
      </FormControl>
    </div>
  );
}