import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Col, Row } from "reactstrap";

import Chart from "react-apexcharts";

const SalesOverview = () => {
  const optionsrevenue = {
    grid: {
      show: true,
      strokeDashArray: 2,
      borderColor: "rgba(0,0,0,.3)",
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        endingShape: "flat",
      },
    },
    colors: ["#4fc3f7", "#555b6e"],
    fill: {
      type: "solid",
      opacity: 1,
    },
    chart: {
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: "category",
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
      labels: {
        style: {
          cssClass: "grey--text lighten-2--text fill-color",
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          cssClass: "grey--text lighten-2--text fill-color",
        },
      },
    },
    stroke: {
      show: true,
      width: 5,
      colors: ['transparent']
    },
    tooltip: {
      theme: "dark",
    },
  };
  const seriesrevenue = [

    {
      name: "B2C",
      data: [22, 26, 19, 34, 41, 38, 29, 32, 44, 47, 38, 51],
    },
    {
      name: "B2B",
      data: [9, 5, 3, 7, 5, 10, 14, 8, 17, 11, 4, 9],
    },
  ];
  return (
    /*--------------------------------------------------------------------------------*/
    /* Used In Remarketing Dashboard                                                  */
    /*--------------------------------------------------------------------------------*/
    <Card
      className="w-100"
      style={{
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        boxShadow: '0 5px 6px #000000',
      }}
    >
      <CardBody>
        <Row>
          <Col xs="12">
            <div className="d-flex flex-wrap">
              <div>
                <CardTitle style={{ marginTop: '-3px', fontSize: "1.2vw", fontWeight: 600 }}>Sales Overview</CardTitle>
                <CardSubtitle style={{ fontSize: "0.85vw" }}>Total sales per calender month</CardSubtitle>
              </div>
            </div>
          </Col>
          <Col xs="12">
            <Chart
              options={optionsrevenue}
              series={seriesrevenue}
              type="bar"
              height="306px"
            />
          </Col>
        </Row>
        <div className="d-flex no-block align-items-center justify-content-center">
          <div>
            <h6 className="text-vehicleblue">
              <i className="fa fa-circle font-10 mr-2" />
              B2C
            </h6>
          </div>
          <div className="ml-3">
            <h6 className="text-vehicleblack">
              <i className="fa fa-circle font-10 mr-2" />
              B2B
            </h6>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default SalesOverview;