import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import rightArrow_black from 'src/assets/img/vehicles/rightArrow_black.png'
import IconButton from "@mui/material/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import arrowLeft_red from 'src/assets/img/vehicles/arrowLeft_red.png';
import arrowLeft_black from 'src/assets/img/vehicles/arrowLeft_black.png';





const SearchButton = (props) => {

    const { handleSearch, placeholder, cancelProp = false, icon, iconProp = false, height, rsa, width, widthProp = false, handleConfirmvehicleDetails } = props;

    const imgs = [arrowLeft_red, arrowLeft_black];

    const [icon1, setIcon] = useState(imgs[0]);
    const [openIcon, setOpenIcon] = useState(false);

    const [textColor, setTextColor] = useState(false);


    const [anchorEl, setAnchorEl] = React.useState(null);

    const useStyles = makeStyles((theme) => ({
        button: {
            backgroundColor: (props) => props.iconProp ? 'transparent' : props.rsa ? '#DC1860' : props.cancelProp ? 'transparent' : '#1ef1c6',
            borderRadius: '300px',
            // height: (props) => props.height ? '40px' : props.width ? '30px' : props.iconProp ? '40px' : props.createProp ? '40px' : '33px',
            height: (props) => props.height ? '35px' : props.width ? '30px' : props.iconProp ? '40px' : props.cancelProp ? '35px' : '33px',

            color: (props) => props.iconProp ? '#1ef1c6' : props.rsa ? 'white' : props.cancelProp ? '#DC1860' : '#000',
            border: (props) => props.iconProp ? '1px solid #1ef1c6' : props.rsa ? '' : props.cancelProp ? '1px solid #DC1860' : '',
            textTransform: 'initial',
            fontFamily: 'LynkcoTypeMedium',
            '&:hover': {
                backgroundColor: (props) => props.iconProp ? 'transparent' : props.rsa ? '#893257' : props.cancelProp ? '#DC1860' : '#088a70',
                color: (props) => props.iconProp ? '#339586' : props.cancelProp ? '#000' : '#000',
            },
            whiteSpace: 'nowrap',
            // width: (props) => props.width ? '3vw' : props.iconProp ? '9vw' : props.widthProp ? '9vw' : props.createProp ? '6vw' : '7vw',
            width: (props) => props.width ? '3vw' : props.iconProp ? '9vw' : props.widthProp ? '6vw' : props.cancelProp ? '6vw' : '7vw',

            fontSize: '14px'
        }
    }));

    const classes = useStyles(props);



    const open1 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseHomeIcon = () => {

        setIcon(imgs[0]);
        setOpenIcon(false);
        setTextColor(false);
    };

    const handleOpenHomeIcon = () => {
        setIcon(imgs[1]);
        setOpenIcon(true);
        setTextColor(true);
    };

    return (
        <Button
            onClick={handleSearch}
            className={classes.button}
            variant="contained"
            placeholder={placeholder}
            icon={icon}
        >
            {
                props.cancelProp ?
                    <Tooltip
                        title=""
                        onOpen={handleOpenHomeIcon}
                        onClose={handleCloseHomeIcon}
                        open={openIcon}
                    >
                        <IconButton
                            // onClick={handleClickAdd}
                            onClick={handleSearch}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open1 ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open1 ? "true" : undefined}
                        >
                            <div onClick={handleSearch} style={{
                                marginLeft: props.cancelProp ? '-45px' : '', color: textColor ? '#000' : '#DC1860', fontSize: '14px', fontFamily: 'LynkcoTypeMedium'
                            }}>
                                {placeholder}
                            </div>
                            <img
                                className={props.cancelProp ? "arrowLeft" : "arrowRight"}
                                src={icon1}
                                alt="Three Dot Image"
                                onClick={handleSearch}
                            />
                        </IconButton>
                    </Tooltip>
                    :
                    <>
                        <div onClick={handleConfirmvehicleDetails} >
                            <div style={{ fontFamily: 'LynkcoTypeMedium', alignItems: 'center', justifyContent: 'center', display: 'flex', position: 'fixed' }}>
                                {placeholder}

                            </div>
                        </div>

                        <img
                            className={props.cancelProp ? "arrowLeft" : "arrowRight"}
                            src={icon}
                            alt="Three Dot Image"
                            onClick={handleConfirmvehicleDetails}
                        />

                    </>
            }
            {/* <div style={{
                marginLeft: props.cancelProp ? '60px' : ''
            }}>
                {placeholder}
            </div>
            {
                props.cancelProp ?
                    <Tooltip
                        title=""
                        onOpen={handleOpenHomeIcon}
                        onClose={handleCloseHomeIcon}
                        open={openIcon}
                    >
                        <IconButton
                            // onClick={handleClickAdd}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open1 ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open1 ? "true" : undefined}
                        >
                            <img
                                className={props.cancelProp ? "arrowLeft" : "arrowRight"}
                                src={icon1}
                                alt="Three Dot Image"
                            />
                        </IconButton>
                    </Tooltip>
                    :
                    <img
                        className={props.cancelProp ? "arrowLeft" : "arrowRight"}
                        src={icon}
                        alt="Three Dot Image"
                    />
            } */}
            {/* {} */}
        </Button>
    );
}

export default SearchButton;