import React from 'react';
import { Col } from 'react-bootstrap';
import { CardBody } from 'reactstrap';
import Carousel from 'src/components/vehicleDetails/Carousel/components/carousel/CarouselSingle';
import blueslider1 from 'src/assets/img/vehicles/blueslider1.png';
import blueslider2 from 'src/assets/img/vehicles/blueslider2.png';
import blueslider3 from 'src/assets/img/vehicles/blueslider3.png';
import blueslider4 from 'src/assets/img/vehicles/blueslider4.png';
import blackslider1 from 'src/assets/img/vehicles/blackslider1.png';
import blackslider2 from 'src/assets/img/vehicles/blackslider2.png';
import blackslider3 from 'src/assets/img/vehicles/blackslider3.png';
import blackslider4 from 'src/assets/img/vehicles/blackslider4.png';

const BasicCarouselWithCaption = ({ modelData }) => {

  const data = [
    // { id: 0, src: modelData.color == 'Blue' ? blueslider1 : blackslider1, data: `${modelData.odometerKm} km` },
    // { id: 1, src: modelData.color == 'Blue' ? blueslider2 : blackslider2, data: `${modelData.age} months` },
    // { id: 3, src: modelData.color == 'Blue' ? blueslider4 : blackslider4, data: `Defleet by ${FormmatDate(modelData.forecastedDefleetDateUtc)}` },
    // { id: 4, src: modelData.color == 'Blue' ? blueslider3 : blackslider3, data: `Defleet by ${FormmatDate(modelData.forecastedDefleetDateUtc)}` },
    { id: 0, src: modelData == 'Blue' ? blueslider1 : blackslider1 },
    { id: 1, src: modelData == 'Blue' ? blueslider2 : blackslider2 },
    { id: 3, src: modelData == 'Blue' ? blueslider3 : blackslider4 },
    { id: 4, src: modelData == 'Blue' ? blueslider4 : blackslider3 },

  ];

  return (
    <Col md={12} lg={12} xl={12}>
      <div >
        <CardBody>
          <Carousel>
            {data.map(item => (
              <div key={item.id}

              >
                <div
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    position: 'relative',
                    flexWrap: 'wrap',
                  }}
                >
                  <img
                    src={item.src}
                    className='carouselImage'
                    alt={`slide_${process.env.PUBLIC_URL}/assets/icons/BlueCar.png`}
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </CardBody>
      </div>
    </Col>
  );
};

export default BasicCarouselWithCaption;
