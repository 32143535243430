import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Row, Col, Container } from "reactstrap";
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds } from "src/redux/user/user.selectors";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import { GetFleets } from "src/services/fleet-http.service";
import { QueryVehicleHealth } from 'src/services/vehicle-pages-http.service';
import { QueryConnectivityInformation } from 'src/services/connectivity-http.service';
import VehicleInfo from 'src/assets/img/vehicles/VehicleInfo.png';
import VehicleInfo_Green from 'src/assets/img/vehicles/VehicleInfo_Green.png';
import VehicleRange from 'src/assets/img/vehicles/VehicleRange.png';
import VehicleRange_Green from 'src/assets/img/vehicles/VehicleRange_Green.png';
import VehicleHealth from 'src/assets/img/vehicles/VehicleHealth.png';
import VehicleHealth_Green from 'src/assets/img/vehicles/VehicleHealth_Green.png';
import VehicleLock from 'src/assets/img/vehicles/VehicleLock.png';
import VehicleLock_Green from 'src/assets/img/vehicles/VehicleLock_Green.png';
import PositionTracking from 'src/pages/vsp/position-tracking-page';
import Carousel from 'src/components/vehicleDetails/Carousel/index';
import './styles.css';

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(3.7),
        position: 'sticky',
        marginLeft: '11px',
        marginTop: '-22px'
    },
    drawer: {
        width: 250,
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px'
    },
    gridContainerStyle1: {
        width: '100%',
    },
    cardStyle: {
        height: '744px',
        borderRadius: '15px',
        marginRight: '-5px',
        marginLeft: '5px',
    },
    cardStyleLarge: {
        height: '605px',
        borderRadius: '15px',
        marginRight: '-22px'
    },
    imageStyle: {
        marginTop: '60px',
        marginLeft: '15px',
        maxHeight: '585px',
        minHeight: '500px',
        maxWidth: '34vh',
        minWidth: '23vh'
    },
    tooltipImg: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        marginTop: '2px',
    },
    tooltipImgButton: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        marginTop: '50px',
    },
    rowheight: {
        marginTop: '-210px',
        '@media (min-width: 1401px) and (max-width: 1600px)': {
            marginTop: '-180px',
        },
    },
    rowheightMiddle: {
        marginTop: '-235px',
        '@media (min-width: 1401px) and (max-width: 1600px)': {
            marginTop: '-200px',
        },
    }
}));

// let vin = ''

const VehicleDetailsPage = (props) => {
    const { userOrg, userMarketIds, vin, value, vehiclePosition, mapSpinner, setAddressInput, addressInput, detailsMap = true } = props
    const classes = UseStyles(props);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [searchInputVIN, setSearchInputVIN] = useState(false);
    const [displaySelectvehicle, setDisplaySelectedVehicle] = useState(false);
    const [searchVIN, setSearchVIN] = React.useState('')
    const [vehicleDetail, setVehicleDetail] = React.useState({});
    const [message, setMessage] = React.useState('');

    const [openVehicleInfoIconToggle, setOpenVehicleInfoIconToggle] = useState(false);
    const imgsVehicleInfo = [VehicleInfo, VehicleInfo_Green];
    const [vehicleImg, setVehicleImg] = useState(imgsVehicleInfo[0]);
    const [openVehicleInfoIcon, setVehicleInfoIcon] = useState(false);

    const [openVehicleRangeIconToggle, setOpenVehicleRangeIconToggle] = useState(false);
    const imgsVehicleRange = [VehicleRange, VehicleRange_Green];
    const [VehicleRangeImg, setVehicleRangeImg] = useState(imgsVehicleRange[0]);
    const [openVehicleRangeIcon, setVehicleRangeIcon] = useState(false);


    const [openVehicleHealthIconToggle, setOpenVehicleHealthIconToggle] = useState(false);
    const imgsVehicleHealth = [VehicleHealth, VehicleHealth_Green];
    const [VehicleHealthImg, setVehicleHealthImg] = useState(imgsVehicleHealth[0]);
    const [openVehicleHealthIcon, setVehicleHealthIcon] = useState(false);

    const [openVehicleLockIconToggle, setOpenVehicleLockIconToggle] = useState(false);
    const imgsVehicleLock = [VehicleLock, VehicleLock_Green];
    const [VehicleLockImg, setVehicleLockImg] = useState(imgsVehicleLock[0]);
    const [openVehicleLockIcon, setVehicleLockIcon] = useState(false);
    const [displayVehicleInformation, setDisplayVehicleInformation] = useState(false);

    const [displayVehicleMaintenance, setDisplayVehicleMaintenance] = useState(false);

    const [displayVehicleRange, setDisplayVehicleRange] = useState(false);

    const [displayVehicleLock, setDisplayVehicleLock] = useState(false);


    const [vehicleHealth, setVehicleHealth] = React.useState({
        "colour": "",
        "distanceToService": {
            "isDistanceToServiceOk": null,
            "value": ""
        },
        "daysToService": {
            "isDaysToServiceOk": null,
            "value": ""
        },
        "serviceWarningStatus": {
            "isServiceWarningStatusOk": null,
            "value": null
        },
        "engineOilLevelStatus": {
            "isEngineOilLevelStatusOk": null,
            "value": null
        },
        "engineOilPressureStatus": {
            "isEngineOilPressureStatusOk": null,
            "value": null
        },
        "washerFluidLevelStatus": {
            "isWasherFluidLevelStatusOk": null,
            "value": null
        }
    });

    useEffect(() => {
        if (vin !== undefined) {
            handleChangeVINorLIcensePlate(vin.trim());
            handleVehicleHealth(vin);
            handleVehicleInformation();
        }
    }, [])

    const handleDialogOk = () => {
        setOpenDialog(false);
        setSearchInputVIN(false);
    }

    const handleChangeVINorLIcensePlate = (vin) => {
        QueryConnectivityInformation(vin)
            .then(data => {
                if (data.ok == true) {
                    setSearchInputVIN(true);
                    setVehicleDetail(data.vspConnectivity);
                    setDisplaySelectedVehicle(true);
                } else {
                    setSearchVIN();
                    // setOpenDialog(true);
                    setMessage(data.title);
                    setDialogMessage(data.errorMessage);
                }
            })

    };

    const handleVehicleHealth = (vin) => {
        if (value == 1) {
            let fleetIds = []
            let marketIds = userMarketIds;

            GetFleets(userOrg.id)
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(item => {
                            fleetIds.push(item.id);
                        })

                        QueryVehicleHealth(vin, fleetIds, marketIds)
                            .then(data => {
                                if (data.ok == true) {
                                    setVehicleHealth(data.vehicleMaintenance);
                                }
                            })
                    }
                })
        }
    };

    const handleOpenVehicleInfoIcon = () => {
        setVehicleImg(imgsVehicleInfo[0]);
        setVehicleInfoIcon(false)
    };

    const handleCloseVehicleInfoIcon = () => {
        setVehicleImg(imgsVehicleInfo[1]);
        setVehicleInfoIcon(true)
    };

    const handleOpenVehicleRangeIcon = () => {
        setVehicleRangeImg(imgsVehicleRange[0]);
        setVehicleRangeIcon(false)
    };

    const handleCloseVehicleRangeIcon = () => {
        setVehicleRangeImg(imgsVehicleRange[1]);
        setVehicleRangeIcon(true)
    };

    const handleOpenVehicleHealthIcon = () => {
        setVehicleHealthImg(imgsVehicleHealth[0]);
        setVehicleHealthIcon(false)
    };

    const handleCloseVehiclehealthIcon = () => {
        setVehicleHealthImg(imgsVehicleHealth[1]);
        setVehicleHealthIcon(true)
    };

    const handleOpenVehicleHealthLock = () => {
        setVehicleLockImg(imgsVehicleLock[0]);
        setVehicleLockIcon(false)
    };

    const handleCloseVehiclehealthLock = () => {
        setVehicleLockImg(imgsVehicleLock[1]);
        setVehicleLockIcon(true)
    };

    const handleVehicleInformation = () => {
        setDisplayVehicleInformation(true);
        setVehicleImg(imgsVehicleInfo[1]);
        setVehicleInfoIcon(true);

        // setOpenHomeIcon(false)
        // setHomeImg(imgsHome[0]);

        setOpenVehicleInfoIconToggle(true);
        setVehicleRangeIcon(false);
        setVehicleHealthIcon(false);
        setVehicleLockIcon(false)
        setOpenVehicleRangeIconToggle(false);
        setOpenVehicleHealthIconToggle(false);
        setOpenVehicleLockIconToggle(false);
        // setOpenHomeIconToggle(false);
        setVehicleRangeImg(imgsVehicleRange[0]);
        setVehicleHealthImg(imgsVehicleHealth[0]);
        setVehicleLockImg(imgsVehicleLock[0]);
        setDisplayVehicleRange(false);
        setDisplayVehicleLock(false);
        setDisplayVehicleMaintenance(false);
    };

    const handleVehicleRange = () => {
        setDisplayVehicleRange(true);
        setVehicleRangeImg(imgsVehicleRange[1]);
        setVehicleRangeIcon(true);
        setOpenVehicleRangeIconToggle(true);

        // setOpenHomeIcon(false)
        // setHomeImg(imgsHome[0]);

        setOpenVehicleHealthIconToggle(false);
        setOpenVehicleLockIconToggle(false);
        setOpenVehicleInfoIconToggle(false);

        setVehicleInfoIcon(false);
        setVehicleLockIcon(false);
        setVehicleHealthIcon(false);

        setDisplayVehicleMaintenance(false);
        setDisplayVehicleLock(false);
        setDisplayVehicleInformation(false);

        // setOpenHomeIconToggle(false);
        setVehicleImg(imgsVehicleInfo[0]);
        setVehicleLockImg(imgsVehicleLock[0]);
        setVehicleHealthImg(imgsVehicleHealth[0]);
    };

    const handleVehicleMaintenance = () => {
        setDisplayVehicleMaintenance(true);
        setVehicleHealthImg(imgsVehicleHealth[1]);
        setVehicleHealthIcon(true);
        setOpenVehicleHealthIconToggle(true);

        // setOpenHomeIcon(false)
        // setHomeImg(imgsHome[0]);

        setOpenVehicleRangeIconToggle(false);
        setOpenVehicleLockIconToggle(false);
        setOpenVehicleInfoIconToggle(false);

        setVehicleInfoIcon(false);
        setVehicleRangeIcon(false);
        setVehicleLockIcon(false);

        setDisplayVehicleRange(false);
        setDisplayVehicleLock(false);
        setDisplayVehicleInformation(false);
        // setOpenHomeIconToggle(false);

        setVehicleImg(imgsVehicleInfo[0]);
        setVehicleRangeImg(imgsVehicleRange[0]);
        setVehicleLockImg(imgsVehicleLock[0]);
    };

    const handleVehicleLock = () => {
        setDisplayVehicleLock(true);
        setVehicleLockImg(imgsVehicleLock[1]);
        setVehicleLockIcon(true);
        setOpenVehicleLockIconToggle(true);

        // setOpenHomeIcon(false)
        // setHomeImg(imgsHome[0]);

        setOpenVehicleRangeIconToggle(false);
        setOpenVehicleHealthIconToggle(false);
        setOpenVehicleInfoIconToggle(false);

        setVehicleRangeIcon(false);
        setVehicleInfoIcon(false);
        setVehicleHealthIcon(false);

        setDisplayVehicleRange(false);
        setDisplayVehicleMaintenance(false);
        setDisplayVehicleInformation(false);
        // setOpenHomeIconToggle(false);
        setVehicleImg(imgsVehicleInfo[0]);
        setVehicleRangeImg(imgsVehicleRange[0]);
        setVehicleHealthImg(imgsVehicleHealth[0]);
    };

    return (
        <Container >
            <VehiclePageDialog
                dialogue='true'
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
                title={message}
            />

            {/* <Row> */}
            <Col md={12} sm={12} xs={12} lg={12} style={{ marginTop: '-30px' }}>
                <Row className='detailsRSA' >
                    <Col lg="4" md="12" sm="12" xs="12" >
                        <div style={{ marginLeft: '10px' }} >
                            <div className="mapCardRSADetails" >
                                <PositionTracking
                                    vin={vin}
                                    value={3}
                                    vehiclePosition={vehiclePosition}
                                    mapSpinner={mapSpinner}
                                    setAddressInput={setAddressInput}
                                    addressInput={addressInput}
                                    detailsMap={detailsMap}
                                />
                            </div>
                        </div>
                    </Col>

                    <Col lg="4" md="12" sm="12" xs="12">
                        <div className="carouselCardRSADetails">
                            <Card id="CardBodyCardAllowanceDetailsCarCard" style={{ marginBottom: '10px' }}>
                                <Carousel modelData={vehicleDetail?.vehicleDetails?.color} />
                            </Card>
                            <Card id="CardBodyCardAllowanceDetails" >
                                <div>
                                    <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                        Days to service
                                    </div>
                                    <div className="rsaDaysToService" style={{ fontFamily: 'LynkcoTypeMedium', marginTop: '-2px' }}>
                                        {vehicleHealth.daysToService.value}
                                    </div>
                                </div>
                            </Card>
                            <Card
                                id="CardBodyCardAllowanceDetails"
                                onClick={() => {
                                    // setDisplayDetails(false);
                                    // setOpenRVChart(true);
                                    // setToggleReturnButton(true);
                                }}
                                style={{ cursor: vin !== 'L6TCX2E72NE031885' ? 'pointer' : 'default' }}
                            >
                                <div>
                                    <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                        Vehicle Health
                                    </div>
                                    <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium', marginTop: '10px', color: vin == 'L6TCX2E72NE031885' ? '#1ef1c6' : '#dc1860' }}>
                                        {vin == 'L6TCX2E72NE031885' ? 'Looking good' : 'ATTENTION'}
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </Col>

                    <Col lg="2" md="6" sm="6" xs="6">
                        <Row className="vehicleInfoCardRSADetails">
                            {/* <Row> */}
                            <Col lg="12" md="12" >
                                <Card id="CardBodyCardAllowanceDetailsCarCard2" >
                                    <div >
                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Model
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.model}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            License Plate
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.licensePlate}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            VIN
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vin}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Color
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.color}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Engine Type
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.engineType}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Towbar
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.towbarState}
                                        </div>

                                        <div className="rsaHeaderFontStyle" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Market
                                        </div>
                                        <div className="rsaBodyVehicleDetailsFontStyle" style={{ fontFamily: 'LynkcoTypeMedium' }}>
                                            {vehicleDetail?.vehicleDetails?.market}
                                        </div>
                                    </div>
                                </Card>

                            </Col>
                        </Row>

                    </Col>

                    <Col lg="2" md="6" sm="6" xs="6">
                        <Row id="CardRowStyle">
                            {/* <Row> */}

                            <Col lg="12" md="12" >
                                <Card id="CardBodyCardAllowanceDetails" >
                                    <div>
                                        <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            RSA VALID TO
                                        </div>
                                        <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium', marginTop: '10px' }}>
                                            2024-05-14
                                        </div>
                                    </div>
                                </Card>
                                <Card id="CardBodyCardAllowanceDetails" >
                                    <div>
                                        <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Range Left
                                        </div>
                                        <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium', marginTop: '10px' }}>
                                            347 km
                                        </div>
                                    </div>
                                </Card>

                                <Card id="CardBodyCardAllowanceDetails" >
                                    <div>
                                        <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            Connectivity Package
                                        </div>
                                        <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium', marginTop: '10px' }}>
                                            Premium
                                        </div>
                                    </div>
                                </Card>
                                <Card id="CardBodyCardAllowanceDetails" >
                                    <div>
                                        <div className="rsaHeaderFontStyle2" style={{ fontFamily: 'LynkcoTypeRegular' }}>
                                            SIM Card Status
                                        </div>
                                        <div className="rsaBodyFontStyle" style={{ fontFamily: 'LynkcoTypeMedium', marginTop: '10px' }}>
                                            Active
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Col>
        </Container>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(VehicleDetailsPage);