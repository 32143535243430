import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Row, Col, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions } from "src/redux/user/user.selectors";
import DefaultPage from "src/pages/backgrounds/default-page";
import RSATable from "src/pages/reports/RSATable";
import UCReservedPricingTable from "src/pages/reports/UCReservedPricingTable";
import { permission } from 'src/auth/permissions';

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginLeft: '11px',
    },
    drawer: {
        width: '100%',
        flexShrink: 0,
        marginTop: '100px',
        alignItems: 'center',
        position: 'absolute',
        height: '460px',
        display: 'flex',
        justifyContent: 'center'
    },
    gridContainerStyle1: {
        width: '100%',
    },
    cardStyle: {
        backgroundImage: 'linear-gradient(60deg, rgba(13,13,13,1), rgba(37,37,37,1) 99%)',
        boxShadow: '0 5px 6px #000000',
        height: '820px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        marginTop: '25px',
        width: '100%'
    },
    backgroundSyle: {
        marginLeft: '35px',
        position: "fixed",
        height: "100%",
        width: "100%",
        display: "block",
        top: "0",
        left: "0",
        padding: "55px",
        backgroundImage: 'linear-gradient(97deg, rgba(10,10,10,1) 40%, rgba(37,37,37,1) 99%)',
    },
}));

const ReportsMainPage = (props) => {
    const { userPermissions } = props
    const classes = UseStyles(props);

    const [openRSA, setRSA] = React.useState(false);
    const [openLandedCostBreach, setOpenlandedCostBreach] = React.useState(false);
    const [openUsedCarStock, setOpenUsedCarStock] = React.useState(false);

    const [hovered, setHovered] = useState(false);
    const [hovered1, setHovered1] = useState(false);

    const handleClick = (type) => {
        if (type === 'rsa') {
            setRSA(!openRSA);
            setOpenlandedCostBreach(false);
            setOpenUsedCarStock(false);
        }
        if (type === 'lcb') {
            setOpenlandedCostBreach(!openLandedCostBreach);
            setRSA(false);
            setOpenUsedCarStock(false);
        }
        if (type === 'ucs') {
            setOpenUsedCarStock(!openUsedCarStock);
            setRSA(false);
            setOpenlandedCostBreach(false);
        }
    };

    const matchesPermission = userPermissions.includes(permission.Report_Reader);

    return (
        <>
            {
                matchesPermission ?
                    <Col md={12} lg={12}>
                        <div className={classes.backgroundSyle}>
                            <Row>
                                <Col lg="3" md="12" >
                                    <div>
                                        <Card className={classes.cardStyle} style={{ backgroundColor: 'rgb(0 0 0 / 90%)' }} >
                                            <CardBody>
                                                <table style={{ width: '100%', color: 'white' }}>
                                                    <tr>
                                                        <td ><span style={{ float: 'left', fontSize: '18px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray' }}>RSA</span><br /><span style={{ whiteSpace: 'pre-line', float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeRegular', color: 'darkgray' }}>Vehicles Qualified for Road Side Assistance</span></td>
                                                        <td style={{ float: 'right' }}>
                                                            <Card
                                                                onMouseEnter={() => setHovered(true)}
                                                                onMouseLeave={() => setHovered(false)}
                                                                onClick={() => props.onClick && props.onClick()}
                                                                style={{ marginTop: '5px', padding: '10px', height: '80px', width: '100%', alignContent: 'center', justifyContent: 'center', borderRadius: '1px', ...{ backgroundColor: hovered ? '#2c2c2c' : '#131313' } }}
                                                            >
                                                                <span style={{ fontSize: '16px', fontFamily: "LynkcoTypeBold", textAlign: 'center', color: `${hovered ? 'white' : !openRSA ? '#1EF1C6' : '#DC1860'}`, cursor: 'pointer' }} onClick={() => handleClick('rsa')}><span >{!openRSA ? 'OPEN' : 'CLOSE'}</span></span>
                                                            </Card>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td ><span style={{ float: 'left', fontSize: '18px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray' }}>UC Reserved Pricing</span><br /><span style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeRegular', color: 'darkgray' }}>Setting UC reserved pricing for BCA sales platform</span></td>
                                                        <td style={{ float: 'right' }}>
                                                            <Card
                                                                onMouseEnter={() => setHovered1(true)}
                                                                onMouseLeave={() => setHovered1(false)}
                                                                onClick={() => props.onClick && props.onClick()}
                                                                style={{ marginTop: '5px', padding: '10px', height: '80px', width: '100%', alignContent: 'center', justifyContent: 'center', borderRadius: '1px', ...{ backgroundColor: hovered1 ? '#2c2c2c' : '#131313' } }}
                                                            >
                                                                <span style={{ fontSize: '16px', fontFamily: "LynkcoTypeBold", textAlign: 'center', color: `${hovered1 ? 'white' : !openLandedCostBreach ? '#1EF1C6' : '#DC1860'}`, cursor: 'pointer' }} onClick={() => handleClick('lcb')}><span >{!openLandedCostBreach ? 'OPEN' : 'CLOSE'}</span></span>
                                                            </Card>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>

                                <Col lg="9" md="12">
                                    {!openRSA || !openLandedCostBreach || !openUsedCarStock ?
                                        <>
                                            {openRSA ?
                                                <Card className={classes.cardStyle} style={{ marginLeft: '-14px', marginRight: '120px', backgroundColor: 'transparent' }} >
                                                    <CardBody>
                                                        <RSATable />
                                                    </CardBody>
                                                </Card>
                                                : openLandedCostBreach ?
                                                    <Card className={classes.cardStyle} style={{ marginLeft: '-14px', marginRight: '120px', backgroundColor: 'rgb(0 0 0 / 90%)' }} >
                                                        <CardBody>
                                                            <UCReservedPricingTable />
                                                        </CardBody>
                                                    </Card>
                                                    : openUsedCarStock &&
                                                    <div style={{ position: 'absolute', width: '100%', height: '820px', marginLeft: '-20px', alignItems: 'center', backgroundColor: 'rgb(0 0 0 / 90%)', display: 'flex', justifyContent: 'center' }}>
                                                        <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                                                            Coming soon !!!
                                                        </div>
                                                    </div>}
                                        </>
                                        : <></>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    :
                    <DefaultPage />
            }
        </>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(ReportsMainPage);