import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SingleSlider } from 'src/components/vehicleDetails/Carousel/components/carousel/CarouselElements';

// /Users/eseagadagba/Downloads/workspace/FMPortal_Iteration_WrapPixel/src/components/vehicleDetails/Carousel/components/carousel/CarouselElements.jsx

const CarouselSingle = ({ children }) => {
  // const rtl = useSelector(state => state.rtl);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    swipeToSlide: true,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 1 } },
      { breakpoint: 1200, settings: { slidesToShow: 2 } },
      { breakpoint: 1200, settings: { slidesToShow: 3 } },
      // { breakpoint: 100000, settings: { slidesToShow: 4 } },
    ],
    // rtl: rtl.direction === 'rtl',
  };

  return (
    <SingleSlider {...settings}>
      {children}
    </SingleSlider>
  );
};

CarouselSingle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CarouselSingle;
