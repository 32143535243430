import React, { useState } from 'react';
import { Card, CardBody } from "reactstrap";
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import Slider from '@mui/material/Slider';

const CustomSliderStyles = {
    '& .MuiSlider-thumb': {
        backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/icons/SliderIcon.png"})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: 30,
        height: 30,
        boxShadow: '2px 2px 3px 1px rgba(0,0,0, 0.6)',
        borderRadius: '50%'
    },
    '& .MuiSlider-track': {
        color: "#1EF1C6",
        height: 10,
        boxShadow: 'inset 3px 3px 5px rgba(0,0,0,0.8)',
        border: 'none',
        opacity: 1,
    },
    '& .MuiSlider-rail': {
        opacity: 1,
        backgroundColor: '#BFBFBF',
        borderRadius: 20 / 2,
        height: 10,
        boxShadow: 'inset 0px 2px 4px rgba(0,0,0,0.9)',
    },
    '& .MuiSlider-active': {
        color: "gray"
    },
    '& .MuiSlider-markLabel': {
        color: "#BFBFBF",
        fontSize: '16px',
        fontFamily: "LynkcoTypeMedium",
    },

};

const RVRangeCard = ({ setQueryParamsRvRange, setDataLoaded }) => {

    const [queryParamsURvRange, setQueryParamsRvRangeCard] = useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    });

    const [value, setValue] = React.useState([10000, 60000]);

    const handleChange = (event, newValue) => {

        if (newValue[0] !== 10000 && newValue[1] === 60000) {
            setQueryParamsRvRange({ ...queryParamsURvRange, [`lessThan`]: false, [`greaterThan`]: false, [`between`]: true, [`startValue`]: newValue[0].toString(), [`endValue`]: '60000' });
            setDataLoaded(false);
        }
        if (newValue[0] !== 10000 && newValue[1] !== 60000) {
            setQueryParamsRvRange({ ...queryParamsURvRange, [`lessThan`]: false, [`greaterThan`]: false, [`between`]: true, [`startValue`]: newValue[0].toString(), [`endValue`]: newValue[1].toString() });
            setDataLoaded(false);
        }

        if (newValue[0] == 10000 && newValue[1] !== 60000) {
            setQueryParamsRvRange({ ...queryParamsURvRange, [`lessThan`]: false, [`greaterThan`]: false, [`between`]: true, [`startValue`]: '10000', [`endValue`]: newValue[1].toString() });
            setDataLoaded(false);
        }

        if (newValue[0] == 10000 && newValue[1] == 60000) {
            setQueryParamsRvRange({ ...queryParamsURvRange, [`lessThan`]: false, [`greaterThan`]: false, [`between`]: false, [`startValue`]: '0', [`endValue`]: '0' });
            setDataLoaded(false);
        }

        setValue(newValue);
    };

    let str = (value[0]).toString()
    const remStr = str.replace(/000/g, "");

    let str1 = (value[1]).toString()
    const remStr1 = str1.replace(/000/g, "");

    function valuetext(value) {
        return `${value}Month`;
    }

    const marks = [
        {
            value: value[0],
            label: `${Number(remStr)}k`
        },

        {
            value: value[1],
            label: `${Number(remStr1)}k`
        },
    ];

    return (
        <Card id="CardBodyCardAllowance1" style={{ marginBottom: '40px' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "16px", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF', }}>RV Range [Km]</span> <br /><br />
                </span>
                <Slider
                    valueLabelDisplay="auto"
                    marks={marks}
                    min={10000}
                    max={60000}
                    step={10000}
                    aria-labelledby="track-inverted-range-slider"
                    getAriaValueText={valuetext}
                    style={{ width: '10.6vw', marginLeft: '15px' }}
                    value={value}
                    onChange={handleChange}
                    sx={CustomSliderStyles}
                />
            </CardBody>
        </Card>
    )
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(RVRangeCard);