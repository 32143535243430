import React, { useState, useEffect } from 'react';
import { Card, CardBody } from "reactstrap";
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';

const CarsAtMCC = ({ handlePosition, setShowMCC, disableButtons }) => {
    useEffect(() => {
        if (!disableButtons) {
            setChecked(false);
        }
    }, [disableButtons])

    const [checked, setChecked] = useState(false);

    const switchHandler = (event) => {
        let value = event.target.checked;

        if (value == true) {
            handlePosition('MCC');
            setShowMCC(true);
        } else {
            handlePosition('false');
            setShowMCC(false);
        }

        setChecked(event.target.checked);
    };

    return (
        <Card id="CardBodyCardAllowance1" style={{ marginBottom: '40px' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "15px", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF' }}>CARS AT MCC</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white' }}>
                    <tr>
                        <td style={{ float: 'left', fontSize: '34px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray' }}>{274}<br /></td>
                        <td style={{ float: 'right' }}>
                            <ToggleComponent checked={checked} disabled={!disableButtons} onChange={switchHandler} />
                        </td>
                    </tr>
                </table>
            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(CarsAtMCC);