import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import M2MTable from 'src/pages/Tables/DataTable/components/M2MTable';
import CashCarsTable from 'src/pages/Tables/DataTable/components/CashCarsTable';
import UndefinedStockTable from 'src/pages/Tables/DataTable/components/UndefinedStockTable';
import { Typography } from '@material-ui/core';
import { permission } from 'src/auth/permissions';
import { selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';
import DefaultPage from 'src/pages/backgrounds/default-page';
import MyOpenCasesTable from 'src/pages/Tables/DataTable/components/MyOpenCasesTable';
import AssignedCases from 'src/pages/Tables/DataTable/components/AssignedCases';
import UnassignedCases from 'src/pages/Tables/DataTable/components/UnassignedCases';
import ResolvedCases from 'src/pages/Tables/DataTable/components/ResolvedCases';

const TabPanel = (props) => {
  const { children, userPermissions, userToken, value, index, ...other } = props;
  const values = [permission.Vehicle_Administrator, permission.Vehicle_Reader];
  const matchesPermission = values.some(permission => userPermissions.includes(permission));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {
        matchesPermission ?
          <>
            {value === index && value === 0 && (
              <MyOpenCasesTable
                value={value}
              />
            )}
            {value === index && value === 1 && (
              <UnassignedCases
                value={value}
              />
            )}
            {value === index && value === 2 && (
              <AssignedCases
                value={value}
              />
            )}
            {value === index && value === 3 && (
              <ResolvedCases
                value={value}
              />
            )}
          </>
          :
          <>
            {value === index && value === 0 && (
              <DefaultPage />
            )}
            {value === index && value === 1 && (
              <DefaultPage />
            )}
            {value === index && value === 2 && (
              <DefaultPage />
            )}
            {value === index && value === 2 && (
              <DefaultPage />
            )}
          </>
      }
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
    margin: -theme.spacing(2.9),
    height: '55px',
    marginLeft: '115px'
  },
  panel: {
    // marginLeft: '135px',
    position: "fixed",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    padding: "55px",
    backgroundImage: 'linear-gradient(60deg, rgba(13,13,13,1), rgba(37,37,37,1) 99%)'
  },

  tabBar: {
    marginRight: theme.spacing(-22),
    marginLeft: theme.spacing(22.45),
    textTransform: 'initial',
    fontSize: '15px',
  },
  appBar: {
    backgroundColor: "#131313",
    position: 'fixed',
    marginTop: '-70px',
    marginLeft: '-220px',
    zIndex: '20',
    height: '48px',
    width: '100%',
    display: 'flex',
    boxShadow: '0 5px 6px #000000'
  }
}));

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#1EF1C6',
    height: '3px',
    maxWidth: '110px',
    marginLeft: '25px'
  }
})(Tabs);

const VehicleTabs = ({ userPermissions, userToken }) => {

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Typography style={{ textAlign: 'center', fontSize: '15px', marginTop: '15px', marginLeft: '40px', fontFamily: "LynkcoTypeRegular", cursor: 'pointer', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', letterSpacing: '2px' }} variant="h4" id="tableTitle" component="div">
          CASES
        </Typography>
        <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="My Open Cases" {...a11yProps(0)} className={classes.tabBar} />
          <Tab label="Unassigned Cases" {...a11yProps(1)} className={classes.tabBar} />
          <Tab label="Assigned Cases" {...a11yProps(2)} className={classes.tabBar} />
          <Tab label="Resolved Cases" {...a11yProps(3)} className={classes.tabBar} />
        </StyledTabs>
      </AppBar>
      <TabPanel
        value={value}
        index={0}
        className={classes.panel}
        userPermissions={userPermissions}
        userToken={userToken}
      >
        Undefined Stock
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        className={classes.panel}
        userPermissions={userPermissions}
        userToken={userToken}
      >
        {'L&C Assets'}
      </TabPanel>
      <TabPanel
        value={value}
        index={2}
        className={classes.panel}
        userPermissions={userPermissions}
        userToken={userToken}
      >
        Sold Cars
      </TabPanel>
      <TabPanel
        value={value}
        index={3}
        className={classes.panel}
        userPermissions={userPermissions}
        userToken={userToken}
      >
        Sold Cars
      </TabPanel>
    </div>
  );
};

const mapStateToProps = state => ({
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(VehicleTabs);