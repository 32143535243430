import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Row, Col } from "reactstrap";
import Chart from "react-apexcharts";

const CarSwap = () => {
  const optionsvisitors = {
    labels: ["6 months", "5 months", "4 months", "3 months"],
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
      borderColor: "transparent",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "82px",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "18px",
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: true,
              color: "#99abb4",
            },
            total: {
              show: true,
              label: "Total",
              color: "#99abb4",
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: "transparent",
    },
    legend: {
      show: false,
    },
    colors: ["#eceff1", "#1ef1c6", "#9437ff", "#fd7e14"],
    tooltip: {
      fillSeriesColor: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
        },
      },
    ],
  };
  const seriesvisitors = [47, 31, 23, 11];
  return (
    /*--------------------------------------------------------------------------------*/
    /* Used In Remarketing Dashboard                                                  */
    /*--------------------------------------------------------------------------------*/
    <Card
      className="w-100"
      style={{
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        boxShadow: '0 5px 6px #000000',
      }}
    >      <CardBody>
        {/* <CardTitle>Used car stock at LCC</CardTitle>
        <CardSubtitle className="mb-3">
          VINs assigned to used car stock and available at LCC
        </CardSubtitle> */}

        <Row  >
          <Col lg="12" md="12" >
            <CardTitle style={{ marginTop: '-3px', fontSize: "1.2vw", fontWeight: 600 }}>Car Swap Triggered by RV</CardTitle>
            <div style={{ fontSize: '30px', color: '#d8d8d8', cursor: 'pointer', marginLeft: '92%', marginTop: '-43px', }} >
              <span>
                <i className="mdi mdi-filter-variant" />
              </span>
            </div>
          </Col>
        </Row>
        <div>
        </div>
        <CardSubtitle className="mb-3" style={{ fontSize: "0.85vw", marginTop: '-12px' }}>
          Cars with Residual Value reaching Landed Cost within 3 - 6 months
        </CardSubtitle>
        <Chart
          options={optionsvisitors}
          series={seriesvisitors}
          type="donut"
          height="255px"
        />
      </CardBody>
      <div>
        <hr className="mt-0 mb-0" />
      </div>
      <CardBody>
        <div className="d-flex no-block align-items-center justify-content-center">
          <div>
            {/* <h6 className="text-blue">
              <i className="fa fa-circle font-10 mr-2" />
              Proposed
            </h6> */}
          </div>
          <div className="ml-3">
            <h6 className="text-white">
              <i className="fa fa-circle font-10 mr-2" />
              6 months
            </h6>
          </div>
          <div className="ml-3">
            <h6 style={{ color: '#1ef1c6' }}>
              <i className="fa fa-circle font-10 mr-2" />
              5 months
            </h6>
          </div>
          <div className="ml-3">
            <h6 className="text-primary">
              <i className="fa fa-circle font-10 mr-2" />
              4 months
            </h6>
          </div>
          <div className="ml-3">
            <h6 className="text-info">
              <i className="fa fa-circle font-10 mr-2" />
              3 months
            </h6>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default CarSwap;