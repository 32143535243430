import React, { Fragment } from "react";
import { Input, CustomInput, } from "reactstrap";
//import todoDetails from "../../redux/todoDetails";
import { useSelector, useDispatch } from "react-redux";
import { connect } from 'react-redux';
import { selectUserGraphData } from 'src/redux/user/user.selectors';
import { DeleteSelectedTodo, TransformTodoData } from 'src/utils/TodoFormatter';
import { SaveTodo, fetchUserTodos } from 'src/services/todo-http.service';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { updateTodo, todoCompleted, fetchTodosSuccess } from "../../redux/todos/Action";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#dc1860',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#893257',
      color: '#FFF'
    },
    marginTop: '25px'
  }
}));


const TodoDetails = ({ user }) => {
  const classes = useStyles();

  let userId = user.id
  const todoData = useSelector((state) => state.maintodoReducer.todos);

  const todoDetails = useSelector((state) => state.maintodoReducer.todoDetails);
  const selectedTodos = useSelector(
    (state) =>
      todoData[todoDetails - 1]
  );

  const dispatch = useDispatch();

  return (
    <Fragment>
      {selectedTodos && !selectedTodos.deleted ? (
        <div className="p-4">
          <div>
            <CustomInput
              type="checkbox"
              id={selectedTodos.id}
              checked={selectedTodos.isCompleted}
              label=" "
              onChange={() => dispatch(todoCompleted(selectedTodos.id))}
            />
            <h5 className="mt-4">Task title</h5>
            <Input
              type="text"
              id="title"
              name="title"
              rows="5"
              value={selectedTodos.title}
              onChange={(e) =>
                dispatch(updateTodo(selectedTodos.id, "title", e.target.value))
              }
            />
            <h5 className="mt-4">Task Description</h5>
            <Input
              type="textarea"
              id="title"
              name="title"
              rows="5"
              value={selectedTodos.desc}
              onChange={(e) =>
                dispatch(updateTodo(selectedTodos.id, "desc", e.target.value))
              }
            />
            <Button            
              onClick={() => {
                SaveTodo(userId, DeleteSelectedTodo(selectedTodos, todoData, selectedTodos.id))
                  .then(data => {
                    if (data && data.tableData && Array.isArray(data.tableData)) {
                      dispatch(fetchTodosSuccess(TransformTodoData(data.tableData)));
                    }
                  })
              }}
              className={classes.button}
              type="submit"
            >
              Delete
            </Button>
          </div>
        </div>
      ) : (
        <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
          <i className="far fa-address-card display-5"></i>
          <h4 className="mt-2">Please select a Todo to see details.</h4>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  user: selectUserGraphData(state),
});

export default connect(mapStateToProps, null)(TodoDetails)