import React, { useState } from "react";
import { Row, Col } from "reactstrap";

import {
  VisitrateView,
  TotalVisits,
  BrowserStats,
  TotalRevenue,
  SalesPd,
  MyCalendar,
  Chat,
  Messages,
} from "../../components/dashboard/index.js";
import DefaultPage from 'src/pages/backgrounds/default-page';
import MarketSelect from 'src/components/form-elements/market-select.dashboard.component';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';


const VehicleInsights = ({ userOrg, userMarketIds }) => {

  const [isMarketSelected, setIsMarkedSelected] = useState(false)

  let selectedMarket = userMarketIds.toString()

  let marketId = 'ALL'

  const handleChange = (event, markets) => {
    const { name, value } = event.target;
    let orgsMarkets = [];
    marketId = value.split('#')[0];
    let marketNmae = value.split('#')[1];

    if (marketId == 'ALL') { selectedMarket = userMarketIds }
    else {
      selectedMarket = [marketId]
      setIsMarkedSelected(true)
    }
  }

  return (
    <div style={{ overflow: 'auto', height: '96vh', backgroundColor: 'rgb(19 19 19 / 95%)', marginTop: '-170px', padding: '40px 0 10px 0', marginLeft: '-20px', marginRight: '-26px' }}>
      <DefaultPage backgroundtype={true} />
      <MarketSelect
        orgId={userOrg.id}
        value={selectedMarket.value}
        userMarketIds={userMarketIds}
        handleChange={handleChange}
      />

      <VisitrateView />
      {/* --------------------------------------------------------------------------------*/}
      {/* Row-1                                                                          */}
      {/* --------------------------------------------------------------------------------*/}
      <Row>
        <Col lg="8">
          <TotalVisits />
        </Col>
        <Col lg="4">
          <BrowserStats />
        </Col>
      </Row>
      {/* --------------------------------------------------------------------------------*/}
      {/* Row-2                                                                          */}
      {/* --------------------------------------------------------------------------------*/}
      <Row>
        <Col lg="8">
          <TotalRevenue />
        </Col>
        <Col lg="4">
          <SalesPd />
        </Col>
      </Row>
      {/* --------------------------------------------------------------------------------*/}
      {/* Row-3                                                                          */}
      {/* --------------------------------------------------------------------------------*/}
      <Row>
        <Col lg="12">
          <MyCalendar />
        </Col>
      </Row>
      {/* --------------------------------------------------------------------------------*/}
      {/* Row-4                                                                          */}
      {/* --------------------------------------------------------------------------------*/}
      <Row>
        <Col lg="6">
          <Chat />
        </Col>
        <Col lg="6">
          <Messages />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  userOrg: selectUserOrganization(state),
  userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(VehicleInsights);