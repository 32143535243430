import { loginRequest, profileRequest, graphConfig } from "src/authConfig";
import { msalInstance } from "src/index";

export async function getSignedInUserPhoto_Beta() {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    return (
      <div>
        No active account! Verify a user has been signed in and setActiveAccount
        has been called.
      </div>
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...profileRequest,
    account: account,
  });
  console.log(response);

  const headers = new Headers();
  const bearer = `Bearer ${response.accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
    responseType: "blob",
  };

  return await fetch(graphConfig.graphMePhotoEndpoint, options)
    .then((response) => {
      if (response.status === 404) {
        return { status: false }
      }
      return { status: true, blob: response.blob() };
    })
    .catch((err) => {
      console.error(err);
      return { status: false }
    }

    );
  // .then(response => { return { status: response.status, body: response.body } })
  // .then(stream => new Response(stream.body, { "status": stream.status }))
  // .then(response => response.status === 200 ? response.blob() : response)
  // .then(blob => blob.status ? blob : { status: response.status, url: URL.createObjectURL(blob) })
  // .catch(err => console.error(err));
}

export async function callMsGraphPhoto() {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    return (
      <div>
        No active account! Verify a user has been signed in and setActiveAccount
        has been called.
      </div>
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });

  const headers = new Headers();
  const bearer = `Bearer ${response.accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMePhotoEndpoint, options)
    .then((response) => {
      return { status: response.status, body: response.body };
    })
    .then((stream) => new Response(stream.body, { status: stream.status }))
    .then((response) => (response.status === 200 ? response.blob() : response))
    .then((blob) =>
      blob.status
        ? blob
        : { status: response.status, url: URL.createObjectURL(blob) }
    )
    .catch((error) => console.log(error));
}

export async function callMsGraph() {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    return (
      <div>
        No active account! Verify a user has been signed in and setActiveAccount
        has been called.
      </div>
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });

  const headers = new Headers();
  const bearer = `Bearer ${response.accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

// export async function callMsGraph() {
//   const account = msalInstance.getActiveAccount();

//   if (!account) {
//     return (
//       <div>
//         No active account! Verify a user has been signed in and setActiveAccount
//         has been called.
//       </div>
//     );
//   }

//   const authRequest = {
//     ...loginRequest
//   };

//   const response = await msalInstance.ssoSilent(authRequest)({
//     ...loginRequest,
//     account: account,
//   });

//   const headers = new Headers();
//   const bearer = `Bearer ${response.accessToken}`;

//   headers.append("Authorization", bearer);

//   const options = {
//     method: "GET",
//     headers: headers,
//   };

//   return fetch(graphConfig.graphMeEndpoint, options)
//     .then((response) => response.json())
//     .catch((error) => console.log(error));
// }
