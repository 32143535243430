import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import UserTable from 'src/pages/Tables/DataTable/indexUser';
import RoleTable from 'src/pages/Tables/DataTable/indexRole';
import PermissionPage from 'src/pages/permission-page/PermissionPage';
import DefaultPage from 'src/pages/backgrounds/default-page';
import { selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';
import { permission } from 'src/auth/permissions';
import { Typography } from '@material-ui/core';
import InternalFleetsTable from 'src/pages/Tables/DataTable/components/InternalFleetsTable';
import OrganizationTable from 'src/pages/Tables/DataTable/components/OrganizationTable';
import FleetTable from 'src/pages/Tables/DataTable/components/FleetTable';

function TabPanel(props) {
  const { userPermissions, userToken, children, value, index, ...other } = props;
  const values = [permission.Administration_Administrator, permission.Administration_Reader, permission.ManageAsset_Administrator, permission.ManageAsset_Reader];
  const matchesPermission = values.some(permission => userPermissions.includes(permission))
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {matchesPermission ? (
        <>
          {value === index && value === 0 && (
            <UserTable />
          )}
          {value === index && value === 1 && (
            <RoleTable />
          )}
          {value === index && value === 2 && (
            <PermissionPage />
          )}
          {value === index && value === 3 && (
            <OrganizationTable />
          )}
          {value === index && value === 4 && (
            <FleetTable />
          )}
          {value === index && value === 5 && (
            <InternalFleetsTable />
          )}
        </>) : (
        <>
          {value === index && value === 0 && (
            <DefaultPage />
          )}
          {value === index && value === 1 && (
            <DefaultPage />
          )}
          {value === index && value === 2 && (
            <DefaultPage />
          )}
          {value === index && value === 3 && (
            <DefaultPage />
          )}
          {value === index && value === 4 && (
            <DefaultPage />
          )}
          {value === index && value === 5 && (
            <DefaultPage />
          )}
        </>)}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
    margin: -theme.spacing(2.9),
    height: '55px',
    marginLeft: '115px'
  },
  panel: {
    marginLeft: '35px',
    position: "fixed",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    // backgroundSize: "cover",
    // backgroundPosition: "center center",
    // backgroundRepeat: "no-repeat",
    padding: "55px",
    backgroundImage: 'linear-gradient(60deg, rgba(13,13,13,1), rgba(37,37,37,1) 99%)',
  },
  tabBar: {
    marginRight: theme.spacing(-22),
    marginLeft: theme.spacing(22.5),
    textTransform: 'initial',
    fontSize: '15px',

  },
  appBar: {
    backgroundColor: "#131313",
    position: 'fixed',
    marginTop: '-100px',
    marginLeft: '-180px',
    zIndex: '20',
    height: '48px',
    boxShadow: '0 5px 6px #000000'
  }
}));

const StyledTabs = withStyles({

  indicator: {
    backgroundColor: '#1EF1C6',
    height: '3px',
    maxWidth: '110px',
    marginLeft: '25px',
    "&.Mui-clicked": {
      backgroundColor: "#2c2c2c",
      color: "white",
      borderBottomStyle: 'solid',
      borderTopStyle: 'solid',
      borderWidth: '0.1px',
      borderColor: '#BFBFBF',
    },
  },
})(Tabs);

const AdministrationTabs = ({ userPermissions, userToken }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Typography style={{ textAlign: 'center', fontSize: '15px', marginTop: '15px', marginLeft: '35px', fontFamily: "LynkcoTypeRegular", cursor: 'pointer', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', letterSpacing: '2px' }} variant="h4" id="tableTitle" component="div">
          ADMIN
        </Typography>
        <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Users" {...a11yProps(0)} className={classes.tabBar} />
          <Tab label="Roles" {...a11yProps(1)} className={classes.tabBar} />
          <Tab label="Permissions" {...a11yProps(2)} className={classes.tabBar} />
          <Tab label="Organizations" {...a11yProps(3)} className={classes.tabBar} />
          <Tab label="Fleets" {...a11yProps(4)} className={classes.tabBar} />
          <Tab label="Internal Assets" {...a11yProps(5)} className={classes.tabBar} />

        </StyledTabs>

      </AppBar>
      <TabPanel value={value} index={0} className={classes.panel} userPermissions={userPermissions} userToken={userToken}>
        Users
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.panel} userPermissions={userPermissions} userToken={userToken}>
        Roles
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.panel} userPermissions={userPermissions} userToken={userToken}>
        Permissions
      </TabPanel>
      <TabPanel value={value} index={3} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
        Organizations
      </TabPanel>
      <TabPanel value={value} index={4} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
        Fleets
      </TabPanel>
      <TabPanel value={value} index={5} className={classes.panel} userPermissions={userPermissions} userToken={userToken} >
        Internal Assets
      </TabPanel>
    </div>
  );
};

const mapStateToProps = state => ({
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(AdministrationTabs);