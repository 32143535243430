// import React from 'react';
// import { MsalAuthenticationTemplate, withMsal } from "@azure/msal-react";
// import { InteractionType } from "@azure/msal-browser";
// import { loginRequest } from "src/authConfig";

// import { Error } from "src/pages/ErrorPage"
// import Layout from 'src/containers/Layout/NewLayout'
// import {AuthProgress} from 'src/pages/auth-progress-page/auth-progress'

// class MasterLayout extends React.Component {

//     render() {

//         const authRequest = {
//             ...loginRequest};
//             //// TODO Remove Commented code before merging to development
//         return (
//             <MsalAuthenticationTemplate 
//                 interactionType={InteractionType.Redirect} 
//                 authenticationRequest={authRequest} 
//                 errorComponent={Error} 
//                 loadingComponent={AuthProgress}
//             >
//                 <Layout msalContext={this.props.msalContext}/>
//             </MsalAuthenticationTemplate>
//             //<Layout msalContext={this.props.msalContext}/>
//         );
//     }
//   }

//   export const LayoutWithMsal = withMsal(MasterLayout);



import React, { useEffect } from 'react';
import { MsalAuthenticationTemplate, withMsal, useMsal, useAccount } from "@azure/msal-react";


import { InteractionRequiredAuthError } from "@azure/msal-common";

import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "src/authConfig";
import { Error } from "src/pages/ErrorPage"
import Layout from 'src/containers/Layout/NewLayout'
import { AuthProgress } from 'src/pages/auth-progress-page/auth-progress';
import { msalInstance } from "src/index";
import LoginPage from "src/pages/loading-page/LoginPage";
import { callMsGraph } from "src/services/graph.service";



// class MasterLayout extends React.Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             ...props,
//             response: {}
//         };
//     };

//     // componentWillMount = () => {
//     //     const authRequest = {
//     //         ...loginRequest
//     //     };

//     //     const account = msalInstance.getActiveAccount();

//     //     try {
//     //         const loginResponse = () =>  msalInstance.ssoSilent(authRequest);
//     //         let x = loginResponse()
//     //         x.then(result => {
//     //             // let authResponse = result
//     //             console.log('***********_________________result______----result---result---result---', result)

//     //             this.setState({ 
//     //                 response: result
//     //               }, 
//     //               () => console.log('-----------____________________________-----------------________________________________', this.state.response),
//     //             )
//     //         });

//     //     } catch (err) {
//     //         alert('hello')
//     //         if (err instanceof InteractionRequiredAuthError) {

//     //             const loginResponse = async () => await msalInstance.loginRedirect(authRequest).catch(error => {

//     //             });
//     //         } else {

//     //         }
//     //     }
//     //   }
//      render() {
//         // const [response, setResponse] = React.useState(0);

//         console.log('***********this.state.response}----result---this.state.response}---this.state.response}---', this.state.response)

//         //// TODO Remove Commented code before merging to development
//         return (

//             // <MsalAuthenticationTemplate
//             //     interactionType={InteractionType.Redirect}
//             //     authenticationRequest={authRequest}
//             //     errorComponent={LoginPage}
//             //     loadingComponent={AuthProgress}
//             // >

//             //     <Layout msalContext={this.props.msalContext} />
//             //     {/* <LoginPage /> */}
//             // </MsalAuthenticationTemplate>

//             // <Layout response={this.state.response} />
//             <>
//                 <AuthenticatedTemplate>
//                 <Layout response={this.state.response} msalContext={this.props.msalContext} />
//                 {/* <LoginPage />  */}
//                 </AuthenticatedTemplate>

//                 <UnauthenticatedTemplate>
//                 {/* <Layout response={this.state.response} msalContext={this.props.msalContext} /> */}
//                 <LoginPage /> 
//                 </UnauthenticatedTemplate>

//             </>

//             // <MsalAuthenticationTemplate
//             //     interactionType={InteractionType.Redirect}
//             //     authenticationRequest={authRequest}
//             //     errorComponent={Error}
//             //     loadingComponent={AuthProgress}
//             // >

//             //     <React.Fragment>
//             //         {
//             //             account && (
//             //                 <Layout msalContext={this.props.msalContext} />
//             //             )
//             //         }
//             //         {
//             //             !account && (

//             //                 <LoginPage />
//             //             )
//             //         }
//             //     </React.Fragment>
//             // </MsalAuthenticationTemplate>

//             // <Layout msalContext={this.props.msalContext}/>
//         );
//     }
// }

const MasterLayout = (instance) => {

    const [showLoggin, setShowLoggin] = React.useState(true);

    const accounts = instance.msalContext.accounts

    if (accounts.length > 0) {
        return (
            <>
                <Layout msalContext={instance.msalContext} />
            </>
        );
    }
    else if (instance.msalContext.inProgress === "login") {
        // alert('hello')
        return <div className="black-background">Login is currently in progress!</div>
    }
    else {

        return <LoginPage />
    }
}

export const LayoutWithMsal = withMsal(MasterLayout);
