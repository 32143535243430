import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import EnhancedVehicleTableToolbar from 'src/components/tables/assetmovement-table-toolbar.component'
import clsx from 'clsx';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { QueryVehiclesInProductVariant } from 'src/services/sales-settings-http.service';
import SimpleAlertDialog from 'src/components/form-elements/simple-dialog-window.component';
import ReactTableBasePendingForVin from 'src/components/tables/newtable/table/ReactTableBasePendingForVin';

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const useDrawerStyles = makeStyles((theme) => ({
    content: {

        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: 2,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 580,
        display: 'flex'
    },
}));

const VInListTable = ({ userMarketIds, handleClose, variantName }) => {

    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [selected, setSelected] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [rows, setVehicleFleet] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState({});
    let selectedRowVehiclePostion = {}
    const [isEnableSave, setIsEnableSave] = React.useState(false);
    const [isColumnHidden, setIsColumnHidden] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');

    const drawerClasses = useDrawerStyles();

    const columns = useMemo(
        () => [
            { Header: 'License Plate', accessor: "licensePlate", disableGlobalFilter: true },
            { Header: 'VIN', accessor: "vin", disableGlobalFilter: true },
            { Header: 'Odometer', accessor: "odometerKm", disableGlobalFilter: true },
            { Header: 'RV [gross]', accessor: "rvGross", disableGlobalFilter: true },
            { Header: 'Age [Months]', accessor: "age", disableGlobalFilter: true },
            { Header: 'Inventory Status', accessor: "inventoryState", disableGlobalFilter: true },
            { Header: 'Below Landed Cost', accessor: "belowLandedCost", disableGlobalFilter: true }
        ],
        [],
    );

    React.useEffect(() => {
        initializeFleetVehicles();
    }, []);

    const initializeFleetVehicles = () => {
        let vehicleFleetArray = [];
        let variantIdOfInterest = variantName
        let includeOtherMarketVariantVehicles = false
        let marketIds = userMarketIds

        QueryVehiclesInProductVariant(variantIdOfInterest, includeOtherMarketVariantVehicles, marketIds)
            .then(data => {
                if (data.ok == true) {
                    if (data.vehicles && Array.isArray(data.vehicles)) {
                        data.vehicles.map(vehicle => {
                            vehicleFleetArray.push(
                                {
                                    licensePlate: vehicle.licensePlate,
                                    vin: vehicle.vin,
                                    odometerKm: vehicle.odometerKm,
                                    rvGross: vehicle.rvGross,
                                    age: vehicle.age,
                                    inventoryState: vehicle.inventoryState,
                                }
                            );
                        });
                        setVehicleFleet(vehicleFleetArray);
                    }
                }
                else {
                    setOpenDialog(true)
                    setDialogMessage(data.errorMessage)
                }
            })
    }


    const handleClickDownload = () => {
        let vehicleFleetArray = []
        let variantIdOfInterest = variantName
        let includeOtherMarketVariantVehicles = false
        let marketIds = userMarketIds

        QueryVehiclesInProductVariant(variantIdOfInterest, includeOtherMarketVariantVehicles, marketIds)
            .then(data => {
                if (data.ok == true) {
                    if (data.vehicles && Array.isArray(data.vehicles)) {
                        data.vehicles.map(vehicle => {
                            vehicleFleetArray.push(
                                {
                                    licensePlate: vehicle.licensePlate,
                                    vin: vehicle.vin,
                                    odometerKm: vehicle.odometerKm,
                                    rvGross: vehicle.rvGross,
                                    age: vehicle.age,
                                    inventoryState: vehicle.inventoryState,
                                }
                            );
                        });
                        initiateDownload(vehicleFleetArray);
                    }
                }
            })
    }

    const initiateDownload = (rows) => {

        const fileName = 'Variant Report';

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const fileExtension = '.xlsx';

        const header = [

            [
                'License Plate',
                'VIN',
                'Odometer',
                'RV [gross]',
                'Age [Months]',
                'Inventory Status'
            ]

        ]

        const exportToExcel = (rows, fileName) => {

            const ws = XLSX.utils.book_new();

            XLSX.utils.sheet_add_aoa(ws, header);

            XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });

            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            const data = new Blob([excelBuffer], { type: fileType });

            FileSaver.saveAs(data, fileName + fileExtension);

        }

        exportToExcel(rows, fileName)

    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const handleClickIsEditable = () => {
        setIsEnableSave(true);
        setIsColumnHidden(true);
        initializeFleetVehicles();
    };


    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setVehicleFleet(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setVehicleFleet(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    const selectedRowValues = (row) => {
        setSelectedRow({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            age: row.values.age,
            inventoryState: row.values.inventoryState

        });
        setSelected({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            age: row.values.age,
            inventoryState: row.values.inventoryState
        })
        selectedRowVehiclePostion = {
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            age: row.values.age,
            inventoryState: row.values.inventoryState
        }
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by Licence plate...',
    };

    const handleDrawar = () => {
        let fleetIds = []
        let marketIds = userMarketIds;
        let vin = selectedRowVehiclePostion.vin
        let vehiclePositionInstance = {
            "vin": "",
            "latitude": 0,
            "longitude": 0,
            "altitude": 0,
            "canBeTrusted": false,
            "vehicleUpdatedAt": ""
        }
    }

    const handleDialogOk = () => {
        setOpenDialog(false);
    }

    return (
        <Col md={12} lg={12}>
            <Card style={{ backgroundColor: 'rgb(19 19 19 / 97%)', marginTop: '-15px', borderRadius: '1px' }} className={clsx(drawerClasses.content, {
                [drawerClasses.contentShift]: open,
            })}>
                <SimpleAlertDialog
                    open={openDialog}
                    message={dialogMessage}
                    handleDialogOk={handleDialogOk}
                    isErrorDialog={false}
                />
                <CardBody>
                    <div style={{ marginRight: '-85px' }}>
                        <EnhancedVehicleTableToolbar selectedRowId={selected}
                            handleClickDownload={handleClickDownload}
                            handleClickIsEditable={handleClickIsEditable}
                            isEnableSave={isEnableSave}
                            handleClose={handleClose}
                        />
                    </div>
                    <div className="react-table__wrapper" style={{ marginTop: '-40px', marginBottom: '30px' }}>
                        <div className="card__title" style={{ marginLeft: '9px', fontSize: '18px', textTransform: 'initial' }}>
                            VINs for Product Variant: {variantName}
                        </div>
                    </div>
                    <ReactTableBasePendingForVin
                        key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                        columns={columns}
                        data={rows}
                        updateEditableData={updateEditableData}
                        updateDraggableData={updateDraggableData}
                        tableConfig={tableConfig}
                        isColumnHidden={isColumnHidden}
                        isSelected={isSelected}
                        selectedRowValues={selectedRowValues}
                        handleDrawar={handleDrawar}
                        selected={selected}
                    />
                </CardBody>
            </Card>
        </Col>
    );
};

VInListTable.propTypes = {
    reactTableData: PropTypes.shape({
        tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
        })),
        tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
        defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
        defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(VInListTable);