import React from 'react';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useToolbarStyles } from 'src/assets/jss/components/tableToolbarStyle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

export default function EnhancedTableToolbar(props) {
    const classes = useToolbarStyles();
    const { selectedRowId = '', handleClickDelete, handleClickEdit, handleClickAdd } = props;
    return (
        <Toolbar
            className={clsx(classes.root2, {
                [classes.highlight2]: selectedRowId.length > 0,
            })}
        >
            <Typography className={classes.title2} variant="h4" id="tableTitle" component="div">

            </Typography>
            <div className={classes.tooltipDiv}>
                <Tooltip className={classes.tooltipImg} title="Add" onClick={handleClickAdd}>
                    <span>

                        <IconButton aria-label="filter list">
                            <AddIcon style={{ width: '20px', height: '40px', marginBottom: '-200px' }} />
                        </IconButton>
                    </span>

                </Tooltip>
                <Tooltip className={classes.tooltipImg} title="Edit" onClick={handleClickEdit}>
                    <span>
                        <IconButton aria-label="edit">
                            <EditIcon style={{ width: '20px', height: '40px', marginBottom: '-200px' }} />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip className={classes.tooltipImg} title="Delete" onClick={handleClickDelete}>
                    <span>
                        <IconButton aria-label="delete">
                            <DeleteIcon style={{ width: '20px', height: '40px', marginBottom: '-200px' }} />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        </Toolbar>
    );
};

// EnhancedTableToolbar.propTypes = {
//     selectedRowId: PropTypes.string.isRequired,
//     handleClickAdd: PropTypes.func.isRequired,
//     handleClickDelete: PropTypes.func.isRequired,
//     handleClickEdit: PropTypes.func.isRequired,
// };