import styled from 'styled-components';
import { lighten } from 'polished';
import { Link } from 'react-router-dom';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { colorGray } from 'src/containers/LayoutNew/scrollBar/palette';
import img from 'src/assets/img/LynkCoLogo/Lynk&co_Logo_White.png'

export const TopbarContainer = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  top: 0;
  min-height: 60px;
  z-index: 101;
  // box-shadow: 0 2px 15px 0 rgba(19, 19, 19, 1.5);
  background: transparent;
  @media screen and (max-width: 376px) {
    min-height: 120px;
    overflow-x: hidden;
    width: 35%;
  }
`;

export const TopbarLogoContainer = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  top: 0;
  min-height: 60px;
  z-index: 101;
  // box-shadow: 0 2px 15px 0 rgba(19, 19, 19, 1.5);
  background: transparent;
  // background-image: linear-gradient(97deg, rgba(10, 10, 10, 1) 40%, rgba(37, 37, 37, 1) 99%);
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 376px) {
    min-height: 120px;
    align-items: center;
  justify-content: center;
  overflow-x: hidden;
  width: 35%;
  }
`;
export const TopbarLeft = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  margin-right 20px;
  
`;

export const TopbarRight = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  right: 0;
  margin-right: 15px;
  min-height: 120px;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media screen and (min-width: 376px) {
    min-height: auto;
    flex-wrap: nowrap;
  }
`;

export const TopbarRightOver = styled.div`
  display: flex;
  flex: auto;
  justify-content: space-between;
  height: 60px;
`;

export const TopbarLogo = styled(Link)`
  width: 110px;
  height: 18px;
  // margin: auto 30px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: contain;
  display: block;
  z-index: 3000;
  align-items: center;
  justify-content: center;
  display: flex;
  background-image: url(${img});

  @media screen and (min-width: 835px) {
    display: block;
  }
`;

export const LoginLogo = styled(Link)`
  width: 120px;
  height: 48px;
  margin: auto 30px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: contain;
  display: block;
  z-index: 3000;
  background-image: url(${img});

  @media screen and (min-width: 835px) {
    display: block;
  }
`;


export const TopbarSearchWrap = styled.div`
  margin: 0;
  display: flex;
  height: 60px;
  
  @media screen and (min-width: 576px) {
    margin: auto 0 auto 15px;
  }

  @media screen and (min-width: 640px) {
    margin-right: 15px;
  }
`;

export const TopbarButton = styled.button`
  font-size: 18px;
  height: 100%;
  min-width: 60px;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;
  color: #dddddd;

  &:hover {
    background-color: #38373f
  }

  svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: ${lighten(0.25, colorGray)};
  }

  @media screen and (max-width: 640px) {
    padding: 0 5px;
  }

  @keyframes beforePulse {

    from {
      width: 7px;
      height: 7px;
    }

    25% {
      width: 13px;
      height: 13px;
    }

    to {
      width: 7px;
      height: 7px;
    }
  }
`;

export const TopbarButtonNewLabel = styled.span`
  position: absolute;
  display: block;
  top: 20px;
  left: 9px;

  & > span {
    position: relative;
    display: block;

    &:before {
      background-color: rgba(224, 83, 111, 0.2);
      content: "";
      position: absolute;
      top: 50%;
      border-radius: 50%;
      animation: beforePulse 1.5s infinite;
      right: 50%;
      transform: translate;
    }

    &:after {
      height: 7px;
      width: 7px;
      background-color: #38373f;
      content: "";
      position: absolute;
      top: 50%;
      border-radius: 50%;
      right: 50%;
      transform: translate;
    }
  }

  @media screen and (max-width: 576px) {
    top: 10px;
  }
`;

export const TopbarBack = styled.button`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
`;

export const TopbarLinkIcon = styled.span`
  font-size: 13px;
  line-height: 13px;
  margin-left: 10px;
  color: #605f7b;
`;

export const TopbarLinkTitle = styled.p`
  display: flex;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
`;

export const TopbarDownIcon = styled(DownIcon)`
  height: 18px;
  margin: auto 0;
  fill: ${lighten(0.25, colorGray)};
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
