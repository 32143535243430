import { environment } from 'src/environments/environment';
import { msalInstance } from "src/index";
import { loginRequest } from "src/authConfig";
import { GetToken } from 'src/auth/userToken';

export async function UserProvisionedVehicleCheck(vin, customerSnowflakeId, isUserProvisioning) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    // headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            vin,
            customerSnowflakeId,
            isUserProvisioning
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/Provisioning/UserProvisionedVehicleCheck`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

////////////////////////////////////////////////// Provisioning Role Selection Drop Down

export async function GetRoles() {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendProvisioningService}/api/DriverRole/GetDriverRoles`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};


////////////////////////////////////////////  Get User provisioned to vehicle 

export async function GetUsersProvisionedToVehicle(vin) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendProvisioningService}/api/Provisioning/GetUsersProvisionedToVehicle?vin=${vin}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};


//////////////////////////////////////////////// Provisioning Vehicle

export async function ProvisionVehicle(vin, customerNumber, packageId, driverRoleId) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "vin": vin,
            "customerNumber": customerNumber,
            "packageId": packageId,
            "driverRoleId": driverRoleId
        })
    };

    let apiData = await fetch(`${environment.backendProvisioningService}/api/Provisioning/ProvisionUserToVehicle`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

////////////////////////////////////////////////// Unprovision Vehicle

export async function UnprovisionVehicle(vin, customerNumber, packageId, roleID) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "vin": vin,
            "customerNumber": customerNumber,
            "packageId": packageId,
            "roleID": roleID
        })
    };

    let apiData = await fetch(`${environment.backendProvisioningService}/api/Provisioning/UnprovisionUserFromVehicle`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

////////////////////////////////////// Who's Connected Page

export async function GetWhoIsConnectedData(vin) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "vin": vin
        })
    };

    let apiData = await fetch(`${environment.backendProvisioningService}/api/Provisioning/GetWhoIsConnectedData`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};