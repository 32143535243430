import React, { useState } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
import { FormTextFieldSales } from 'src/components/form-elements/custom-form-text-field.salessettings'
import { FormSelectSalesBordered } from 'src/components/form-elements/custom-form-select.SalesSettings.component';
import { SearchButtonSalesSettings } from 'src/components/buttons/search-icon-button.component';
import Tooltip from '@material-ui/core/Tooltip';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardText } from "reactstrap";
import classnames from 'classnames';
import MasterProductVariant from 'src/pages/sales-settings-page/MasterProductVariant';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { permission } from 'src/auth/permissions';

const UseStyles = makeStyles((theme) => ({
  dividerStyle: {
    backgroundColor: "rgba(222, 226, 230, 0.20)",
    marginLeft: theme.spacing(.3),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    height: '1.7px'
  },
  gridContainerStyle: {
    height: '50%',
    backgroundColor: "rgb(19 19 19 / 20%)",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'sticky',
  },
  heading: {
    "&.MuiTypography-root": {
      color: "#fff",
      fontSize: '0.8rem',
      fontFamily: 'LynkcoTypeLight'
    }
  },
  formElement: {
    color: "#ff0000",
    backgroundColor: "red",
    width: "50%",
    marginTop: "0px",
    marginBottom: "0px",
  },
  tooltipImg: {
    flexDirection: 'row',
    flexWrap: 'noWrap',
    marginTop: '65px',
    marginRight: '-20px'
  },
  tooltipImg1: {
    flexDirection: 'row',
    flexWrap: 'noWrap',
    marginTop: '68px',
  },
  tooltipImg2: {
    flexDirection: 'row',
    flexWrap: 'noWrap',
    marginTop: '60px',
    marginLeft: '0.7vw'

  },
  tooltipImg3: {
    flexDirection: 'row',
    flexWrap: 'noWrap',
    marginTop: '60px',
    marginLeft: '12.5vw'

  },
  tooltipImg4: {
    flexDirection: 'row',
    flexWrap: 'noWrap',
    marginTop: '60px',
    marginLeft: '14.5vw'

  },
  root: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),
    marginTop: '-60px'
  },
  root1: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: '-80px'
  },
  tooltipDiv: {
    display: 'flex',
  },
  highlight:
  {
    color: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    flex: '1 1 100%',
    fontSize: 'large'
  },
}));

const MasterBucket = (props) => {
  const { onPriceBucketChange, onPriceVariantChange, data, vehicleColors, handleNewVariantCreation, handleDeleteProductVariant, handleRefreshProductVariant, click, bucketName, variantName, userPermissions, userToken } = props
  const classes = UseStyles();
  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const matchesPermission = userPermissions.includes(permission.Remarketing_Administrator)

  return (
    <div >
      <Divider className={classes.dividerStyle} orientation='horizontal' />
      {/* <Row style={{ marginTop: '-4px' }} className={classes.gridContainerStyle}> */}
        <div className="tabs">
          <Nav tabs style={{ marginLeft: '-7px', marginTop: '10px' }}>
            <Col lg="3" md="12" >
              <NavItem style={{ marginBottom: '20px' }}>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => toggle('1')}
                  style={{ cursor: 'pointer' }}
                >
                  <SearchButtonSalesSettings priceBucketActiveTab={activeTab === '1'} placeholder={'Price bucket A'} />
                  <ProductBucketA
                    bucket={data != undefined ? data.priceBuckets.filter(item => item.name == 'A')[0] : undefined}
                    handleChange={onPriceBucketChange}
                    activeTab={activeTab === '1'}
                  />
                </NavLink>
              </NavItem>
            </Col>
            <Col lg="3" md="12" >
              <NavItem style={{ marginBottom: '20px',  }}>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => toggle('2')}
                  style={{ cursor: 'pointer' }}
                >
                  <SearchButtonSalesSettings priceBucketActiveTab={activeTab === '2'} placeholder={'Price bucket B'} />
                  <ProductBucketB
                    bucket={data != undefined ? data.priceBuckets.filter(item => item.name == 'B')[0] : undefined}
                    handleChange={onPriceBucketChange}
                    activeTab={activeTab === '2'}
                  />
                </NavLink>
              </NavItem>
            </Col>
            <Col lg="3" md="12" >
              <NavItem style={{ marginBottom: '20px',  }}>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => toggle('3')}
                  style={{ cursor: 'pointer' }}
                >
                  <SearchButtonSalesSettings priceBucketActiveTab={activeTab === '3'} placeholder={'Price bucket C'} />
                  <ProductBucketC
                    bucket={data != undefined ? data.priceBuckets.filter(item => item.name == 'C')[0] : undefined}
                    handleChange={onPriceBucketChange}
                    activeTab={activeTab === '3'}
                  />
                </NavLink>
              </NavItem>
            </Col>
            <Col lg="3" md="12" >
              <NavItem style={{ marginBottom: '20px',  }}>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => toggle('4')}
                  style={{ cursor: 'pointer' }}
                >
                  <SearchButtonSalesSettings priceBucketActiveTab={activeTab === '4'} placeholder={'Price bucket D'} />
                  <ProductBucketD
                    bucket={data != undefined ? data.priceBuckets.filter(item => item.name == 'D')[0] : undefined}
                    handleChange={onPriceBucketChange}
                    activeTab={activeTab === '4'}
                  />
                </NavLink>
              </NavItem>
            </Col>
          </Nav>
        </div>

        <TabContent activeTab={activeTab} style={{ marginTop: '40px', backgroundColor: "rgb(19 19 19 / 10%)" }}>
          <TabPane tabId="1">
            <Row style={{ marginTop: '-15px', marginLeft: '13px' }} >
              {
                data != undefined ? data.priceBuckets.filter(item => item.name == 'A')[0].variants.map(variant => {
                  return (<Col lg="3" md="12" >
                    <div style={{ marginBottom: '30px' }}>
                      <div style={{ width: '17vw', marginRight: '60vw' }}>
                        {matchesPermission ? (
                          <>
                            <Toolbar
                              className={clsx(classes.root)}>
                              <div className="mb-3" style={{ marginLeft: '3px' }}>
                                <Typography className={classes.title} variant="h5" id="tableTitle" component="div" />
                                <div className={classes.tooltipDiv}>
                                  <Tooltip onClick={handleRefreshProductVariant('A', variant.name)} className={classes.tooltipImg1} title="Refresh variant" style={{ fontSize: '20px', color: '#1EF1C6', cursor: 'pointer', marginTop: '59px', marginLeft: '-30px' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-refresh" />
                                    </span>
                                  </Tooltip>
                                  <Tooltip onClick={click('A', variant.name)} className={classes.tooltipImg3} title="Fetch VINs" style={{ fontSize: '19px', color: '#1EF1C6', cursor: 'pointer' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-information-outline" />
                                    </span>
                                  </Tooltip>
                                  <Tooltip onClick={handleDeleteProductVariant('A', variant.name)} className={classes.tooltipImg2} title="Remove variant" style={{ fontSize: '20px', color: '#DC1860', cursor: 'pointer' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-close" />
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            </Toolbar>
                          </>
                        ) : (
                          <>
                            <Toolbar
                              className={clsx(classes.root)}>
                              <div className="mb-3" style={{ marginLeft: '3px' }}>
                                <Typography className={classes.title} variant="h5" id="tableTitle" component="div" />
                                <div className={classes.tooltipDiv}>
                                  <Tooltip onClick={click('A', variant.name)} className={classes.tooltipImg4} title="Fetch VINs" style={{ fontSize: '19px', color: '#1EF1C6', cursor: 'pointer' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-information-outline" />
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            </Toolbar>
                          </>
                        )}

                      </div>
                      <div style={{ border: '1px solid rgba(255, 255, 255, 0.2)', height: '80%', marginTop: '-53px', width: '17vw', marginRight: '60vw', borderRadius: '5px' }}>
                        <MasterProductVariant
                          vehicleColor={variant.color != undefined ? variant.color : vehicleColors[variant.name]}
                          onChange={onPriceVariantChange}
                          bucketName={'A'}
                          variantName={variant.name}
                          variant={data != undefined ? variant : undefined}
                          click={click('A', variant.name)}
                        />
                      </div>
                    </div>
                  </Col>)
                }) : ''
              }

              {
                data != undefined ? data.priceBuckets.filter(item => item.name == 'A')[0].variants.length < 8 ?
                  <VariantCreation bucketName={'A'} onClick={handleNewVariantCreation} userPermissions={userPermissions} /> : '' : ''
              }
            </Row>

          </TabPane>
          <TabPane tabId="2">
            <Row style={{ marginTop: '-15px', marginLeft: '13px' }} >
              {
                data != undefined ? data.priceBuckets.filter(item => item.name == 'B')[0].variants.map(variant => {
                  return (<Col lg="3" md="12" >
                    <div style={{ marginBottom: '30px' }}>
                      <div style={{ width: '17vw', marginRight: '60vw' }}>
                        {matchesPermission ? (
                          <>
                            <Toolbar
                              className={clsx(classes.root)}>
                              <div className="mb-3" style={{ marginLeft: '3px' }}>
                                <Typography className={classes.title} variant="h5" id="tableTitle" component="div" />
                                <div className={classes.tooltipDiv}>
                                  <Tooltip onClick={handleRefreshProductVariant('B', variant.name)} className={classes.tooltipImg1} title="Refresh variant" style={{ fontSize: '20px', color: '#1EF1C6', cursor: 'pointer', marginTop: '59px', marginLeft: '-30px' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-refresh" />
                                    </span>
                                  </Tooltip>
                                  <Tooltip onClick={click('B', variant.name)} className={classes.tooltipImg3} title="Fetch VINs" style={{ fontSize: '19px', color: '#1EF1C6', cursor: 'pointer' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-information-outline" />
                                    </span>
                                  </Tooltip>
                                  <Tooltip onClick={handleDeleteProductVariant('B', variant.name)} className={classes.tooltipImg2} title="Remove variant" style={{ fontSize: '20px', color: '#DC1860', cursor: 'pointer' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-close" />
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            </Toolbar>
                          </>
                        ) : (
                          <>
                            <Toolbar
                              className={clsx(classes.root)}>
                              <div className="mb-3" style={{ marginLeft: '3px' }}>
                                <Typography className={classes.title} variant="h5" id="tableTitle" component="div" />
                                <div className={classes.tooltipDiv}>
                                  <Tooltip onClick={click('B', variant.name)} className={classes.tooltipImg4} title="Fetch VINs" style={{ fontSize: '19px', color: '#1EF1C6', cursor: 'pointer' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-information-outline" />
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            </Toolbar>
                          </>
                        )}
                      </div>
                      <div style={{ border: '1px solid rgba(255, 255, 255, 0.2)', height: '80%', marginTop: '-53px', width: '17vw', marginRight: '60vw', borderRadius: '5px' }}>
                        <MasterProductVariant
                          vehicleColor={variant.color != undefined ? variant.color : vehicleColors[variant.name]}
                          onChange={onPriceVariantChange}
                          bucketName={'B'}
                          variantName={variant.name}
                          variant={data != undefined ? variant : undefined}
                          click={click('B', variant.name)}

                        />
                      </div>
                    </div>
                  </Col>)
                }) : ''
              }

              {
                data != undefined ? data.priceBuckets.filter(item => item.name == 'B')[0].variants.length < 8 ?
                  <VariantCreation bucketName={'B'} onClick={handleNewVariantCreation} userPermissions={userPermissions} /> : '' : ''
              }
            </Row>
          </TabPane>

          <TabPane tabId="3">
            <Row style={{ marginTop: '-15px', marginLeft: '13px' }} >
              {
                data != undefined ? data.priceBuckets.filter(item => item.name == 'C')[0].variants.map(variant => {
                  return (<Col lg="3" md="12" >
                    <div style={{ marginBottom: '30px' }}>
                      <div style={{ width: '17vw', marginRight: '60vw' }}>
                        {matchesPermission ? (
                          <>
                            <Toolbar
                              className={clsx(classes.root)}>
                              <div className="mb-3" style={{ marginLeft: '3px' }}>
                                <Typography className={classes.title} variant="h5" id="tableTitle" component="div" />
                                <div className={classes.tooltipDiv}>
                                  <Tooltip onClick={handleRefreshProductVariant('C', variant.name)} className={classes.tooltipImg1} title="Refresh variant" style={{ fontSize: '20px', color: '#1EF1C6', cursor: 'pointer', marginTop: '59px', marginLeft: '-30px' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-refresh" />
                                    </span>
                                  </Tooltip>
                                  <Tooltip onClick={click('C', variant.name)} className={classes.tooltipImg3} title="Fetch VINs" style={{ fontSize: '19px', color: '#1EF1C6', cursor: 'pointer' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-information-outline" />
                                    </span>
                                  </Tooltip>
                                  <Tooltip onClick={handleDeleteProductVariant('C', variant.name)} className={classes.tooltipImg2} title="Remove variant" style={{ fontSize: '20px', color: '#DC1860', cursor: 'pointer' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-close" />
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            </Toolbar>
                          </>
                        ) : (
                          <>
                            <Toolbar
                              className={clsx(classes.root)}>
                              <div className="mb-3" style={{ marginLeft: '3px' }}>
                                <Typography className={classes.title} variant="h5" id="tableTitle" component="div" />
                                <div className={classes.tooltipDiv}>
                                  <Tooltip onClick={click('C', variant.name)} className={classes.tooltipImg4} title="Fetch VINs" style={{ fontSize: '19px', color: '#1EF1C6', cursor: 'pointer' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-information-outline" />
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            </Toolbar>
                          </>
                        )}
                      </div>
                      <div style={{ border: '1px solid rgba(255, 255, 255, 0.2)', height: '80%', marginTop: '-53px', width: '17vw', marginRight: '60vw', borderRadius: '5px' }}>
                        <MasterProductVariant
                          vehicleColor={variant.color != undefined ? variant.color : vehicleColors[variant.name]}
                          onChange={onPriceVariantChange}
                          bucketName={'C'}
                          variantName={variant.name}
                          variant={data != undefined ? variant : undefined}
                          click={click('C', variant.name)}
                        />
                      </div>
                    </div>
                  </Col>)
                }) : ''
              }

              {
                data != undefined ? data.priceBuckets.filter(item => item.name == 'C')[0].variants.length < 8 ?
                  <VariantCreation bucketName={'C'} onClick={handleNewVariantCreation} userPermissions={userPermissions} /> : '' : ''
              }
            </Row>
          </TabPane>

          <TabPane tabId="4">
            <Row style={{ marginTop: '-15px', marginLeft: '13px' }} >
              {
                data != undefined ? data.priceBuckets.filter(item => item.name == 'D')[0].variants.map(variant => {
                  return (<Col lg="3" md="12" >
                    <div style={{ marginBottom: '30px' }}>
                      <div style={{ width: '17vw', marginRight: '60vw' }}>
                        {matchesPermission ? (
                          <>
                            <Toolbar
                              className={clsx(classes.root)}>
                              <div className="mb-3" style={{ marginLeft: '3px' }}>
                                <Typography className={classes.title} variant="h5" id="tableTitle" component="div" />
                                <div className={classes.tooltipDiv}>
                                  <Tooltip onClick={handleRefreshProductVariant('D', variant.name)} className={classes.tooltipImg1} title="Refresh variant" style={{ fontSize: '20px', color: '#1EF1C6', cursor: 'pointer', marginTop: '59px', marginLeft: '-30px' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-refresh" />
                                    </span>
                                  </Tooltip>
                                  <Tooltip onClick={click('D', variant.name)} className={classes.tooltipImg3} title="Fetch VINs" style={{ fontSize: '19px', color: '#1EF1C6', cursor: 'pointer' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-information-outline" />
                                    </span>
                                  </Tooltip>
                                  <Tooltip onClick={handleDeleteProductVariant('D', variant.name)} className={classes.tooltipImg2} title="Remove variant" style={{ fontSize: '20px', color: '#DC1860', cursor: 'pointer' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-close" />
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            </Toolbar>
                          </>
                        ) : (
                          <>
                            <Toolbar
                              className={clsx(classes.root)}>
                              <div className="mb-3" style={{ marginLeft: '3px' }}>
                                <Typography className={classes.title} variant="h5" id="tableTitle" component="div" />
                                <div className={classes.tooltipDiv}>
                                  <Tooltip onClick={click('D', variant.name)} className={classes.tooltipImg4} title="Fetch VINs" style={{ fontSize: '19px', color: '#1EF1C6', cursor: 'pointer' }}>
                                    <span>
                                      <i className="mr-2 mdi mdi-information-outline" />
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            </Toolbar>
                          </>
                        )}
                      </div>
                      <div style={{ border: '1px solid rgba(255, 255, 255, 0.2)', height: '80%', marginTop: '-53px', width: '17vw', marginRight: '60vw', borderRadius: '5px' }}>
                        <MasterProductVariant
                          vehicleColor={variant.color != undefined ? variant.color : vehicleColors[variant.name]}
                          onChange={onPriceVariantChange}
                          bucketName={'D'}
                          variantName={variant.name}
                          variant={data != undefined ? variant : undefined}
                          click={click('D', variant.name)}
                        />
                      </div>
                    </div>
                  </Col>)

                }) : ''
              }

              {
                data != undefined ? data.priceBuckets.filter(item => item.name == 'D')[0].variants.length < 8 ?
                  <VariantCreation bucketName={'D'} onClick={handleNewVariantCreation} userPermissions={userPermissions} /> : '' : ''
              }
            </Row>
          </TabPane>
        </TabContent>
      {/* </Row> */}

    </div>
  );
}

const VariantCreation = (props) => {
  const { onClick, bucketName, userPermissions } = props
  const classes = UseStyles();

  const matchesPermission = userPermissions.includes(permission.Remarketing_Administrator)

  return (
    <Col lg="3" md="12"  >
      <div className="mb-3" style={{ marginLeft: '1px', marginTop: '25px', width: '17vw', }}>
        {
          matchesPermission ? (
            <>
              <Card name={bucketName} onClick={onClick(bucketName)} style={{ border: '1px solid rgba(255, 255, 255, 0.2)', cursor: 'pointer', height: '51.3vh', backgroundColor: 'rgba(33, 33, 33, 0.5)' }}>
                <Tooltip className={classes.tooltipImg} title="Add variant" style={{ fontSize: '150px', textAlign: 'center', }}>
                  <span>
                    <i className="mr-2 mdi mdi-plus" />
                  </span>
                </Tooltip>
                <CardText style={{ textAlign: 'center', marginBottom: '128px' }}>Add Variant</CardText>
              </Card>
            </>) : (
            <>
              <div></div>
            </>)
        }

      </div>
    </Col>
  )
}

let productBucketMenuObject = [
  { 'key': '26000', 'value': '26000' }, { 'key': '26500', 'value': '26500' }, { 'key': '27000', 'value': '27000' },
  { 'key': '27500', 'value': '27500' }, { 'key': '28000', 'value': '28000' }, { 'key': '28500', 'value': '28500' },
  { 'key': '29000', 'value': '29000' }, { 'key': '29500', 'value': '29500' }, { 'key': '30000', 'value': '30000' },
  { 'key': '30500', 'value': '30500' }, { 'key': '31000', 'value': '31000' }, { 'key': '31500', 'value': '31500' },
  { 'key': '32000', 'value': '32000' }, { 'key': '32500', 'value': '32500' }, { 'key': '33000', 'value': '33000' },
  { 'key': '33500', 'value': '33500' }, { 'key': '34000', 'value': '34000' }, { 'key': '34500', 'value': '34500' },
  { 'key': '35000', 'value': '35000' }, { 'key': '35500', 'value': '35500' }, { 'key': '36000', 'value': '36000' },
  { 'key': '36500', 'value': '36500' }, { 'key': '37000', 'value': '37000' }, { 'key': '37500', 'value': '37500' },
  { 'key': '38000', 'value': '38000' }, { 'key': '38500', 'value': '38500' }, { 'key': '39000', 'value': '39000' },
  { 'key': '39500', 'value': '39500' }, { 'key': '40000', 'value': '40000' }, { 'key': '40500', 'value': '40500' },
  { 'key': '41000', 'value': '41000' }, { 'key': '41500', 'value': '41500' }, { 'key': '42000', 'value': '42000' },
  { 'key': '42500', 'value': '42500' }, { 'key': '43000', 'value': '43000' }, { 'key': '43500', 'value': '43500' },
  { 'key': '44000', 'value': '44000' }, { 'key': '44500', 'value': '44500' }, { 'key': '45000', 'value': '45000' }
]

const ProductBucketA = (props) => {
  const { bucket, handleChange, activeTab } = props

  const classes = UseStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} style={{ maxWidth: '18.8vw' }}>
        <FormSelectSalesBordered value={bucket != undefined ? bucket.priceValue.toString() : ''} onChange={handleChange('A', 'priceValue')} label='' name='Price bucket A'
          menuObjectArray={productBucketMenuObject} priceBucketActiveTab={activeTab} isPriceBucketField={true}
        />
      </Grid>
      {/* <Grid item xs={6} style={{ marginTop: '-35px', maxWidth: '9.5vw' }}>
        <FormTextFieldSales value={bucket != undefined ? bucket.priceRange.minValue : ''}
          onChange={handleChange('A', 'minValue')} bucketName={'A'} name='minValue' labelText='from'
          adjustTopMargin={true} placeholder='' priceBucketActiveTab={activeTab} isPriceBucketField={true} />
      </Grid>
      <Grid item xs={6} style={{ marginTop: '-35px', maxWidth: '9.5vw' }}>
        <FormTextFieldSales value={bucket != undefined ? bucket.priceRange.maxValue : ''}
          onChange={handleChange('A', 'maxValue')} bucketName={'A'} name='maxValue' labelText='to'
          adjustTopMargin={true} placeholder='' priceBucketActiveTab={activeTab} isPriceBucketField={true} />
      </Grid> */}
    </Grid>
  )
}

const ProductBucketB = (props) => {
  const { bucket, handleChange, activeTab } = props

  const classes = UseStyles();

  return (
    <Grid container spacing={4} style={{ maxWidth: '18.8vw' }}>
      <Grid item xs={12} >
        <FormSelectSalesBordered value={bucket != undefined ? bucket.priceValue.toString() : ''} onChange={handleChange('B', 'priceValue')} label='' name='Price bucket B'
          menuObjectArray={productBucketMenuObject} priceBucketActiveTab={activeTab} isPriceBucketField={true}
        />
      </Grid>
      {/* <Grid item xs={6} style={{ marginTop: '-35px', maxWidth: '9.5vw' }}>
        <FormTextFieldSales value={bucket != undefined ? bucket.priceRange.minValue : ''}
          onChange={handleChange('B', 'minValue')} bucketName={'B'} name='minValue' labelText='from'
          adjustTopMargin={true} placeholder='' priceBucketActiveTab={activeTab} isPriceBucketField={true} />
      </Grid>
      <Grid item xs={6} style={{ marginTop: '-35px', maxWidth: '9.5vw' }}>
        <FormTextFieldSales value={bucket != undefined ? bucket.priceRange.maxValue : ''}
          onChange={handleChange('B', 'maxValue')} bucketName={'B'} name='maxValue' labelText='to'
          adjustTopMargin={true} placeholder='' priceBucketActiveTab={activeTab} isPriceBucketField={true} />
      </Grid> */}
    </Grid>
  )
}

const ProductBucketC = (props) => {
  const { bucket, handleChange, activeTab } = props

  const classes = UseStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} style={{ maxWidth: '18.8vw' }}>
        <FormSelectSalesBordered value={bucket != undefined ? bucket.priceValue.toString() : ''} onChange={handleChange('C', 'priceValue')} label='' name='Price bucket C'
          menuObjectArray={productBucketMenuObject} priceBucketActiveTab={activeTab} isPriceBucketField={true}
        />
      </Grid>
      {/* <Grid item xs={6} style={{ marginTop: '-35px', maxWidth: '9.5vw' }}>
        <FormTextFieldSales value={bucket != undefined ? bucket.priceRange.minValue : ''}
          onChange={handleChange('C', 'minValue')} bucketName={'C'} name='minValue' labelText='from'
          adjustTopMargin={true} placeholder='' priceBucketActiveTab={activeTab} isPriceBucketField={true} />
      </Grid>
      <Grid item xs={6} style={{ marginTop: '-35px', maxWidth: '9.5vw' }}>
        <FormTextFieldSales value={bucket != undefined ? bucket.priceRange.maxValue : ''}
          onChange={handleChange('C', 'maxValue')} bucketName={'C'} name='maxValue' labelText='to'
          adjustTopMargin={true} placeholder='' priceBucketActiveTab={activeTab} isPriceBucketField={true} />
      </Grid> */}
    </Grid>
  )
}

const ProductBucketD = (props) => {
  const { bucket, handleChange, activeTab } = props

  const classes = UseStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} style={{ maxWidth: '18.8vw' }}>
        <FormSelectSalesBordered value={bucket != undefined ? bucket.priceValue : ''} onChange={handleChange('D', 'priceValue')} label='' name='Price bucket D'
          menuObjectArray={productBucketMenuObject} priceBucketActiveTab={activeTab} isPriceBucketField={true}
        />
      </Grid>
      {/* <Grid item xs={6} style={{ marginTop: '-35px', maxWidth: '9.5vw' }}>
        <FormTextFieldSales value={bucket != undefined ? bucket.priceRange.minValue : ''}
          onChange={handleChange('D', 'minValue')} bucketName={'D'} name='minValue' labelText='from'
          adjustTopMargin={true} placeholder='' priceBucketActiveTab={activeTab} isPriceBucketField={true} />
      </Grid>
      <Grid item xs={6} style={{ marginTop: '-35px', maxWidth: '9.5vw' }}>
        <FormTextFieldSales value={bucket != undefined ? bucket.priceRange.maxValue : ''}
          onChange={handleChange('D', 'maxValue')} bucketName={'D'} name='maxValue' labelText='to'
          adjustTopMargin={true} placeholder='' priceBucketActiveTab={activeTab} isPriceBucketField={true} />
      </Grid> */}
    </Grid>
  )
}

const mapStateToProps = state => ({
  userOrg: selectUserOrganization(state),
  userMarketIds: selectUserMarketIds(state),
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(MasterBucket);