import React, { useState } from 'react'
import FormSelect from 'src/components/form-elements/form-select.component'
import { makeStyles } from '@material-ui/core/styles';
import { GetRoles } from 'src/services/provisioning-http.service';

const useSelectStyles = makeStyles((theme) => ({
    SelectControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: "100%",
    },
}));

const RoleSelect = ({ value, onChange, variant }) => {
    const [roles, setRoles] = useState([]);
    const [open, setOpen] = useState(false);
    const classes = useSelectStyles();

    React.useEffect(() => {
        initializeRoles();
    }, [roles.length]);

    const initializeRoles = () => {
        let roleArray = [];
        GetRoles()
            .then(data => {
                if (data?.driverRoles && Array.isArray(data?.driverRoles)) {
                    data?.driverRoles.map(role => {
                        roleArray.push({ key: role.roleId, value: role.name });
                    });
                }
                setRoles(roleArray);
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormSelect
            className={classes.SelectControl}
            label="Role"
            id="role"
            name="roleId"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            value={value}
            onChange={onChange}
            menuObjectArray={roles}
            variant={variant ? variant : false}
        />
    )
};

export default RoleSelect;