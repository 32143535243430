import React from 'react'
import { FormSelectSales } from 'src/components/form-elements/custom-form-select.SalesSettings.component'
import { GetTranslation } from 'src/services/fleet-translation-http.service'
import { makeStyles } from '@material-ui/core/styles';

const useSelectStyles = makeStyles((theme) => ({
    SelectControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        minWidth: "100%",
    },
}));

const ModelYearSelect = ({ onChange, variant, value, placeholder }) => {
    const [modelYear, setModelYear] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const classes = useSelectStyles();

    modelYear.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

    React.useEffect(() => {
        selectModelYear();
    }, []);

    const selectModelYear = () => {
        let modelYearArray = [];

        GetTranslation(['MODELYEAR'])
            .then(data => {               
                Array.isArray(data) && data.length > 0 ? data.map(model => {
                    modelYearArray.push({ key: model.fleetValue, value: model.integratorValue });
                })
                    : console.log("Error Occured");
                    setModelYear(modelYearArray);
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormSelectSales
            className={classes.SelectControl}
            label="Model Year"
            id="modelYear"
            name={''}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            value={value}
            onChange={onChange}
            menuObjectArray={modelYear}
            variant={variant ? variant : false}
            placeholder={placeholder}
        />
    )
}

export default ModelYearSelect;