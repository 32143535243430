import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import VehiclePage from 'src/components/vehicleDetails/VehiclePage';
import PositionTracking from 'src/pages/vsp/position-tracking-page';
import { QueryConnectivityInformation } from 'src/services/connectivity-http.service';
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import WorkOrder from 'src/pages/vsp/WorkOrder';
import Chat from 'src/containers/Chat';
import { GetComments } from 'src/services/vehicle-comments-http.service';
import { GetVehicleWorkOrderCountByStatus } from 'src/services/vehicle-pages-http.service';
import { FormmatDate } from 'src/utils/DateFormatter';
import { formatAndVerifyNumericValue } from 'src/utils/RvTableExcelFormatter';

const DetailsMainPage = (props) => {
    const { closeVehicleDetail, vin, statuses = true, transportStatus, variant = true, setSelectedVIN, selectedVIN, valueVIN, setSelectedVINMap, selectedVINMap } = props

    const [vehiclePosition, setVeiclePosition] = useState({});
    const [displayDetails, setDisplayDetails] = useState(false)
    const [vehicleDetail, setVehicleDetails] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [message, setMessage] = useState('');
    const [comments, setComments] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [getWorkOrderStatus, setWorkOrderStatus] = useState({});
    const [mapSpinner, setMapSpinner] = useState(false);
    const [showSVTMap, setShowSVTMap] = useState(false);

    const [addressInput, setAddressInput] = useState('');

    useEffect(() => {
        setComments([]);
        setVehicleDetails({});
        setVeiclePosition({});
        setWorkOrderStatus({});
    }, []);

    useEffect(() => {
        if (valueVIN == 1) {
            setAddressInput(selectedVIN || selectedVINMap)
            handleChangeVIN()
        }
    }, [])

    useEffect(() => {
        setShowSVTMap(true)
        handleChangeVIN()
    }, [showSVTMap])

    const getComments = () => {
        GetComments(addressInput.trim())
            .then(data => {
                if (data.ok == true) {
                    setComments(data.comments)
                }
                setDataLoaded(true);
            })
    };

    const handleChangeVIN = () => {
        if (showSVTMap) {
            setDataLoaded(false);

            QueryConnectivityInformation(addressInput.trim())
                .then(data => {
                    if (data.ok == true) {
                        setVehicleDetails(data.vspConnectivity);
                        setDisplayDetails(true)
                    } else {
                        if (addressInput !== '') {
                            setOpenDialog(true);
                        }

                        setMessage(data.title);
                        setDialogMessage(data.errorMessage);
                        setDisplayDetails(false);
                    }
                })
            GetComments(addressInput.trim())
                .then(data => {
                    if (data.ok == true) {
                        setComments(data.comments)
                    }
                    setDataLoaded(true);
                })
            GetVehicleWorkOrderCountByStatus(addressInput.trim())
                .then(data => {
                    if (data.ok == true) {
                        setWorkOrderStatus(data)
                    }
                })
        }
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
        setAddressInput('');
        setComments([]);
        setVehicleDetails({});
        setVeiclePosition({});
        setWorkOrderStatus({});
    }

    return (
        <Col md={12} lg={12}>

            <Card style={{ marginTop: '20px', marginLeft: '-8px', marginRight: '5px', position: 'fixed' }}>
                <VehiclePageDialog
                    dialogue='true'
                    open={openDialog}
                    message={dialogMessage}
                    handleDialogOk={handleDialogOk}
                    title={message}
                />
                <Card style={{ marginLeft: '-8px', marginRight: '-18px', position: 'fixed', width: '93%', marginTop: '55px', height: '745px', backgroundColor: 'transparent' }}>
                    <CardBody>
                        <Row style={{ marginTop: '-15px', height: '718px', }}>
                            <Col lg="4" md="4" >
                                <div style={{ marginLeft: '10px' }} >
                                    <div style={{ marginLeft: '-35px', marginTop: '770px', marginRight: '-89px' }} >
                                        <PositionTracking
                                            vin={vin}
                                            value={2}
                                            vehiclePosition={vehiclePosition}
                                            mapSpinner={mapSpinner}
                                            showSVTMap={showSVTMap}
                                            setAddressInput={setAddressInput}
                                            addressInput={addressInput}
                                            setVehicleDetails={setVehicleDetails}
                                            setComments={setComments}
                                            setShowSVTMap={setShowSVTMap}
                                            handleChangeVIN={handleChangeVIN}
                                            setWorkOrderStatus={setWorkOrderStatus}
                                            valueVIN={valueVIN}
                                            selectedVIN={selectedVIN}
                                            setSelectedVIN={setSelectedVIN}
                                            setSelectedVINMap={setSelectedVINMap}
                                            selectedVINMap={selectedVINMap}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col lg="2" md="2" >
                                <div style={{ marginLeft: '36px', marginRight: '-20px' }} >
                                    <VehiclePage vehicleDetail={vehicleDetail} variant={variant} />
                                </div>
                            </Col>
                            <Col lg="2" md="2" >
                                <Card id="CardBodyCardAllowance2" style={{ marginLeft: '-27px', marginRight: '90px', height: '98px', marginTop: '-5px', backgroundColor: '#212121' }} >
                                    <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '15px', marginTop: '13px' }}> Estimated De-Fleet</span>
                                    <span style={{ fontSize: '24px', fontFamily: 'LynkcoTypeMedium', marginLeft: '15px', color: 'rgb(255, 102, 0)' }}>{FormmatDate(vehicleDetail?.vehicleDetails?.forecastedDefleetDateUtc)}</span> <br /><br />
                                </Card>

                                <Card id="CardBodyCardAllowance2" style={{ marginLeft: '-27px', marginRight: '90px', height: '98px', marginTop: '-20px', backgroundColor: '#212121' }} >
                                    <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '15px', marginTop: '13px' }}> RV [Gross]</span>
                                    <span style={{ fontSize: '24px', fontFamily: 'LynkcoTypeMedium', marginLeft: '15px', color: 'rgb(255, 102, 0)' }}>{formatAndVerifyNumericValue(vehicleDetail?.vehicleDetails?.rvGross)} €</span> <br /><br />
                                </Card>

                                <Card id="CardBodyCardAllowance2" style={{ marginLeft: '-27px', marginRight: '90px', height: '98px', marginTop: '-20px', backgroundColor: '#212121' }} >
                                    <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '15px', marginTop: '13px' }}>  RV to LC [Net]</span>
                                    <span style={{ fontSize: '24px', fontFamily: 'LynkcoTypeMedium', marginLeft: '15px', color: 'rgb(255, 102, 0)' }}>{formatAndVerifyNumericValue(vehicleDetail?.vehicleDetails?.rvNetToLandingCost)} €</span> <br /><br />
                                </Card>

                                <Card id="CardBodyCardAllowance2" style={{ marginLeft: '-27px', marginRight: '90px', height: '178px', marginTop: '-20px', backgroundColor: '#212121' }} >
                                    <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeBold', marginLeft: '15px' }}>Vehicle Movement</span> <br />
                                    <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '15px' }}> Transport Status</span> <br />
                                    <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '15px' }}>{transportStatus}</span> <br />
                                    <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '15px' }}>Customer Order Status</span>
                                    <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '15px' }}>{vehicleDetail?.orderDetails?.orderStatus}</span> <br /><br />

                                </Card>

                                <Card id="CardBodyCardAllowance2" style={{ marginLeft: '-27px', marginRight: '90px', height: '208px', marginTop: '-20px' }} >
                                    <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '15px' }}>Status</span>
                                    <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '15px' }}>coming soon</span> <br />
                                    <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '15px' }}>Swap Reason</span>
                                    <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '15px' }}>coming soon</span> <br />
                                    <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '15px' }}>New VIN</span>
                                    <span style={{ fontSize: '12px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '15px' }}>coming soon</span> <br /><br />
                                </Card>
                            </Col>
                            <Col lg="4" md="4" >
                                <Row style={{ marginBottom: '8px', marginTop: '-5px', marginLeft: '-140px', marginRight: '-50px' }}>
                                    <Col lg="12" md="12" >
                                        <Chat
                                            vin={addressInput}
                                            comments={comments}
                                            getComments={getComments}
                                            dataLoaded={dataLoaded}
                                            showSVTMap={showSVTMap}
                                            closeVehicleDetail={closeVehicleDetail}
                                            variant={variant}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginRight: '-20px', marginLeft: '-109px', marginTop: '-10px' }}>
                                    <WorkOrder getWorkOrderStatus={getWorkOrderStatus} vin={addressInput} statuses={statuses} variant={variant} />
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Card>
        </Col>
    );
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(DetailsMainPage);