import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ReactTableBaseVehiclePage from 'src/components/tables/newtable/table/ReactTableBaseVehiclePage';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import { GetFleets } from 'src/services/fleet-http.service'
import { FormmatDate, } from 'src/utils/DateFormatter';
import { Typography } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { useTableStyles } from 'src/assets/jss/components/tableStyle';
import VehiclePageDialog from 'src/components/form-elements/vehiclePage-dialog.component';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import SearchButton from 'src/components/buttons/search-button-component';
import LoadingImagePlaceHolder from 'src/components/placeholder/loadingimageplaceholder';
import SearchBar from 'src/components/buttons/search-icon-button.component';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import CSVExport from 'src/services/csv-export.service';
import Modal from "@mui/material/Modal";
import FadeLoader from "react-spinners/FadeLoader";
import { GetFleetTypes } from 'src/services/fleetType-http.service';
import { SearchByExtendedSearch } from 'src/services/vehicle-pages-http.service';
import { formatAndVerifyNumericValue } from 'src/utils/RvTableExcelFormatter';
import { GetVehicleModelYears } from 'src/services/vehicle-pages-http.service';
import { GetOrderStatusList } from 'src/services/vehicle-pages-http.service';

let rowsPerPage = 50;

let newPageVariable = 1;

let otdFleetType = 'Yes';

let vin = '';

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const useDrawerStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },

    drawer: {
        width: '100%',
        flexShrink: 0,
        marginTop: '100px',
        alignItems: 'center',
        position: 'absolute',
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
    },
    gridContainerStyle: {
        padding: '40px',
        position: 'sticky',
        height: '200px',
    }
}));

const UCReservedPricingTable = ({ userOrg, userMarketIds }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const classes = useTableStyles();
    const [totalPages, setTotalPages] = React.useState(0);
    const [totalItems, setTotalItems] = React.useState(0);
    const [selected, setSelected] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [open, setOpen] = React.useState(false);

    const [openDownload, setOpenDownload] = React.useState(false);
    const imgs = ['../assets/icons/DownloadIcon.png', '../assets/icons/DownloadIcon_Green.png'];
    const [bcgImg, setBcgImg] = useState(imgs[0]);

    let selectedRowVehiclePostion = {}
    let queryParams = {}

    let fleetTypes = []
    let marketIds = []

    const [rows, setVehicleFleet] = React.useState([]);

    const [selectedRow, setSelectedRow] = React.useState({});
    const [presSelectedFleetTypes, setPresSelectedFleetTypes] = useState([]);

    const [presSelectedMarketIds, setPresSelectedMarketIds] = React.useState([]);

    const [earMarked, setEarMarked] = React.useState([]);
    const [isColumnHidden, setIsColumnHidden] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [isErrorDialog, setErrorDialogFlag] = React.useState(false);
    const drawerClasses = useDrawerStyles();
    const [vehiclePosition, setVeiclePosition] = React.useState({
        "vin": "",
        "latitude": 0,
        "longitude": 0,
        "altitude": 0,
        "canBeTrusted": false,
        "vehicleUpdatedAt": ""
    });
    const [openDialog, setOpenDialog] = React.useState(false);
    const [downloadSpinner, setDownloadSpinner] = React.useState(false);

    const [fleetVehicle, setFleetVehicle] = useState({
        licensePlate: "",
        vin: "",
        pnoCode: "",
        model: "",
        modelYear: "",
        engineType: "",
        obcVersion: "",
        color: "",
        hasTowbar: "",
        odometerKm: "",
        rvGross: "",
        registeredDateUtc: "",
        age: "",
        forecastedDefleetDateUtc: "",
        transportStatus: "",
        lastMileStatus: "",
        lastMileType: "",
        orderStatus: "",
        membershipEndDateUtc: "",
        usedCar: "",
        usedCarIsB2B: "",
        market: "",
        fleetType: "",
        orderType: "",
        orderNumber: "",
        rvCurve: "",
        rvRangeKm: "",
        inventoryState: "",
        fleetIds: "",
        belowLandedCost: ""
    });

    const [selectedMarket, setSelectedMarket] = useState({ key: "NONE", value: "" })
    const [selectedFleetType, setSelectedFleetType] = useState({ key: "NONE", value: "" })
    const [queryParamsAge, setQueryParamsAge] = useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    })

    const [queryParamsMarket, setQueryParamsMarket] = useState('')
    const [queryParamsFleetType, setQueryParamsFleetType] = useState('')
    const [queryParamsVin, setQueryParamsVin] = useState('')
    const [queryParamsModelYear, setQueryParamsModelYear] = useState([]);
    const [queryParamsLicensePlate, setQueryParamsLicensePlate] = useState('');
    const [queryParamsOrderType, setQueryParamsOrderType] = useState(['B2B', 'B2C', '']);
    const [queryParamsOrderStatus, setQueryParamsOrderStatus] = useState([]);
    const [queryParamsOrderNumber, setQueryParamsOrderNumber] = useState('');
    const [queryParamsPNOCode, setQueryParamsPNOCode] = useState('');
    const [queryParamsEngine, setQueryParamsEngine] = useState(['HEV', 'PHEV']);
    const [queryParamsColor, setQueryParamsColor] = useState(['Blue', 'Black']);
    const [queryParamsRvBelowLandedCost, setQueryParamsRvBelowLandedCost] = useState('');
    const [queryParamsCcWithin3Months, setQueryParamsCcWithin3Months] = useState('');
    const [queryParamsUsedCarStock, setQueryParamsUsedCarStock] = useState('Yes');
    const [queryParamsURvRange, setQueryParamsRvRange] = useState({
        lessThan: false,
        greaterThan: false,
        between: false,
        startValue: "0",
        endValue: "0",
    });
    const [queryParamsAvailableForSales, setQueryParamsAvailableForSales] = useState(false);
    const [queryParamsCommercialCostMonthIndex, setQueryParamsCommercialCostMonthIndex] = useState('');
    const [queryParamsModel, setQueryParamsModel] = useState('');
    const [queryParamsFilteredTowbar, setQueryParamsFilteredTowbar] = useState(false);

    const [queryParamsUsedInventoryStatus, setQueryParamsInventoryStatus] = useState('')
    const [queryParamsLastMileStatus, setQueryParamsLastmileStatus] = useState('')
    const [queryParamsProvisionStatus, setQueryParamsProvisionStatus] = useState('')
    const [queryParamsMovementStatus, setQueryParamsMovementStatus] = useState('');

    const [dataLoaded, setDataLoaded] = useState(false)

    const columns = useMemo(
        () => [
            { Header: 'License Plate', accessor: "licensePlate", disableGlobalFilter: true },
            { Header: 'VIN', accessor: "vin", disableGlobalFilter: true },
            { Header: 'PNO', accessor: "pnoCode", disableGlobalFilter: true },
            { Header: 'Model', accessor: "model", disableGlobalFilter: true, },
            { Header: 'Model Year', accessor: "modelYear", disableGlobalFilter: true },
            { Header: 'Engine Type', accessor: "engineType", disableGlobalFilter: true, },
            { Header: 'OBC', accessor: "obcVersion", disableGlobalFilter: true, },
            { Header: 'Color', accessor: "color", disableGlobalFilter: true, },
            { Header: 'Towbar', accessor: "hasTowbar", disableGlobalFilter: true },
            { Header: 'Odometer', accessor: "odometerKm", disableGlobalFilter: true },
            { Header: 'RV [gross]', accessor: "rvGross", disableGlobalFilter: true },
            { Header: 'Registered', accessor: "registeredDateUtc", disableGlobalFilter: true },
            { Header: 'Est. De-fleet', accessor: "forecastedDefleetDateUtc", disableGlobalFilter: true },
            { Header: 'Last Mile Status', accessor: "lastMileStatus", disableGlobalFilter: true },
            { Header: 'Order Status', accessor: "orderStatus", disableGlobalFilter: true },
            { Header: 'Membership Ends', accessor: "membershipEndDateUtc", disableGlobalFilter: true },
            { Header: 'Age [Months]', accessor: "age", disableGlobalFilter: true },
            { Header: 'Inventory Status', accessor: "inventoryState", disableGlobalFilter: true },
            { Header: 'Used Car Status', accessor: "usedCarStatus", disableGlobalFilter: true },
            { Header: 'Used Car Stock', accessor: "usedCar", disableGlobalFilter: true },
            { Header: 'Used Car B2B', accessor: "usedCarIsB2B", disableGlobalFilter: true },
            { Header: 'Market', accessor: "market", disableGlobalFilter: true },
            { Header: 'Fleet Type', accessor: "fleetType", disableGlobalFilter: true },
            { Header: 'Order Type', accessor: "orderType", disableGlobalFilter: true },
            { Header: 'Order Number', accessor: "orderNumber", disableGlobalFilter: true },
            { Header: 'Below Landed Cost', accessor: "belowLandedCost", disableGlobalFilter: true }
        ],
        [],
    );

    useEffect(() => {
        marketIds = userMarketIds;
        setPresSelectedMarketIds(marketIds)
        setOpenDialog(false);
        setModelYears();
        setOderStatus();
        if (queryParamsModelYear.length !== 0 && queryParamsOrderStatus.length !== 0) {
            setQueryParamsVin('')
            initializeFleetVehicles(marketIds);
        }
    }, [queryParamsModelYear.length, queryParamsOrderStatus.length]);

    const setModelYears = () => {
        let modelYearArray = []

        GetVehicleModelYears().then(data => {
            Array.isArray(data.data) && data.data.length > 0 ? data.data.map(

                modelYear => {
                    modelYearArray.push(
                        modelYear.value
                    );
                })
                : console.log("Error Occured");
            setQueryParamsModelYear(modelYearArray);
        });
    };

    const setOderStatus = () => {
        let orderStatusArray = []

        GetOrderStatusList().then(data => {
            Array.isArray(data.data) && data.data.length > 0 ? data.data.map(

                orderStatus => {
                    orderStatusArray.push(
                        orderStatus.value
                    );
                })
                : console.log("Error Occured");
            setQueryParamsOrderStatus(orderStatusArray);
        });
    };

    //////////////////////////////////// Fetch Data for Table /////////////////////////////

    const initializeFleetVehicles = (marketIds) => {
        let fleetIds = []
        let vehicleFleetArray = [];

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, model: queryParamsModel }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCcWithin3Months }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, availableForSales: queryParamsAvailableForSales }
        queryParams = { ...queryParams, commercialCostMonthIndex: queryParamsCommercialCostMonthIndex }
        queryParams = { ...queryParams, filterByTowbar: queryParamsFilteredTowbar }

        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: newPageVariable, pageSize: rowsPerPage, listViewType: 2, fleetTypeIds: fleetTypes, fleetIds: fleetIds, marketIds, otdFleetType })
                                .then(data => {

                                    if (data && Array.isArray(data.items)) {
                                        setTotalPages(data.totalPages)
                                        setTotalItems(data.totalItems ?? 0)
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push(
                                                {
                                                    licensePlate: vehicle.licensePlate,
                                                    vin: vehicle.vin,
                                                    pnoCode: vehicle.pnoCode,
                                                    model: vehicle.model,
                                                    modelYear: vehicle.modelYear,
                                                    engineType: vehicle.engineType,
                                                    obcVersion: vehicle.obcVersion,
                                                    color: vehicle.color,
                                                    hasTowbar: vehicle.hasTowbar,
                                                    odometerKm: vehicle.odometerKm,
                                                    rvGross: vehicle.rvDetails.rvGross,
                                                    registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                    forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                    lastMileStatus: vehicle.lastMileStatus,
                                                    orderStatus: vehicle.orderStatus,
                                                    membershipEndDateUtc: FormmatDate(vehicle.membershipEndDateUtc),
                                                    age: vehicle.age,
                                                    inventoryState: vehicle.inventoryState,
                                                    usedCarStatus: vehicle.usedCarStatus,
                                                    usedCar: vehicle.usedCar,
                                                    usedCarIsB2B: vehicle.usedCarIsB2B,
                                                    market: vehicle.market,
                                                    fleetType: vehicle.fleetType,
                                                    orderType: vehicle.orderType,
                                                    orderNumber: vehicle.orderNumber
                                                }
                                            );
                                        });
                                    }
                                    setDataLoaded(true);
                                    setVehicleFleet(vehicleFleetArray);
                                    newPageVariable = data.currentPage;
                                });
                        })
                }
            })
    };

    //////////////////////////////////// Download in Excel and CSV Format ////////////////////////////////

    const header = [
        [
            'License Plate',
            'VIN',
            'PNO',
            'Model',
            'Model Year',
            'Engine Type',
            'OBC',
            'Color',
            'Towbar',
            'Odometer',
            'RV [gross]',
            'Registered',
            'Est. De-fleet',
            'Last Mile Status',
            'Order Status',
            'Membership Ends',
            'Age [Months]',
            'Inventory Status',
            'Used Car Status',
            'Used Car Stock',
            'Used Car B2B',
            'Market',
            'Fleet Type',
            'Order Type',
            'Order Number'
        ]
    ]

    const handleClickDownloadExcel = () => {
        setDownloadSpinner(true);

        fleetTypes = selectedFleetType.value == '' ? presSelectedFleetTypes : selectedFleetType.value;
        let fleetIds = []
        let vehicleFleetArray = [];
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCcWithin3Months }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear }
        queryParams = { ...queryParams, model: queryParamsModel }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }

        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: 0, pageSize: 0, listViewType: 2, fleetTypeIds: fleetTypes, fleetIds: fleetIds, marketIds, otdFleetType })
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                odometerKm: vehicle.odometerKm,
                                                rvGross: vehicle.rvDetails.rvGross,
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                lastMileStatus: vehicle.lastMileStatus,
                                                orderStatus: vehicle.orderStatus,
                                                membershipEndDateUtc: FormmatDate(vehicle.membershipEndDateUtc),
                                                age: vehicle.age,
                                                inventoryState: vehicle.inventoryState,
                                                usedCarStatus: vehicle.usedCarStatus,
                                                usedCar: vehicle.usedCar,
                                                usedCarIsB2B: vehicle.usedCarIsB2B,
                                                market: vehicle.market,
                                                fleetType: vehicle.fleetType,
                                                orderType: vehicle.orderType,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    initiateDownload(vehicleFleetArray);
                                    setDownloadSpinner(false);
                                })
                        })
                }
            })

        const initiateDownload = (row) => {

            const fileName = 'UC Reserved Pricing Report';

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            const fileExtension = '.xlsx';
            const exportToExcel = (row, fileName) => {

                const ws = XLSX.utils.book_new();

                XLSX.utils.sheet_add_aoa(ws, header);

                XLSX.utils.sheet_add_json(ws, row, { origin: 'A2', skipHeader: true });

                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                const data = new Blob([excelBuffer], { type: fileType });

                FileSaver.saveAs(data, fileName + fileExtension);

            }
            exportToExcel(row, fileName);
        }
        setOpen(false);
    }

    const handleClickDownloadCSV = () => {
        setDownloadSpinner(true);

        fleetTypes = selectedFleetType.value == '' ? presSelectedFleetTypes : selectedFleetType.value;
        let fleetIds = []
        let vehicleFleetArray = [];
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCcWithin3Months }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear }
        queryParams = { ...queryParams, model: queryParamsModel }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }

        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            SearchByExtendedSearch({ ...queryParams, pageNumber: 0, pageSize: 0, listViewType: 2, fleetTypeIds: fleetTypes, fleetIds: fleetIds, marketIds, otdFleetType })
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                odometerKm: vehicle.odometerKm,
                                                rvGross: vehicle.rvDetails.rvGross,
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                lastMileStatus: vehicle.lastMileStatus,
                                                orderStatus: vehicle.orderStatus,
                                                membershipEndDateUtc: FormmatDate(vehicle.membershipEndDateUtc),
                                                age: vehicle.age,
                                                inventoryState: vehicle.inventoryState,
                                                usedCarStatus: vehicle.usedCarStatus,
                                                usedCar: vehicle.usedCar,
                                                usedCarIsB2B: vehicle.usedCarIsB2B,
                                                market: vehicle.market,
                                                fleetType: vehicle.fleetType,
                                                orderType: vehicle.orderType,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    initiateDownload(vehicleFleetArray);
                                    setDownloadSpinner(false);
                                })
                        })
                }
            })

        const initiateDownload = (row) => {
            CSVExport(header, row, 'UC Reserved Pricing Report')
        }
        setOpen(false);
    };

    ///////////////////////////////// Extended Search /////////////////////////////////////

    const handleExtendedSearch = () => {
        setDataLoaded(false);

        queryParams = { ...queryParams, age: queryParamsAge }
        queryParams = { ...queryParams, engine: queryParamsEngine }
        queryParams = { ...queryParams, color: queryParamsColor }
        queryParams = { ...queryParams, vin: queryParamsVin }
        queryParams = { ...queryParams, licensePlate: queryParamsLicensePlate }
        queryParams = { ...queryParams, orderNumber: queryParamsOrderNumber }
        queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
        queryParams = { ...queryParams, market: queryParamsMarket }
        queryParams = { ...queryParams, fleetType: queryParamsFleetType }
        queryParams = { ...queryParams, orderType: queryParamsOrderType }
        queryParams = { ...queryParams, orderStatus: queryParamsOrderStatus }
        queryParams = { ...queryParams, rvBelowLandedCost: queryParamsRvBelowLandedCost }
        queryParams = { ...queryParams, carSwapInXMonths: queryParamsCcWithin3Months }
        queryParams = { ...queryParams, usedCar: queryParamsUsedCarStock }
        queryParams = { ...queryParams, rvRange: queryParamsURvRange }
        queryParams = { ...queryParams, modelYear: queryParamsModelYear }
        queryParams = { ...queryParams, model: queryParamsModel }
        queryParams = { ...queryParams, inventoryStatus: queryParamsUsedInventoryStatus }
        queryParams = { ...queryParams, lastMileStatus: queryParamsLastMileStatus }
        queryParams = { ...queryParams, provisionStatus: queryParamsProvisionStatus }

        console.log(queryParams)

        fleetTypes = selectedFleetType.value == '' ? presSelectedFleetTypes : selectedFleetType.value;
        let fleetIds = []
        let vehicleFleetArray = [];
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;

        GetFleets(userOrg.id, userMarketIds)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data.map(item => {
                        fleetIds.push(item.id);
                    })
                    GetFleetTypes(userOrg.id)
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                data.map(fleetType => {
                                    fleetTypes.push(fleetType['id'])
                                })
                            }
                            queryParams = { ...queryParams, pageNumber: 1, pageSize: rowsPerPage, listViewType: 2, fleetTypeIds: fleetTypes, fleetIds: fleetIds, marketIds, otdFleetType }
                            SearchByExtendedSearch(queryParams)
                                .then(data => {
                                    if (data && Array.isArray(data.items)) {
                                        setTotalPages(data.totalPages);
                                        setTotalItems(data.totalItems ?? 0);
                                        newPageVariable = data.currentPage;
                                        data.items.map(vehicle => {
                                            vehicleFleetArray.push({
                                                licensePlate: vehicle.licensePlate,
                                                vin: vehicle.vin,
                                                pnoCode: vehicle.pnoCode,
                                                model: vehicle.model,
                                                modelYear: vehicle.modelYear,
                                                engineType: vehicle.engineType,
                                                obcVersion: vehicle.obcVersion,
                                                color: vehicle.color,
                                                hasTowbar: vehicle.hasTowbar,
                                                odometerKm: formatAndVerifyNumericValue(vehicle.odometerKm),
                                                rvGross: formatAndVerifyNumericValue(vehicle.rvDetails.rvGross),
                                                registeredDateUtc: FormmatDate(vehicle.registeredDateUtc),
                                                age: vehicle.age,
                                                forecastedDefleetDateUtc: FormmatDate(vehicle.forecastedDefleetDateUtc),
                                                transportStatus: vehicle.transportStatus,
                                                lastMileStatus: vehicle.lastMileStatus,
                                                lastMileType: vehicle.lastMileType,
                                                orderStatus: vehicle.orderStatus,
                                                membershipEndDateUtc: FormmatDate(vehicle.membershipEndDateUtc),
                                                inventoryState: vehicle.inventoryState,
                                                usedCarStatus: vehicle.usedCarStatus,
                                                usedCar: vehicle.usedCar,
                                                usedCarIsB2B: vehicle.usedCarIsB2B,
                                                market: vehicle.market,
                                                fleetType: vehicle.fleetType,
                                                orderType: vehicle.orderType,
                                                orderNumber: vehicle.orderNumber
                                            });
                                        });
                                    }
                                    setVehicleFleet(vehicleFleetArray);
                                    setDataLoaded(true);
                                });
                        })
                }
            })
    }

    /////////////////////////////////////////////////////////////////////

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setDownloadSpinner(false);
        setOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setDataLoaded(false);
        newPageVariable = newPage + 1
        setPage(newPage);
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        initializeFleetVehicles(marketIds);
        setOpen(false);
    };

    const handleChangeRowsPerPage = (event) => {
        setDataLoaded(false);
        newPageVariable = 1
        rowsPerPage = parseInt(event.target.value, 10);
        marketIds = selectedMarket.value.length == 0 ? presSelectedMarketIds : selectedMarket.value;
        initializeFleetVehicles(marketIds);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const handleDialogOk = () => {
        setOpenDialog(false);
    }

    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setVehicleFleet(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setVehicleFleet(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    const earmarkedRowValues = (row) => {
        let currentRow = earMarked.find((item) => item.id === row.values.vin);
        let usedCar = row.values.usedCarIsB2B == 'Yes' ? true : false;

        if (currentRow) {
            let filterArray = earMarked.filter((item) => item.id != row.values.vin)
            setEarMarked([...filterArray])
        }
        else {
            setEarMarked([...earMarked, { id: row.values.vin, status: !usedCar }])
        }
    }

    const selectedRowValues = (row) => {
        setSelectedRow({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            age: row.values.age,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            transportStatus: row.values.transportStatus,
            lastMileStatus: row.values.lastMileStatus,
            lastMileType: row.values.lastMileType,
            orderStatus: row.values.orderStatus,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            inventoryState: row.values.inventoryState,
            usedCarStatus: row.values.usedCarStatus,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            fleetType: row.values.fleetType,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber
        });
        setSelected({
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            lastMileStatus: row.values.lastMileStatus,
            orderStatus: row.values.orderStatus,
            fleetType: row.values.fleetType,
            age: row.values.age,
            inventoryState: row.values.inventoryState,
            usedCarStatus: row.values.usedCarStatus,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber,
        })
        selectedRowVehiclePostion = {
            licensePlate: row.values.licensePlate,
            vin: row.values.vin,
            pnoCode: row.values.pnoCode,
            model: row.values.model,
            modelYear: row.values.modelYear,
            engineType: row.values.engineType,
            obcVersion: row.values.obcVersion,
            color: row.values.color,
            hasTowbar: row.values.hasTowbar,
            odometerKm: row.values.odometerKm,
            rvGross: row.values.rvGross,
            registeredDateUtc: row.values.registeredDateUtc,
            forecastedDefleetDateUtc: row.values.forecastedDefleetDateUtc,
            membershipEndDateUtc: row.values.membershipEndDateUtc,
            lastMileStatus: row.values.lastMileStatus,
            orderStatus: row.values.orderStatus,
            fleetType: row.values.fleetType,
            age: row.values.age,
            inventoryState: row.values.inventoryState,
            usedCarStatus: row.values.usedCarStatus,
            usedCar: row.values.usedCar,
            usedCarIsB2B: row.values.usedCarIsB2B,
            market: row.values.market,
            orderType: row.values.orderType,
            orderNumber: row.values.orderNumber
        }
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by Licence plate...',
    };

    const handleDrawar = () => {
        vin = selectedRowVehiclePostion.vin;
    }

    const closeHandleDrawer = () => {
        setOpen(false);
    }

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            handleExtendedSearch();
        }
    }

    const handleSearchInput = (event) => {
        setQueryParamsVin(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
    }

    const handleCloseDownloadIcon = () => {
        setBcgImg(imgs[0]);
        setOpenDownload(false)
    };

    const handleOpenDownloadIcon = () => {
        setBcgImg(imgs[1]);
        setOpenDownload(true)
    };

    return (
        <Col md={12} lg={12}>

            <VehiclePageDialog
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
                isErrorDialog={isErrorDialog} />
            <Modal
                className={drawerClasses.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleClose}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
            >
                <div style={{
                    border: '2px solid rgb(64, 64, 64)', height: '170px', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', padding: '20px'
                }}>
                    <Typography style={{ textAlign: 'center', fontSize: '24px', fontFamily: "LynkcoTypeRegular", cursor: 'pointer', marginTop: '-80px', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex' }} onClick={() => handleDrawar('fnl')} variant="h4" id="tableTitle" component="div">
                        Download Report
                    </Typography>
                    <Row style={{ marginBottom: '-40px' }}>
                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'Excel'}
                                handleSearch={() => handleClickDownloadExcel(rows)}
                                height='true'
                            />
                        </Col>

                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'CSV'}
                                handleSearch={() => handleClickDownloadCSV(rows)}
                                height='true'
                            />
                        </Col>

                        <Col lg="4" md="4">
                            <SearchButton
                                placeholder={'Cancel'}
                                handleSearch={handleClose}
                                height='true'
                                rsa='true'
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>
            <div >
                <div className="react-table__wrapper">
                    <div className="card__title">
                    </div>
                </div>
                <div style={{ marginTop: '140px', marginBottom: '51px' }}>
                </div>
            </div>
            <>
                <div style={{ backgroundColor: 'transparent', marginTop: '-160px', borderRadius: '4px' }} >
                    <>
                        <Col lg="12" md="12" >
                            <Row>
                                <Col lg="11" md="11">
                                    <div style={{ width: '100%', marginTop: '25px', marginLeft: '-25px', marginBottom: '30px' }}>
                                        <SearchBar
                                            placeholder={'VIN'}
                                            handleSearch={handleExtendedSearch}
                                            handleSearchInput={handleSearchInput}
                                            onKeyPress={textFieldKeypress}
                                        />
                                    </div>
                                </Col>

                                <Col lg="1" md="1">
                                    {!downloadSpinner ?
                                        <Toolbar  >
                                            <Tooltip
                                                onClick={handleOpen}
                                                title="Download"
                                                style={{ marginTop: '30px', fontSize: '20px', marginRight: '40px', cursor: 'pointer', color: 'gray' }}
                                                onOpen={handleOpenDownloadIcon}
                                                onClose={handleCloseDownloadIcon}
                                                open={openDownload}
                                            >
                                                <span>
                                                    <img src={bcgImg} height="22" style={{ marginTop: '-10px', marginLeft: '33px' }} />
                                                </span>
                                            </Tooltip>
                                        </Toolbar>
                                        :
                                        <div style={{ marginTop: '35px', marginLeft: '51px' }}>
                                            <FadeLoader color="#1EF1C6" radius={1} width={3} height={8} margin={-5} />
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        {dataLoaded ? <>
                            <ReactTableBaseVehiclePage
                                key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                columns={columns}
                                data={rows}
                                updateEditableData={updateEditableData}
                                updateDraggableData={updateDraggableData}
                                tableConfig={tableConfig}
                                isColumnHidden={isColumnHidden}
                                isSelected={isSelected}
                                selectedRowValues={selectedRowValues}
                                earmarkedRowValues={earmarkedRowValues}
                                earMarkedRows={earMarked}
                                handleDrawar={handleDrawar}
                                closeHandleDrawer={closeHandleDrawer}
                                vehiclePosition={vehiclePosition}
                                selected={selected}
                            />

                        </> :
                            <Card style={{ backgroundColor: 'transparent', marginTop: '56px', height: '61.3vh' }} >
                                <CardBody style={{ marginTop: '-75px', marginLeft: '7px' }}>
                                    <LoadingImagePlaceHolder />
                                </CardBody>

                            </Card>
                        }
                        <div style={{ display: 'flex', margin: '5px 0 -20px 0' }}>
                            <Typography style={{ fontFamily: 'LynkcoTypeLight', color: '#fff', fontSize: '14px', marginLeft: '9px', marginTop: '16px' }}>Total Records: {formatAndVerifyNumericValue(totalItems)}</Typography>

                            <TablePagination
                                style={{ flex: 'auto', marginRight: '-20px' }}

                                classes={{
                                    toolbar: classes.paginationToolbar,
                                    caption: classes.paginationCaption,
                                    SelectProps: classes.selectStyle
                                }}
                                SelectProps={{
                                    MenuProps: {
                                        classes: { paper: classes.selectStyle },
                                        disableScrollLock: true,
                                    }
                                }}
                                rowsPerPageOptions={[50, 100, 150, 200]}
                                component="div"
                                count={totalPages * rowsPerPage}
                                rowsPerPage={rowsPerPage}
                                page={newPageVariable - 1}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={<span>Rows per page:</span>} labelDisplayedRows={({ page }) => { return `Page: ${newPageVariable} of ${totalPages}` }}
                            />
                        </div>
                    </>
                </div>
            </>
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(UCReservedPricingTable);