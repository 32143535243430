import * as React from 'react';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { BsSearch } from "react-icons/bs";
import { makeStyles } from '@material-ui/styles';
import { Col, Table, Row, Container } from 'react-bootstrap';
import magnifyIcon from 'src/assets/img/vehicles/magnifyIcon.png';
// import magnifyicon_hover from 'src/shared/img/magnifyicon_hover.png';
import closebutton from 'src/assets/img/vehicles/closebutton.png';
// import closebutton_hover from 'src/shared/img/closebutton_hover.png';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles({
  root: {
    width: '20%',
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'flex-end',
    position: 'relative',
    backgroundColor: '#131313',
    borderColor: 'transparent',
    zIndex: 200,
    borderStyle: 'solid',
    borderWidth: '1px 1px',
    marginTop: '10px',
    marginLeft: '15px',
    marginBottom: '25px',
    // transition: 'backgroundColor ',
    transform: 'translate(-50%,0%)',
    transition: 'all 2.9s ease-in-out',

    height: (props) => props.priceBucketActiveTab ? '35px' : '34px',
    borderRadius: '30px',
    '&:hover': {
      backgroundImage: 'linear-gradient(#131313, #131313), linear-gradient(to right, #1EF1C6 0%, #1EF1C6 100%)',
      borderRadius: '30px',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      borderWidth: '2px',
    },
    '&:focus-within': {
      backgroundImage: 'linear-gradient(#131313, #131313), linear-gradient(to right, #5D1EF1 0%, #1EF1C6 100%)',
      borderRadius: '30px',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      borderWidth: '2px',
    },
  },
  input: {
    flex: 'auto',
    paddingLeft: '10px',
    '&.Mui-focused': {
      color: "#bfbfbf",
    }
  },
  iconbutton: {
    color: "#bfbfbf",
    marginLeft: '-12px',
    float: 'left'
  },
  iconbuttonclose: {
    color: "#bfbfbf",
    marginRight: '-12px',
    float: 'right'
  },
  button: {
    backgroundColor: (props) => props.priceBucketActiveTab ? '#1ef1c6' : 'rgba(255, 255, 255, 0.1)',
    color: (props) => props.priceBucketActiveTab ? '#000' : '#1ef1c6',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: '#088a70',
      color: '#fff'
    },
    '&:selected': {
      backgroundColor: '#088a70',
      color: '#fff'
    },
    width: '140px',
    marginBottom: '10px'

  },
  heading: {
    "&.MuiTypography-root": {
      color: "#ff0000",
      fontFamily: 'LynkcoTypeLight,LynkcoTypeMedium'
    }
  },

});

export default function SearchField(props) {
  const { handleSearch, handleSearchInput, placeholder, onKeyPress, disabled, priceBucketActiveTab = false, defaultValue, mapstyle = false, handleSearchbar } = props
  const classes = useStyles(props);
  
  return (
    <div className={classes.root}
    style={{ borderRadius: '30px', }}
      component="form"
    >
      <Button className={classes.iconbutton} aria-label="search" onClick={handleSearch}>
        <img
        src={magnifyIcon}
        className="magnifyIcon"
        />
      </Button>
      <InputBase
        className={classes.input}
        inputProps={{ 'aria-label': 'Search license plate' }}
        onChange={handleSearchInput}
        placeholder={placeholder}
        // onKeyPress={onKeyPress}
        disabled={disabled}
        defaultValue={defaultValue}
      />
      <Button className={classes.iconbuttonclose} aria-label="search" onClick={handleSearchbar}>
        <Tooltip
        title="close search"
        >
          <IconButton>
          <img 
        src={closebutton}
        className="closebutton"
        />
          </IconButton>
        </Tooltip>
       
      </Button>
    </div>
  );
};

export const SearchButtonSalesSettings = (props) => {
  const { handleSearch, placeholder, priceBucketActiveTab = false } = props
  const classes = useStyles(props);
  return (
    <Button onClick={handleSearch} className={classes.button} variant="contained" placeholder={placeholder}>
      {placeholder}
    </Button>
  );
};