import React, { Fragment, useState } from "react";
import TodoList from "src/components/todos/TodoList";
import TodoSearch from "src/components/todos/TodoSearch";
import TodoDetails from "../../components/todos/TodoDetails";
import TodosFilter from "../../components/todos/TodosFilter";
import "./Todo.scss";
import DefaultPage from 'src/pages/backgrounds/default-page';
import { permission } from 'src/auth/permissions';
import { selectUserPermissions } from "src/redux/user/user.selectors";
import { connect } from "react-redux";

const Todo = ({ userPermissions }) => {
  //const [Todos, setTodos] = useState([]);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setshowRight] = useState(false);

  const showLeftPart = () => {
    setShowLeft(!showLeft);
  };

  const showRightPart = () => {
    setshowRight(!showRight);
  };

  const matchesPermission = userPermissions.includes(permission.Todo_Reader);

  return (
    <>
      {
        matchesPermission ?

          <div className="app-drawer" style={{
            marginTop: '25px', border: 'solid 0.3px #595959', marginLeft: '-30px', marginRight: '-25px', marginLeft: '35px',
            position: "fixed",
            height: "100%",
            width: "100%",
            display: "block",
            top: "0",
            left: "0",
            padding: "55px",
            backgroundImage: 'linear-gradient(60deg, rgba(13,13,13,1), rgba(37,37,37,1) 99%)'
          }}>
            <div
              className={
                "left-part bg-white" + (showLeft === true ? "show-panel" : "")
              }
            >
              <TodosFilter />
            </div>
            <div className="right-part bg-white app-drawer-content">
              <div
                className={
                  "right-left-part " +
                  (showRight === true ? "show-right-left-panel" : "")
                }
              >
                <span
                  onClick={showLeftPart.bind(null)}
                  className={
                    "bg-primary show-left-part text-white d-block d-lg-none " +
                    (showLeft === true ? "left-part-open" : "")
                  }
                >
                  <i
                    className={
                      showLeft === true
                        ? "fas fa-chevron-left"
                        : "fas fa-chevron-right"
                    }
                  ></i>
                </span>
                <TodoSearch />
                <TodoList showRightPart={showRightPart.bind(null)} />
              </div>
              <div
                className={
                  "right-right-part " +
                  (showRight === true
                    ? "show-right-right-panel"
                    : "hide-right-right-part")
                }
              >
                <span
                  onClick={() => {
                    showRightPart();
                  }}
                  className={
                    "hide-right-right-part d-block d-md-none " +
                    (showRight === true ? "right-right-part-open" : "")
                  }
                >
                  <i className="fas fa-times"></i>
                </span>
                <TodoDetails />
              </div>
            </div>
          </div>
          :
          <DefaultPage variant='true' />
      }
    </>
  );
};

const mapStateToProps = state => ({
  userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(Todo);
