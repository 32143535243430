import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useToolbarStyles } from 'src/assets/jss/components/tableToolbarStyle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import { RiSave2Line } from 'react-icons/ri';
import { selectUserPermissions } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';
import { permission } from 'src/auth/permissions';

const EnhancedRvCurveTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { handleClickDownload, handleClickUpload, handleClickIsEditable, handleClickSave, isEnableSave, userPermissions } = props;
    const matchesPermission = userPermissions.includes(permission.Remarketing_Administrator)

    return (
        <div>
            {matchesPermission ? (
                <>
                    <Toolbar className={classes.root3} >
                        <Typography className={classes.title3} variant="h4" id="tableTitle" component="div">
                        </Typography>
                        <div className={classes.tooltipDiv}>
                            <Tooltip className={classes.tooltipImg3} title="Upload" onClick={handleClickUpload} style={{ marginTop: '-107px', fontSize: '20px', letterSpacing: '30px', cursor: 'pointer' }}>
                                <span>
                                    <i className="mr-2 mdi mdi-upload" />
                                </span>
                            </Tooltip>
                            <Tooltip className={classes.tooltipImg3} title="Download" onClick={handleClickDownload} style={{ marginTop: '-107px', fontSize: '20px', letterSpacing: '15px', cursor: 'pointer' }}>
                                <span>
                                    <i className="mr-2 mdi mdi-download" />
                                </span>
                            </Tooltip>
                            {isEnableSave ? (<Tooltip className={classes.tooltipImg3} title="save" onClick={handleClickSave} style={{ marginTop: '-113px', fontSize: '15px', marginLeft: '-5px' }}>
                                <span>
                                    <IconButton aria-label="save">
                                        <RiSave2Line />
                                    </IconButton>
                                </span>
                            </Tooltip>) : (<Tooltip className={classes.tooltipImg3} title="Edit" onClick={handleClickIsEditable} style={{ marginTop: '-111px', fontSize: '15px', marginLeft: '-1px' }}>
                                <span>
                                    <IconButton aria-label="edit">
                                        <EditIcon style={{ width: '20px', height: '18px' }} />
                                    </IconButton>
                                </span>
                            </Tooltip>)}
                        </div>
                    </Toolbar>
                </>) : (
                <>
                    <Toolbar className={classes.root3} >
                        <Typography className={classes.title3} variant="h4" id="tableTitle" component="div">
                        </Typography>
                        <div className={classes.tooltipDiv}>
                            <Tooltip className={classes.tooltipImg3} title="Download" onClick={handleClickDownload} style={{ marginTop: '-113px', fontSize: '20px', letterSpacing: '-15px', cursor: 'pointer' }}>
                                <span>
                                    <i className="mr-2 mdi mdi-download" />
                                </span>
                            </Tooltip>
                        </div>
                    </Toolbar>
                </>)}
        </div>
    );
};

EnhancedRvCurveTableToolbar.propTypes = {
    handleClickUpload: PropTypes.func.isRequired,
    handleClickDownload: PropTypes.func.isRequired,
    handleClickIsEditable: PropTypes.func.isRequired,
    handleClickSave: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(EnhancedRvCurveTableToolbar)