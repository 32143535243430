import { environment } from 'src/environments/environment';
import { msalInstance } from "src/index";
import { loginRequest } from "src/authConfig";

export async function GetFleetTypes(orgId) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a user has been signed in and setActiveAccount has been called.</div>)
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    // headers.append("Authorization", bearer);
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    var url = new URL(`${environment.backendVehicleService}/api/FleetType/CreatedByOrganizationId`);
    var params = { organizationId: orgId }
    url.search = new URLSearchParams(params).toString();

    let apiData = await fetch(url, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;

}
