import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: "50%",
    "& .MuiOutlinedInput-input": {
      color: "white",
      backgroundColor: (props) => props.varianttype ? "transparent" : "rgb(33, 33, 33)",
      borderRadius: '4px',
      fontSize: '15px',
    },
    "& .MuiInputLabel-root": {
      color: "white",
      backgroundColor: "transparent",
      fontSize: '13px',
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: 'transparent',
      borderRadius: '1px'
    },

    "&:hover .MuiOutlinedInput-input": {
      color: "white"
    },
    "&:hover .MuiInputLabel-root": {
      color: "white"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: (props) => props.varianttype ? "transparent" : "#1EF1C6",
      borderRadius: '4px'
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: (props) => props.varianttype ? "white" : "#1EF1C6",
      borderRadius: '4px'
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: (props) => props.varianttype ? "transparent" : "#1EF1C6",
      borderRadius: '4px',
      backgroundColor: (props) => props.varianttype ? "transparent" : "rgb(33, 33, 33)",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: (props) => props.varianttype ? "transparent" : "#1EF1C6",
      backgroundColor: (props) => props.varianttype ? "transparent" : "rgb(33, 33, 33)",
      color: (props) => props.varianttype ? "transparent" : "#1EF1C6",
      borderRadius: '4px'
    },
  },

  dropdownStyle: {
    border: "1px solid black",
    color: '#fff',
    width: '20px',
    borderRadius: '4px',
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: "#2c2c2c",
      borderRadius: '4px',
    },
    '& .MuiListItem-root': {
      // background: '#222222',
      fontSize: '13px',
      fontFamily: 'LynkcoTypeLight',
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#212121",
      borderRadius: '4px'
    },
    "& .MuiMenuItem-root.Mui-selected:hover": {
      backgroundColor: "#1EF1C6",
      color: '#000',
      borderRadius: '4px'
    },
    "&::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      opacity: "0.3",
      backgroundColor: "#101010"
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "1px solid transparent",
      backgroundColor: "#1EF1C6"
    }
  }
}));

const FormSelect = (props) => {
  const { open, onClose, onOpen, value, onChange, menuObjectArray, label, id, name, variant, varianttype = false } = props
  const classes = useStyles(props);

  return (
    <FormControl className={classes.root} variant={variant ? variant : "outlined"} margin='dense' varianttype={varianttype}>
      <InputLabel id={`${label}-label`}>{varianttype ? 'Case Type' : 'label'}</InputLabel>
      <Select
        labelId={`${label}-label`}
        IconComponent={ChevronDownIcon}
        id={id}
        name={name}
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        value={value}
        onChange={onChange}
        MenuProps={{ classes: { paper: classes.dropdownStyle, }, disableScrollLock: true }}
      >
        {/* <MenuItem value="0">
          <em style={{ fontStyle: 'normal' }}>{varianttype ? 'Select Case Type' : label}</em>
        </MenuItem> */}
        {
          menuObjectArray.map(menu => {
            return (<MenuItem value={menu.key}>{menu.value}</MenuItem>);
          })
        }
      </Select>
    </FormControl>
  );
}

export default FormSelect;