export async function ReverseGeocoding(lat, lng) {
  let responseMessage = {
    status: "",
    message: "",
    errorMessage: ""
  }

  const latlng = {
    lat: lat,
    lng: lng,
  };

  const geocoder = new window.google.maps.Geocoder();

  let responseData = geocoder.geocode({ location: latlng })
    .then((response) => {
      if (response.results[0]) {
        responseMessage['status'] = 'ok'
        responseMessage['message'] = response.results[0].formatted_address
        return responseMessage
      } else {
        responseMessage['status'] = 'no content'
        responseMessage['errorMessage'] = "No results found"
        return responseMessage
      }
    })
    .catch((e) => {
      responseMessage['status'] = 'error'
      responseMessage['errorMessage'] = "Geocoder failed due to: " + e
      return responseMessage
    });

  return responseData
}


export async function Geocoding(address = '') {
  let responseMessage = {
    status: "",
    message: "",
    errorMessage: ""
  }

  const geocoder = new window.google.maps.Geocoder();

  let responseData = geocoder.geocode(address)
    .then((response) => {
      if (response.results[0]) {
        responseMessage['status'] = 'ok'
        responseMessage['message'] = response.results
        return responseMessage
      } else {
        responseMessage['status'] = response.status
        responseMessage['errorMessage'] = "No results found"
        return responseMessage
      }
    })
    .catch((e) => {
      responseMessage['status'] = 'error'
      responseMessage['errorMessage'] = "Geocoder failed due to: " + e
      return responseMessage
    });

  return responseData
}