import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SVTCards from 'src/pages/svt-page/SVTCards';
import 'src/pages/svt-page/dashboard.css';
import 'src/pages/svt-page/standingorder.css';
import MapPage from 'src/pages/svt-page/MapPage';

const SVTMainComponent = ({ mapheight = true, selectedVIN, valueVIN }) => {

    const [vehiclePosition, setVeiclePosition] = React.useState({});

    return (
        <Col md={12} lg={12}>
            <Row style={{ marginLeft: '' }}>
                <Col md={12} sm={12} xs={12} lg={12}>
                    <MapPage mapheight={mapheight} vehiclePosition={vehiclePosition} setVeiclePosition={setVeiclePosition} selectedVIN={selectedVIN}  valueVIN={valueVIN} />
                </Col>

                {/* <Col md={12} sm={12} xs={12} lg={2} style={{ marginTop: '65px' }}>
                    <SVTCards vehiclePosition={vehiclePosition} />
                </Col> */}
            </Row>
        </Col>
    )

};

export default SVTMainComponent;
