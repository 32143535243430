import React, { useState, useMemo } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ReactTableBase from 'src/components/tables/newtable/table/ReactTableBase';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FormmatDate } from 'src/utils/DateFormatter';
import { useTableStyles } from 'src/assets/jss/components/tableStyle';
import EnhancedTableToolbar from 'src/components/tables/table-toolbar.component';
import AlertDialog from 'src/components/form-elements/dialog-window.component';
import Drawer from '@material-ui/core/Drawer';
import FleetAddEdit from 'src/components/form-elements/fleet-add-edit.component';
import { GetFleets, CreateFleet, UpdateFleet, DeleteFleet, SearchFleet, GetFleetPaged } from 'src/services/fleet-http.service';
import { selectUserOrganization, selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import TablePagination from '@material-ui/core/TablePagination';
import { permission } from 'src/auth/permissions';
import SearchBar from 'src/components/buttons/search-icon-button.component';

let rowsPerPage = 50;

let newPageVariable = 1;

const drawerWidth = 240;

const useDrawerStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: '45px'
    },
    paper: {
        '&:: -webkit-scrollbar': {
            width: 'Zpx',
            height: '1px',
            backgroundColor: '#aaa',
            background: '#000',
        },
        width: '100%',
        marginBottom: theme.spacing(2),
        overflow: 'auto',
        marginTop: theme.spacing(5),
        marginTop: '30px',
    },

    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        "&.MuiDrawer-paperAnchorDockedRight": {
            top: '160px'
        }
    },
    drawerPaper: {
        width: drawerWidth,
        height: 'max-content',
        "&.MuiDrawer-paperAnchorDockedRight": {
            top: '9%',
            right: '45px'
        }
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const FleetTable = ({ userOrg, userPermissions }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [isSortable, setIsSortable] = useState(false);
    const [isDisabledDragAndDrop, setIsDisabledDragAndDrop] = useState(false);
    const [isDisabledEditable, setIsDisabledEditable] = useState(false);
    const [isDisabledResizable, setIsDisabledResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withPagination, setWithPaginationTable] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [selected, setSelected] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [fleetNameInput, setFleetNameInput] = React.useState('')
    const classes = useTableStyles();
    const drawerClasses = useDrawerStyles();
    const [page, setPage] = React.useState(0);
    const [rows, setFleetRows] = React.useState([]);
    const [openDeleteDialog, setDeleteDialog] = React.useState(false);
    const [totalPages, setTotalPages] = React.useState(0);
    const [fleet, setFleet] = React.useState({
        id: "",
        name: "",
        createdDateUtc: "",
        vehiclesCount: "",
        editMode: false
    });

    const [searchInput, setSearchInput] = React.useState('');

    const columns = useMemo(
        () => [
            { Header: 'Fleet Name', accessor: "name", disableGlobalFilter: true, },
            { Header: 'Created', accessor: "createdDateUtc", disableGlobalFilter: true, },
            { Header: 'Fleet Size', accessor: "vehiclesCount", disableGlobalFilter: true, }
        ],
        [],
    );

    const [selectedRows, setSelectedRows] = useState({ "0": true, "9": true });

    React.useEffect(() => {
        initializeFleets();
        setDeleteDialog(false);
    }, []);

    React.useEffect(() => {
        if (searchInput?.length === 0) {
            initializeFleets();
        }

    }, [searchInput]);

    const initializeFleets = () => {
        let fleetArray = [];
        let fleetIdArray = [];

        GetFleets(userOrg.id)
            .then(data => {
                !Array.isArray(data) ?
                    fleetIdArray.push("")
                    : data.map(fleet => {
                        fleetIdArray.push(fleet.id);
                    });

                GetFleetPaged({ pageNumber: newPageVariable, pageSize: rowsPerPage, fleetIdArray })
                    .then(data => {
                        if (data && Array.isArray(data.fleets)) {
                            data.fleets.map(fleet => {
                                setTotalPages(data.totalPages)
                                fleetArray.push(
                                    {
                                        id: fleet.id,
                                        name: fleet.name,
                                        createdDateUtc: FormmatDate(fleet.createdDateUtc),
                                        vehiclesCount: fleet.vehiclesCount
                                    }
                                );
                            });
                        }
                        setFleetRows(fleetArray);
                    });
            });
    };

    const handleClickAdd = () => {
        setOpen(true);
        setFleet({
            ...fleet, id: "", name: "", createdDateUtc: "", vehiclesCount: "", editMode: false
        });
    };

    const handleClickEdit = () => {
        if (selectedRow !== null) {
            setFleet({
                ...fleet, id: selectedRow.id, name: selectedRow.name,
                createdDateUtc: selectedRow.createdDateUtc, vehiclesCount: selectedRow.vehiclesCount, editMode: true
            });
            setOpen(true);
        }
    };

    const handleDeleteDialogContinue = () => {
        DeleteSelectedFleet(selected);
    }

    const handleDeleteDialogCancel = () => {
        setDeleteDialog(false)
        setSelected('');
    }

    const handleClickDelete = () => {
        if (selected.length !== 0) {
            setDeleteDialog(true);
        }
    };

    const DeleteSelectedFleet = (selectedValue) => {
        if (selectedValue.id === selectedRow.id) {
            DeleteFleet(selectedValue.id).then(data => {
                initializeFleets();
                setSelected('');
                setDeleteDialog(false)
            })
        }
    };

    const saveFleet = () => {
        fleet.editMode ?
            UpdateFleet({
                "id": fleet.id,
                "name": fleet.name
            })
                .then(data => {
                    initializeFleets();
                    setSelected('');
                    resetFleetVehicle();
                })
            :
            CreateFleet({
                "name": fleet.name,
                "createdByOrganizationId": userOrg.id
            })
                .then(data => {
                    initializeFleets();
                    setSelected('');
                    resetFleetVehicle();
                });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDone = () => {
        setOpen(false);
        saveFleet();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFleet({ ...fleet, [name]: value });
    };

    const handleClick = (event, name, row) => {
        let newSelected = selected;

        if (name !== selected) {
            newSelected = name;
        }
        setSelected(newSelected);
        setSelectedRow({
            id: row.id,
            name: row.name,
            createdDateUtc: row.createdDateUtc,
            vehiclesCount: row.vehiclesCount
        });
        setOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        newPageVariable = newPage + 1
        setPage(newPage);
        initializeFleets();

    };

    const handleChangeRowsPerPage = (event) => {
        newPageVariable = 1
        rowsPerPage = parseInt(event.target.value, 10);
        initializeFleets();
    };

    const resetFleetVehicle = () => {
        setFleet({
            id: "",
            name: "",
            createdDateUtc: "",
            vehiclesCount: "",
            editMode: false
        });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1 && selected.length === name.length;

    const handleClickIsEditable = () => {
        if (!withDragAndDrop) setIsDisabledResizable(!isDisabledResizable);
        setIsResizable(false);
        setIsEditable(!isEditable);
    };
    const handleClickIsResizable = () => {
        setIsEditable(false);
        setWithDragAndDrop(false);
        setIsDisabledDragAndDrop(!isDisabledDragAndDrop);
        setIsDisabledEditable(!isDisabledEditable);
        setIsResizable(!isResizable);
    };
    const handleClickIsSortable = () => {
        setIsSortable(!isSortable);
    };
    const handleClickWithDragAndDrop = () => {
        if (!isEditable) setIsDisabledResizable(!isDisabledResizable);
        setIsResizable(false);
        setWithDragAndDrop(!withDragAndDrop);
    };
    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };
    const handleClickWithSearchEngine = () => {
        setWithSearchEngine(!withSearchEngine);
    };

    const selectedRowValues = (row) => {
        setSelectedRow(row.original)
        setSelected(row.original)
    }

    const tableConfig = {
        isEditable,
        isResizable: true,
        isSortable: true,
        withDragAndDrop,
        withPagination: false,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by first name...',
    };

    //................. Search.....................//

    const handleSearchInput = (event) => {
        setSearchInput(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            setSearchInput('');
        }
    };

    const handleSearch = () => {
        if (searchInput) {
            let fleetArray = [];

            SearchFleet(searchInput.trim())
                .then(data => {
                    if (data && Array.isArray(data)) {
                        data.map(fleet => {
                            fleetArray.push(
                                {
                                    id: fleet.id,
                                    name: fleet.name,
                                    createdDateUtc: FormmatDate(fleet.createdDateUtc),
                                    vehiclesCount: fleet.vehiclesCount
                                }
                            );
                        });
                    }
                    setFleetRows(fleetArray);
                });
        }
    }

    const matchesPermission = userPermissions.includes(permission.Administration_Administrator);

    const textFieldKeypress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Row>
            <Col md={12} lg={12}>
                <Card id="CardBodyCardAllowance3" style={{ marginTop: '75px', marginRight: '11px', borderRadius: '4px' }} >
                    <AlertDialog
                        open={openDeleteDialog}
                        message={`You are about to delete: ${selectedRow.name} ?`}
                        name={selectedRow.name}
                        handleDeleteDialogCancel={handleDeleteDialogCancel}
                        handleDeleteDialogContinue={handleDeleteDialogContinue} />
                    <CardBody>
                        {matchesPermission ? (
                            <>
                                <span>
                                    <span style={{ fontSize: '18px', float: 'left', color: 'darkgray' }}>
                                        <div style={{ width: '498%', marginLeft: '-10px', }}>
                                            <SearchBar
                                                placeholder={'Fleet Name'}
                                                handleSearch={handleSearch}
                                                handleSearchInput={handleSearchInput}
                                                onKeyPress={textFieldKeypress}
                                                value={searchInput}
                                                priceBucketActiveTab='true'
                                                autoFocus='true'
                                            />
                                        </div>
                                    </span>
                                </span>
                                <EnhancedTableToolbar selectedRowId={selected}
                                    handleClickDelete={handleClickDelete}
                                    handleClickEdit={handleClickEdit}
                                    handleClickAdd={handleClickAdd}
                                />

                                <div className="react-table__wrapper">
                                    <div className="card__title">
                                        <div>
                                        </div>

                                    </div>
                                </div>
                                <ReactTableBase
                                    key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                    columns={columns}
                                    data={rows}
                                    tableConfig={tableConfig}
                                    onClick={(event) => handleClick(event, rows.email, rows)}
                                    onRowSelected={handleClick}
                                    isSelected={isSelected}
                                    selectedRows={selectedRows}
                                    onSelectedRowsChange={setSelectedRows}
                                    selectedRowValues={selectedRowValues}
                                />
                            </>) : (
                            <>
                                <div className="react-table__wrapper">
                                    <div className="card__title">
                                        <div>
                                        </div>

                                    </div>
                                </div>
                                <span>
                                    <span style={{ fontSize: '18px', float: 'left', color: 'darkgray' }}>
                                        <div style={{ width: '498%', marginLeft: '-10px', }}>
                                            <SearchBar
                                                placeholder={'Fleet Name'}
                                                handleSearch={handleSearch}
                                                handleSearchInput={handleSearchInput}
                                                onKeyPress={textFieldKeypress}
                                                value={searchInput}
                                                priceBucketActiveTab='true'
                                                autoFocus='true'
                                            />
                                        </div>
                                    </span>
                                </span>
                                <ReactTableBase
                                    key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                                    columns={columns}
                                    data={rows}
                                    tableConfig={tableConfig}
                                    onClick={(event) => handleClick(event, rows.email, rows)}
                                    onRowSelected={handleClick}
                                    isSelected={isSelected}
                                    selectedRows={selectedRows}
                                    onSelectedRowsChange={setSelectedRows}
                                    selectedRowValues={selectedRowValues}
                                />
                            </>)}
                        <Drawer
                            variant="permanet"
                            anchor="right"
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            classes={{
                                paper: drawerClasses.drawerPaper,
                            }}
                        >
                            <FleetAddEdit fleet={fleet} onChange={handleChange}
                                handleClose={handleClose} handleDone={handleDone}></FleetAddEdit>
                        </Drawer>
                        <TablePagination
                            style={{ marginTop: '42px' }}
                            classes={{
                                toolbar: classes.paginationToolbar,
                                caption: classes.paginationCaption
                            }}
                            SelectProps={{
                                MenuProps: {
                                    classes: { paper: classes.selectStyle },
                                    disableScrollLock: true,
                                }
                            }}
                            rowsPerPageOptions={[50, 100, 150, 200]}
                            component="div"
                            count={totalPages * rowsPerPage}
                            rowsPerPage={rowsPerPage}
                            page={newPageVariable - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={<span>Rows per page:</span>} labelDisplayedRows={({ page }) => { return `Page: ${newPageVariable} of ${totalPages}`; }}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userPermissions: selectUserPermissions(state),
    userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(FleetTable)