import styled from 'styled-components';
// import {
//   colorAccent,
//   colorAdditional,
//   colorBackgroundBody,
//   colorBorder, colorDustyWhite,
//   colorFieldsBorder,
//   colorText,
//   colorWhite,
// } from '@/utils/palette';
// import {
//   left,
//   paddingLeft,
//   direction,
//   marginLeft,
//   marginRight,
// } from '@/utils/directions';
// import { ButtonToolbar } from '../Button';

export const FormGroup = styled.div`
  margin-bottom: 50px;
  width: 100%;
  position: relative;
`;

export const FormGroupField = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
  direction: ltr;
`;

export const FormGroupLabel = styled.span`
  margin-bottom: 4px;
  display: inline-block;
  color: #dddddd;

  span {
    color: #dddddd;
  }
`;

// export const FormButtonToolbar = styled(ButtonToolbar)`
//   margin-top: 10px;
//   direction: ltr;
// `;

export const FormGroupDescription = styled.span`
  font-size: 10px;
  color: '#999999';
  line-height: 13px;
  margin-top: 2px;
`;

export const FormGroupIcon = styled.div`
  padding: 6px;
  height: 40px;
  background: transparent;
  border: 1px solid #33333a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-right: none;

  svg {
    fill: #999999;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  direction: ltr;

  input, textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: 1px solid #33333a;
    border-right: 1px solid transparent;

    border-left: 1px solid transparent;

    color: #dddddd;
    
    &::-webkit-input-placeholder {
      color: #999999;
    }
    &::-moz-placeholder {
      color: #999999;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: '#999999';
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: #999999;
    }
    
    &[disabled] {
      background: #2a2a31;

      &:focus, &:active {
        border-color: #333246;
      }
    }

    &:focus, &:active {
      outline: none;
      border-color: #4ce1b6;
    }
  }

  textarea {
    min-height: 85px;
  }

  ${props => props.horizontal && `

    ${FormGroup} {
      display: flex;
      flex-wrap: wrap;
    }

    ${FormGroupField} {
      width: calc(100% - 80px);
      ${`padding-left`(props)}: 10px;
      ${`margin-left`(props)}: 80px;
    }

    ${FormGroupLabel} {
      width: 80px;
      max-height: 32px;
      line-height: 18px;
      margin: auto 0;

      & + ${FormGroupField} {
        ${`margin-left`(props)}: 0;
      }
    }

    ${FormGroupDescription} {
      ${`margin-left`(props)}: 90px;
    }

    

    @media screen and (min-width: 480px) {

      ${FormGroupLabel} {
        width: 120px;
      }

      ${FormGroupField} {
        width: calc(100% - 120px);
        ${`margin-left`(props)}: 120px;
        ${`padding-left`(props)}: 20px;
      }

      
    }
  `}

  ${props => props.preview && `
    display: flex;

    & > div:nth-child(2) {
      ${`margin-right`(props)}: 50px;
    }

    ${FormGroup} {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    p {
      margin-bottom: 10px;
    }
  `}

  ${props => props.justify && `
    display: flex;
    flex-wrap: wrap;

    ${FormGroup} {
      width: 33.3333%;
    }
  `}
`;

export const FormHalfContainer = styled.div`
  width: calc(50% - 15px);
  height: 100%;

  &:first-child {
    margin-left: 30px;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const FormFieldButton = styled.button`
  padding: 6px;
  height: 40px;
  cursor: pointer;
  transition: all 0.3s;
  background: ${props => (props.active ? '#4ce1b6' : 'transparent')};
  border: 1px solid ${props => (props.active ? '#4ce1b6' : '#33333a')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-left: none;

  svg {
    fill: ${props => (props.active ? '#ffffff' : '#999999')};
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
`;
