import DefaultPage from './pages/backgrounds/default-page';
import VehicleTabs from 'src/components/tabs/vehicle-tabs.component.New'
import RvTabs from 'src/components/tabs/rvCurve-tabs-New.component';
import DashboardTabs from 'src/components/tabs/dashboard-tabs.component';
import AssetMovementDashboard from 'src/pages/dashboard-page/assetMovement-dashboard-page';
import RemarketingDashboard from 'src/pages/dashboard-page/remarketing-dashboard-page';
import UtilizationDashboard from 'src/pages/dashboard-page/utilization-dashboard-page';
import Subscriptions from 'src/pages/dashboard-page/subscriptions-dashboard-page';
import VehicleInsights from 'src/pages/dashboard-page/vehicleInsights-dashboards-page';
import M2MTable from 'src/pages/Tables/DataTable/indexM2M';
import CashCarsTable from 'src/pages/Tables/DataTable/components/CashCarsTable';
import MapPage from 'src/pages/maps/MapPage';
import ProvisioningPage from 'src/pages/driver-page/ProvisioningPage';
import UnProvisioningPage from 'src/pages/driver-page/UnProvisioningPage';
import RvReactTable from 'src/pages/Tables/DataTable/indexRV';
import SalesSettings from 'src/pages/sales-settings-page/SalesSettings';
import Todo from 'src/pages/todo/Todo';
import InternalFleetsTable from 'src/pages/Tables/DataTable/indexInternalFleets';
import UserTable from 'src/pages/Tables/DataTable/indexUser';
import OrganizationTable from 'src/pages/Tables/DataTable/indexOrganization';
import FleetTable from 'src/pages/Tables/DataTable/indexFleet'
import RoleTable from 'src/pages/Tables/DataTable/indexRole';
import PermissionPage from 'src/pages/permission-page/PermissionPage';
import ReportsMainPage from 'src/pages/reports/ReportsMainPage';
import BMCPricing from 'src/pages/bmc-pricing/BMCPricing';
import Administration from 'src/pages/driver-page/Administration';
import * as AppSettings from 'src/AppSettings.json';
import UndefinedStockTable from 'src/pages/Tables/DataTable/components/UndefinedStockTable';
import VSPDetailsTabs from 'src/components/tabs/vspDetails-tabs.component';
import LocationTabs from 'src/components/tabs/location-tabs.component';
import ConnectivityTabs from 'src/components/tabs/connectivity-tabs.component';
import administrationTabsComponentNew from 'src/components/tabs/administration-tabs.componentNew';
import SVTTab from 'src/components/tabs/svt-tabs.component';
import MyOpenCasesTable from 'src/pages/Tables/DataTable/components/MyOpenCasesTable';
import ArcEuropePage from 'src/pages/Tables/DataTable/components/ArcEuropePage';

const routes = [
  { path: '/', exact: true, name: 'Home', component: MyOpenCasesTable },
  { path: '/dashboard', name: 'Dashboards', component: DashboardTabs, exact: true },
  { path: '/dashboard/assetMovement', name: 'Asset Movement', component: AppSettings.config["environment"] === "production" ? DefaultPage : AssetMovementDashboard, exact: true },
  { path: '/dashboard/remarketing', name: 'Remarketing', component: AppSettings.config["environment"] === "production" ? DefaultPage : RemarketingDashboard, exact: true },
  { path: '/dashboard/utilization', name: 'Utilization', component: AppSettings.config["environment"] === "production" ? DefaultPage : UtilizationDashboard, exact: true },
  { path: '/dashboard/Subscriptions', name: 'Subscriptions', component: AppSettings.config["environment"] === "production" ? DefaultPage : Subscriptions, exact: true },
  { path: '/dashboard/VehicleInsights', name: 'Vehicle Insights', component: AppSettings.config["environment"] === "production" ? DefaultPage : VehicleInsights, exact: true },

  { path: '/vehicles', name: 'Vehicles', component: VehicleTabs, exact: true },
  { path: '/myOpenCases', name: 'My Open Cases', component: MyOpenCasesTable, exact: true },
  { path: '/vehicles/undefinedStock', name: 'Undefined Stock', component: UndefinedStockTable, exact: true },
  { path: '/vehicles/L&CAssets', name: 'L & C Assets', component: M2MTable, exact: true },
  { path: '/vehicles/soldCars', name: 'Sold Cars', component: CashCarsTable, exact: true },

  { path: '/locations', name: 'Locations', component: LocationTabs, exact: true },
  { path: '/locations/map', name: 'Maps', component: MapPage, exact: true },
  { path: '/locations/geofence', name: 'Geofence', component: DefaultPage, exact: true },

  { path: '/connectivity', name: 'Drivers', component: ConnectivityTabs, exact: true },
  { path: '/connectivity/provisioning', name: 'Provisioning', component: ProvisioningPage, exact: true },
  { path: '/connectivity/unprovisioning', name: 'Unprovisioning', component: UnProvisioningPage, exact: true },
  { path: '/connectivity/administration', name: 'Admininstration', component: AppSettings.config["environment"] === "production" ? DefaultPage : Administration, exact: true },

  { path: '/remarketing', name: 'Remarketing', component: RvTabs, exact: true },
  { path: '/remarketing/manageRV', name: 'Manage RV', component: RvReactTable, exact: true },
  { path: '/remarketing/salesSettings', name: 'Sales Settings', component: SalesSettings, exact: true },
  { path: '/remarketing/bmcPricing', name: 'BMC Pricing', component: BMCPricing, exact: true },

  { path: '/vsp', name: 'VSP', component: VSPDetailsTabs, exact: false },

  { path: '/reports', name: 'Reports', component: ReportsMainPage, exact: true },

  { path: '/svt', name: 'Stolen Vehicle Tracker', component: SVTTab, exact: true },

  { path: '/todos', name: 'Todo', component: Todo, exact: true },

  { path: '/admin', name: 'Administration', component: administrationTabsComponentNew, exact: true },
  { path: '/admin/users', name: 'Users', component: UserTable, exact: true },
  { path: '/admin/roles', name: 'Roles', component: RoleTable, exact: true },
  { path: '/admin/permissions', name: 'Permissions', component: PermissionPage, exact: true },
  { path: '/admin/organizations', name: 'Organizations', component: OrganizationTable, exact: true },
  { path: '/admin/fleets', name: 'Fleets', component: FleetTable, exact: true },
  { path: '/admin/internalAssets', name: 'Internal Assets', component: InternalFleetsTable, exact: true },

  { path: '/arcEurope', name: 'ARC Europe', component: ArcEuropePage, exact: true },

];

export default routes;