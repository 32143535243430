import React, { useState } from 'react';
import { Card, CardBody } from "reactstrap";
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';

const VehicleColourCard = ({ setQueryParamsColor, queryParamsColor, setDataLoaded }) => {

    const [checkedBlackCar, setCheckedBlackCar] = useState(true);
    const [checkedBlueCar, setCheckedBlueCar] = useState(true);

    let updatedVehicleColorArray = [];

    const switchHandlerBlueCar = (event) => {
        let value = event.target.checked;

        if (value == true) {
            updatedVehicleColorArray = [...queryParamsColor, 'Blue'];
            setQueryParamsColor(updatedVehicleColorArray);
            setDataLoaded(false);
        }

        if (value == false) {
            updatedVehicleColorArray = queryParamsColor.filter((item) => item !== 'Blue');
            setQueryParamsColor(updatedVehicleColorArray);
            setDataLoaded(false);
        }
        setCheckedBlueCar(event.target.checked);
    };

    const switchHandlerBlackCar = (event) => {
        let value = event.target.checked;

        if (value == true) {
            updatedVehicleColorArray = [...queryParamsColor, 'Black']
            setQueryParamsColor(updatedVehicleColorArray);
            setDataLoaded(false);
        }

        if (value == false) {
            updatedVehicleColorArray = queryParamsColor.filter((item) => item !== 'Black');
            setQueryParamsColor(updatedVehicleColorArray);
            setDataLoaded(false);
        }
        setCheckedBlackCar(event.target.checked);
    };

    return (
        <Card id="CardBodyCardAllowance5" style={{ marginBottom: '40px', height: '155px' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "15px", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF' }}>VEHICLE COLOR</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white' }}>
                    <tr style={{ marginTop: '20px' }}>
                        <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', }}>BLUE<br /></td>
                        <td style={{ float: 'right', marginTop: '-13px' }}>
                            <ToggleComponent checked={checkedBlueCar} onChange={switchHandlerBlueCar} />
                        </td>
                    </tr>

                    <tr >
                        <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', marginTop: '25px' }}>BLACK<br /></td>
                        <td style={{ float: 'right', marginTop: '13px' }}>
                            <ToggleComponent checked={checkedBlackCar}  onChange={switchHandlerBlackCar} />
                        </td>
                    </tr>
                </table>
            </CardBody>
        </Card>
    )
}

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(VehicleColourCard);