import { environment } from 'src/environments/environment';
import { msalInstance } from "src/index";
import { loginRequest } from "src/authConfig";
import { GetToken } from 'src/auth/userToken';

export async function QueryCEPCustomer(id, email, customerNumber, snowflakeId) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    // headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "id": id,
            "email": email,
            "customerNumber": customerNumber,
            "snowflakeId": snowflakeId
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/CepProxy/QueryCepCustomer`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

////////////////////////////////////////////////// To call Connectivity Cases in VSP Page

export async function GetVehicleConnectivityCases(vin) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/CepProxy/GetVehicleConnectivityCases?vin=${vin}`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

////////////////////////////////////////////////// To get Connectivity Cases in VSP Page


export async function GetConnectivityCaseDetailsByCaseNumber(caseNumber) {

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account! Verify a fleetVehicle has been signed in and setActiveAccount has been called.</div>)
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    // headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "caseNumber": caseNumber
        })
    };

    let apiData = await fetch(`${environment.backendVehicleService}/api/CepProxy/GetConnectivityCaseDetailsByCaseNumber`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}
