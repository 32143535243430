import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    zIndex: 300,
    "& .MuiOutlinedInput-input": {
      color: "white",
      backgroundColor: "rgb(33, 33, 33)",
      borderRadius: '40px',
      height: "1px",
      paddingTop: '10px',
      paddingBottom: '17px',
      fontSize: (props) => props.dropdownfont ? 'calc(5px + 0.390625vw)' : 'calc(7px + 0.390625vw)',
      boxShadow: '3px 5px 6px #000000',
      '@media (min-width: 1501px) and (max-width: 1600px)': {
        height: "1px",
        fontSize: (props) => props.dropDownFont ? 'calc(8.5px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 1401px) and (max-width: 1500px)': {
        height: "1px",
        fontSize: (props) => props.dropDownFont ? 'calc(5px + 0.390625vw)' : 'calc(9px + 0.390625vw)'
      },
      '@media (min-width: 1111px) and (max-width: 1400px)': {
        height: "1px",
        fontSize: (props) => props.dropDownFont ? 'calc(5px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
        paddingTop: '7px',
        paddingBottom: '12px'
      },
      '@media (min-width: 600px) and (max-width: 1110px)': {
        height: "1px",
        fontSize: (props) => props.dropDownFont ? 'calc(12px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
        paddingTop: '7px',
        paddingBottom: '9px'
      },
    },
    "& .MuiInputLabel-root": {
      color: "white",
      backgroundColor: "transparent",
      fontSize: (props) => props.dropdownfont ? 'calc(9px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
      marginTop: '-2px',
      zIndex: 1,

    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: 'rgba(255, 255, 255, 0.01)',
      borderRadius: '40px',
      color: 'white',
      zIndex: 1,

    },
    "&:hover .MuiOutlinedInput-input": {
      color: "white"
    },
    "&:hover .MuiInputLabel-root": {
      color: "white"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1EF1C6",
      borderRadius: '40px'
    },
    "& .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-input": {
      color: "white",
      borderColor: "#1EF1C6"
    },
    "& .MuiOutlinedInput-root .Mui-selected .MuiOutlinedInput-input": {
      color: "white",
      borderColor: "#1EF1C6"
    },
    "& .MuiInputLabel-root .Mui-focused": {
      color: "#white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.01)"
      // backgroundImage: 'linear-gradient(#131313, #131313), linear-gradient(to right, #5D1EF1 0%, #1EF1C6 100%)',
      // borderRadius: '30px',
      // backgroundOrigin: 'border-box',
      // backgroundClip: 'padding-box, border-box',
      // borderWidth: '2px',
      // -webkit-mask: 'linear-gradient(to top,transparent, #fff)'
      // mask: 'linear-gradient(to left, transparent 10%, #fff)',
      // webkitMasK: 'linear-gradient(to left, transparent 10%, #fff)'
      // maskImage: 'linear-gradient(rgb(0 0 0 / 90%), transparent)'
    },

  },
  dropdownStyle: {
    border: "1px solid black",
    color: '#fff',
    width: '20px',
    backgroundImage: 'linear-gradient(135deg, #000000, #272727 99%)',
    height: (props) => props.tabledropdownheight ? '200px' : props.tabledropdownheight ? '185px' : '300px',
    borderRadius: '9px',
    fontFamily: "LynkcoTypeLight",
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: "#2c2c2c",
      borderRadius: '8px',
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#212121",
      borderRadius: '8px',
    },
    "& .MuiMenuItem-root.Mui-selected:hover": {
      backgroundColor: "#1EF1C6",
      color: '#000',
      borderRadius: '8px'
    },
    "&::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      opacity: "0.3",
      backgroundColor: "#101010",
      borderRadius: '10px'
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "1px solid transparent",
      backgroundColor: "#1EF1C6"
    },
    "& .MuiMenuItem-root": {
      fontSize: (props) => props.dropdownfont ? 'calc(9px + 0.390625vw)' : 'calc(9px + 0.390625vw)',
      fontFamily: "LynkcoTypeLight",
    }
  }
}));

const DropdownSelect = (props) => {
  const { open, onClose, onOpen, value, onChange, menuObjectArray = [{ key: '', value: '' }], label, id, name, variant, disabled = false, dropdownfont = false, tabledropdownheight = false } = props
  const classes = useStyles(props);

  return (
    <div>
      <FormControl className={classes.root} variant={variant ? variant : "outlined"} margin='dense'>
        <InputLabel id={`${label}-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-label`}
          IconComponent={ChevronDownIcon}
          id={id}
          name={name}
          open={open}
          onClose={onClose}
          onOpen={onOpen}
          value={value}
          onChange={onChange}
          disabled={disabled}
          dropdownfont={dropdownfont}
          tabledropdownheight={tabledropdownheight}
          MenuProps={{
            classes: { paper: classes.dropdownStyle }, disableScrollLock: true
          }}
        >
          <MenuItem disabled value="">
            <em style={{ fontStyle: 'normal' }}>{label} </em>
          </MenuItem>
          {
            menuObjectArray.map((menu, key) => {
              return (<MenuItem key={key} value={menu.key}>{menu.value}</MenuItem>);
            })
          }
        </Select>
      </FormControl>
    </div>
  );
}

export default DropdownSelect;