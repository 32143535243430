import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import MarketSelect from 'src/components/form-elements/market-select.dashboard.component';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import {
  TodoList,
  Feeds,
  ExcessMileageView,
  UtilizationLevelOverview,
  TotalFleetSizeView,
  VehicleColorrView,
  EngineTypeView,
  WorkOrderStatusOverview,
  CarSwapRequired,
  CarSwapProgress
} from "../../components/dashboard/index.js";
import { makeStyles } from '@material-ui/core/styles';

const UseStyles = makeStyles({
  root: {
    overflow: 'auto', height: '96vh', backgroundColor: 'transparent', marginTop: '-10px', padding: '40px 0 10px 0',
    "&::-webkit-scrollbar": {
      height: "6px",
      width: "0.1px",
      opacity: "0.3",
      backgroundColor: 'rgb(19 19 19/ 1%)'
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      backgroundColor: 'rgb(19 19 19/ 1%)',
      border: 'none'
    },
  },
});

const UtilizationDashboard = ({ userOrg, userMarketIds }) => {
  const classes = UseStyles();

  const [isMarketSelected, setIsMarkedSelected] = useState(false)

  let selectedMarket = userMarketIds.toString()

  let marketId = 'ALL'

  const handleChange = (event, markets) => {
    const { name, value } = event.target;
    let orgsMarkets = [];
    marketId = value.split('#')[0];
    let marketNmae = value.split('#')[1];

    if (marketId == 'ALL') { selectedMarket = userMarketIds }
    else {
      selectedMarket = [marketId]
      setIsMarkedSelected(true)
    }
  }

  return (
    <div className={classes.root}>
      <div style={{ marginTop: '30px', width: '15%' }}>
        <MarketSelect
          orgId={userOrg.id}
          value={selectedMarket.value}
          userMarketIds={userMarketIds}
          handleChange={handleChange}
        />
      </div>

      <ExcessMileageView />
      {/* --------------------------------------------------------------------------------*/}
      {/* Row-1                                                                          */}
      {/* --------------------------------------------------------------------------------*/}
      <Row>
        <Col lg="4" md="12" className="d-flex align-items-stretch">
          <TotalFleetSizeView />
        </Col>
        <Col lg="4" md="12">
          <VehicleColorrView />
        </Col>
        <Col lg="4" md="12">
          <EngineTypeView />
        </Col>
      </Row>
      {/* --------------------------------------------------------------------------------*/}
      {/* Row-2                                                                          */}
      {/* --------------------------------------------------------------------------------*/}
      <Row>

        <Col lg="4" md="12" className="d-flex align-items-stretch">
          <WorkOrderStatusOverview />
        </Col>
        <Col lg="4" md="12">
          <CarSwapRequired />
          <CarSwapProgress />
        </Col>
        <Col lg="4">
          <UtilizationLevelOverview />
        </Col>
      </Row>
      {/* --------------------------------------------------------------------------------*/}
      {/* Row-3                                                                          */}
      {/* --------------------------------------------------------------------------------*/}
      <Row>
        <Col lg="6" >
          <Feeds />
        </Col>
        <Col lg="6" >
          <TodoList />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  userOrg: selectUserOrganization(state),
  userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(UtilizationDashboard);
