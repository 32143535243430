import React, { useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import MarketSelect from 'src/components/form-elements/market-select.component';
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import EnhancedSalesSettingsToolbar from 'src/pages/sales-settings-page/SalesSettings-toolbar.component';
import MasterBucket from 'src/pages/sales-settings-page/MasterBucket';
import { CreateSalesSetting, UpdateSalesSetting, VerifySalesSetting, refreshNumberofVins, DeleteSalesSettingVariant, QueryVehiclesInProductVariant } from 'src/services/sales-settings-http.service';
import SimpleAlertDialog from 'src/components/form-elements/simple-dialog-window.component';
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import VInListTable from '../Tables/DataTable/components/VInListTable';
import Drawer from '@material-ui/core/Drawer';
import { permission } from 'src/auth/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: '758px',
    marginRight: '-25px',
    paddingRight: '20px',
    marginLeft: '5px',
    marginTop: '-15px',
    "&::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      opacity: "0.3",
      backgroundColor: "rgba(255, 255, 255, 0.01)"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "none",
      backgroundColor: "rgba(255, 255, 255, 0.01)"
    }
  }
}));

const useDrawerStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: '40%',
    height: '66%',

    flexShrink: 0,
    "&.MuiDrawer-paperAnchorDockedRight": {
      top: '10px'
    }
  },
  drawerPaper: {
    marginRight: '39px',
    marginLeft: '102px',
    height: '45%',
    "&.MuiDrawer-paperAnchorRight": {
      top: '135px',
    },
    zIndex: 1,
    backgroundColor: 'rgb(19 19 19 / 99%)',
    display: 'grid',
    "&::-webkit-scrollbar": {
      height: "6px",
      width: "6px",
      opacity: "0.3",
      backgroundColor: "#101010"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "100px",
      border: "1px solid transparent",
      backgroundColor: "#212121"
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 2,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginBottom: 580,
    display: 'flex'
  },
}));

const SalesSettings = ({ userOrg, userMarketIds, userPermissions, userToken }) => {
  const classes = useStyles();
  const drawerClasses = useDrawerStyles();
  const [isMarketSelected, setIsMarkedSelected] = useState(false)
  const [selectedMarket, setSelectedMarket] = useState({ key: "NONE", value: "" })
  const [vehicleColors, setVehicleColors] = useState({});
  const [salesSettingObject, setSalesSettingObject] = useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState('');
  const [variantTableName, setVariantTableName] = React.useState('');
  const [open, setOpen] = React.useState(false);

  let marketId = 'NONE'

  const handleChange = (event, markets) => {
    const { name, value } = event.target;
    let orgsMarkets = [];
    marketId = value.split('#')[0];
    let marketName = value.split('#')[1];

    if (marketId == 'NONE') { setIsMarkedSelected(false) }
    else { setIsMarkedSelected(true) }

    setSelectedMarket({ key: name, value: value });
    querySalesSetting();
  }

  React.useEffect(() => {
    if (isMarketSelected && salesSettingObject == undefined) {
      querySalesSetting();
    }
  }, [salesSettingObject, variantTableName]);

  const click = (bucketName, variantName) => (event) => {
    setVariantTableName(variantName)
    setOpen(true);
  }

  const querySalesSetting = () => {
    let salesSettingGemsMarketId = marketId;
    let gemsMarketIds = userMarketIds

    CreateSalesSetting(salesSettingGemsMarketId, gemsMarketIds)
      .then(data => {
        data.salesSetting.createdByOrganizationId = data.salesSetting.createdByOrganizationId == "" ? userOrg.id : data.salesSetting.createdByOrganizationId
        setSalesSettingObject(data.salesSetting);
        // salesSettingObjectTest = { ...data.salesSetting }
      });
  }

  const uploadHandler = () => {

    let gemsMarketIds = userMarketIds
    let salesSetting = salesSettingObject

    swal({
      title: "Are you sure?",
      text: "You are about to upload a sale setting. Are you sure you want to continue?",
      icon: "warning",
      className: "swal-modal",
      buttons: {
        cancel: {
          text: "NO WAY!",
          data: false,
          visible: true,
          className: "swal-buttons",
          closeModal: true,
        },
        confirm: ({
          text: "CONTINUE",
          data: true,
          visible: true,
          className: "swal-button",
          closeModal: true
        })
      },
    }).then((willSave) => {
      if (willSave) {
        UpdateSalesSetting(gemsMarketIds, salesSetting, 1)
          .then(data => {
            if (data.ok == true) {
              setSalesSettingObject(data.salesSetting);
            }
            else {
              if (data.salesSetting != undefined) {
                setSalesSettingObject(data.salesSetting);
              }
              setOpenDialog(true)
              setDialogMessage(data.errorMessage)
            }
          }
          );
      }
    })
  }

  const handleDeleteProductVariant = (bucketName, variantName) => (event) => {
    let salesSettingsObjectClone = { ...salesSettingObject }
    const activeVariant = salesSettingsObjectClone.priceBuckets.filter(item => item.name == bucketName)[0].variants.filter(v => v.name == variantName)

    if (activeVariant.length > 0) {
      activeVariant[0].isFlaggedForDelete = true
    }
    salesSettingsObjectClone.priceBuckets.filter(item => item.name == bucketName)[0].variants.filter(v => v.name != variantName).push(activeVariant)
    let salesSetting = salesSettingsObjectClone
    let gemsMarketIds = userMarketIds

    swal({
      title: "Are you sure?",
      text: "You are about to delete a configured product variant. Are you sure you want to continue?",
      icon: "warning",
      className: "swal-modal",
      buttons: {
        cancel: {
          text: "NO WAY!",
          data: false,
          visible: true,
          className: "swal-buttons",
          closeModal: true,
        },
        confirm: ({
          text: "CONTINUE",
          data: true,
          visible: true,
          className: "swal-button",
          closeModal: true
        })
      },
    })
      .then((willSave) => {
        if (willSave) {
          DeleteSalesSettingVariant(gemsMarketIds, salesSetting, 1)
            .then(data => {
              if (data.ok == true) {
                setSalesSettingObject(data.salesSetting);
              }
              else {
                if (data.salesSetting != undefined) {
                  setSalesSettingObject(data.salesSetting);
                }
                setOpenDialog(true)
                setDialogMessage(data.errorMessage)
              }
            }
            )
        }
      })
  }

  const setBucketonSaleSettings = (bucketName, name) => (event) => {
    let salesSettingsObjectClone = { ...salesSettingObject }
    let priceBucket = salesSettingsObjectClone.priceBuckets.filter(item => item.name == bucketName)[0]
    let priceBucketIndex = salesSettingsObjectClone.priceBuckets.findIndex(item => item.name == bucketName)

    const value = event.target.value
    let defaultPriceRange = {
      "minValue": 0,
      "maxValue": 0
    }

    console.log(event.target)
    if (name == 'priceValue') {
      priceBucket.priceValue = parseInt(value, 10);
    }
    else if (name == 'minValue') {
      priceBucket.priceRange == null ? priceBucket.priceRange = defaultPriceRange : priceBucket.priceRange.minValue = parseInt(value | 0, 10);

    }
    else if (name == 'maxValue') {
      priceBucket.priceRange == null ? priceBucket.priceRange = defaultPriceRange : priceBucket.priceRange.maxValue = parseInt(value | 0, 10);
    }
    salesSettingsObjectClone.priceBuckets[priceBucketIndex] = priceBucket

    let updatedSalesSettings = salesSettingsObjectClone.priceBuckets.filter(item => item.name != bucketName).push(priceBucket)

    setSalesSettingObject(salesSettingsObjectClone);
  }

  const setPriceVariantOnBucketOfSaleSettings = (bucketName, variantName, name) => (event) => {

    let productVariantInstance = {
      "name": "",
      "model": "",
      "modelYear": 0,
      "engineType": "",
      "color": "",
      "hasTowbar": true,
      "age": {
        "minValue": 0,
        "maxValue": 0
      },
      "mileage": {
        "minValue": 0,
        "maxValue": 0
      },
      // "numberOfVins": 0,
      // "pendingForVINCount": 0

    }

    let salesSettingsObjectClone = { ...salesSettingObject }

    let priceBucket = salesSettingsObjectClone.priceBuckets.filter(item => item.name == bucketName)[0]
    let priceBucketIndex = salesSettingsObjectClone.priceBuckets.findIndex(item => item.name == bucketName)

    let productVariant = priceBucket.variants.filter(v => v.name == variantName)

    let priceVariantIndex = priceBucket.variants.findIndex(v => v.name == variantName)

    if (priceVariantIndex == -1) {
      productVariantInstance.name = variantName
      priceBucket.variants.push(productVariantInstance)
      priceVariantIndex = priceBucket.variants.findIndex(v => v.name == variantName)
    }

    const value = event.target.value
    console.log(event.target)
    if (name == 'fromAge') {
      priceBucket.variants[priceVariantIndex].age.minValue = parseInt(value | 0, 10);
    }
    else if (name == 'toAge') {
      priceBucket.variants[priceVariantIndex].age.maxValue = parseInt(value | 0, 10);
    }
    else if (name == 'fromMileage') {
      priceBucket.variants[priceVariantIndex].mileage.minValue = parseInt(value | 0, 10);
    }
    else if (name == 'toMileage') {
      priceBucket.variants[priceVariantIndex].mileage.maxValue = parseInt(value | 0, 10);

    }
    else if (name == 'color') {
      priceBucket.variants[priceVariantIndex][name] = value
      let colors = { ...vehicleColors }
      colors[variantName] = value
      setVehicleColors(colors)
    }
    else {
      priceBucket.variants[priceVariantIndex][name] = value
    }
    // console.log("DDDDDDDDDDDDDDDDD")
    // console.log(priceBucket)
    // console.log("EEEEEEEEEEEEEEEEE")

    salesSettingsObjectClone.priceBuckets[priceBucketIndex] = priceBucket

    let updatedpriceBucket = priceBucket.variants.filter(x => x.name != variantName).push(productVariant)

    let updatedSalesSettings = salesSettingObject.priceBuckets.filter(item => item.name != bucketName).push(updatedpriceBucket)
    setSalesSettingObject(salesSettingsObjectClone);
  }

  const handleNewVariantCreation = (bucketName) => (event) => {
    let gemsMarketIds = userMarketIds
    let salesSetting = { ...salesSettingObject }
    const activeBucket = salesSetting.priceBuckets.filter(item => item.name == bucketName)

    if (activeBucket.length > 0) {
      activeBucket[0].isNewVariantRequired = true
    }

    salesSetting.priceBuckets.filter(item => item.name != bucketName).push(activeBucket)

    VerifySalesSetting(gemsMarketIds, salesSetting, 3, true)
      .then(data => {
        if (data.ok == true) {
          setSalesSettingObject(data.salesSetting);
        }
        else {
          setOpenDialog(true)
          setDialogMessage(data.errorMessage)
        }
      })
  }

  const handleDialogOk = () => {
    setOpenDialog(false);
  }

  const handleRefreshProductVariant = (bucketName, variantName) => (event) => {

    let gemsMarketIds = salesSettingObject.gemsMarketId
    let organizationId = userOrg.id
    let salesSettingsObjectClone = { ...salesSettingObject }
    let priceBucket = salesSettingsObjectClone.priceBuckets.filter(item => item.name == bucketName)[0];
    let priceValue = priceBucket.priceValue
    let priceRange = priceBucket.priceRange
    let variant = priceBucket.variants.filter(item => item.name == variantName)[0]

    const variantIndex = salesSettingsObjectClone.priceBuckets.filter(item => item.name == bucketName)[0].variants.findIndex(v => v.name == variantName)
    const priceBucketIndex = salesSettingsObjectClone.priceBuckets.findIndex(item => item.name == bucketName)
    let newNumberOfVins = salesSettingsObjectClone.priceBuckets[priceBucketIndex].variants[variantIndex].numberOfVins

    refreshNumberofVins(gemsMarketIds, organizationId, priceValue, priceRange, variant, 5)
      .then(data => {
        newNumberOfVins = data.numberOfVins
        salesSettingsObjectClone.priceBuckets[priceBucketIndex].variants[variantIndex].numberOfVins = newNumberOfVins
        setSalesSettingObject(salesSettingsObjectClone)
      })
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const matchesPermission = userPermissions.includes(permission.Remarketing_Administrator);

  return (
    <Row >
      <Col lg="12" md="12" >
        <Card id="CardBodyCardAllowance3" style={{ marginTop: '75px', marginRight: '13px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '4px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} >

          <SimpleAlertDialog
            open={openDialog}
            message={dialogMessage}
            handleDialogOk={handleDialogOk}
            isErrorDialog={false}
          />
          <CardBody >
            {isMarketSelected ?
              <div className={classes.root} >
                <div style={{ marginLeft: '23px', width: '15%', marginTop: '-5px' }}>
                  <MarketSelect orgId={userOrg.id} value={selectedMarket.value} userMarketIds={userMarketIds} handleChange={handleChange} />
                </div>
                {matchesPermission ?
                  <>
                    <div style={{ marginTop: '48px' }}>
                      <EnhancedSalesSettingsToolbar handleClick={uploadHandler} />
                    </div>

                    <Row style={{ marginTop: '-95px' }}>
                      <Col lg="12" md="12" >
                        <MasterBucket
                          handleDeleteProductVariant={handleDeleteProductVariant}
                          handleRefreshProductVariant={handleRefreshProductVariant}
                          vehicleColors={vehicleColors}
                          data={salesSettingObject}
                          onPriceBucketChange={setBucketonSaleSettings}
                          onPriceVariantChange={setPriceVariantOnBucketOfSaleSettings}
                          handleNewVariantCreation={handleNewVariantCreation}
                          click={click}
                        />
                      </Col>
                      <Drawer
                        variant="temporary"
                        anchor="bottom"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        classes={{
                          paper: drawerClasses.drawerPaper,
                        }}
                      >
                        <VInListTable handleClose={handleClose} variantName={variantTableName} />
                      </Drawer>
                    </Row>
                  </>
                  :
                  <>
                    <Row style={{ marginTop: '20px' }}>
                      <Col lg="12" md="12" >
                        <MasterBucket
                          handleDeleteProductVariant={handleDeleteProductVariant}
                          handleRefreshProductVariant={handleRefreshProductVariant}
                          vehicleColors={vehicleColors}
                          data={salesSettingObject}
                          onPriceBucketChange={setBucketonSaleSettings}
                          onPriceVariantChange={setPriceVariantOnBucketOfSaleSettings}
                          handleNewVariantCreation={handleNewVariantCreation}
                          click={click}
                        />
                      </Col>
                      <Drawer
                        variant="temporary"
                        anchor="bottom"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        classes={{
                          paper: drawerClasses.drawerPaper,
                        }}
                      >
                        <VInListTable handleClose={handleClose} variantName={variantTableName} />
                      </Drawer>
                    </Row>
                  </>
                }
              </div>
              :
              <>
                {matchesPermission ?
                  <Row>
                    <Col lg="12" md="12" >
                      <div style={{ marginLeft: '13px', width: '15%', marginTop: '-20px' }}>
                        <MarketSelect orgId={userOrg.id} value={selectedMarket.value} userMarketIds={userMarketIds} handleChange={handleChange} />
                      </div>
                      <div style={{ marginTop: '48px' }}>
                        <EnhancedSalesSettingsToolbar />
                      </div>
                    </Col>
                  </Row>
                  :
                  <Row>
                    <Col lg="12" md="12" >
                      <div style={{ marginLeft: '13px', width: '15%', marginTop: '-20px' }}>
                        <MarketSelect orgId={userOrg.id} value={selectedMarket.value} userMarketIds={userMarketIds} handleChange={handleChange} />
                      </div>
                    </Col>
                  </Row>
                }
              </>
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  userOrg: selectUserOrganization(state),
  userMarketIds: selectUserMarketIds(state),
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(SalesSettings);