import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import M2MTable from './components/M2MTable';
// import CreateDataVehiclePage from '../CreateDataVehiclePage';

const indexM2M = () => {
  // const { t } = useTranslation('common');
  // const reactTableData = CreateDataVehiclePage();

  return (
    <div>
      <Row>
        <Col md={12}>
          {/* <h3 className="page-title">{('tables.data_table.title')}</h3>
          <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
            information
          </h3> */}
        </Col>
      </Row>
      <Row>
        <M2MTable />
      </Row>
    </div>
  );
};

export default indexM2M;
