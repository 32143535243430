import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import OrganizationTable from './components/OrganizationTable';
import CreateDataVehiclePage from '../CreateDataVehiclePage';

const indexOrganization = () => {
  // const { t } = useTranslation('common');
  const reactTableData = CreateDataVehiclePage();

  return (
    <div>
      <Row>
        {/* <Col md={12}>
          <h3 className="page-title">{('tables.data_table.title')}</h3>
          <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
            information
          </h3>
        </Col> */}
      </Row>
      <Row>
        <OrganizationTable  />
      </Row>
    </div>
  );
};

export default indexOrganization;
