import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useToolbarStyles } from 'src/assets/jss/components/tableToolbarStyle';
import Tooltip from '@material-ui/core/Tooltip';

export default function EnhancedSalesSettingsToolbar(props) {
    const classes = useToolbarStyles();
    const { handleClick } = props;

    return (
        <Toolbar
            className={clsx(classes.root3, {
                // [classes.highlight]: selectedRowId.length > 0,
            })}
        >
            <Typography className={classes.title3} variant="h4" id="tableTitle" component="div">

            </Typography>
            <div className={classes.tooltipDiv}>
                <Tooltip className={classes.tooltipImg3} title="Edit" style={{ marginTop: '-130px', fontSize: '18px', letterSpacing: '30px', cursor: 'pointer' }}>
                    <span>
                        {/* <i className="mr-2 mdi mdi-pencil" /> */}
                    </span>
                </Tooltip>
                <Tooltip onClick={handleClick} className={classes.tooltipImg3} title="Upload" style={{ marginTop: '-130px', fontSize: '20px', letterSpacing: '10px', cursor: 'pointer' }}>
                    <span>
                        <i className="mr-2 mdi mdi-upload" />
                    </span>
                </Tooltip>
            </div>
        </Toolbar>
    );
};

EnhancedSalesSettingsToolbar.propTypes = {
    handleClick: PropTypes.func.isRequired
};