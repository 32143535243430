import React, { useState } from 'react';
import {
  BarChart, Bar, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid,
} from 'recharts';
import Panel from 'src/components/dashboard/Panel';
import ToggleSwitch from './toggle'

const dataYearly = [
  { name: 'Jan', uv: 900 },
  { name: 'Feb', uv: 1150 },
  { name: 'Mar', uv: 1270 },
  { name: 'Apr', uv: 940 },
  { name: 'May', uv: 1120 },
  { name: 'June', uv: 1080 },
  { name: 'July', uv: 1100 },
  { name: 'Aug', uv: 980 },
  { name: 'Sep', uv: 940 },
  { name: 'Oct', uv: 1200 },
  { name: 'Nov', uv: 1280 },
  { name: 'Dec', uv: 1340 },
];

const dataMontly = [
  { name: '01.02', uv: 3654 },
  { name: '02.02', uv: 2578 },
  { name: '03.02', uv: 2000 },
  { name: '04.02', uv: 4383 },
  { name: '05.02', uv: 1890 },
  { name: '06.02', uv: 2390 },
  { name: '07.02', uv: 3490 },
  { name: '08.02', uv: 2000 },
  { name: '09.02', uv: 2780 },
  { name: '10.02', uv: 1890 },
  { name: '11.02', uv: 3578 },
  { name: '12.02', uv: 3644 },
  { name: '13.02', uv: 4000 },
  { name: '14.02', uv: 3000 },
  { name: '15.02', uv: 2000 },
  { name: '16.02', uv: 2780 },
  { name: '17.02', uv: 1890 },
  { name: '18.02', uv: 2390 },
  { name: '19.02', uv: 3490 },
  { name: '20.02', uv: 2000 },
  { name: '21.02', uv: 2780 },
  { name: '22.02', uv: 1890 },
  { name: '23.02', uv: 3578 },
  { name: '24.02', uv: 2390 },
  { name: '25.02', uv: 3578 },
  { name: '26.02', uv: 1890 },
  { name: '27.02', uv: 4000 },
  { name: '28.02', uv: 2378 },
];

const NewOrderSubscriptions = () => {

  const [isYearly, setYearly] = useState(true);

  const handleChange = () => {
    setYearly(!isYearly);
  };

  return (
    <Panel
      // xl={3}
      // lg={7}
      // md={12}
      title={('New orders')}
      subhead="Number of new subscription orders"
    >
      <div >
        <ResponsiveContainer height={260}>
          {isYearly
            ? (
              <BarChart data={dataYearly} margin={{ top: 20, left: -15 }}>
                <XAxis dataKey="name" tickLine={false} />
                <YAxis tickLine={false} />
                <Tooltip contentStyle={{ backgroundColor: '#131313' }} />
                <CartesianGrid vertical={false} />
                <Bar dataKey="uv" name="Sales" fill="#dc1860" barSize={10} />
              </BarChart>
            ) : (
              <BarChart data={dataMontly} margin={{ top: 20, left: -15 }}>
                <XAxis dataKey="name" tickLine={false} />
                <YAxis tickLine={false} />
                <Tooltip contentStyle={{ backgroundColor: '#131313' }} />
                <CartesianGrid vertical={false} />
                <Bar dataKey="uv" name="Sales" fill="#1EF1C6" barSize={4} />
              </BarChart>
            )}
        </ResponsiveContainer>
      </div>
      <hr style={{ marginBottom: '0px' }} />
      <ToggleSwitch label="new_order_subscription" handleChange={handleChange} />
    </Panel>
  );
};

export default NewOrderSubscriptions
