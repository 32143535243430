import { environment } from 'src/environments/environment';
import { msalInstance } from "src/index";
import { loginRequest } from "src/authConfig";

export async function UploadFleet(file, fleetId, fleetTypeId, marketIds) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        return (<div>No active account!</div>)
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    // headers.append("Authorization", bearer);
    // headers.append("Content-Type", "multipart/form-data");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey)

    const formData = new FormData();

		formData.append('File', file);
        formData.append('FleetId', fleetId);
        formData.append('FleetTypeId', null);
        
        var index = 0;
        marketIds.map(marketId => {
            formData.append('MarketIds[' + index + ']', marketId);
            index = index + 1;
        })

    const options = {
        method: "POST",
        headers: headers,
        body: formData
    };

    let apiData = await fetch(`${environment.backendMaintenanceService}/api/Infleeting/BulkInfleet`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log({errorCode: error}));
    return apiData;
}