import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import { QueryConnectivityInformation } from 'src/services/connectivity-http.service';
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import { GetFleets } from 'src/services/fleet-http.service';
import { QueryVehiclePositionForMapView } from 'src/services/vehicle-pages-http.service';
import { GetComments } from 'src/services/vehicle-comments-http.service';
import { GetVehicleWorkOrderCountByStatus } from 'src/services/vehicle-pages-http.service';
import SearchButton from "src/components/buttons/button.component";
import VehicleDetailsTabs from 'src/components/tabs/vehicleDetails-tabs.component';
import CaseTypeSelect from 'src/components/vehicleDetails/CaseTypeSelect';
import RadioButtonsGroup from './RadioButtonsGroup';
import FormTextField from 'src/components/form-elements/form-text-field.component';
import CardContent from '@material-ui/core/CardContent';
import editCaseOwnerButton from 'src/assets/img/vehicles/editCaseOwnerButton.png';
import editCaseOwnerButton_Green from 'src/assets/img/vehicles/editCaseOwnerButton_Green.png';
import Modal from "@mui/material/Modal";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@mui/material/IconButton";
import ConfirmDetails from 'src/components/vehicleDetails/ConfirmDetails';
import UserPage from "src/components/vehicleDetails/UserPage"

const UseStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        marginRight: '-32px',
        paddingRight: '20px',
        marginLeft: '23px',
        marginTop: '70px'
    },
    root2: {
        overflow: 'scroll',
        height: '63vh',
        marginLeft: '-30px',

        "&::-webkit-scrollbar": {
            height: "1px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 0.1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 0.1%)',
            border: 'none'
        },
    },
    gridContainerStyle: {
        marginBottom: theme.spacing(2),
        position: 'sticky',
        marginTop: '-17px',
        marginLeft: '-30px',
        display: 'flex'
    },
    tooltipImg: {
        flexDirection: 'row',
        flexWrap: 'noWrap',
        marginTop: '-10px'
    },
    cardActionStyle: {
        "&.MuiCardActions-root": {
            display: 'flex',
            // padding: '16px',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'relative',
            marginBottom: -30,
            top: -25,
            right: -51,
            overflow: 'auto',
        },
    },
    contentStyle: {
        marginLeft: '1px',
        fontSize: '14px',
        marginBottom: '-10px'
    },
    contentStyle2: {
        marginLeft: '1px',
        fontSize: '14px',
        marginBottom: '-10px'
    },
    contentheader: {
        marginLeft: '-20px',
        marginTop: '3px',
    },
    drawarModal: {
        width: '100%',
        flexShrink: 0,
        marginTop: '320px',
        alignItems: 'center',
        position: 'absolute',
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
    },
    root2: {
        overflow: 'auto',
        height: '780px',
        paddingRight: '6px',
        marginRight: '-22px',
        "&::-webkit-scrollbar": {
            height: "6px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 1%)',
            border: 'none'
        },
        overflowX: 'hidden'
    },
    rotateIcon: {
        animation: "$bounce 2s linear infinite"
    },
    "@keyframes bounce": {
        "0%, 20%, 50%, 80%, 100%": {
            transform: "translateY(0)"
        },
        "40%": {
            transform: "translateY(-10px)"
        },
        "60%": {
            transform: "translateY(-5px)"
        }
    },
    drawer: {
        width: '20vw',
        flexShrink: 0,
        textAlign: 'center',
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '40%',
        position: 'relative',
        height: '20px',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    },

    drawerConfirmDetails: {
        width: '100%',
        flexShrink: 0,
        marginTop: '320px',
        alignItems: 'center',
        position: 'absolute',
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
    },
}));


const DetailsMainPage = (props) => {
    const { userOrg, userMarketIds, closeVehicleDetail, vin, inputVIN, caseNumber, customerName, prefferedLanguage, validRSA, status, caseOwner, vehicleHealth } = props

    const [vehiclePosition, setVeiclePosition] = useState({});
    const [displayDetails, setDisplayDetails] = useState(false)
    const [vehicleDetail, setVehicleDetails] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [message, setMessage] = useState('');
    const [comments, setComments] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [getWorkOrderStatus, setWorkOrderStatus] = useState({});
    const [mapSpinner, setMapSpinner] = useState(false);

    const [shouldRenderMap, setShouldRenderMap] = useState(false)

    const [updatePackage, setUpdatePackage] = useState(false);
    const [role, setRole] = useState('');

    const [roleDetails, setRoleDetails] = useState([]);

    const [roleSubDetails, setRoleSubDetails] = useState([]);

    const [roleSubDetails1, setRoleSubDetails1] = useState('');
    const [showServices, setShowServices] = useState(false);

    const [stolenVehicleFlag, setStolenVehicleFlag] = useState(false);


    const [addressInput, setAddressInput] = useState('');

    const imgs = [editCaseOwnerButton, editCaseOwnerButton_Green];

    const [icon, setIcon] = useState(imgs[0]);
    const [openIcon, setOpenIcon] = useState(false);

    const [roles, setRoles] = React.useState({
        id: "",
        name: "Vehicle reported stolen",
        createdDate: "24.11.2021",
        description: "Vehicle reported stolen 23rd Novemeber 21. Last know position was at central station in Gothenburg. Vehicle is no longer reporting its position. Police investigation ongoing. Case ref no: 1234569.",
        editMode: false
    });

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setComments([]);
        setVehicleDetails({});
        setVeiclePosition({});
        setWorkOrderStatus({});

        handleChangeVIN();

    }, [vin.length]);

    const getComments = () => {
        GetComments(inputVIN.trim())
            .then(data => {
                if (data.ok == true) {
                    setComments(data.comments)
                }
                setDataLoaded(true);
            })
    };

    const handleChangeVIN = () => {
        if (inputVIN.length !== 0) {
            handleTab(inputVIN.trim());
            setDataLoaded(false);

            QueryConnectivityInformation(inputVIN.trim())
                .then(data => {
                    if (data.ok == true) {
                        setVehicleDetails(data.vspConnectivity);
                        setDisplayDetails(true)
                    } else {
                        setOpenDialog(true);
                        setMessage(data.title);
                        setDialogMessage(data.errorMessage);
                        setDisplayDetails(false);
                    }
                })
            GetComments(inputVIN.trim())
                .then(data => {
                    if (data.ok == true) {
                        setComments(data.comments)
                    }
                    setDataLoaded(true);
                })
            GetVehicleWorkOrderCountByStatus(inputVIN.trim())
                .then(data => {
                    if (data.ok == true) {
                        setWorkOrderStatus(data)
                    }
                })
        }
    };

    const handleDialogOk = () => {
        setOpenDialog(false);
        closeVehicleDetail();
    };

    const handleTab = (vspVIN, addressInput) => {
        setMapSpinner(true);
        let fleetIds = []
        let marketIds = userMarketIds;
        let vin = addressInput || vspVIN;

        let vehiclePositionInstance = {
            "latitude": 0,
            "longitude": 0,
            "odometer": "",
            "color": "",
            "street": "",
            "postalCode": "",
            "city": "",
            "country": "",
        };

        GetFleets(userOrg.id)
            .then(data => {
                if (data && Array.isArray(data)) {
                    data?.map(item => {
                        fleetIds.push(item.id);
                    })

                    QueryVehiclePositionForMapView(vin, fleetIds, marketIds)
                        .then(data => {
                            if (data.ok == true) {
                                vehiclePositionInstance.vin = data.vehiclePosition.vin
                                vehiclePositionInstance.latitude = data.vehiclePosition.latitude
                                vehiclePositionInstance.longitude = data.vehiclePosition.longitude
                                vehiclePositionInstance.vehicleUpdatedAt = data.vehiclePosition.vehicleUpdatedAt
                                vehiclePositionInstance.color = data.vehiclePosition.color
                                vehiclePositionInstance.odometer = data.vehiclePosition.odometer
                                vehiclePositionInstance.street = data.vehiclePosition.addressData.street
                                vehiclePositionInstance.postalCode = data.vehiclePosition.addressData.postalCode
                                vehiclePositionInstance.city = data.vehiclePosition.addressData.city
                                vehiclePositionInstance.country = data.vehiclePosition.addressData.country
                                setVeiclePosition(vehiclePositionInstance);
                                setMapSpinner(false);
                            }

                        })
                }
            })
    };


    const handleChangeDropdown = (event) => {
        const { value } = event.target;
        if (value == 1) {
            setRoleDetails([
                { "value": "Yes" },
                { "value": "No" }
            ]);
            setStolenVehicleFlag(false);
        }
        if (value == 2) {
            setRoleDetails([
                { "value": "Resolved by phone" },
                { "value": "Fixed on site" },
                {
                    "value": "Vehicle towed to workshop"
                }
            ])

            setRoleSubDetails([
                { id: 1, "value": "Replacement car required" },
                { id: 2, "value": "No replacement car required" },
            ])
            setStolenVehicleFlag(false);
        }

        if (value == 3) {
            setRoleDetails([
                { "value": "Replacement car required" },
                { "value": "No replacemnt car required" }
            ])
            setStolenVehicleFlag(true);
        }

        setRole(value);
        setShowServices(true);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRoles({ ...roles, [name]: value });
    };

    const handleClickEdit = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const classes = UseStyles(props);

    const handleCloseHomeIcon = () => {

        setIcon(imgs[0]);
        setOpenIcon(false);
    };

    const handleOpenHomeIcon = () => {
        setIcon(imgs[1]);
        setOpenIcon(true);
    };

    return (
        <Col md={12} lg={12}>
            <VehiclePageDialog
                dialogue='true'
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
                title={message}
            />
            <Modal
                className={classes.drawerConfirmDetails}
                variant="persistent"
                anchor="right"
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleCloseModal}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' } }}
            >
                <UserPage
                    setOpenModal={setOpenModal}
                // handleOpenDetailsPage={handleOpenDetailsPage} 
                // handleReturnPage={handleReturnPage} 
                />

            </Modal>

            {displayDetails && inputVIN &&
                <div className={status == 'Open' ? 'detailsOpenCasestyle' : 'detailsAssignedCasestyle'} >
                    <Row className='detailsRowStyle'>
                        <Col lg="3" md="3" >
                            <Row style={{ marginBottom: '8px', marginTop: '-5px', }}>
                                <Col lg="12" md="12" >
                                    <Card id="CardBodyCardAllowance2" style={{ height: '250px', marginTop: '-5px', backgroundColor: '#212121', marginBottom: '40px' }} >
                                        <Col lg="12" md="12" style={{ marginLeft: '15px', marginTop: '17px' }} >
                                            <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeBold' }}>CASE DETAILS</span> <br />
                                            <br />
                                            <span style={{ fontSize: '13px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '10px' }}>Status</span> <br />
                                            <span style={{ fontSize: '15px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '10px' }}>{status}</span> <br /><br />

                                            <span style={{ fontSize: '13px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '10px' }}>Case Number</span> <br />
                                            <span style={{ fontSize: '15px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '10px' }}>{caseNumber}</span> <br /><br />

                                            <span style={{ fontSize: '13px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '10px' }}>Case Owner</span> <br />
                                            <span style={{ fontSize: '15px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '10px' }}>

                                                <Row style={{ marginBottom: '8px', marginTop: '-20px', marginLeft: '-5px', marginRight: '20px' }}>
                                                    <Col lg="8" md="8">
                                                        <span style={{ float: 'left' }}>
                                                            {caseOwner}
                                                        </span>
                                                    </Col>
                                                    <Col lg="4" md="4">
                                                        <span style={{ float: 'right', marginLeft: '40px' }}>
                                                            {/* {'caseNumber'} */}
                                                            {
                                                                status == 'Open' || status == 'Unassigned' || status == 'Assigned' ?
                                                                    <Tooltip
                                                                        title="Change Case Owner"
                                                                        onOpen={handleOpenHomeIcon}
                                                                        onClose={handleCloseHomeIcon}
                                                                        open={openIcon}
                                                                    >
                                                                        <IconButton
                                                                            onClick={handleClickEdit}
                                                                            size="small"
                                                                            sx={{ ml: 2 }}
                                                                            // aria-controls={open1 ? "account-menu" : undefined}
                                                                            aria-haspopup="true"
                                                                        // aria-expanded={open1 ? "true" : undefined}
                                                                        >
                                                                            <img
                                                                                className="edit"
                                                                                src={icon}
                                                                                alt="Three Dot Image"
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    :
                                                                    <></>
                                                            }

                                                        </span>
                                                    </Col>
                                                </Row>

                                            </span><br />


                                        </Col>
                                    </Card>
                                </Col>

                                <Col lg="12" md="12" >
                                    <Card id="CardBodyCardAllowance2" style={{ height: '470px', marginTop: '-5px', backgroundColor: '#212121' }} >
                                        <Col lg="12" md="12" style={{ marginLeft: '15px', marginTop: '17px' }} >
                                            <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeBold' }}>CUSTOMER DETAILS</span> <br />
                                            <br />

                                            <span style={{ fontSize: '13px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '10px' }}>Name</span> <br />
                                            <span style={{ fontSize: '15px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '10px' }}>{customerName}</span> <br /><br />

                                            <span style={{ fontSize: '13px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '10px' }}>Phone</span> <br />
                                            <span style={{ fontSize: '15px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '10px' }}>{'0123456789'}</span> <br /><br />

                                            <span style={{ fontSize: '13px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '10px' }}>Email</span> <br />
                                            <span style={{ fontSize: '15px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '10px' }}>{'test@testing.se'}</span> <br /><br />

                                            <span style={{ fontSize: '13px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '10px' }}>Preferred Language</span> <br />
                                            <span style={{ fontSize: '15px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '10px' }}>{prefferedLanguage}</span> <br /><br />

                                            <span style={{ fontSize: '13px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '10px' }}>Entitled to RSA</span> <br />
                                            <span style={{ fontSize: '15px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '10px' }}>{validRSA}</span> <br /><br />

                                            <span style={{ fontSize: '13px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular', marginLeft: '10px' }}>Borrower</span> <br />
                                            <span style={{ fontSize: '15px', color: 'darkgray', fontFamily: 'LynkcoTypeMedium', marginLeft: '10px' }}>{'James Stephen Winters'}</span> <br /><br />
                                        </Col>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>


                        <Col lg="6" md="6" >
                            <Row style={{ marginBottom: '8px', marginTop: '-5px', }}>
                                <Col lg="12" md="12" >
                                    <Card id="CardBodyCardAllowance2" style={{ height: '755px', marginTop: '-5px', backgroundColor: 'transparent' }} >
                                        <span style={{ marginTop: '10px' }}>
                                            <VehicleDetailsTabs
                                                vehiclePosition={vehiclePosition}
                                                shouldRenderMap={shouldRenderMap}
                                                vin={vin}
                                                value={2}
                                                mapSpinner={mapSpinner}
                                                setAddressInput={setAddressInput}
                                                addressInput={addressInput}
                                                comments={comments}
                                                getComments={getComments}
                                                dataLoaded={dataLoaded}
                                                handleChangeVIN={handleChangeVIN}
                                            />
                                        </span>
                                    </Card>
                                </Col>

                            </Row>

                        </Col>
                        <Col lg="3" md="3" >
                            <Row style={{ marginBottom: '8px', marginTop: '-5px', }}>
                                <Col lg="12" md="12" >
                                    <Card id="CardBodyCardAllowance2" style={{ height: '370px', backgroundColor: '#212121', marginBottom: '40px' }} >
                                        <Col lg="12" md="12" style={{ marginLeft: '15px', marginTop: '17px' }}>
                                            <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeBold' }}>CASE REPORT</span> <br />
                                            <br />
                                            <CaseTypeSelect value={role.id} onChange={handleChangeDropdown} updatePackage={updatePackage} />
                                            <br />
                                            <span style={{ marginLeft: '15px' }}>
                                                <RadioButtonsGroup roleDetails={roleDetails} roleSubDetails={roleSubDetails} roleSubDetails1={roleSubDetails1} stolenVehicleFlag={stolenVehicleFlag} />
                                            </span>
                                        </Col>
                                    </Card>
                                </Col>
                                <Col lg="12" md="12" >
                                    <Card id="CardBodyCardAllowance2" style={{ height: '276px', marginTop: '-5px', backgroundColor: '#212121' }} >
                                        <Col lg="12" md="12" style={{ marginLeft: '15px', marginTop: '17px' }} >
                                            <span style={{ fontSize: '14px', color: 'darkgray', fontFamily: 'LynkcoTypeBold' }}>CASE NOTES</span> <br />
                                            <span style={{ fontSize: '10px', color: 'darkgray', fontFamily: 'LynkcoTypeRegular' }}>
                                                <CardContent style={{ marginTop: '-78px', marginLeft: '-80px', marginRight: '-60px' }}>
                                                    <div style={{ width: '79%', marginTop: '60px', marginLeft: '30px', marginRight: '-10px' }}>
                                                        <FormTextField
                                                            name="description"
                                                            onChange={handleChange}
                                                            value={roles?.description}
                                                            multiline={true}
                                                            inputProps={{ style: { height: "125px" } }}
                                                            outline='true'
                                                        />
                                                    </div>
                                                </CardContent>
                                            </span> <br /><br />
                                        </Col>
                                    </Card>
                                </Col>
                            </Row>

                            <Row style={{ marginBottom: '8px', marginTop: '-5px', }}>
                                <Col lg="6" md="6">
                                    <span style={{ float: 'left' }}>
                                        <SearchButton
                                            placeholder={'Save and Close'}
                                            iconProp='true'
                                            handleSearch={
                                                // setDisplaySelectedVehicle(true);
                                                // setSearchVIN('');
                                                closeVehicleDetail

                                            }
                                        />
                                    </span>
                                </Col>
                                <Col lg="6" md="6">
                                    <span style={{ float: 'right', marginLeft: '40px' }}>
                                        <SearchButton
                                            placeholder={'Resolved'}
                                            height='true'
                                            widthProp='true'
                                        // handleSearch={() => {
                                        //     setDisplaySelectedVehicle(true);
                                        //     setSearchVIN('');
                                        // }
                                        // }
                                        />
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </div>
            }
        </Col>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(DetailsMainPage);