import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useToolbarStyles } from 'src/assets/jss/components/tableToolbarStyle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

export default function EnhancedTableToolbarInternalFleet(props) {
    const classes = useToolbarStyles();
    const { selectedRowId = '', handleClickDelete, handleClickEdit, handleClickAdd, handleUpload } = props;
    return (
        <Toolbar
            className={clsx(classes.root4, {
                [classes.highlight2]: selectedRowId.length > 0,
            })}
        >
            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                
            </Typography>
            
            <div className={classes.tooltipDiv4}>
            <Tooltip className={classes.tooltipImg} onClick={handleUpload} title="Upload" style={{ marginTop: '90px', fontSize: '21px', letterSpacing: '10px' }}>
                    <span>
                        <i className="mr-2 mdi mdi-upload" ></i>

                    </span>
                </Tooltip>
                <Tooltip className={classes.tooltipImg} title="Add VIN" onClick={handleClickAdd}>
                <span>

                    <IconButton aria-label="filter list">
                        <AddIcon style={{ width: '20px', height: '40px', marginBottom: '-190px' }} />
                    </IconButton>
                    </span>

                </Tooltip>
                <Tooltip className={classes.tooltipImg} title="Edit" onClick={handleClickEdit}>
                    <span>
                        <IconButton disabled={selectedRowId.length === 0} aria-label="edit">
                            <EditIcon style={{ width: '20px', height: '40px', marginBottom: '-190px' }} />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip className={classes.tooltipImg} title="Delete" onClick={handleClickDelete}>
                    <span>
                        <IconButton disabled={selectedRowId.length === 0} aria-label="delete">
                            <DeleteIcon style={{ width: '20px', height: '40px', marginBottom: '-190px' }} />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbarInternalFleet.propTypes = {
    selectedRowId: PropTypes.string.isRequired,
    handleClickAdd: PropTypes.func.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
    handleClickEdit: PropTypes.func.isRequired,
    handleUpload:PropTypes.func.isRequired
};