import React, { useState, useEffect } from "react";
import { Card, CardBody, CardSubtitle, Col, Row } from "reactstrap";
import Chart from "react-apexcharts";
import { GetCommercialCost } from 'src/services/vehicle-pages-http.service';
import { GetFleetTypes } from 'src/services/fleetType-http.service';
import { connect } from 'react-redux';
import { selectUserOrganization } from 'src/redux/user/user.selectors';

const CommercialCostCard = ({ userOrg, setQueryParamsCommercialCostMonthIndex, setDataLoaded }) => {

  const [commercialCostValue, setCommercialCostValue] = useState([]);
  const [commercialcostMonth, setCommercialCostMonth] = useState([]);

  // useEffect(() => {
  //   setCommercialCost();
  // }, []);

  // const setCommercialCost = () => {
  //   let fleetTypes = [];
  //   let commercialCostArrayValue = [];
  //   let commercialCostArrayMonth = [];

  //   GetFleetTypes(userOrg.id)
  //     .then(data => {
  //       if (data && Array.isArray(data)) {
  //         data.map(fleetType => {
  //           fleetTypes.push(fleetType['id'])
  //         })
  //         GetCommercialCost(fleetTypes).then(data => {
  //           Array.isArray(data.data) && data.data.length > 0 ? data.data.map(

  //             commercialCost => {
  //               commercialCostArrayValue.push(
  //                 commercialCost.value
  //               );
  //             })
  //             : console.log("Error Occured");
  //           setCommercialCostValue(commercialCostArrayValue);
  //         });

  //         GetCommercialCost(fleetTypes).then(data => {
  //           Array.isArray(data.data) && data.data.length > 0 ? data.data.map(

  //             commercialCost => {
  //               commercialCostArrayMonth.push(
  //                 commercialCost.month
  //               );
  //             })
  //             : console.log("Error Occured");
  //           setCommercialCostMonth(commercialCostArrayMonth);
  //         });
  //       }
  //     })


  // };

  const optionsrevenue = {
    grid: {
      show: true,
      strokeDashArray: 2,
      borderColor: "rgba(0,0,0,.3)",
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "flat",
      },
    },
    // colors: ["#DC1860"],
    colors: ['#131313'],
    fill: {
      type: "solid",
      opacity: 1,
    },
    chart: {
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
      // events: {
      //   dataPointSelection: (event, chartContext, config) => {
      //     if (config.selectedDataPoints?.length == 0) {
      //       return
      //     }
      //     if (config.selectedDataPoints[0].length > 0) {
      //       let value = (config.dataPointIndex + 1)
      //       setQueryParamsCommercialCostMonthIndex(value.toString());
      //       setDataLoaded(false);
      //     } else {
      //       setQueryParamsCommercialCostMonthIndex('');
      //       setDataLoaded(false);
      //     }
      //   }
      // }
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: "category",
      show: false,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      // categories: commercialcostMonth,
      categories: [''],
      labels: {
        style: {
          cssClass: "grey--text lighten-1--text fill-color"
        },
      },
    },
    yaxis: {
      show: false,
      labels: {
        style: {
          cssClass: "grey--text lighten-2--text fill-color",
          fontSize: '24px'
        },
      },
    },
    stroke: {
      show: true,
      width: 5,
      colors: ['transparent']
    },
    tooltip: {
      theme: "dark",
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        }
      },
      hover: {
        filter: {
          type: 'lighten',
          value: 0.15,
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'darken',
          value: 0.1,
        }
      },
    }
  };
  const seriesrevenue = [
    {
      name: "Commercial cost breach",
      // data: commercialCostValue
      data: [29, 44, 28, 37, 21, 17]
    },
  ];

  return (
    <Card id="CardBodyCardAllowance" style={{ marginBottom: '40px' }}>
      <CardBody >
        <Row >
          <Col xs="12">
            {/* <div className="d-flex flex-wrap"> */}
            <span style={{ fontSize: "15px", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF' }}>COMMERCIAL COST</span>
            <br />
            <span style={{ fontSize: "15px", fontFamily: 'LynkcoTypeRegular', color: '#FF6600' }}>coming soon</span>
            {/* </div> */}
          </Col>
          <Col xs="12" style={{ marginTop: '-35px' }}>
            <Chart
              options={optionsrevenue}
              series={seriesrevenue}
              type="bar"
              height="180px"
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = state => ({
  userOrg: selectUserOrganization(state)
});

export default connect(mapStateToProps, null)(CommercialCostCard);