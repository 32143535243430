import { environment } from 'src/environments/environment';
import { GetToken } from 'src/auth/userToken';

export async function GetPackages() {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendProvisioningService}/api/Package/GetPackages`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

export async function GetServices() {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "GET",
        headers: headers
    };

    let apiData = await fetch(`${environment.backendProvisioningService}/api/Package/GetServices`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

export async function CreatePackage(requestParams) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestParams)
    };

    let apiData = await fetch(`${environment.backendProvisioningService}/api/Package/CreatePackage`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function UpdatePackage(requestParams) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestParams)
    };

    let apiData = await fetch(`${environment.backendProvisioningService}/api/Package/UpdatePackage`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
}

export async function MapServiceToPackage(packageId, serviceId) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "packageId": packageId,
            "serviceId": serviceId
        })
    };

    let apiData = await fetch(`${environment.backendProvisioningService}/api/Package/MapServiceToPackage`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};

export async function UnmapServiceFromPackage(packageId, serviceId) {
    let fleetToken = await GetToken();

    const headers = new Headers();
    headers.append("Authorization", fleetToken);
    headers.append("Content-Type", "application/json");
    headers.append("accept", "text/plain");
    headers.append("Ocp-Apim-Subscription-Key", environment.apimSubscriptionKey);

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "packageId": packageId,
            "serviceId": serviceId
        })
    };

    let apiData = await fetch(`${environment.backendProvisioningService}/api/Package/UnmapServiceFromPackage`, options)
        .then((response) => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.log(error));
    return apiData;
};