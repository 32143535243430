import styled from 'styled-components';
import { darken } from 'polished';
// import {
//   colorAccent,
//   colorBackground,
//   colorBackgroundBody,
//   colorBlue,
//   colorBlueHover,
//   colorDarkRed,
//   colorDustyWhite,
//   colorLightText,
//   colorVeryLightRed,
//   colorWhite,
// } from '@/utils/palette';
// import {
//  borderLeft, borderRight, marginLeft, marginRight, right, left, paddingLeft,
// } from '@/utils/directions';
import { PasswordIcon } from 'src/containers/logincomponents/Password';
// import { Button } from '../Button';
import {
 FormContainer, FormGroup, FormGroupIcon, FormFieldButton, FormGroupLabel,
} from 'src/containers/logincomponents/FormElements';

export const AccountWrap = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background: #2a2a31;
`;

export const AccountContent = styled.div`
  margin: auto;
  padding: 10px;
  text-align: left;
`;

export const AccountCard = styled.div`
  padding: 50px 60px;
  max-width: 520px;
  width: 100%;
  background-color: #232329;

  @media screen and (max-width: 520px) {
    padding: 35px 30px;
  }

`;

export const AccountHead = styled.div`
  margin-bottom: 30px;
  ${`border-left`}: 10px;
  ${`border-left`}: 4px solid #70bbfd;
`;

export const AccountTitle = styled.h3`

`;

export const AccountLogo = styled.span`
  font-weight: 700;
`;

export const AccountLogoAccent = styled.span`
  color: #70bbfd;
`;

export const AccountOr = styled.div`
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;
  position: relative;

  &:before, &:after {
    content: "";
    height: 1px;
    width: calc(50% - 90px);
    background: #dddddd;
    position: absolute;
    top: 10px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
`;

export const AccountSocial = styled.div`
  text-align: center;
`;

export const AccountPhoto = styled(AccountWrap)`
  background: url(img/404/bg_404.jpeg) no-repeat center;
  background-size: cover;
  
  ${FormGroupLabel} {
    color: #646777;
  }

  ${AccountCard} {
    background-color: rgba(0, 10, 16, 0.8);
    color: #ffffff;
  }

  ${FormGroupIcon}, ${PasswordIcon}, ${FormFieldButton} {
    background: transparent;
  }

  ${FormGroupIcon}, ${PasswordIcon}, input:not(:last-child) {
    border-right: none;
  }
  
  ${FormGroupIcon}, ${PasswordIcon}, ${FormFieldButton}, input {
    border-color: #ffffff;
  }

  ${FormFieldButton}, input {
    border-left: none
  }

  ${FormFieldButton}.active {

    svg {
      fill: #4ce1b6;
    }
  }

  input {
    color: #ffffff;
    box-shadow: none;
  }

  p {
    color: #ffffff;
  }

  ${AccountTitle} {
    color: #ffffff;
  }
`;

export const AccountButtons = styled.div`
  display: flex;
  width: calc(100% + 10px);
  margin-top: -10px;
  margin-bottom: -20px;
  ${`margin-right`}: 0;
  ${`margin-left`}: -10px;

  a {
    margin-bottom: 20px;
    white-space: nowrap;
    ${`margin-right`}: 0;
    ${`margin-left`}: 10px;
  }

  @media screen and (max-width: 425px) {
    flex-wrap: wrap;
  }
`;

// export const AccountButton = styled(Button)`
//   width: 100%;
//   ${`margin-right`}: 0;
//   display: inline-block;
// `;

/////////////////////////////////// #70bbfd to covert to rgba @opcaity of 0.1 in hover
export const AccountForgotPassword = styled.div`
  position: absolute;
  font-size: 14px;
  line-height: 15px;
  bottom: -25px;
  right: 0;

  a {
    color: #70bbfd;

    &:hover {
      color: #70bbfd;
      text-decoration: none;
    }
  }
`;

export const ForgotFormGroup = styled(FormGroup)`
  margin-bottom: 40px;
`;

/////////////////////////////////// #70bbfd to covert to rgba @opcaity of 0.1 in hover

export const AccountHaveAccount = styled.div`
  text-align: center;
  margin-top: 20px;

  a {
    color: #70bbfd;
    transition: all 0.3s;

    &:hover {
      color: #70bbfd;
      text-decoration: none;
    }
  }
`;


export const LoginForm = styled(FormContainer)`
  
  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
  }
  .alert {
    position: relative;
    padding: 12px 20px;
    margin-bottom: 16px;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 100%
  }
`;

// const AccountSocialButton = styled(Button)`
//   display: inline-block;
//   height: 38px;
//   width: 38px;
//   border-radius: 5px;
//   padding: 9px;
//   transition: all 0.3s;
//   border: none;
//   margin-right: 10px;

//   &:last-child {
//     margin-right: 0;
//   }

//   svg {
//     fill: #ffffff;
//     height: 20px;
//     width: 20px;
//   }
// `;

/////////////////////// convert #4766a4 to rgba with opacity of 0.1 in hover

// // export const AccountSocialButtonFacebook = styled(AccountSocialButton)`
// //   background: #4766a4;

// //   &:hover {
// //     background: #4766a4;
// //   }
// // `;

// // /////////////////////// convert #c74d4d to rgba with opacity of 0.1 in hover
// // export const AccountSocialButtonGoogle = styled(AccountSocialButton)`
// //   background: #c74d4d;

// //   &:hover {
// //     background: #c74d4d;
// //   }
// // `;

// // /////////////////////// convert #c46a3fff to rgba with opacity of 0.1 in hover
// // export const AccountSocialButtonOffice = styled(AccountSocialButton)`
// //   background: #c46a3fff;

// //   &:hover {
// //     background: #c46a3fff;
// //   }
// // `;

// // /////////////////////// convert #4287f5 to rgba with opacity of 0.1 in hover

// // export const AccountSocialButtonFirebase = styled(AccountSocialButton)`
// //   background: #4287f5;
// //   padding: 0 0 0 5px;

// //   &:before {
// //     background-color: #4287f5;
// //   }

// //   &:hover {
// //     background: #4287f5;
// //   }
// // `;

// // export const AccountSocialButtonMetaMask = styled(AccountSocialButton)`
// //   background: #ECECEC;
// //   padding: 5px;
// // `;
// export const AccountSocialButtonAuth0 = styled(AccountSocialButton)`
// `;
