import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Card, Row, Col, CardBody, CardImg } from "reactstrap";
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions } from "src/redux/user/user.selectors";
import profilebgBlue from "src/assets/img/BackgroundImages/ProvisioningPicture_Blue.png";
import profilebgBlack from "src/assets/img/BackgroundImages/ProvisioningPicture_Black.png";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { FormTextFieldSalesPlain } from "src/components/form-elements/custom-form-text-field.driverPage";
import SearchButton from "src/components/buttons/search-button-component";
import BelgiumFlag from "src/assets/img/flags/BelgiumFlag.png";
import FranceFlag from "src/assets/img/flags/FranceFlag.png";
import SwedenFlag from "src/assets/img/flags/SwedenFlag.png";
import ItalyFlag from "src/assets/img/flags/ItalyFlag.png";
import GermanyFlag from "src/assets/img/flags/GermanyFlag.png";
import SpainFlag from "src/assets/img/flags/SpainFlag.png";
import NetherlandsFlag from "src/assets/img/flags/Netherlands.png";
import MarketSelect from 'src/components/form-elements/market-select.component';
import FormSelect from 'src/components/form-elements/custom-form-select.component';
import { GetPNOCodesByMarket } from "src/services/pnoCode-http.service";
import { UpdateMarketMarkDownPrice, GetByGemsId } from "src/services/market-http.service";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import { permission } from 'src/auth/permissions';

const UseStyles = makeStyles((theme) => ({
    dividerStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(6),
        height: '2px',
        marginBottom: '10px'
    },
    gridContainerStyle: {
        marginBottom: -theme.spacing(15),
        position: 'sticky',
        marginLeft: '11px',
    },
    drawer: {
        width: 250,
        flexShrink: 0,
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '43%',
        position: 'absolute',
        height: '20px'
    },
    checkbox: {
        "&.Mui-checked": {
            color: "#1EF1C6"
        },
        "&.Mui-disabled": {
            color: "#BFBFBF"
        },
        color: "#BFBFBF",
    },
    titleStyle: {
        marginLeft: '5px',
        fontSize: '16px',
        marginTop: '20px',
        fontFamily: "LynkcoTypeMedium",
    },
    contentStyle: {
        fontSize: '16px',
        fontFamily: "LynkcoTypeMedium"
    },
    imageStyleFlag: {
        marginTop: '70px',
        transform: 'scale(0.8)',
        alignItems: 'center',
        justifyContent: 'center',
        height: '300px'
    },
    imageStyleVehicle: {
        marginTop: '-13px',
        transform: 'scale(0.9)',
        marginBottom: '30px',
        marginLeft: '-10px',
        height: '520px'
    },
    backgroundStyle: {
        backgroundColor: 'transparent',
        marginTop: '75px',
        marginRight: '34px',
        borderRadius: '1px',
        position: 'relative'
    },
    cardStyle: {
        backgroundImage: 'linear-gradient(135deg, #101010 20%, #1B1B1B)',
        borderBottomLeftRadius: '10px',
        boxShadow: '0 5px 6px #000000',
        height: '720px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        marginRight: '10px',
    },
    iconStyle: {
        marginTop: '155px',
        transform: 'scale(1.3)',
        marginBottom: '30px',
        padding: '10px',
        height: '28vh',
        width: '100%',
        alignContent: 'center'
    },
    searchfieldStyle: {
        width: '300%',
        marginTop: '50px',
        marginLeft: '5px'
    },
    inputWrapStyle: {
        marginLeft: '-3px',
        marginRight: '10px',
        marginTop: '100px'
    },
    vehicleInputWrapStyle: {
        marginLeft: '6px',
        marginRight: '10px',
        marginTop: '100px'
    },
    roleWrapStyle: {
        marginTop: '30px',
        marginBottom: '-60px'
    },
    root: {
        overflow: 'scroll',
        height: '500px',
        marginLeft: '-2px',

        "&::-webkit-scrollbar": {
            height: "1px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 0.1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 0.1%)',
            border: 'none'
        },
    },
    flagStyle: {
        position: 'absolute',
        width: '100%',
        height: '400px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        transition: 'all 0.50s ease',
        "&::-webkit-transition": 'all 0.50s ease',
        "&::-moz-transition:": 'all 0.50s ease'
    },
}));

let queryParams = {}
let markdown = {}

const BMCPricing = (props) => {
    const { userOrg, userMarketIds, userPermissions } = props
    const classes = UseStyles(props);

    const [selectPNO, setSelectPNO] = useState(false);
    const [selectMarket, setSelectMarket] = useState(false);
    const [isMarketSelected, setIsMarkedSelected] = useState(false)
    const [selectedMarket, setSelectedMarket] = useState({ key: "NONE", value: "" })
    const [pnoCode, setPNOCode] = React.useState([]);
    const [pnoCodeDetails, setPNOCodeDetails] = React.useState([]);
    const [pnoCodeDetail, setPNOCodeDetail] = React.useState({});
    const [queryParamsPNOCode, setQueryParamsPNOCode] = React.useState('');
    const [queryParamsPriceRange, setQueryParamsPriceRange] = React.useState('');
    const [queryParamsMarket, setQueryParamsMarket] = React.useState('');
    const [updatedQueryParamsPriceRange, setUpdatedQueryParamsPriceRange] = React.useState('');
    const [placeholder, setPlaceholder] = React.useState(false);
    const [hovered, setHovered] = useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [openDialog, setOpenDialog] = React.useState(false);

    React.useEffect(() => {
        selectPNOCode();
        getPNOCode();
    }, [queryParamsMarket]);

    let marketId = 'NONE'

    const handleChange = (event, markets) => {
        const { name, value } = event.target;
        let orgsMarkets = [];
        marketId = value.split('#')[0];
        let marketName = value.split('#')[1];

        if (marketId == 'NONE') {
            setIsMarkedSelected(false);
            setSelectMarket(false);
        }
        else {
            setIsMarkedSelected(true);
            setSelectMarket(true);
        }

        setSelectedMarket({ key: name, value: value });
        setQueryParamsMarket(marketId);
        handleReset();
    }
    const handleReset = () => {
        setSelectPNO(false);
        setUpdatedQueryParamsPriceRange('');
        setQueryParamsPriceRange('');
        setQueryParamsPNOCode('');
    }

    const selectPNOCode = () => {
        let pnoCodeArray = []
        let pnoCodeDetails = []

        GetPNOCodesByMarket(queryParamsMarket).then(data => {
            Array.isArray(data.pnoCodes) && data.pnoCodes.length > 0 ? data.pnoCodes.map(
                pnoCode => {
                    pnoCodeArray.push({
                        key: pnoCode.pnoCode,
                        value: pnoCode.pnoCode
                    });
                })
                : console.log("Error Occured");
            setPNOCode(pnoCodeArray);

            Array.isArray(data.pnoCodes) && data.pnoCodes.length > 0 ? data.pnoCodes.map(
                pnoCode => {
                    pnoCodeDetails.push({
                        pnoCode: pnoCode.pnoCode,
                        lcColor: pnoCode.lcColor,
                        lcEngineType: pnoCode.lcEngineType,
                        lcModelYear: pnoCode.lcModelYear,
                        obcVersion: pnoCode.obcVersion
                    });
                    setPNOCodeDetails(pnoCodeDetails);
                })
                : console.log("Error Occured");
        });
    }

    queryParams = { ...queryParams, marketId: queryParamsMarket }
    queryParams = { ...queryParams, pnoCode: queryParamsPNOCode }
    queryParams = { ...queryParams, markdownAmount: parseFloat(queryParamsPriceRange) }

    const getPNOCode = (pnoCodeValue) => {
        let filteredValue = pnoCodeDetails.filter(item => item.pnoCode === pnoCodeValue)[0];
        setPNOCodeDetail(filteredValue);
    }

    const handleChangeExtended = (name) => (event) => {
        const value = event.target.value
        console.log(event.target)

        if (name == 'pnoCode') {
            setQueryParamsPNOCode(value);
            getPNOCode(value);
            setSelectPNO(true);
            GetByGemsId(queryParamsMarket).then(data => {
                data = data ?? 0
                markdown = data.settings.buyMyCarCashMarkdown.filter(item => item.pnoCode === value)[0];
                if (markdown) {
                    setUpdatedQueryParamsPriceRange(`${markdown.markdownAmount} EUR`)
                    setPlaceholder(true)
                } else {
                    setUpdatedQueryParamsPriceRange(`0 EUR`)
                    setPlaceholder(false)
                }
            })
        }

        else if (name == 'priceRange') {
            setQueryParamsPriceRange(value);
            setUpdatedQueryParamsPriceRange(value);
        }
    }

    const handleSubmit = () => {
        let marketId = queryParamsMarket
        let pnoCode = queryParamsPNOCode
        let markdownAmount = parseFloat(queryParamsPriceRange)

        if (placeholder === true) {
            setPlaceholder(false);
        } else {
            UpdateMarketMarkDownPrice(marketId, pnoCode, markdownAmount).then(data => {
                if (data.ok === true) {
                    setPlaceholder(true);
                    setOpenDialog(true);
                    setDialogMessage(`Your 'Buy My Car' price configuration is now applied`);
                } else {
                    setOpenDialog(true);
                    setDialogMessage('Please make sure that Market, PNO and BMC value is selected ');
                }
            })
        }
    }

    const handleDialogOk = () => {
        setOpenDialog(false);

    }

    const matchesPermission = userPermissions.includes(permission.Remarketing_Administrator);

    return (
        <div className={classes.backgroundStyle}>
            <VehiclePageDialog
                dialogue='true'
                open={openDialog}
                message={dialogMessage}
                handleDialogOk={handleDialogOk}
            />

            <Col lg="12" md="12" >
                <Row style={{ marginBottom: '20px', marginTop: '3px' }}>
                    <Col lg="11" md="11">
                    </Col>
                    <Col lg="1" md="1">
                        {
                            matchesPermission &&
                            <div style={{ marginLeft: '17px' }}>
                                <SearchButton
                                    placeholder={placeholder ? 'EDIT' : 'CONFIRM'}
                                    handleSearch={handleSubmit}
                                />
                            </div>
                        }

                    </Col>
                </Row>
            </Col>

            <Row style={{ marginRight: '-48px' }}>
                <Col lg="4" md="12" >
                    <Card className={classes.cardStyle} >
                        <CardBody>
                            <Row>
                                <Col lg="12" md="12" >
                                    <Col lg="12" md="12" >
                                        <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                            Select Market
                                        </Typography>
                                    </Col>

                                    <div style={{ marginLeft: '10px', width: '60%', marginTop: '5px', marginBottom: '-5px' }}>
                                        <MarketSelect
                                            orgId={userOrg.id}
                                            value={selectedMarket.value}
                                            userMarketIds={userMarketIds}
                                            handleChange={handleChange}
                                            isVariant='true'
                                        />
                                    </div>

                                    <Divider className={classes.dividerStyle} orientation='horizontal' />
                                    <Row >
                                        {selectMarket &&
                                            <div
                                                className={classes.flagStyle}
                                                onMouseEnter={() => setHovered(true)}
                                                onMouseLeave={() => setHovered(false)}
                                                onClick={() => props.onClick && props.onClick()}
                                                style={{
                                                    ...{ opacity: hovered ? '0.5' : '1' }
                                                }}
                                            >
                                                <CardImg
                                                    alt="Card image cap"
                                                    src={
                                                        queryParamsMarket == 'BE' ? BelgiumFlag :
                                                            queryParamsMarket == 'SE' ? SwedenFlag :
                                                                queryParamsMarket == 'DE' ? GermanyFlag :
                                                                    queryParamsMarket == 'ES' ? SpainFlag :
                                                                        queryParamsMarket == 'NL' ? NetherlandsFlag :
                                                                            queryParamsMarket == 'FR' ? FranceFlag :
                                                                                queryParamsMarket == 'IT' ? ItalyFlag : <></>
                                                    }
                                                    className={classes.imageStyleFlag}
                                                />
                                            </div>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg="4" md="12" >
                    <Card className={classes.cardStyle}  >
                        <CardBody>
                            <Row>
                                <Col lg="12" md="12" >
                                    <Col lg="12" md="12" >
                                        <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                            Select PNO
                                        </Typography>
                                    </Col>

                                    <div style={{ marginLeft: '20px', width: '61%', marginTop: '20px' }}>

                                        <FormSelect
                                            label='PNO'
                                            name='pnoCode'
                                            value={queryParamsPNOCode}
                                            menuObjectArray={pnoCode}
                                            onChange={handleChangeExtended("pnoCode")}
                                        />
                                    </div>

                                    <Divider className={classes.dividerStyle} orientation='horizontal' />

                                    <Row>
                                        {selectPNO &&
                                            <>
                                                {
                                                    pnoCodeDetail !== undefined &&
                                                    <div style={{ marginTop: '10px', marginLeft: '5px' }}>
                                                        <Row>
                                                            <Col lg="7" md="7" >
                                                                <CardImg
                                                                    alt="Card image cap"
                                                                    src={pnoCodeDetail.lcColor == 'Blue' ? profilebgBlue : profilebgBlack}
                                                                    className={classes.imageStyleVehicle}
                                                                />
                                                            </Col>

                                                            <Col lg="5" md="5" >
                                                                <div style={{ marginLeft: '15px', marginTop: '-5px' }} >
                                                                    <div style={{ marginLeft: '6px', marginRight: '10px' }}>
                                                                        <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                            <Grid item xs={12} md={12} lg={12} style={{ marginTop: '26px' }}>
                                                                                <Typography className={classes.contentStyle}><span >Model</span></Typography>
                                                                                <div style={{ marginLeft: '-13px', fontSize: '20px', marginTop: '-8px' }}>
                                                                                    <FormTextFieldSalesPlain value={'Lynk & Co 01'} updateFontSize='true' disabled />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>

                                                                    <div className={classes.vehicleInputWrapStyle} >
                                                                        <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                            <Grid item xs={12} md={12} lg={12} style={{ marginTop: '50px' }}>
                                                                                <Typography className={classes.contentStyle}><span >Model Year</span></Typography>
                                                                                <div style={{ marginLeft: '-13px', marginTop: '-8px' }}>
                                                                                    <FormTextFieldSalesPlain value={pnoCodeDetail.lcModelYear} updateFontSize='true' disabled />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>

                                                                    <div className={classes.vehicleInputWrapStyle} >
                                                                        <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                            <Grid item xs={12} md={12} lg={12} style={{ marginTop: '50px' }}>
                                                                                <Typography className={classes.contentStyle}><span >Color</span></Typography>
                                                                                <div style={{ marginLeft: '-13px', marginTop: '-8px' }}>
                                                                                    <FormTextFieldSalesPlain value={pnoCodeDetail.lcColor === 'Blue' ? 'Blue' : 'Black'} updateFontSize='true' disabled />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                    <div className={classes.vehicleInputWrapStyle} >
                                                                        <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                            <Grid item xs={12} md={12} lg={12} style={{ marginTop: '50px' }}>
                                                                                <Typography className={classes.contentStyle}><span >Engine Type</span></Typography>
                                                                                <div style={{ marginLeft: '-13px', marginTop: '-8px' }}>
                                                                                    <FormTextFieldSalesPlain value={pnoCodeDetail.lcEngineType} updateFontSize='true' disabled />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>

                                                                    <div className={classes.vehicleInputWrapStyle} >
                                                                        <Grid container className={classes.gridContainerStyle} spacing={1}>
                                                                            <Grid item xs={12} md={12} lg={12} style={{ marginTop: '50px' }}>
                                                                                <Typography className={classes.contentStyle}>OBC Version</Typography>
                                                                                <div style={{ marginLeft: '-13px', marginTop: '-8px' }}>
                                                                                    <FormTextFieldSalesPlain value={pnoCodeDetail.obcVersion} updateFontSize='true' disabled />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }
                                            </>}
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg="4" md="12" >
                    <Card id="CardBodyCardAllowance8" >
                        <CardBody>
                            <Row>
                                <Col lg="12" md="12" >
                                    <Col lg="12" md="12" >
                                        <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                            Select BMC Discount or Markup
                                        </Typography>
                                    </Col>

                                    <div style={{ marginLeft: '20px', width: '61%', marginTop: '20px' }}>
                                        <FormSelect
                                            label='Discount/Markup'
                                            name='priceRange'
                                            disabled={placeholder ? true : false}
                                            value={queryParamsPriceRange}
                                            menuObjectArray={[
                                                { 'key': '-1500 EUR', 'value': '-1500 EUR' },
                                                { 'key': '-1250 EUR', 'value': '-1250 EUR' },
                                                { 'key': '-1000 EUR', 'value': '-1000 EUR' },
                                                { 'key': '-750 EUR', 'value': '-750 EUR' },
                                                { 'key': '-500 EUR', 'value': '-500 EUR' },
                                                { 'key': '-250 EUR', 'value': '-250 EUR' },
                                                { 'key': '0 EUR', 'value': '0 EUR' },
                                                { 'key': '+250 EUR', 'value': '+250 EUR' },
                                                { 'key': '+500 EUR', 'value': '+500 EUR' },
                                                { 'key': '+750 EUR', 'value': '+750 EUR' },
                                                { 'key': '+1000 EUR', 'value': '+1000 EUR' },
                                                { 'key': '+1250 EUR', 'value': '+1250 EUR' },
                                                { 'key': '+1500 EUR', 'value': '+1500 EUR' }
                                            ]}
                                            onChange={handleChangeExtended("priceRange")}
                                        />
                                    </div>

                                    <Divider className={classes.dividerStyle} orientation='horizontal' />

                                    <Row>
                                        {
                                            <div style={{ position: 'absolute', width: '100%', height: '400px', alignItems: 'center', display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                                                <Row>
                                                    <Col lg="12" md="12" >
                                                        <Typography style={{ textAlign: 'center', fontSize: '65px', fontFamily: "LynkcoTypeRegular", cursor: 'pointer', color: 'rgb(255, 102, 0)', alignItems: 'center', justifyContent: 'center', }}>
                                                            {updatedQueryParamsPriceRange !== undefined ? updatedQueryParamsPriceRange : queryParamsPriceRange}
                                                        </Typography>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state)
});

export default connect(mapStateToProps, null)(BMCPricing);