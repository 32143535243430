import React, { useState, useEffect } from 'react';
import { Card, CardBody } from "reactstrap";
import { connect } from 'react-redux';
import { selectUserOrganization, selectUserMarketIds } from 'src/redux/user/user.selectors';
import ToggleComponent from 'src/containers/advancedSearchComponents/ToggleComponent';
import { GetVehicleModelYears } from 'src/services/vehicle-pages-http.service';

const ModelYearCard = ({ setQueryParamsModelYear, setDataLoaded, setModelYearToggle }) => {

    const [queryParamsModelYear, setQueryParamsModelYearSelect] = useState([]);
    const [selectedModelYear, setSelectedModelYearSelect] = useState([]);
    const [sliderUser, setSliderUser] = useState({
        editMode: false
    });

    useEffect(() => {
        setModelYears();
        preselectModelYear();
        setSliderUser({
            editMode: true
        })
    }, [sliderUser.editMode]);

    const setModelYears = () => {

        GetVehicleModelYears().then(data => {
            if (data.ok == true) {
                setQueryParamsModelYearSelect(data.data);
            }
        });
    };

    const preselectModelYear = () => {
        let modelYearArray = [];
        setSelectedModelYearSelect([]);

        if (sliderUser.editMode === true) {
            GetVehicleModelYears()
                .then(data => {
                    Array.isArray(data.data) && data.data.length > 0 ? data.data.map(modelYear => {

                        modelYearArray.push(modelYear.key);
                    })
                        : console.log("Error Occured");

                    setSelectedModelYearSelect(modelYearArray);
                });
        }
        else {
            setSelectedModelYearSelect([]);
        }
    };

    const handleChange = (name, isDisabled = true) => (event) => {

        let updatedSelected = []

        if (isDisabled) {
            updatedSelected = selectedModelYear.filter((item) => item !== name);
            setQueryParamsModelYear(updatedSelected);
            setModelYearToggle(true);
            setDataLoaded(false);
        }
        if (!isDisabled) {
            updatedSelected = [...selectedModelYear, name];
            setQueryParamsModelYear(updatedSelected);
            setModelYearToggle(true);
            setDataLoaded(false);
        }
        setSelectedModelYearSelect(updatedSelected);
    };

    return (
        <Card id="CardBodyCardAllowance6" style={{ marginBottom: '40px' }}>
            <CardBody >
                <span>
                    <span style={{ fontSize: "16px", fontFamily: 'LynkcoTypeMedium', color: '#BFBFBF' }}>MODEL YEAR</span> <br /><br />
                </span>

                <table style={{ width: '100%', color: 'white', marginTop: '-21px' }}>
                    {queryParamsModelYear.map((item) => (
                        <tr>
                            <td style={{ float: 'left', fontSize: '15px', fontFamily: 'LynkcoTypeMedium', color: 'darkgray', marginTop: '25px' }}>{item?.value}<br /></td>
                            <td style={{ float: 'right', marginTop: '13px' }}>
                                <ToggleComponent checked={selectedModelYear.indexOf(item?.key) > -1} onChange={handleChange(item?.key, selectedModelYear.indexOf(item?.key) > -1)} />
                            </td>
                        </tr>
                    ))}
                </table>
            </CardBody>
        </Card>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state)
});

export default connect(mapStateToProps, null)(ModelYearCard);