import React from 'react'
import FormSelect from 'src/components/form-elements/form-select.component'
import { makeStyles } from '@material-ui/core/styles';
import { GetPackages } from "src/services/provisioning-package.service";

const useSelectStyles = makeStyles((theme) => ({
    SelectControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: "100%",
    },
}));

const CaseTypeSelect = ({ value, onChange, variant, updatePackage }) => {

    const [roles, setRoles] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const classes = useSelectStyles();

    React.useEffect(() => {
        initializeRoles();
    }, [roles.length, updatePackage]);

    const initializeRoles = () => {
        let roleArray = [];
        setRoles([
            { key: 1, value:'Information Call' },
            { key: 2, value:'Breakdown Call' },
            { key: 3, value:'Stolen Vehicle' }
        ])
        // GetPackages()
        //     .then(data => {
        //         if (data?.packages && Array.isArray(data?.packages)) {
        //             data?.packages.map(role => {
        //                 roleArray.push({ key: role.id, value: role.name });
        //             });
        //         }
        //         setRoles(roleArray);
        //     });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormSelect
            className={classes.SelectControl}
            label="Case Type Select"
            id="role"
            name="roleId"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            value={value}
            onChange={onChange}
            menuObjectArray={roles}
            varianttype = 'true'
            variant={variant ? variant : false}
       />
    )
}

export default CaseTypeSelect