import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TopbarProfile from 'src/containers/LayoutNew/components/topbar/TopbarProfile';
import TopbarSidebarButton from 'src/containers/LayoutNew/components/topbar/TopbarSidebarButton';
// import TopbarLanguage from 'src/containers/LayoutNew/components/topbar/TopbarLanguage';
import {
  TopbarContainer,
  TopbarLeft,
  TopbarLogo,
  TopbarRight,
  TopbarRightOver,
  TopbarSearchWrap,
  TopbarLogoContainer
} from 'src/containers/LayoutNew/components/topbar/BasicTopbarComponents';

import TopbarMetaMask from 'src/containers/LayoutNew/components/topbar/TopbarMetaMask';

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility, userName, adUserDetails, setUserIsLoggedIn
}) => {
  const wallet = useSelector(state => state.wallet);
  const [toggleSidebarButton, setToggleSidebarButton] = useState(false);

  return (
    <TopbarContainer>
      <TopbarLogoContainer>
        <TopbarLogo to="/vehicles/L&CAssets" />

        <TopbarRight>
          <TopbarSearchWrap>
            {/* <TopbarSearch /> */}
          </TopbarSearchWrap>
          <TopbarRightOver>
            <TopbarProfile adUserDetails={adUserDetails} userName={userName} />
            {/* {wallet && <TopbarMetaMask />} */}
            {/* <TopbarHelpLogo /> */}
            {/* <TopbarLanguage /> */}
            {wallet && <TopbarMetaMask />}
          </TopbarRightOver>
        </TopbarRight>
      </TopbarLogoContainer>
      <TopbarLeft>
        <TopbarSidebarButton
          setUserIsLoggedIn={setUserIsLoggedIn}
          onClickMobile={changeMobileSidebarVisibility}
          onClickDesktop={changeSidebarVisibility}
          setToggleSidebarButton={setToggleSidebarButton}
            toggleSidebarButton={toggleSidebarButton}
        />
      </TopbarLeft>
    </TopbarContainer>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
