export const permission = {
    Remarketing_Reader: "Remarketing_Reader",
    Remarketing_Administrator: "Remarketing_Administrator",
    Administration_Reader: "Administration_Reader",
    Administration_Administrator: "Administration_Administrator",
    Dashboard_Administrator: "Dashboard_Administrator",
    Dashboard_Reader: "Dashboard_Reader",
    Connectivity_Administrator: "Connectivity_Administrator",
    Connectivity_Reader: "Connectivity_Reader",
    Location_Reader: "Location_Reader",
    Location_Administrator: "Location_Administrator",
    ManageAsset_Reader: "ManageAsset_Reader",
    ManageAsset_Administrator: "ManageAsset_Administrator",
    Report_Reader: "Report_Reader",
    Notification_Administrator: "Notification_Administrator",
    Todo_Reader: "Todo_Reader",
    Todo_Administrator: "Todo_Administrator",
    Vehicle_Reader: "Vehicle_Reader",
    Vehicle_Administrator: "Vehicle_Administrator",
    VSP_Reader: "VSP_Reader",
    VSP_Administrator: "VSP_Administrator"
}