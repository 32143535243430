import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ChatCard from './components/ChatCard';

const Chat = ({ vin, comments, getComments, dataLoaded, closeVehicleDetail, showSVTMap, variant }) => {

  return (
    <Container>
      <Row>
        <ChatCard
          vin={vin}
          comments={comments}
          getComments={getComments}
          dataLoaded={dataLoaded}
          closeVehicleDetail={closeVehicleDetail}
          showSVTMap={showSVTMap}
          variant={variant}
        />
      </Row>
    </Container>
  );
};

export default Chat;