import React from "react";
import { Form, FormGroup, Label, Button, ModalBody,ModalFooter } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { connect } from 'react-redux';
import { selectUserGraphData } from 'src/redux/user/user.selectors';
import { SaveTodo } from 'src/services/todo-http.service';
import { AddTodoData, TransformTodoData } from 'src/utils/TodoFormatter';
import { fetchTodosSuccess } from 'src/redux/todos/Action';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#1ef1c6',
    color: '#000',
    '&:hover': {
      backgroundColor: '#339586',
      color: '#FFF'
    }
  }
}));

const AddContact = ({ onButtonClick, user }) => {
  const classes = useStyles();

  let title, desc;
  const todoData = useSelector((state) => state.maintodoReducer.todos);

  const id = useSelector((state) => state.maintodoReducer.todos.length + 1);
  const dispatch = useDispatch();
  let userId = user.id

  const AddTodo = (userId, title, desc, todoData) => {
    SaveTodo(userId, AddTodoData(title, desc, todoData))
      .then(data => {
        if (data && data.tableData && Array.isArray(data.tableData)) {
          dispatch(fetchTodosSuccess(TransformTodoData(data.tableData)));
        }
      })
  };


  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          AddTodo(userId, title.value, desc.value, todoData)
          title.value = "";
          desc.value = "";
        }}
      >
        <ModalBody >
          <FormGroup>
            <Label for="title">Title</Label>
            <input
              className="form-control"
              type="text"
              name="title"
              id="title"
              ref={(node) => (title = node)}
              required
              style={{ backgroundColor: 'rgb(19 19 19 / 93%)', border: 'solid 0.3px #1ef1c6 ', color: '#fff' }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="desc">Description</Label>
            <textarea
              className="form-control"
              name="desc"
              rows="5"
              id="desc"
              ref={(node) => (desc = node)}
              required
              style={{ backgroundColor: 'rgb(19 19 19 / 93%)', border: 'solid 0.3px #1ef1c6', color: '#fff' }}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onButtonClick} className={classes.button} type="submit">
            Create
          </Button>
        </ModalFooter>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  user: selectUserGraphData(state),
});

export default connect(mapStateToProps, null)(AddContact)