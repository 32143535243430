import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import VSPMainPage from 'src/pages/vsp/VSPMainPage';
import { Typography } from '@material-ui/core';
import SearchBar from 'src/components/buttons/search-icon-button.component';
import DefaultPage from 'src/pages/backgrounds/default-page';
import { permission } from 'src/auth/permissions';
import { selectUserPermissions, selectUserToken } from 'src/redux/user/user.selectors';
import { connect } from 'react-redux';

function TabPanel(props) {
  const { children, matchesPermission, value, inputVIN, searchInputVIN, setAddressInput, index, displayDialogue, setDisplayDialogue, setSearchInput, displayErrorMessage, vin, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {
        matchesPermission ?
          <>
            {value === index && value === 0 && (
              <VSPMainPage inputVIN={inputVIN?.trim()} searchInputVIN={searchInputVIN} setAddressInput={setAddressInput} displayDialogue={displayDialogue} setDisplayDialogue={setDisplayDialogue} value={value} displayErrorMessage={displayErrorMessage} setSearchInput={setSearchInput} />
            )}
            {value === index && value === 1 && (
              <DefaultPage backgroundtype='true' />
            )}
            {value === index && value === 2 && (
              <DefaultPage backgroundtype='true' />
            )}
            {value === index && value === 3 && (
              <DefaultPage backgroundtype='true' />
            )}
          </>
          :
          <>
            {value === index && value === 0 && (
              <DefaultPage />
            )}
            {value === index && value === 1 && (
              <DefaultPage backgroundtype='true' />
            )}
            {value === index && value === 2 && (
              <DefaultPage backgroundtype='true' />
            )}
            {value === index && value === 3 && (
              <DefaultPage backgroundtype='true' />
            )}
          </>
      }

    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  vehiclePosition: PropTypes.object,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
    margin: -theme.spacing(2.9),
    height: '55px',
    marginLeft: '115px'
  },
  panel: {
    marginLeft: '35px',
    position: "fixed",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundImage: 'linear-gradient(60deg, rgba(13,13,13,1), rgba(37,37,37,1) 99%)',
    padding: "55px",
  },
  tabBar: {
    marginRight: theme.spacing(-22),
    marginLeft: theme.spacing(22.5),
    textTransform: 'initial',
    fontSize: '15px',

  },
  appBar: {
    backgroundColor: "#131313",
    position: 'fixed',
    marginTop: '-100px',
    marginLeft: '-180px',
    zIndex: '20',
    height: '48px',
    boxShadow: '0 5px 6px #000000'
  }
}));

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#1EF1C6',
    height: '3px',
    maxWidth: '110px',
    marginLeft: '25px'
  }

})(Tabs);

const VSPDetailsTabs = (props) => {
  const { vin, userPermissions, userToken } = props
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [searchInput, setSearchInput] = useState(false);
  const [addressInput, setAddressInput] = useState('');
  const [displayDialogue, setDisplayDialogue] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

  const values = [permission.VSP_Administrator, permission.VSP_Reader];
  const matchesPermission = values.some(permission => userPermissions.includes(permission));

  let newVIN = localStorage.getItem('vin')
  let vspVIN = newVIN?.split('=')[1]

  React.useEffect(() => {
    setAddressInput(vspVIN);
    if (vspVIN !== '') {
      setSearchInput(!searchInput);
    }
  }, [newVIN])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeVIN = () => {
    setSearchInput(!searchInput);
    if (vspVIN == 0 || addressInput == 0) {
      setSearchInput(false);
      setDisplayErrorMessage(!displayErrorMessage);
      setAddressInput('');
      localStorage.removeItem('vin');
    }
  };

  const handleSearchInput = (event) => {
    setAddressInput(event.target.value)
    console.log(event.target.value)
    console.log(event.target.value.length)
    if (event.target.value.length === 0) {
      setSearchInput(false);
      setDisplayDialogue(false);
    }
    if (addressInput > 0) {
      setSearchInput(false);
    }
  };

  const textFieldKeypress = (e) => {
    if (e.key === 'Enter') {
      setSearchInput(!searchInput);
      handleChangeVIN();
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Typography style={{ textAlign: 'center', fontSize: '15px', marginTop: '15px', marginLeft: '35px', fontFamily: "LynkcoTypeRegular", cursor: 'pointer', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', letterSpacing: '2px' }} variant="h4" id="tableTitle" component="div">
          VSP
        </Typography>
        <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example" >
          <Tab label="Summary" {...a11yProps(0)} className={classes.tabBar} />
          <Tab label="Map View" {...a11yProps(1)} className={classes.tabBar} />
          <Tab label="Vehicle Details" {...a11yProps(2)} className={classes.tabBar} />
          <Tab label="Driver Details" {...a11yProps(3)} className={classes.tabBar} style={{ marginRight: '100px' }} />
        </StyledTabs>
        <div lassName={classes.tabBar} style={{ width: '60vw', marginTop: '-53px', marginLeft: '81.6vw' }}>
          {value === 0 && matchesPermission &&
            <SearchBar
              placeholder='VIN'
              handleSearch={handleChangeVIN}
              handleSearchInput={handleSearchInput}
              onKeyPress={textFieldKeypress}
              value={vspVIN ? vspVIN : addressInput}
              priceBucketActiveTab='true'
            />
          }
        </div>
      </AppBar>
      <TabPanel
        value={value}
        inputVIN={addressInput}
        setAddressInput={setAddressInput}
        searchInputVIN={searchInput}
        displayDialogue={displayDialogue}
        setDisplayDialogue={setDisplayDialogue}
        displayErrorMessage={displayErrorMessage}
        setSearchInput={setDisplayErrorMessage}
        index={0}
        className={classes.panel}
        userPermissions={userPermissions}
        userToken={userToken}
        matchesPermission={matchesPermission}
      >
        Summary
      </TabPanel>
      <TabPanel
        value={value}
        vin={vin}
        index={1}
        className={classes.panel}
        userPermissions={userPermissions}
        userToken={userToken}
        matchesPermission={matchesPermission}
      >
        Work Orders
      </TabPanel>
      <TabPanel
        value={value}
        vin={vin}
        index={2}
        className={classes.panel}
        userPermissions={userPermissions}
        userToken={userToken}
        matchesPermission={matchesPermission}
      >
        Vehicle Details
      </TabPanel>
      <TabPanel
        value={value}
        vin={vin}
        index={3}
        className={classes.panel}
        userPermissions={userPermissions}
        userToken={userToken}
        matchesPermission={matchesPermission}
      >
        Driver Details
      </TabPanel>
    </div>
  );
};

const mapStateToProps = state => ({
  userPermissions: selectUserPermissions(state),
  userToken: selectUserToken(state)
});

export default connect(mapStateToProps, null)(VSPDetailsTabs);